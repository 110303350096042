import React, { useState, useEffect } from "react";
import plus from "../../../src/assets/Images/plus.svg";
import { Breadcrumb } from "antd";
import {
  DoubleRightOutlined,
  DeleteOutlined,
  EditOutlined,
  StopOutlined,
} from "@ant-design/icons";
import Navbar from "../../components/Navbar/Navbar";
import { routes } from "../../Routes";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  deleteShippingCost,
  getShippingCosts,
} from "../../../src/API  Functions/ShippingCostFunctions";
import { Link } from "react-router-dom";
import i18next from "i18next";
import {
  getPosSessionHistory,
  getPosSessionList,
} from "../../API  Functions/SessionHistoryFunctions";
import CloseSessionModal from "../../modals/CloseSession_Modal";
import { Drawer } from "antd";
import ShimmerLoader from "../../loading";
import SelectBox from "../../components/CustomWidgets/SelectBox";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

const SessionHistory = () => {
  const { t, i18n } = useTranslation();
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);

  const [showComponent, setShowComponent] = useState(false);
  const [storeuser, setstoreuser] = useState(null);

  const [popup, setpopup] = useState(false);
  const [blockdata, setblockdata] = useState([]);
  const [status, setstatus] = useState(1);
  const [successMessage, setSuccessMessage] = useState("");
  const [showCloseSessionModal, setShowCloseSessionModal] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [visible, setVisible] = useState(false);
  const [sessionHistoryData, setSessionHistoryData] = useState([]);
  const [drawerData, setDrawerData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRange, setSelectedRange] = useState("Current Week"); // New state to track selected range
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [data, setData] = useState([]);

  const showDrawer = (historyData) => {
    setDrawerData(historyData);
    setVisible(true);
    getSessionHistory(historyData.user_id);
    setSelectedUserId(historyData.user_id);
  };

  const getSessionHistory = (userId, startDate = null, endDate = null) => {
    const payload = {
      user_id: userId,
      limit: 10,
      offset: 0,
      filter_type: selectedRange,
      start_date: startDate, // Optional for custom range
      end_date: endDate, // Optional for custom range
    };
    getPosSessionHistory(payload)
      .then((res) => {
        if (res?.result?.length > 0) {
          setSessionHistoryData(res.result);
          setLoading(false);
        } else {
          console.error("No session history found");
        }
      })
      .catch((error) => {
        console.error("Error fetching session history:", error);
      });
  };
  const onClose = () => {
    setVisible(false);
  };

  const getSessionList = () => {
    getPosSessionList(user_id)
      .then((res) => {
        // console.log("SSSSSSSSS", res);

        if (res?.result.length > 0) {
          const AllList = res.result;

          if (AllList) {
            setData(AllList);
          } else {
            console.error("No users found in the response.");
          }
        } else {
          console.error("Response was not successful:", res);
        }
      })
      .catch((error) => {
        console.error("Error fetching store users:", error);
      });
  };

  // console.log("DATAAA A", data);

  useEffect(() => {
    getSessionList();
  }, []);

  const handleCloseSessionClick = (sessionId) => {
    // console.log("LLLLLLL", sessionId);

    setSelectedSessionId(sessionId);
    setShowCloseSessionModal(true);
  };
  // console.log("showCloseSessionModal", showCloseSessionModal);

  const handleDateRangeChange = (range) => {
    let CurrentSelection = range.target.value;

    const currentDate = new Date(); // Use native Date
    let startDate, endDate;

    if (CurrentSelection === "current_week") {
      setSelectedRange("current_week");
    } else if (CurrentSelection === "last_week") {
      setSelectedRange("last_week");
    } else if (CurrentSelection === "custom") {
      setSelectedRange("custom");
    }

    getSessionHistory(selectedUserId, startDate, endDate);
  };

  const handleRangePickerChange = (dates, dateStrings) => {
    if (dates && dates.length === 2) {
      const startDate = dateStrings[0];
      const endDate = dateStrings[1];

      setSelectedRange("custom"); // Set custom range
      getSessionHistory(selectedUserId, startDate, endDate); // Call API with custom range
    }
  };

  const renderSessionBox = (session) => {
    return (
      <div
        key={session.session_id}
        className="bg-[#F6F6F6] p-4 mb-4 rounded-lg"
      >
        <div className="text-lg justify-center text-center font-semibold">
          {session.session_name}
        </div>
        <div className="grid grid-cols-1 gap-2 mt-2">
          <div className="flex flex-row justify-between">
            <p className="font-normal text-sm">{t("Opening Date")}</p>
            <p className="text-sm">{session.opening_date || "--"}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="font-normal text-sm">{t("Closing Date")}</p>
            <p className="text-sm">{session.closing_date || "--"}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="font-normal text-sm">{t("Status")}</p>
            <p className="text-sm">{session.status || "--"}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="font-normal text-sm">{t("Number of Orders")}</p>
            <p className="text-sm">{session.order_count || "0"}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="font-normal text-sm">
              {t("Number of Refunded Orders")}
            </p>
            <p className="text-sm">{session.refunded_order || "0"}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="font-semibold text-sm">{t("Total Sales")}</p>
            <p className="text-sm">{`EGP ${session.total_sales || "0"}`}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="font-normal text-sm">{t("Refunded Amount")}</p>
            <p className="text-sm">{`EGP ${session.refunded_amt || "0"}`}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="font-normal text-sm">{t("Bank Collection")}</p>
            <p className="text-sm">{`EGP ${session.bank_collection || "0"}`}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="font-semibold text-sm">{t("Opening Cash")}</p>
            <p className="text-sm">{`EGP ${session.opening_cash || "0"}`}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="font-normal text-sm">{t("Cash Collection")}</p>
            <p className="text-sm">{`EGP ${session.cash_collection || "0"}`}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="font-normal text-sm">{t("Cash In")}</p>
            <p className="text-sm">{`EGP ${session.cash_in || "0"}`}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="font-normal text-sm">{t("Cash Out")}</p>
            <p className="text-sm">{`EGP ${session.cash_out || "0"}`}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="font-semibold text-sm">{t("Available Cash")}</p>
            <p className="text-sm">{`EGP ${session.cash_available || "0"}`}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex">
        <div className="flex flex-col bg-[#F6F7F9] min-h-[93vh]  flex-1 mt-14">
          <Navbar heading="Session History" />
          <div className="mr-4 ml-3 font-extrabold">
            <Breadcrumb
              separator={<DoubleRightOutlined />}
              items={[
                {
                  title: t("POS"),
                  key: "pos",
                  children: (
                    <Link to={routes.sessionHistory}>
                      {t("sessionHistory")}
                    </Link>
                  ),
                },
                { title: t("Session History") },
              ]}
              className="relative mt-3"
            />
            <>
              <div className="flex justify-between items-center"></div>
              <div className="grid grid-cols-1 gap-x-8 mt-1 mb-4">
                {data.map((val, i) => (
                  <div
                    key={i}
                    className="w-[345px] md:w-full h-30 shadow-sm hover:shadow-lg rounded-xl bg-[#FFFFFF] mt-4 pb-2"
                  >
                    {/* {console.log("val", val)} */}
                    {/* Check if last_sessions exist */}
                    {val?.last_sessions?.length > 0 ? (
                      <div className="flex flex-col justify-between ms-4 me-4 mt-4">
                        <div className="flex flex-row justify-between font-medium text-lg text-[#272728]">
                          <span className="font-semibold">
                            {val.user_name} (S-{val.session_count}, O-
                            {val.order_count})
                          </span>
                          <span
                            className={`font-semibold justify-end ${
                              val?.last_sessions[0]?.session_state === "opened"
                                ? "text-yellow-500"
                                : val?.last_sessions[0]?.session_state ===
                                  "closed"
                                ? "text-green-500"
                                : "text-gray-500"
                            }`}
                          >
                            {t(
                              val?.last_sessions[0]?.session_state === "opened"
                                ? "Opened"
                                : "Closed"
                            )}
                          </span>
                        </div>

                        {/* Boxes for displaying additional data */}
                        {val?.last_sessions[0]?.session_state === "opened" && (
                          <>
                            <div className="grid grid-cols-5 gap-4 mt-4 mx-4">
                              <div className="bg-[#F6F6F6] p-2 rounded-lg text-center">
                                <p className="font-normal text-sm">
                                  {t("Opening Date")}
                                </p>
                                <p className="text-sm">
                                  {val.last_sessions[0]?.start_at || "N/A"}
                                </p>
                              </div>
                              <div className="bg-[#F6F6F6] p-2 rounded-lg text-center">
                                <p className="font-normal text-sm">
                                  {t("Current Orders")}
                                </p>
                                <p className="text-sm">
                                  {val.last_sessions[0]?.order_count}
                                </p>
                              </div>
                              <div className="bg-[#F6F6F6] p-2 rounded-lg text-center">
                                <p className="font-normal text-sm">
                                  {t("Current Sales")}
                                </p>
                                <p className="text-sm">
                                  EGP{" "}
                                  {val.last_sessions[0]?.total_sales || "0.00"}
                                </p>
                              </div>

                              <div className="bg-[#F6F6F6] p-2 rounded-lg text-center">
                                <p className="font-normal text-sm">
                                  {t("Last Session Orders")}
                                </p>
                                <p className="text-sm">
                                  {val.last_sessions[1]?.order_count || "0.00"}
                                </p>
                              </div>
                              <div className="bg-[#F6F6F6] p-2 rounded-lg text-center">
                                <p className="font-normal text-sm">
                                  {t("Last Order Sales")}
                                </p>
                                <p className="text-sm">
                                  EGP{" "}
                                  {val.last_sessions[1]?.total_sales || "0.00"}
                                </p>
                              </div>
                            </div>
                          </>
                        )}

                        {/* Additional data for closed sessions */}
                        {val?.last_sessions[0]?.session_state === "closed" && (
                          <div className="grid grid-cols-5 gap-4 mt-4 mx-4">
                            <div className="bg-[#F6F6F6] p-2 rounded-lg text-center">
                              <p className="font-normal text-sm">
                                {t("Last Closing Date")}
                              </p>
                              <p className="text-sm">
                                {val.last_sessions[0]?.stop_at || "N/A"}
                              </p>
                            </div>
                            <div className="bg-[#F6F6F6] p-2 rounded-lg text-center">
                              <p className="font-normal text-sm">
                                {t("Last Closing Cash")}
                              </p>
                              <p className="text-sm">
                                EGP{" "}
                                {val.last_sessions[0]?.closing_balance ||
                                  "0.00"}
                              </p>
                            </div>
                            <div className="bg-[#F6F6F6] p-2 rounded-lg text-center">
                              <p className="font-normal text-sm">
                                {t("Last Session Orders")}
                              </p>
                              <p className="text-sm">
                                {val.last_sessions[0]?.order_count || "0.00"}
                              </p>
                            </div>
                            <div className="bg-[#F6F6F6] p-2 rounded-lg text-center">
                              <p className="font-normal text-sm">
                                {t("Last Session Sales")}
                              </p>
                              <p className="text-sm">
                                EGP{" "}
                                {val.last_sessions[0]?.total_sales || "0.00"}
                              </p>
                            </div>
                            <div className="bg-[#F6F6F6] p-2 rounded-lg text-center">
                              <p className="font-normal text-sm">
                                {t("Last Session Profit")}
                              </p>
                              <p className="text-sm">
                                EGP{" "}
                                {val.last_sessions[0]?.session_profit || "0.00"}
                              </p>
                            </div>
                          </div>
                        )}

                        <div className="flex flex-row justify-end mt-4 ms-4 me-4 gap-2">
                          <button
                            onClick={() => showDrawer(val)}
                            className={`font-semibold w-32 h-10 text-sm border border-primaryBlue rounded-lg bg-white text-primaryBlue flex justify-center items-center gap-2`}
                            type="submit"
                          >
                            <p className="">{t("History")}</p>
                          </button>
                          {val?.last_sessions[0]?.session_state ===
                            "opened" && (
                            <button
                              onClick={() =>
                                handleCloseSessionClick(
                                  val?.last_sessions[0]?.session_id
                                )
                              }
                              className={`font-semibold w-32 h-10 border rounded-lg bg-primaryBlue text-white text-sm flex justify-center items-center gap-2`}
                              type="submit"
                            >
                              <p className="">{t("Close Session")}</p>
                            </button>
                          )}
                        </div>
                      </div>
                    ) : (
                      // If no last_sessions, display "No Session Data"
                      <div className="flex flex-col">
                        <div className="flex flex-row justify-start mt-4 ms-4 font-medium text-lg text-[#272728]">
                          <span className="font-semibold">
                            {val.user_name} (S-{val.session_count}, O-
                            {val.order_count})
                          </span>
                        </div>
                        <p className="mt-2 text-lg text-center text-primaryBlue">
                          {t("No Session Data")}
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
            {/* )} */}
            {/* {popup && (
              <Blockuserpopup
                onClose={() => setpopup(false)}
                data={blockdata}
                status={status}
                successMessage={successMessage}
              />
            )} */}
            {/* <p className='ml-20 mt-20'>Yet to be integrated</p> */}
          </div>
        </div>
      </div>
      {showCloseSessionModal && (
        <CloseSessionModal
          closeModal={() => setShowCloseSessionModal(false)}
          sessionId={selectedSessionId}
        />
      )}

      <Drawer
        // title={t("Session History")}
        title={`${drawerData?.user_name} (S-${drawerData?.session_count}, O-${drawerData?.order_count})`}
        placement="right"
        width={500}
        onClose={onClose}
        visible={visible}
      >
        {/* Display the session history data */}
        {loading ? (
          <div>{t("Loading session data...")}</div>
        ) : (
          <div>
            <div className="mb-4">
              <SelectBox
                value={selectedRange}
                options={[
                  { value: "current_week", label: t("Current Week") },
                  { value: "last_week", label: t("Last Week") },
                  { value: "custom", label: t("Custom Range") },
                ]}
                onChange={handleDateRangeChange}
                placeholder={t("select_date_range")}
              />
              {/* RangePicker for custom date selection */}
              {selectedRange === "custom" && (
                <RangePicker
                  className="w-full mt-2 h-10 range-picker-placeholder"
                  style={{ minWidth: 220 }}
                  onChange={handleRangePickerChange}
                  // onChange={handleRangePickerChange}
                  // locale={i18next.language === "ar" ? arEG : undefined}
                />
              )}
            </div>
            {sessionHistoryData.length > 0 ? (
              sessionHistoryData.map((session) => renderSessionBox(session))
            ) : (
              <p>{t("No session data available")}</p>
            )}
          </div>
        )}
      </Drawer>
    </>
  );
};

export default SessionHistory;
