import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { InputBox, Password } from "../CustomWidgets/InputBox";
import SelectBox from "../CustomWidgets/SelectBox";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { SignupSchema, basicDetailSchema } from "../validationSchema";
import axios from "axios";
import { Image, Select } from "antd";
import FlagIcon from "../../assets/egypt.svg";
import { Input } from "antd";
import "../../styles.css";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

function BasicDetails({
  onDataSubmit,
  email,
  mobile,
  savedata,
  data,
  shopLocationData,
}) {
  const { t, i18n } = useTranslation();
  const [shoptype, setshoptype] = useState([]);

  useEffect(() => {
    fetchData();
    console.log("SAVEDATA", savedata);
    console.log("DATAAA", shopLocationData);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://uat.myginne.com/Store/1/GetPlans",
        { lang: i18n.language }
      );

      // console.log("SHOP TYPE RESPONSE", response);
      // console.log("RESPONSE", response?.data?.result?.[0]?.response);

      setshoptype(response?.data?.result?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      mobile:
        typeof window !== "undefined"
          ? localStorage?.getItem("UserPhone")
          : mobile,
      country_code: "+20",
      user_type: savedata?.user_type ?? "Retailer",
      page: "basic_details",
      emailid: savedata?.emailid ?? "",
      storename: savedata?.storename ?? "",
      retailername: savedata?.retailername ?? "",
      profile: savedata?.profile ?? 0,
      password: savedata?.password ?? "",
      confirmPassword: "",
    },
    validationSchema: basicDetailSchema,
    onSubmit: (values) => {
      if (values.storename === values.retailername) {
        formik.setErrors({
          retailername: t("business_owner_name_must_be_different"),
        });
        return; // Prevent submission
      }
      const payload = {
        business_type: "retailer",
        plan_id: values.profile,
        email: values?.emailid?.toLowerCase(),
        mobile: values?.mobile,
        term_condition: shopLocationData?.term_condition,
        password: values?.password,
        owner_name: values?.retailername,
        business_name: values?.storename,
        state: shopLocationData?.state,
        city: shopLocationData?.city,
        street: shopLocationData?.street,
        street2: `${shopLocationData?.address1}${
          shopLocationData?.address2 ? ", " + shopLocationData?.address2 : ""
        }`,
        delivery_area: shopLocationData?.delivery_area,
        zone_id: shopLocationData?.zone_id,
        area: shopLocationData?.area,
        country: shopLocationData?.country,
        zip: Number(shopLocationData?.zip_code),
        latitude: shopLocationData?.latitude,
        longitude: shopLocationData?.longitude,
      };
      onDataSubmit("basicDetailsData", payload);
    },
  });

  return (
    <div className="container w-full px-0">
      <h1 className="text-xl font-bold ">{t("basic_details")}</h1>
      <form onSubmit={formik.handleSubmit} className="">
        <div className="grid grid-cols-1 ">
          {/* Shop Type */}
          <div className="grid grid-cols-2 gap-x-8">
            <SelectBox
              label={t("Trial Plan")}
              name="profile"
              onChange={formik.handleChange}
              options={[
                { value: 0, label: t("Select Plan"), disabled: true },
                ...shoptype?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                })),
              ]}
              onBlur={formik.handleBlur}
              value={
                shoptype?.find((item) => item?.id == formik.values.profile)
                  ?.id || 0
              }
              error={formik.errors.profile}
              touched={formik.touched.profile}
            />
          </div>

          {/* Business Name and Business Owner Name */}
          <div className="grid grid-cols-2 gap-x-8">
            <div>
              <InputBox
                label={t("business_name")}
                name="storename"
                placeholder={t("enter_business_name")}
                maxLength={20}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.storename}
                error={formik.errors.storename}
                touched={formik.touched.storename}
              />
            </div>
            <div>
              <InputBox
                label={t("business_owner_name")}
                name="retailername"
                placeholder={t("enter_business_ownername")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.retailername}
                error={formik.errors.retailername}
                touched={formik.touched.retailername}
              />
            </div>
          </div>

          {/* Mobile No. and Email */}
          <div className="grid grid-cols-2 gap-x-8">
            <div className="flex flex-col">
              <label className="font-medium text-base mt-4">
                {t("phone_number")}
              </label>
              <div className="flex items-center mt-2">
                <div className="h-10 w-16 text-md text-body outline-none focus:outline-none rounded-lg border p-3 flex items-center justify-center bg-[#EBEBEB]">
                  <Image src={FlagIcon} alt="img" />
                </div>
                <Input
                  international
                  defaultCountry="EG"
                  label={t("Mobile No.")}
                  value={"0" + formik.values.mobile}
                  placeholder={formik.values.mobile}
                  onBlur={formik.handleBlur}
                  name="mobile"
                  disabled
                  className={`h-10 ml-2 text-md text-body outline-none focus:outline-none ltr:pl-5 rtl:pr-5 rounded-md p-3 ${
                    formik.touched.mobile && formik.errors.mobile
                      ? "border-red" // Apply red border when touched or there's an error
                      : ""
                  }`}
                />
              </div>
              {formik.touched.mobile && formik.errors.mobile && (
                <div className="text-red-500 mt-0">{formik.errors.mobile}</div>
              )}
            </div>
            <div>
              {/* <label className="font-medium text-base">
                {t("phone_number")}
              </label> */}
              <InputBox
                label={t("email")}
                name="emailid"
                placeholder={t("enter_email")}
                maxLength={30}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.emailid}
                error={formik.errors.emailid}
                touched={formik.touched.emailid}
                autoComplete="new-email"
              />
              <div className="text-red text-sm mt-1">
                {email ? t("email_already_registered") : " "}
              </div>
            </div>
          </div>

          {/* Password and Confirm Password */}
          <div className="grid grid-cols-2 gap-x-8">
            <div>
              <Password
                label={t("password")}
                name="password"
                placeholder={t("Enter Password")}
                maxLength={20}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={formik.errors.password}
                touched={formik.touched.password}
                autoComplete="new-password"
              />
            </div>
            <div>
              <Password
                label={t("Confirm Password")}
                name="confirmPassword"
                placeholder={t("Re-Enter password")}
                maxLength={20}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                error={formik.errors.confirmPassword}
                touched={formik.touched.confirmPassword}
                autoComplete="new-password"
              />
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex float-right mt-8 mb-2">
          <button
            type="submit"
            className={`w-[180px] h-[45px] text-white rounded-lg bg-primaryBlue font-semibold`}
          >
            {t("confirm")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default BasicDetails;
