// Third component
import React, { useState, useEffect } from "react";
import SearchBox from "../CustomWidgets/SearchBox";
import { useFormik } from "formik";
import SelectBox from "../CustomWidgets/SelectBox";
import { DatePicker, Space } from "antd";
import "../../styles.css";
import Sessionexpired from "../../modals/Sessionexpired";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getEorderDashboard, getKPIs } from "../../API  Functions/Eorders";
import { setKPIData } from "../../Redux/Action/EordersDataAction";
import arEG from "antd/lib/locale/ar_EG"; // Import Arabic locale
import i18next from "i18next";

// import { dayjs } from "dayjs";
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const formatDate = (date) => date.toISOString().split("T")[0]; // Format date to YYYY-MM-DD

const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0]; // Format YYYY-MM-DD
};

const getStartOfWeek = () => {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - dayOfWeek);
  return startOfWeek.toISOString().split("T")[0];
};

const getStartOfMonth = () => {
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  // Format the date as 'YYYY-MM-DD' without using toISOString to avoid timezone issues
  const year = startOfMonth.getFullYear();
  const month = String(startOfMonth.getMonth() + 1).padStart(2, "0"); // Ensure month is two digits
  const day = String(startOfMonth.getDate()).padStart(2, "0"); // Ensure day is two digits

  return `${year}-${month}-${day}`;
};

const Third = ({ activeHeading, handleFilter, storecount }) => {
  const { t } = useTranslation();
  const is_lahloob = useSelector(
    (state) => state.login?.user?.result?.is_lahloob
  );
  const store_id = useSelector((state) => state.login?.user?.store_id);
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const [sessionexpired, setsessionexpired] = useState(false);
  const [todayDate, settodayDate] = useState("");
  const dispatch = useDispatch(); // Initialize the dispatch

  const [selectedRange, setSelectedRange] = useState("today"); // New state to track selected range

  const options = [
    {
      value: 0,
      label: `${t("all_orders")} (${storecount?.total_order ?? 0})`,
    },
    ...(is_lahloob
      ? []
      : [
          {
            value: 1,
            label: `${t("order_pending")} (${
              storecount?.["Order Pending"] ?? 0
            })`,
          },
        ]),
    {
      value: 2,
      label: `${t("in_progress")} (${storecount?.["In Processing"] ?? 0})`,
    },
    {
      value: 3,
      label: `${t("ready_for_delivery")} (${
        storecount?.["Ready For Delivery"] ?? 0
      })`,
    },
    {
      value: 4,
      label: `${t("on_the_way")} (${storecount?.["Out For Delivery"] ?? 0})`,
    },
    {
      value: 5,
      label: `${t("order_delivered")} (${
        storecount?.["Order Delivered"] ?? 0
      })`,
    },
    {
      value: 7,
      label: `${t("order_cancelled")} (${
        storecount?.["Order Cancelled"] ?? 0
      })`,
    },
  ];

  // Predefined date ranges
  const handleDateRangeChange = (range) => {
    let CurrentSelection = range.target.value;
    // console.log("CurrentSelection", CurrentSelection);

    setSelectedRange(range.target.value); // Update selected range state
    const currentDate = new Date(); // Use native Date
    let startDate, endDate;

    if (CurrentSelection === "today") {
      startDate = getCurrentDate();
      endDate = getCurrentDate();
    } else if (CurrentSelection === "thisWeek") {
      startDate = getStartOfWeek();
      endDate = getCurrentDate();
    } else if (CurrentSelection === "thisMonth") {
      startDate = getStartOfMonth();
      endDate = getCurrentDate();
    } else if (CurrentSelection === "custom") {
      return;
    }

    // console.log("START DATE", startDate);
    // console.log("END DATE", endDate);

    settodayDate(startDate);
    formik.setFieldValue("today", [startDate, endDate]);

    getEorderDashboard(user_id, startDate, endDate)
      .then((res) => {
        const kpiData = res?.result?.[0]?.response;
        dispatch(setKPIData(kpiData)); // Dispatch action to save data in Redux
      })
      .catch((err) => {
        console.error("Error fetching KPIs:", err);
      });
  };

  const formik = useFormik({
    initialValues: {
      orderStatus: 0,
      // today: [todayDate, ""], // Initially empty
      searchvalue: "",
    },
    onSubmit: (values) => {
      const sendvalues = {
        orderStatus: values.orderStatus,
        // today: todayDate === "1970/01/01" ? "" : todayDate,
        searchvalue: values.searchvalue,
      };
      handleFilter(sendvalues);
    },
  });

  const handleRangePickerChange = (dates) => {
    if (dates) {
      const startDate = formatDate(dates[0]);
      const endDate = formatDate(dates[1]);

      // Update Formik field and state with selected range
      // formik.setFieldValue("today", [startDate, endDate]);
      // settodayDate(startDate);

      // Fetch KPIs using the selected custom date range
      getEorderDashboard(user_id, startDate, endDate)
        .then((res) => {
          const kpiData = res?.result?.[0]?.response;
          dispatch(setKPIData(kpiData)); // Dispatch action to save data in Redux
        })
        .catch((err) => {
          console.error("Error fetching KPIs:", err);
        });
    }
  };

  console.log("DATE", formik.values.today);
  console.log("SELECTED DATE filter", selectedRange);

  return (
    <>
      <div className="flex justify-between items-end ">
        <SearchBox
         placeholder={t("Search Order")}
          searchResult={(values) => {
            formik.setFieldValue("searchvalue", values);
            formik.handleSubmit();
          }}
        />
        <div className="flex gap-x-1 items-end justify-end ">
          <SelectBox
            style={{ width: 195 }}
            width={200}
            defaultValue={options.find(
              (option) => option.value === activeHeading
            )}
            placeholder={t("order_status")}
            name="orderStatus"
            onChange={(value) => {
              formik.handleChange(value);
              formik.handleSubmit();
            }}
            options={options}
            onBlur={formik.handleBlur}
            value={
              options?.find((option) => option?.value === activeHeading)?.value
            }
            error={formik.errors.orderStatus}
            touched={formik.touched.orderStatus}
          />
          {/* Dropdown for selecting the date range */}
          {/* {selectedRange != "custom" && ( */}
          <SelectBox
            value={selectedRange}
            options={[
              { value: "today", label: t("Today") },
              { value: "thisWeek", label: t("This Week") },
              { value: "thisMonth", label: t("This Month") },
              { value: "custom", label: t("Custom Range") },
            ]}
            onChange={handleDateRangeChange}
            placeholder={t("select_date_range")}
          />
          {/* )} */}
          {/* RangePicker for custom date selection */}
          {selectedRange === "custom" && (
            <RangePicker
              className="w-10 h-10 range-picker-placeholder"
              style={{ minWidth: 220 }}
              onChange={handleRangePickerChange}
              locale={i18next.language === "ar" ? arEG : undefined} 
            />
          )}
        </div>
      </div>
      {sessionexpired && <Sessionexpired />}
    </>
  );
};

export default Third;
