import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function SearchBox({ showBarcode, searchResult, placeholder }) {
  const { t, i18n } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="relative flex items-center">
      <div
        className={`absolute inset-y-0 ${
          i18n.language === "ar" ? "right-0 pr-4" : "left-0 pl-4"
        } flex items-center pointer-events-none`}
      >
        <svg
          className="w-4 h-4 text-gray-500 "
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
          />
        </svg>
      </div>
      <input
        type="search"
        id="default-search"
        className={`block h-10 w-72 ps-10 text-sm text-gray-950 font-normal border border-[#CCCCCC] rounded-md 
          ${showBarcode ? "ps-10" : "ps-10"}
          ${i18n.language === "ar" ? "pe-4 text-right" : "ps-10 text-left"}
         `}
        style={{ outline: "none" }}
        // style={{ outline: "none", paddingRight: "2px" }}
        placeholder={t(placeholder)}
        value={searchTerm}
        onChange={(event) => {
          setSearchTerm(event.target.value);
          if (
            event.target.value.length >= 3 ||
            event.target.value.length === 0
          ) {
            searchResult(event.target.value);
          }
        }}
        required
      />
    </div>
  );
}

export default SearchBox;
