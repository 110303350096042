import { Button, DatePicker, Input, Select } from "antd";

import React, { useEffect, useState } from "react";
import "../styles.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  AddBarcodeApi,
  GetPaymentModes,
  RegisterPayment,
} from "../API  Functions/PurchaseFunctions";
import { toast } from "react-toastify";
import moment from "moment";

const styles = {
  selectant: {
    border: "none",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
  container: {
    width: "500px",
    padding: "30px",
    borderRadius: "18px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: "25px",
    fontWeight: "bold",
    textAlign: "left",
    // marginBottom: "10px",
    // marginTop: "5px",
  },
  cancelIcon: {
    cursor: "pointer",
  },
  description: {
    textAlign: "left",
    fontSize: "16px",
    color: "#272728",
    marginTop: "10px",
  },
  input: {
    width: "100%",
    height: "6.5vh",
    // padding: "12px",
    borderRadius: "5px",
    // border: "1px solid #CCCCCC",
    marginBottom: "20px",
    boxSizing: "border-box",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: "180px",
    height: "42px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    marginRight: "10px",
  },
  confirmButton: {
    backgroundColor: "primaryBlue",
    color: "#FFFFFF",
  },
  cancelButton: {
    backgroundColor: "#CCCCCC",
    color: "#000000",
  },
  note: {
    color: "primaryBlue",
    marginTop: "20px",
    textAlign: "left",
  },
};

const AddBarcode = ({ isOpen, onClose, prod_id, onBarcodeAdded, }) => {
  const { t, i18n } = useTranslation();
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);

  const [restricted, setrestricted] = useState(false);
  const [barcode, setBarcode] = useState("");
  const [barcodeError, setBarcodeError] = useState("");

  // console.log("selectedPaymentTerm",selectedPaymentTerm);
  useEffect(() => {
    if (isOpen) {
      setBarcode(""); // Reset the barcode value when the modal opens
      setBarcodeError(""); // Optionally reset any error messages
    }
  }, [isOpen]);

  const handleBarcodeChange = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      setBarcode(value);
      setBarcodeError("");
      // } else {
      //   setBarcodeError(
      //     "Barcode must be a whole number without decimals or hyphens."
      //   );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!barcode) {
      return;
    }

    // console.log("ORDER ID", bill_id);
    setrestricted(true);

    const payload = {
      product_id: prod_id,
      barcode: Number(barcode),
    };

    AddBarcodeApi(payload).then((response) => {
      console.log("AAAAAAA", response);

      //   123214213213

      if (
        response?.data?.result[0]?.status_code === 200 &&
        response?.data?.result[0]?.status === "Barcode Updated"
      ) {
        toast.success(t("Barcode Updated Successfully"));
        onBarcodeAdded(barcode);
        setBarcode("")
        // Popup();
      }
    });

    onClose();
  };

  return (
    <>
      {isOpen && (
        <>
          <div style={styles.overlay} onClick={onClose}></div>
          <div style={styles.container}>
            <div style={styles.header}>
              <p style={styles.title}>{t("Add Barcode")}</p>
            </div>

            <div className="justify-content-center align-items-center mt-5">
              <form onSubmit={handleSubmit}>
                {/* Amount Input Field */}
                <p className=" ">{t("Barcode")}</p>
                <Input
                  style={styles.input}
                  value={barcode}
                  onChange={handleBarcodeChange}
                  placeholder={t("Enter Barcode")}
                  type="text"
                  min={0}
                  maxLength={15}
                />
                {barcodeError && (
                  <p className="text-red text-xs mb-2">{barcodeError}</p>
                )}
                <div className="flex flex-row items-center justify-center mb-4"></div>

                <div className="flex justify-center mt-5">
                  <button
                    className="w-52 h-10 border-primaryBlue border text-primaryBlue text-sm font-semibold rounded-lg mr-5"
                    onClick={onClose}
                  >
                    {t("close")}
                  </button>
                  <button
                    type="submit"
                    className={`w-52 h-10 ${
                      barcode ? "bg-primaryBlue" : "bg-primaryBluedisabled"
                    } text-white text-sm font-semibold rounded-lg `}
                    disabled={!barcode}
                  >
                    {t("Done")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddBarcode;
