// ProductTable.js
import React, { useState } from "react";
import { useTable } from "react-table";
import deleteicon from "../../assets/Order/Delete.svg";
import { Input } from "antd";
import { t } from "i18next";

const ProductTable = ({
  productList,
  handleProductChange,
  handleDeleteProduct,
  handleOpenModal,
  disabled,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: t("Barcode"),
        accessor: "barcode",
      },
      {
        Header: t("Product Name"),
        accessor: "name",
      },
      {
        Header: t("UOM"),
        accessor: "uom",
      },
      {
        Header: t("Unit Price"),
        accessor: "cost_price",
        Cell: ({ row }) => {
          const [localCostPrice, setLocalCostPrice] = useState(
            row.original.cost_price || 0
          );
          return (
            <Input
              type="text"
              value={localCostPrice}
              onChange={(e) => {
                const value = e.target.value;

                // Allow empty input or valid decimal numbers
                if (
                  value === "" || // Allow empty input
                  /^\d*\.?\d{0,2}$/.test(value) // Allow numbers with up to 2 decimal places
                ) {
                  // setLocalDiscount(value === "" ? 0 : parseFloat(value)); // Update state
                  setLocalCostPrice(value === "" ? 0 : value);
                }
              }}
              onBlur={() =>
                handleProductChange(
                  row.index,
                  "cost_price",
                  parseFloat(localCostPrice)
                )
              }
              disabled={disabled}
              maxLength={8}
              className="w-20"
            />
          );
        },
      },
      {
        Header: t("VAT %"),
        accessor: "vat",
        Cell: ({ row }) => {
          const [localVat, setLocalVat] = useState(row.original.vat || 0);
          return (
            <Input
              value={localVat}
              onChange={(e) => setLocalVat(parseFloat(e.target.value) || 0)}
              onBlur={() => handleProductChange(row.index, "vat", localVat)}
              className="w-20"
              disabled={disabled}
              maxLength={5}
            />
          );
        },
      },
      {
        Header: t("Purchase Qty"),
        accessor: "purchaseQty",
        Cell: ({ row }) => {
          const [localPurchaseQty, setLocalPurchaseQty] = useState(
            row.original.purchaseQty || 0
          );
          const uom = row.original.uom; // Get the UOM for the current row

          return (
            <Input
              type="text" // Keep as text to allow flexible input
              value={localPurchaseQty}
              onChange={(e) => {
                const value = e.target.value;

                // Allow only integers if UOM is "Units", otherwise allow decimals
                if (
                  (uom === "Units" && /^[0-9]*$/.test(value)) || // Allow only integers for "Units"
                  (uom !== "Units" && /^\d*\.?\d{0,7}$/.test(value)) // Allow decimals for other UOMs
                ) {
                  setLocalPurchaseQty(value === "" ? 0 : value); // Store as string for input
                }
              }}
              onBlur={() => {
                // Convert to float when saving
                handleProductChange(
                  row.index,
                  "purchaseQty",
                  parseFloat(localPurchaseQty) || 0
                );
              }}
              disabled={disabled}
              maxLength={8}
            />
          );
        },
      },
      {
        Header: t("Free Qty"),
        accessor: "freeQty",
        Cell: ({ row }) => {
          const [localFreeQty, setLocalFreeQty] = useState(
            row.original.freeQty || 0
          );
          const uom = row.original.uom;
          return (
            <Input
              type="text"
              className="w-20"
              value={localFreeQty}
              // onChange={(e) => setLocalFreeQty(parseFloat(e.target.value) || 0)}
              onChange={(e) => {
                const value = e.target.value;

                // Allow only integers if UOM is "Units", otherwise allow decimals
                if (
                  (uom === "Units" && /^[0-9]*$/.test(value)) || // Allow only integers for "Units"
                  (uom !== "Units" && /^\d*\.?\d{0,7}$/.test(value)) // Allow decimals for other UOMs
                ) {
                  setLocalFreeQty(value === "" ? 0 : value); // Store as string for input
                }
              }}
              onBlur={() =>
                handleProductChange(row.index, "freeQty", localFreeQty)
              }
              disabled={disabled}
              maxLength={8}
            />
          );
        },
      },
      {
        Header: t("Discount %"),
        accessor: "discount",
        Cell: ({ row }) => {
          const [localDiscount, setLocalDiscount] = useState(
            row.original.discount || 0
          );
          return (
            <Input
              type="text"
              value={localDiscount}
              // onChange={(e) =>
              //   setLocalDiscount(parseFloat(e.target.value) || 0)
              // }
              onChange={(e) => {
                const value = e.target.value;

                // Allow empty input or valid decimal numbers
                if (
                  value === "" || // Allow empty input
                  /^\d*\.?\d{0,2}$/.test(value) // Allow numbers with up to 2 decimal places
                ) {
                  // setLocalDiscount(value === "" ? 0 : parseFloat(value)); // Update state
                  setLocalDiscount(value === "" ? 0 : value);
                }
              }}
              onBlur={() =>
                handleProductChange(
                  row.index,
                  "discount",
                  parseFloat(localDiscount)
                )
              }
              disabled={disabled}
              maxLength={6}
            />
          );
        },
      },

      {
        Header: t("Discount Value"),
        accessor: "discount_value",
        Cell: ({ row }) => {
          const [localDiscountValue, setLocalDiscountValue] = useState(
            row.original.discount_value || 0
          );

          return (
            <Input
              value={localDiscountValue}
              onChange={(e) => {
                const value = e.target.value;

                if (
                  value === "" || // Allow empty input
                  /^\d*\.?\d{0,2}$/.test(value) // Allow numbers with up to 2 decimal places
                ) {
                  // setLocalDiscount(value === "" ? 0 : parseFloat(value)); // Update state
                  setLocalDiscountValue(value === "" ? 0 : value);
                }
              }}
              onBlur={() => {
                handleProductChange(
                  row.index,
                  "discount_value",
                  parseFloat(localDiscountValue)
                ); // Update the discount value on blur
              }}
              disabled={disabled}
              maxLength={6}
            />
          );
        },
      },
      {
        Header: t("Total Value (excluding VAT)"),
        accessor: "totalValue",
        Cell: ({ value }) => <span>{value}</span>,
      },
      {
        Header: t("VAT"),
        accessor: "includingVat",
        Cell: ({ value }) => <span>{value.toFixed(2)}</span>,
      },
      {
        Header: t("Expiry"),
        accessor: "expiry",
        Cell: ({ row }) => (
          <span
            onClick={() => !disabled && handleOpenModal(row.index)}
            style={{
              cursor: disabled ? "not-allowed" : "pointer",
              color: disabled ? "gray" : "red",
            }}
            className="italic"
          >
            {row.original.expiry ? row.original.expiry : (t("Expiry"))}
          </span>
        ),
      },
      {
        Header: t("Effective Price Inc. Vat"),
        accessor: "effectivePrice",
        Cell: ({ value }) => <span>{value.toFixed(2)}</span>,
      },
      {
        Header: t("Sales Price"),
        accessor: "salesPrice",
        Cell: ({ row }) => {
          const [localSalesPrice, setLocalSalesPrice] = useState(
            row.original.salesPrice || 0
          );

          return (
            <Input
              value={localSalesPrice}
              // onChange={(e) =>
              //   setLocalSalesPrice(parseFloat(e.target.value) || 0)
              // } // Update local state

              onChange={(e) => {
                const value = e.target.value;

                // Allow empty input or valid decimal numbers
                if (
                  value === "" || // Allow empty input
                  /^\d*\.?\d{0,2}$/.test(value) // Allow numbers with up to 2 decimal places
                ) {
                  // setLocalDiscount(value === "" ? 0 : parseFloat(value)); // Update state
                  setLocalSalesPrice(value === "" ? 0 : value);
                }
              }}
              onBlur={() =>
                handleProductChange(
                  row.index,
                  "salesPrice",
                  parseFloat(localSalesPrice)
                )
              } // Update main state on blur
              disabled={disabled}
              maxLength={8}
            />
          );
        },
      },

      {
        Header: t("Profit %"),
        accessor: "profit",
        Cell: ({ value }) => <span>{value.toFixed(2)}</span>,
      },
      {
        Header: " ",
        accessor: "actions",
        Cell: ({ row }) => (
          <img
            className={`mx-4 h-4 w-4 cursor-pointer ${
              disabled ? "opacity-50 cursor-not-allowed" : ""
            }`} // Change appearance if disabled
            src={deleteicon}
            alt="deleteicon"
            onClick={() => !disabled && handleDeleteProduct(row.index)} // Only allow click if not disabled
          />
        ),
      },
    ],
    [handleDeleteProduct, handleOpenModal]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: productList,
    });

  return (
    <div className="overflow-x-auto mt-5 scrollbar-thin">
      <table
        {...getTableProps()}
        className="sm:w-[97.6%] md:w-[97.6%] lg:w-[97.6%] xl:w-[97.6%] min-h-auto shadow-xl rounded-lg overflow-hidden"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="bg-[#78CFEC66] text-xs font-semibold rounded-lg"
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className="whitespace-nowrap px-2 py-3"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="bg-[#F7F9FD] border-t border-b border-gray-300"
        >
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="border-b border-gray-300">
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={`
                        px-2 py-3 text-xs
                        ${index === 0 ? "ps-4" : ""} 
                        ${index === row.cells.length - 1 ? "rounded-r-lg" : ""}
                      `}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ProductTable;
