import React, { useEffect, useRef, useState } from "react";
import InventoryTableComponent from "./InventoryTableComponent";
import SearchBox from "../CustomWidgets/SearchBox";
import { PlusOutlined } from "@ant-design/icons";
import { Image } from "antd";
import category from "../../assets/Inventory/spinner.svg";
import {
  GenerateExcelReport,
  InventoryCategoryFilter,
  InventorySearch,
  InventoryUpdateDetails,
  InventoryUpdateDetailsIncomplete,
  getInventoryallcategories,
  getInventoryallproducts,
  getInventoryincompleteproducts,
} from "../../API  Functions/InventoryFunctions";
import { debounce } from "@mui/material";
import { Alert } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { Select } from "antd";
import "../../styles.css";
import InCompletetable from "./InCompletetable";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ImportInventory from "../../modals/ImportInventory_Modal";
import showToast from "../../toastService";
import { toast } from "react-toastify";
import LoadingSpinner from "../Loading";

const InventoryTableHeader = ({ dashData }) => {
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const [TableData, setTableData] = useState([]);
  const [IncompleteData, setIncompleteData] = useState([]);

  const [CategoryData, setCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [Showcategories, setShowcategories] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [SelectedsubcategoryOptions, setSelectedsubcategoryOptions] = useState(
    []
  );
  const [subcategory, setsubcategory] = useState([]);
  const [query, setquery] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentLimit, setCurrentLimit] = useState(10); // Default limit
  const [currentOffset, setCurrentOffset] = useState(0); // Default offset

  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const store_id = useSelector(
    (state) => state?.login?.user?.result?.company_id
  );

  //To get categories for filter and get
  t("all_products");
  useEffect(() => {
    if (store_id) {
      // getInventoryKPI();
      getInventoryCategories();
    }
  }, [store_id]);

  const [data] = useState([{ id: "11" }]);
  const [activeHeading, setActiveHeading] = useState(1);
  const [headingWidth, setHeadingWidth] = useState(0);
  const activeHeadingRef = useRef(null);
  const [isImportInventoryOpen, setImportInventoryOpen] = useState(false);

  useEffect(() => {
    setDynamicWidth();
  }, [activeHeading, data]);
  const setDynamicWidth = () => {
    if (activeHeadingRef.current) {
      setHeadingWidth(activeHeadingRef.current.offsetWidth);
    }
  };

  // const handleOptionChange = (selectedValue) => {
  //   // console.log("SELECTED VALUE OF CAT", selectedValue);

  //   setSelectedCategory(selectedValue); // Set the selected category
  //   setsubcategory([]); // Reset subcategory

  //   if (selectedValue) {
  //     // Fetch products based on the selected category ID
  //     fetchProductsByCategory(selectedValue);

  //     // Update subcategories based on the selected category
  //     const cat = CategoryData.filter(
  //       (res) => res.category_id === selectedValue
  //     )[0]?.sub_category_details;
  //     setsubcategory(cat || []); // Set subcategories or an empty array if none found
  //   } else {
  //     // If no category is selected, fetch all products
  //     getInventoryKPI();
  //   }
  // };

  const handleOptionChange = (selectedValue) => {
    if (selectedValue === "All Categories") {
      setSelectedCategory(null); // Reset selected category
      setsubcategory([]); // Reset subcategory
      getInventoryKPI(); // Fetch all products
    } else {
      setSelectedCategory(selectedValue); // Set the selected category
      setsubcategory([]); // Reset subcategory

      if (selectedValue) {
        // Fetch products based on the selected category ID
        fetchProductsByCategory(selectedValue);

        // Update subcategories based on the selected category
        const cat = CategoryData.filter(
          (res) => res.category_id === selectedValue
        )[0]?.sub_category_details;
        setsubcategory(cat || []); // Set subcategories or an empty array if none found
      }
    }
  };

  // New function to fetch products by selected category
  const fetchProductsByCategory = (categoryId, limit = 10, offset = 0) => {
    // Call the API with the selected category ID
    getInventoryallproducts(
      user_id,
      store_id,
      categoryId,
      "",
      limit,
      offset
    ).then((res) => {
      setTableData(res?.result[0]?.response);
    });
  };

  const getInventoryKPI = (limit = currentLimit, offset = currentOffset) => {
    getInventoryallproducts(user_id, store_id, false, "", limit, offset).then(
      (res) => {
        // console.log("ALL PRODUCTS DTA", res?.result[0]?.response);

        setTableData(res?.result[0]?.response);
        setLoading(false);
      }
    );
  };
  //Incomplete products
  const getInventoryIncompleteProducts = (limit = 10, offset = 0) => {
    getInventoryincompleteproducts(user_id, store_id, limit, offset).then(
      (res) => {
        setIncompleteData(res);
      }
    );
  };
  //Inventory categories

  const getInventoryCategories = () => {
    getInventoryallcategories(user_id, store_id).then((res) => {
      // console.log("CATEGORY RESPONSE --->", res);

      setCategoryData(res);
    });
  };

  const handleHeadingClick = (headingNumber) => {
    setSelectedOptions([]);
    setSelectedsubcategoryOptions([]);
    switch (headingNumber) {
      case 1:
        getInventoryKPI();
        break; // Add break statement to prevent falling through to the next case
      case 2:
        getInventoryIncompleteProducts();
        break; // Add break statement to prevent falling through to the default case
      default:
        // Handle default case if needed
        break;
    }
    setActiveHeading(headingNumber);

    // getData(1);
    // getData(id[headingNumber - 1].id);
  };

  const debouncedSearch = debounce((value) => {
    setquery(value);
    setLoading(true);

    const productName = value === "" ? "" : value; // Set product_name as empty string if no value
    const limit = 10; // Set limit
    const offset = 0; // Set offset

    if (value === "") {
      // If the search value is empty, fetch all products
      // getInventoryallproducts(user_id, store_id, false, productName, limit, offset); // Fetch all products
      getInventoryKPI();
    } else {
      // Call getInventoryallproducts with the search term
      getInventoryallproducts(
        user_id,
        store_id,
        false,
        productName,
        limit,
        offset
      ).then((res) => {
        if (res?.result[0]?.response) {
          setTableData(res.result[0].response);
          setSelectedCategory(null);
          setLoading(false);
        }
      });
    }
  }, 1000);

  const handledetailUpdate = async (val) => {
    console.log("VALLLL", val);

    // Iterate over each item in the val array
    for (const item of val) {
      try {
        // Call the API for each item
        const response = await InventoryUpdateDetails(item);
        // console.log("Response for item:", response);

        // Check if the response indicates a successful update

        if (
          response?.result[0]?.status === "Duplicate Machine Barcode" &&
          response?.result[0]?.status_code === 400
        ) {
          toast.error("Duplicate Product Code");
          activeHeading === 1
            ? getInventoryKPI()
            : getInventoryIncompleteProducts();
        }

        if (
          response?.result[0]?.status ===
            "Cost Price cannot be greater than  Sale Price" &&
          response?.result[0]?.status_code === 400
        ) {
          // console.log("ERRRRRRRRR");
          toast.error(t("Cost Price cannot be greater than Sale Price"));
          activeHeading === 1
            ? getInventoryKPI()
            : getInventoryIncompleteProducts();
        }
        if (response?.result[0]?.status === "Inventory Updated") {
          toast.success(t("Inventory Updated"));
          activeHeading === 1
            ? getInventoryKPI()
            : getInventoryIncompleteProducts();
        }
      } catch (error) {
        console.error("Error updating item:", error);
      }
    }
  };

  const handleIncompletedetailUpdate = async (val) => {
    // console.log("VALLLL", val);

    // Iterate over each item in the val array
    InventoryUpdateDetailsIncomplete(val, store_id).then((res) => {
      // console.log("res", res, activeHeading);
      res?.result[0]?.status == "Sales/Cost Price Updated" &&
        (activeHeading == 2
          ? getInventoryIncompleteProducts()
          : getInventoryIncompleteProducts());
    });
  };

  const handleinventoryreportdownload = (val) => {
    // console.log(val);
    GenerateExcelReport(user_id, store_id, val).then((res) => console.log(res));
  };
  useEffect(() => {
    setDynamicWidth();
  }, [activeHeading, data]);

  const underlineStyle = {
    width: `${headingWidth}px`,
    height: "6px",
    borderRadius: "10px",
    backgroundColor: "#78CFEC",
  };

  const components = [
    <InventoryTableComponent
      additionalData={TableData}
      setAdditionalData={(val) => {
        handledetailUpdate(val);
      }}
      pagination={(values) => {
        setCurrentLimit(values.limit);
        setCurrentOffset(values.offset);
        getInventoryKPI(values.limit, values.offset);
      }}
    />,

    <InCompletetable
      additionalData={IncompleteData}
      setAdditionalData={(val) => {
        handleIncompletedetailUpdate(val);
      }}
      pagination={(values) => {
        setCurrentLimit(values.limit);
        setCurrentOffset(values.offset);
        getInventoryIncompleteProducts(values.limit, values.offset);
      }}
    />,
  ];

  const handleImportInventory = () => {
    setImportInventoryOpen(true);
  };

  const closeInventory = () => {
    getInventoryKPI();
    setImportInventoryOpen(false);
  };

  return (
    <>
      {loading && <LoadingSpinner />}

      {isImportInventoryOpen && (
        <ImportInventory
          isOpen={isImportInventoryOpen}
          onClose={() => closeInventory()}
          // onClose={() => setImportInventoryOpen(false)}
          // order_id={orderDetail.order_details?.order_id}
          // popup={() => setcancelledpopup(true)}
        />
      )}
      {activeHeading !== 2 && (
        <div className="flex justify-between items-start mt-2 mx-3">
          <div className="flex gap-3  items-center">
            <SearchBox
              showBarcode={true}
              searchResult={(values) => debouncedSearch(values)}
              placeholder={t("Search Products")}
            />

            <Select
              className="overflow-hidden"
              style={{
                width: 180,
                minWidth: 180,
                height: 40,
              }}
              placeholder={t("select_categories")}
              value={selectedCategory || "All Categories"} // Set default value to "All Categories"
              onChange={handleOptionChange} // Keep this as is
            >
              <Option value="All Categories">{t("All Categories")}</Option>{" "}
              {/* Add All Categories option */}
              {Object.values(CategoryData)?.map((option) => (
                <Option key={option.category_id} value={option.category_id}>
                  {option.name}
                </Option>
              ))}
            </Select>

            {/* <Select
              className="overflow-hidden"
              style={{
                width: 180,
                minWidth: 180,
                height: 40,
              }}
              placeholder={t("select_categories")}
              value={selectedCategory} // Change this to selectedCategory
              onChange={handleOptionChange} // Keep this as is
            >
              {Object.values(CategoryData)?.map((option) => (
                <Option key={option.category_id} value={option.category_id}>
                  {option.name}
                </Option>
              ))}
            </Select> */}
          </div>
          <div className="flex   gap-3">
            <Link to="/inventory/InventoryOnboard">
              <button className="w-36 h-10 bg-primaryBlue text-white text-sm font-semibold rounded-lg flex items-center justify-center gap-2">
                <PlusOutlined
                  style={{ fontSize: "1.5em", MozMarginStart: -10 }}
                />
                <span> {t("add_product")}</span>
              </button>
            </Link>
            <button
              className="w-44 h-10 bg-primaryBlue text-white text-sm font-semibold rounded-lg flex items-center justify-center gap-2"
              onClick={handleImportInventory}
            >
              <PlusOutlined
                style={{ fontSize: "1.5em", MozMarginStart: -10 }}
              />
              <span> {t("Import Inventory")}</span>
            </button>
          </div>
        </div>
      )}
      {data.map((val, index) => (
        <div
          key={val.id}
          className="flex gap-7 mt-4 mx-3 mb-[0.62rem] font-semibold cursor-pointer text-[#999999]"
        >
          {[1, 2].map((headingNumber) => {
            const badgeColor =
              // headingNumber === activeHeading ? "#FCC71D" : "#E7E7E7";
              headingNumber === activeHeading && headingNumber === 1
                ? "#FCC71D"
                : "#E7E7E7"; // Only show badge for active heading 1
            return (
              <h1
                key={headingNumber}
                ref={headingNumber === activeHeading ? activeHeadingRef : null}
                className={`g-${headingNumber}`}
                onClick={() => handleHeadingClick(headingNumber)}
                style={{
                  fontWeight:
                    headingNumber === activeHeading ? "600" : "#999999",
                  display: "flex", // Make the heading a flex container
                  alignItems: "center", // Align items vertically centered
                  justifyContent: "space-between", // Distribute space between heading text and badge
                }}
              >
                <span
                  style={{
                    color: headingNumber === activeHeading ? "#000" : "#999999",
                  }}
                >
                  {headingNumber === 1 && t("all_products")}
                  {headingNumber === 2 && t("incomplete")}
                </span>

                {headingNumber === activeHeading && (
                  <div
                    style={{
                      width: "6px",
                      height: "6px",
                      borderRadius: "50%",
                      backgroundColor: "#FCC71D",
                      marginLeft: i18n.language === "ar" ? "0" : "10px", // Apply marginLeft or marginRight based on language
                      marginRight: i18n.language === "ar" ? "10px" : "0", // Apply marginRight for Arabic
                      // marginLeft: "10px",
                    }}
                  />
                )}
                <div
                  style={{
                    borderRadius: "10px",
                    maxHeight: "23px",
                    backgroundColor: badgeColor,
                    padding: "0px 7px",
                    // marginLeft: "5px", // Adjusted to make space between the badge and heading
                    marginLeft: i18n.language === "ar" ? "0" : "5px", // Apply marginLeft or marginRight based on language
                    marginRight: i18n.language === "ar" ? "5px" : "0", // Apply marginRight for Arabic
                    display:
                      headingNumber === 1 && activeHeading === headingNumber
                        ? "block"
                        : "none", // Only show badge for active heading 1
                  }}
                >
                  <span style={{ color: "#000000" }}>
                    {headingNumber === 2
                      ? IncompleteData?.length
                      : dashData?.no_of_product_added}
                  </span>
                </div>
              </h1>
            );
          })}
        </div>
      ))}
      <hr className=" lg:w-[97.6%] relative  left-[13px]" />

      <div className="flex gap-x-2 items-center justify-center">
        {components[activeHeading - 1]}
      </div>
      {/* <div>{components[0]}</div> */}
    </>
  );
};

export default InventoryTableHeader;
