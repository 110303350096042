// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './components/Translation/en.json';
import translationAR from './components/Translation/ar.json';


const resources = {
  en: {
    translation: translationEN
  },
  ar: {
    translation: translationAR
  }
};

// Function to get language from localStorage or fallback to 'ar'
const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: callback => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    const detectedLanguage = storedLanguage || 'ar'; // Default to 'ar' if not found in localStorage
    // console.log('Detected language:', detectedLanguage);
    setLanguageDirection(detectedLanguage); // Set the direction based on detected language
    callback(detectedLanguage);
  },
  init: () => {},
  cacheUserLanguage: () => {}
};

// Function to set the document direction based on language
const setLanguageDirection = (language) => {
  if (language === 'ar') {
    document.body.setAttribute('dir', 'rtl');
  } else {
    document.body.setAttribute('dir', 'ltr');
  }
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'ar', // Fallback language
    interpolation: {
      escapeValue: false // React already escapes values to prevent XSS
    }
  });

export default i18n;

