import React from "react";
 import "./Loader.css"; // Import CSS file for styling

const LoaderComponent = () => {
  return (
    <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  );
};

export default LoaderComponent;
