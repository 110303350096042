import { useSelector } from "react-redux";
import { URLS } from "../API/API endpoints";
import axiosInstance from "../AxiosInstance";
import i18next from "i18next";



export const GetBillDetails = async (order_id) => {
  try {
    const payload = {
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
      order_id: order_id,
    };
    const response = await axiosInstance.post(URLS.GET_PAYMENT_INFO, payload);
    if (response.data?.result?.status_code == 200) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const getdata = async (user_id, searchid, status, limit, offset) => {
  try {
    const payload = {
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
      user_id: user_id,
      order_no:
        searchid.lastIndexOf(",") != -1
          ? searchid.substring(searchid.lastIndexOf(",") + 1)
          : searchid,
      status: status,
      limit: limit,
      offset: offset,
    };
    const response = await axiosInstance.post(
      URLS.ORDER_SEARCH_FILTER,
      payload
    );

    return response?.data?.result[0]?.response[0];
  } catch (error) {
    return error;
  }
};

//Get cancel reasons
export const getcancelReasons = async (user_id) => {
  try {
    const payload = {
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
      user_id: user_id,
    };
    const response = await axiosInstance.post(
      URLS.GET_ORDER_CANCEL_REASONS,
      payload
    );

    if (response?.data?.result?.status_code === 200) {
      return response.data?.result?.response;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const getLahloobCancelReasons = async (user_id) => {
  try {
    const payload = {
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
      user_id: user_id,
    };
    const response = await axiosInstance.post(
      URLS.LAHLOOB_CANCEL_REASON,
      payload
    );

    if (response?.data?.result?.status_code === 200) {
      return response.data?.result?.response;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const GetDeliveryBoy = async (user_id) => {
  try {
    const payload = {
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
      user_id: user_id,
    };
    const response = await axiosInstance.post(
      URLS.GET_DELIVERY_BOY,
      payload
    );

    console.log(response);
    

    if (response?.data?.result[0]?.status_code === 200) {
      return response.data?.result[0]?.response;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const AssignDeliveryBoy = async (payload) => {
  try {
    const response = await axiosInstance.post(
      URLS.SET_DELIVERY_BOY,
      payload
    );

    // console.log("BBBBBB",response);
    

    if (response?.data?.result[0]?.status_code === 200) {
      return response.data?.result[0];
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const DeliverOrder = async (order_id) => {

  const payload = {
    lang: i18next.language === "ar" ? "ar_001" : "en_US",
    order_id : order_id,
  };
  try {
    const response = await axiosInstance.post(
      URLS.ORDER_DELIVER,
      payload
    );

    // console.log("BBBBBB",response);
    

    if (response?.data?.result[0]?.status_code === 200) {
      return response.data?.result[0];
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};


// Cancel Order
export const updateordercancelreason = async (
  user_id,
  order_id,
  cancel_reason_id
) => {
  const payload = {
    lang: i18next.language === "ar" ? "ar_001" : "en_US",
    user_id: user_id,
    order_id: order_id,
  };

  if (
    typeof cancel_reason_id === "string" &&
    cancel_reason_id.startsWith("7:")
  ) {
    const [reasonId, description] = cancel_reason_id.split(": ");
    payload.cancel_reason_id = reasonId;
    payload.cancel_description = description;
  } else {
    payload.cancel_reason_id = cancel_reason_id;
  }

  try {
    const response = await axiosInstance.post(
      URLS.UPDATE_CANCEL_REASON,
      payload
    );
    if (response.data.message === "Success") {
      return response.data.data;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const lahloobCancelUpdate = async (
  payload
) => {

  try {
    const response = await axiosInstance.post(
      URLS.LAHLOOB_CANCEL_ORDER,
      payload
    );
    if (response.data.message === "Success") {
      return response.data.data;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};


export const acceptOrder = async (user_id, order_id, is_lahloob) => {
  const payload = {
    lang: i18next.language === "ar" ? "ar_001" : "en_US",
    user_id: user_id,
    order_id: order_id,
  };
  try {
    const url = is_lahloob ? URLS.LAHLOOB_ACCEPT_ORDER : URLS.ACCEPT_ORDER;
    const response = await axiosInstance.post(url, payload);
    // console.log("ACCEPT RESPONSE", response);

    if (response?.data?.result[0]?.status === "Order Accept") {
      return response?.data;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const updateOrder = async (
  user_id,
  orderId,
  existingItems,
  newItems
) => {
  // console.log("NEW ITEMS", newItems);

  const filteredNewItems = newItems
    .filter((item) => item.value > 0)
    .map((item) => ({
      product_id: item.product_id,
      price_unit: item.sell_price,
      quantity: item.value === undefined ? item.product_uom_qty : item.value,
    }));

  const payload = {
    lang: i18next.language === "ar" ? "ar_001" : "en_US",
    user_id: user_id,
    order_id: orderId,
    existing_items: existingItems,
    new_items: filteredNewItems,
  };

  try {
    const response = await axiosInstance.post(URLS.UPDATE_E_ORDER, payload);
    // console.log("UPDATE RESPONSE", response?.data?.result[0]);

    if (response?.data?.result[0]?.status_code == 200) {
      return response?.data?.result[0];
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const LahloobupdateOrder = async (
 payload
) => {
  // console.log("NEW ITEMS", newItems);

  // const filteredNewItems = newItems
  //   .filter((item) => item.value > 0)
  //   .map((item) => ({
  //     product_id: item.product_id,
  //     price_unit: item.sell_price,
  //     quantity: item.value === undefined ? item.product_uom_qty : item.value,
  //   }));

  // const payload = {
  //   lang: i18next.language === "ar" ? "ar_001" : "en_US",
  //   user_id: user_id,
  //   order_id: orderId,
  //   existing_items: existingItems,
  //   new_items: filteredNewItems,
  // };

  try {
    const response = await axiosInstance.post(URLS.LAHLOOB_UPDATE_PICKER_ORDER, payload);
    // console.log("UPDATE RESPONSE", response?.data?.result[0]);

    if (response?.data?.result[0]?.status_code == 200) {
      return response?.data?.result[0];
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};


export const LahloobprocessOrder = async (payload) => {

  try {
    const response = await axiosInstance.post(URLS.LAHLOOB_ORDER_PREPARED, payload);
    // console.log("PROCESS RESPONSE", response?.data?.result[0]);

    if (response?.data?.result[0]?.status_code === 200) {
      return response?.data?.result[0];
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const processOrder = async (user_id, orderId) => {
  const payload = {
    lang: i18next.language === "ar" ? "ar_001" : "en_US",
    user_id: user_id,
    order_id: orderId,
  };

  try {
    const response = await axiosInstance.post(URLS.PROCESS_ORDER, payload);
    console.log("PROCESS RESPONSE", response?.data?.result[0]);

    if (response?.data?.result[0]?.status_code === 200) {
      return response?.data?.result[0];
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

//Function to update the Quantity during order processing
export const quantityUpdate = async (
  user_id,
  store_id,
  searchid,
  qty,
  order_id,
  order_line_id,
  sell_price,
  product_id = "",
  barcode = ""
) => {
  try {
    const payload = {
      user_id: user_id,
      store_id: store_id,
      qty: qty,
      order_id: order_id,
      order_line_id: order_line_id,
      product_id: product_id,
      barcode: barcode,
      sell_price: sell_price,
    };
    const response = await axiosInstance.post(URLS.UPDATE_ORDERLINE, payload);

    if (response.data.message === "Success") {
      return response.data.data[0];
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const updateorderStage = async (
  user_id,
  store_id,
  order_id,
  stage_id,
  delivery_partner_id = 0,
  delivery_type_id = 0
) => {
  const payload = {
    user_id: user_id,
    store_id: store_id,
    order_id: order_id,
    stage_id: stage_id,
    delivery_partner_id: delivery_partner_id,
    delivery_type_id: delivery_type_id,
  };
  try {
    const response = await axiosInstance.post(URLS.UPDATE_ORDER_STAGE, payload);
    if (response?.data?.meessage === "Success") {
      return response?.data?.data;
    }
    if (response?.data?.message === "Order update sucessfully") {
      return response?.data;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};



export const confirmReturnOrder = async (
 payload
) => {
  try {
   
    const response = await axiosInstance.post(URLS.LAHLOOB_CONFIRM_RETURN_ORDER, payload);

    console.log("confirmReturnOrder",response);
    

    if (response.data.message === "Success") {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};


export const orderHandover = async (
  payload
 ) => {
   try {
    
     const response = await axiosInstance.post(URLS.ORDER_HANDED_OVER, payload);
 
     console.log("Hand Over REs",response);
     
 
     if (response.data.message === "Success") {
       return response.data;
     } else {
       return response;
     }
   } catch (error) {
     return error;
   }
 };