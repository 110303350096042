import { Button, DatePicker, Input, Select } from "antd";

import React, { useEffect, useState } from "react";
import "../styles.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  GetPaymentModes,
  RegisterPayment,
} from "../API  Functions/PurchaseFunctions";
import { toast } from "react-toastify";
import moment from "moment";
import LoadingSpinner from "../components/Loading";

const styles = {
  selectant: {
    border: "none",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
  container: {
    width: "500px",
    padding: "30px",
    borderRadius: "18px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: "25px",
    fontWeight: "bold",
    textAlign: "left",
    // marginBottom: "10px",
    // marginTop: "5px",
  },
  cancelIcon: {
    cursor: "pointer",
  },
  description: {
    textAlign: "left",
    fontSize: "16px",
    color: "#272728",
    marginTop: "10px",
  },
  input: {
    width: "100%",
    height: "6.5vh",
    // padding: "12px",
    borderRadius: "5px",
    // border: "1px solid #CCCCCC",
    marginBottom: "20px",
    boxSizing: "border-box",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: "180px",
    height: "42px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    marginRight: "10px",
  },
  confirmButton: {
    backgroundColor: "primaryBlue",
    color: "#FFFFFF",
  },
  cancelButton: {
    backgroundColor: "#CCCCCC",
    color: "#000000",
  },
  note: {
    color: "primaryBlue",
    marginTop: "20px",
    textAlign: "left",
  },
};

const PurchasePayment = ({
  isOpen,
  onClose,
  bill_id,
  page,
  due_amount,
  Popup,
  totalAmount,
  billDiscountPer,
  billDiscountVal,
  totalVatVal,
  selectedPaymentTerm,
}) => {
  const { t, i18n } = useTranslation();
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);

  const { Option } = Select;

  const [selectedPaymentMode, setSelectedPaymentMode] = useState(t("select"));
  const [restricted, setrestricted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentMode, setPaymentMode] = useState([]);
  const [amount, setAmount] = useState("");
  const [paymentDate, setPaymentDate] = useState(null);
  const [amountError, setAmountError] = useState("");
  const [cashId, setCashId] = useState(null);
  const [bankId, setBankId] = useState(null);

  const totalBillAmount =
    totalAmount -
    ((billDiscountPer / 100) * totalAmount + parseFloat(billDiscountVal || 0)) +
    totalVatVal;

  console.log("selectedPaymentTerm", selectedPaymentTerm);

  const handleAmountChange = (e) => {
    const value = e.target.value;

    setAmountError("");

    if (value < 0) {
      setAmountError(t("Amount cannot be negative."));
    } else if (value > totalBillAmount) {
      setAmountError(
        t(`Amount cannot exceed the due amount of ${totalBillAmount}`)
      );
    } else {
      setAmount(value);
    }

    if (value > due_amount) {
      setAmountError(t(`Amount cannot exceed the due amount of ${due_amount}`));
    }
  };

  const handleDateChange = (date, dateString) => {
    setPaymentDate(dateString);
  };

  const handleSelectChange = (value) => {
    setSelectedPaymentMode(value);
  };

  const fetchPaymentMethods = () => {
    GetPaymentModes(user_id)
      .then((result) => {
        if (Array.isArray(result?.data?.result?.journals)) {
          setPaymentMode(result.data.result.journals);

          // Set IDs for Cash and Bank
          const cashMode = result.data.result.journals.find(
            (item) => item.name === "Cash"
          );
          const bankMode = result.data.result.journals.find(
            (item) => item.name === "Bank"
          );

          if (cashMode) setCashId(cashMode.id);
          if (bankMode) setBankId(bankMode.id);
        } else {
          console.error(
            "Expected an array but got:",
            result?.data?.result?.journals
          );
          setPaymentMode([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching payment methods:", error);
        setPaymentMode([]);
      });
  };

  useEffect(() => {
    if (user_id) {
      fetchPaymentMethods();
    }
  }, [user_id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!paymentDate || !amount) {
      return;
    }

    // console.log("ORDER ID", bill_id);
    setrestricted(true);
    setLoading(true);

    const payload = {
      // bill_id: bill_id,
      [page === "createOrder" ? "order_id" : "bill_id"]:
        page === "createOrder" ? bill_id : bill_id,
      paid_amount: Number(amount),
      payment_date: paymentDate,
      journal_id: selectedPaymentMode,
    };

    RegisterPayment(payload).then((response) => {
      // console.log("AAAAAAA", response);

      if (response?.status === "Payment Registered") {
        toast.success(t("Payment registered Successfully"));
        setLoading(false);
        Popup();
      }
    });

    onClose();
  };

  useEffect(() => {
    if (
      totalBillAmount &&
      (selectedPaymentTerm === "Immediate Payment" ||
        selectedPaymentTerm === "الدفع الفوري")
    ) {
      setAmount(totalBillAmount);
    } else if (due_amount) {
      setAmount(due_amount);
    }
  }, [totalBillAmount, selectedPaymentTerm, due_amount]);

  return (
    <>
      {loading && <LoadingSpinner />}

      {isOpen && (
        <>
          <div style={styles.overlay} onClick={onClose}></div>
          <div style={styles.container}>
            <div style={styles.header}>
              <p style={styles.title}>{t("Add Payment")}</p>
            </div>

            <div className="justify-content-center align-items-center">
              <form onSubmit={handleSubmit}>
                {/* Date Selection Field */}
                <p className="mb-2 mt-2 ">{t("Payment Date")}</p>
                <DatePicker
                  style={styles.input}
                  onChange={handleDateChange}
                  placeholder={t("Select Payment Date")}
                  disabledDate={(current) =>
                    current && current > moment().endOf("day")
                  } // Disable future dates
                />

                {/* Amount Input Field */}
                <p className=" ">{t("amount")}</p>
                <Input
                  style={styles.input}
                  value={amount}
                  onChange={handleAmountChange}
                  placeholder={t("Enter Amount")}
                  type="number"
                  disabled={
                    totalBillAmount &&
                    (selectedPaymentTerm === "Immediate Payment" ||
                      selectedPaymentTerm === "الدفع الفوري")
                  }
                  min={0}
                  maxLength={7}
                />
                {amountError && (
                  <p className="text-red text-xs mb-2">{amountError}</p>
                )}
                <p className=" mb-2 mt-2">{t("Payment Mode")}</p>
                <Select
                  style={styles.input}
                  placeholder={t("select")}
                  value={selectedPaymentMode.name}
                  onChange={handleSelectChange}
                >
                  <Option value="select" disabled>
                    {t("select")}
                  </Option>
                  {paymentMode.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {t(item.name)}
                    </Option>
                  ))}
                </Select>
                <div className="flex flex-row items-center justify-center mb-4"></div>

                <div className="flex justify-center mt-5">
                  <button
                    className="w-52 h-10 border-primaryBlue border text-primaryBlue text-sm font-semibold rounded-lg me-5"
                    onClick={onClose}
                  >
                    {t("close")}
                  </button>
                  <button
                    type="submit"
                    className={`w-52 h-10 ${
                      paymentDate &&
                      amount &&
                      (selectedPaymentMode === cashId ||
                        selectedPaymentMode === bankId)
                        ? "bg-primaryBlue"
                        : "bg-primaryBluedisabled"
                    } text-white text-sm font-semibold rounded-lg `}
                    disabled={
                      !paymentDate ||
                      // restricted ||
                      !amount ||
                      (selectedPaymentMode !== cashId &&
                        selectedPaymentMode !== bankId)
                    }
                  >
                    {t("Done")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PurchasePayment;
