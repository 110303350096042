import React, { useState, useEffect } from "react";
import Deliverypartners from "../../components/EordersDeliveryPartners/Deliverypartners";
import Navbar from "../../components/Navbar/Navbar";
import OrderAmount from "../../components/Orders/OrderAmount";
import Tableheader from "../../components/Orders/Tableheader";
import Sidebar from "../../components/SideBar/sidebar";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import { getStageIds, getKPIs, getEorderDashboard } from "../../API  Functions/Eorders";
import { routes } from "../../Routes";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setKPIData } from "../../Redux/Action/EordersDataAction";

const Orderpage = () => {
  const navigate=useNavigate();
  
  const [showDeliveryPartners, setShowDeliveryPartners] = useState(false);
  const [activeHeading, setActiveHeading] = useState(1);
  const [id, setid] = useState([]);
  const [kpidata, setkpidata] = useState([]);
  const dispatch = useDispatch();


  const store_id = useSelector(state=>state.login?.user?.store_id)
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id)

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Format YYYY-MM-DD
  };

  useEffect(() => {
    if (user_id) {
      getKPI();
    }
  }, [user_id]);

  

  const getKPI = () => {
    let start_date = getCurrentDate();
    let end_date = getCurrentDate();
    getEorderDashboard(user_id, start_date, end_date).then((res) => {
      const kpiData = res?.result?.[0]?.response;
      setkpidata(kpiData); // Local state update
      dispatch(setKPIData(kpiData)); // Dispatch action to save data in Redux
    });
  };

  return (
    <div className="h-full">
      {/* <div className="float-left fixed z-10">
        <Sidebar />
        </div> */}
         <Navbar heading="eorders" />
         <div className="min-h-14"></div>
      <div className=" pb-6  ">
        <div className="flex flex-col flex-1">
         
          {showDeliveryPartners ? (
            navigate("/deliverypartner")
          ) : (
            <>
             <div className="w-full">
              <span className="">
                <OrderAmount
                  data={kpidata}
                  setShowDeliveryPartners={setShowDeliveryPartners}
                />
              </span>

              <div>
                <Tableheader
                  setActiveHeading={setActiveHeading}
                  id={id?.data}
                  user_id={user_id}
                  store_id={store_id}
                  // getKPI={getKPI}
                />
              </div>
            </div>
            </>
          )}
        </div>
      </div>
    </div>
    
  );
};

export default Orderpage;
