import UserManagement from "../../../components/Setting/UserManagement";
import React, { useState, useEffect } from "react";
import { Image } from "antd";
import menu from "../../../assets/Images/menudots.svg";
import plus from "../../../assets/Images/plus.svg";
import { Dropdown } from "antd";
import AddUser from "../../../components/Setting/AddUser";
import { Breadcrumb } from "antd";
import {
  DoubleRightOutlined,
  DeleteOutlined,
  EditOutlined,
  StopOutlined,
} from "@ant-design/icons";
import Sidebar from "../../../components/SideBar/sidebar";
import Navbar from "../../../components/Navbar/Navbar";
import { routes } from "../../../Routes";
import {
  blockStoreUser,
  getStoreUsers,
} from "../../../API  Functions/UserMangement";
import { useSelector } from "react-redux";
import Blockuserpopup from "../../../modals/Blockuserpopup";
import { useTranslation } from "react-i18next";
import AddCost from "../../../components/Setting/AddCost";
import {
  deleteShippingCost,
  getShippingCosts,
} from "../../../API  Functions/ShippingCostFunctions";
import { Link } from "react-router-dom";
import i18next from "i18next";
// Dropdown data array

//

const ShippingCost = () => {
  const { t, i18n } = useTranslation();
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const store_id = useSelector((state) => state.login?.user?.store_id);

  // This is for use in add user button to open a new component
  const [showComponent, setShowComponent] = useState(false);
  const [storeuser, setstoreuser] = useState(null);

  const [popup, setpopup] = useState(false);
  const [blockdata, setblockdata] = useState([]);
  const [status, setstatus] = useState(1);
  const [successMessage, setSuccessMessage] = useState("");

  // This is for use in users box details
  const [data, setData] = useState([]);
  //

  const getAllShippingCost = () => {
    getShippingCosts(user_id)
      .then((res) => {
        // console.log("USER REPONSE s",res);

        if (res?.result[0]?.status_code === 200) {
          const AllCost = res.result[0]?.response;

          if (AllCost) {
            setData(AllCost);
          } else {
            console.error("No users found in the response.");
          }
        } else {
          console.error("Response was not successful:", res);
        }
      })
      .catch((error) => {
        console.error("Error fetching store users:", error);
      });
  };
  useEffect(() => {
    getAllShippingCost();
  }, []);

  const deleteShipping = async (val, block) => {
    const payload = {
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
      user_id: user_id,
      shipping_cost_id: val?.id, // required
    };
    const response = await deleteShippingCost(payload);
    console.log("responseAAAA", response);

    if (response?.data?.result[0]?.status === "Shipping Cost Deleted") {
      console.log("response", response?.data);
      // setstatus(block);
      // setblockdata(val);
      setpopup(true);
      setSuccessMessage("Shipping cost deleted successfully!");
      getAllShippingCost();
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <>
          <EditOutlined className="pr-2" />
          {t("edit")}
        </>
      ),
    },
    {
      type: "divider",
    },

    {
      key: "2",
      label: (
        <a
          className=" text-[#0F1828] font-normal"
          target="_blank"
          rel="noopener noreferrer"
          href="#"
        >
          <StopOutlined className="pr-2" />
          {t("block")}
        </a>
      ),
    },
    {
      type: "divider",
    },

    // {
    //   key: "3",
    //   label: (
    //     <a
    //       className=" text-[#0F1828] font-normal text-base"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //       href="#"
    //     >
    //       <DeleteOutlined className="pr-2" />
    //       Delete
    //     </a>
    //   ),
    // },
  ];

  return (
    <>
      <div className="flex">
        {/* <Sidebar /> */}
        <div className="flex flex-col bg-[#F6F7F9] min-h-[93vh]  flex-1 mt-14">
          <Navbar heading="shipping_cost" />
          <div className="mr-4 ml-3 font-extrabold">
            <Breadcrumb
              separator={<DoubleRightOutlined />}
              items={[
                {
                  title: t("settings"),
                  key: "settings",
                  children: (
                    <Link to={routes.settings.shippingCost}>
                      {t("settings")}
                    </Link>
                  ),
                },
                { title: t("shipping_cost") },
              ]}
              className="relative mt-3"
            />
            {showComponent ? (
              <AddCost
                onClose={() => {
                  setShowComponent(false);
                  setstoreuser(null);
                  getAllShippingCost();
                }}
                isEdit={storeuser}
              />
            ) : (
              <>
                <div className="flex justify-between items-center">
                  <div className="font-semibold text-xl mt-2 ">
                    {t("All Shipping Cost")}
                  </div>
                  <button
                    onClick={() => setShowComponent(true)}
                    className={`font-semibold w-40   h-10 border  rounded-lg bg-primaryBlue text-white text-base   flex justify-center items-center gap-2`}
                    type="submit"
                  >
                    <img src={plus} alt="plus" />
                    <p className="">{t("Add Cost")}</p>
                  </button>
                </div>
                <div className="grid grid-cols-3 gap-x-8 mt-1 mb-4">
                  {data.map((val, i) => (
                    <div
                      key={i}
                      className="w-[345px] md:w-full h-30 shadow-sm hover:shadow-lg rounded-xl bg-[#FFFFFF] mt-4 pb-2"
                    >
                      <span className="flex flex-row mt-2 justify-end items-center ">
                        {val && (
                          <Dropdown
                            menu={{
                              items: [
                                {
                                  key: "1",
                                  label: (
                                    <p
                                      onClick={() => {
                                        setstoreuser(val);
                                        setShowComponent(true);
                                      }}
                                    >
                                      <EditOutlined className="pr-2" />
                                      {t("edit")}
                                    </p>
                                  ),
                                },
                                {
                                  type: "divider",
                                },
                                {
                                  key: "2",
                                  label: (
                                    <p onClick={() => deleteShipping(val)}>
                                      <DeleteOutlined className="pr-2" />
                                      {t("Delete")}
                                    </p>
                                  ),
                                },
                                {
                                  type: "divider",
                                },
                              ],
                            }}
                            trigger={["click"]}
                            placement="bottomRight"
                            arrow
                          >
                            <img
                              src={menu}
                              alt="menu"
                              className="float-right cursor-pointer"
                            />
                          </Dropdown>
                        )}
                      </span>

                      <div className="flex flex-col justify-between ms-4">
                        <div className="font-medium text-sm text-[#272728]">
                          <span className="font-semibold">
                            {t("Delivery Area")}:
                          </span>{" "}
                          {val.delivery_area}
                        </div>
                        <div className="font-medium text-sm text-[#272728]">
                          <span className="font-semibold">
                            {t("Service Area")}:
                          </span>{" "}
                          {val.service_area}
                        </div>
                        <div className="font-medium text-sm text-[#272728]">
                          <span className="font-semibold">{t("Level")}:</span>{" "}
                          {val.level}
                        </div>
                        <div className="font-medium text-sm text-[#272728]">
                          <span className="font-semibold">{t("Price")}:</span>{" "}
                          EGP {val.price}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {popup && (
              <Blockuserpopup
                onClose={() => setpopup(false)}
                data={blockdata}
                status={status}
                successMessage={successMessage}
              />
            )}
            {/* <p className='ml-20 mt-20'>Yet to be integrated</p> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShippingCost;
