import { useEffect, useState } from "react";
import "./App.css";
import ProjectRoutes from "./Routes";
import "./global.css"
import "./index.css"
import NoInternetPopup from "./Popups/Nointernet popup/NoInternetPopup";
import { ToastContainer } from "react-toastify";

const App=()=> {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(window.navigator.onLine);
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);
  return (
    <div className="App ">
      {/* <header className="App-header"> */}
      {!isOnline && <NoInternetPopup isVisible={!isOnline} />}
        <ProjectRoutes />
        <ToastContainer />
      {/* </header> */}
    </div>
  );
}

export default App;


// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
