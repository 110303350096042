import Navbar from "../../../components/Navbar/Navbar";
import Sidebar from "../../../components/SideBar/sidebar";
import BasicDetails from "../../../components/Profile/BasicDetails";
import BusinessProof from "../../../components/Profile/BusinessProof";
import ChangePassword from "../../../components/Profile/ChangePassword";
import MySubscription from "../../../components/Profile/MySubscription";
import ShopDetails from "../../../components/Profile/ShopDetails";
import React, { useEffect, useState } from "react";
import image from "../../../assets/Images/roundimage.png";
import { Image } from "antd";
import { Breadcrumb } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { routes } from "../../../Routes";
import { useSelector } from "react-redux";
import BDODetails from "../../../components/Profile/BDODetails";
import { store } from "../../../Redux/store";
import { useTranslation } from "react-i18next";
import "../../../styles.css";
import { GetRetailerDetails } from "../../../API  Functions/ProfileFunctions";
import ShopLocation from "../../../components/Profile/ShopLocation";

const Profile = () => {
  const { t, i18n } = useTranslation();
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const store_id = useSelector((state) => state.login?.user?.store_id);
  const company_name = useSelector(
    (state) => state?.login?.user?.result?.company_name
  );
  const image_url = useSelector(
    (state) => state?.login?.user?.result?.image_url
  );
  const [retailerData, setretailerData] = useState("");

  const [Profile, setprofile] = useState({
    name: company_name,
    image: image_url,
  });

  const fetchdetails = () => {
    GetRetailerDetails(user_id).then(
      (result) => result && setretailerData(result) && setprofile({name: result?.retailer_details?.company_name, image: result?.retailer_details?.logo, })
    );
  };

  useEffect(() => fetchdetails(), []);

  console.log("RETAILER DATA", retailerData);

  //const userid =userDetail.user_id;
  // const accesstoken=userDetail.access_token
  const payload = {
    user_id: user_id,
    store_id: store_id,
    // access_token:accesstoken
  };

  const handleDataSubmit = (dataType, Data) => {
    // console.log(dataType, Data);
  };

  // this condition for active color and active component
  const [selectedValue, setSelectedValue] = useState("basic_details");
  //
  // This array index for render the data in box
  const data = [
    {
      name: "basic_details",
      component: (
        <BasicDetails
          payload={payload}
          onDataSubmit={handleDataSubmit}
          profiledetail={(val) => {
            setprofile(val);
          }}
          data={retailerData}
          fetchdetails={fetchdetails}
        />
      ),
    },
    {
      name: "Shop Location",
      component: (
        <ShopLocation onDataSubmit={handleDataSubmit} data={retailerData} />
      ),
    },

    {
      name: "shop_details",
      component: (
        <ShopDetails
          payload={payload}
          onDataSubmit={handleDataSubmit}
          data={retailerData}
        />
      ),
    },
    {
      name: "business_proof",
      component: (
        <BusinessProof
          payload={payload}
          onDataSubmit={handleDataSubmit}
          data={retailerData}
        />
      ),
    },
    // {
    //   name: "My Subscription",
    //   component: <MySubscription />,
    // },
    // {
    //   name: "change_password",
    //   component: (
    //     <ChangePassword payload={payload} onDataSubmit={handleDataSubmit} />
    //   ),
    // },
    // {
    //   name: "BDO Details",
    //   component: (
    //     <BDODetails   />
    //   ),
    // },
  ];
  //
  return (
    <>
      {/* <div className="float-left">
        <Sidebar />
      </div> */}
      <Navbar heading="My Profile" Profile={Profile} />
      <div className="flex flex-col bg-[#F6F7F9] flex-1 min-h-screen overflow-y-auto custom-scrollbar">
        <div className="mr-4 ml-3 font-extrabold mt-14">
          <Breadcrumb
            separator={<DoubleRightOutlined />}
            items={[
              { title: t("settings"),
                //  href: routes.settings.profile 
                },
              { title: t("profile") },
            ]}
            className="relative top-3"
          />
          <div className="font-semibold text-xl mt-4">{t("my_profile")}</div>
        </div>
        <div
          className="mx-3 sm:mx-3   mt-3 mb-10 bg-white rounded-xl overflow-y-auto custom-scrollbar"
          style={{ minWidth: 700 }}
        >
          <div className="flex ">
            <div className="md:w-[24%] pb-6 border-r-2 text-[#929EAE] flex flex-col text-center items-center font-medium text-lg">
              <div className="mt-4 flex flex-col gap-2 justify-center items-center">
                <img
                  className="h-[85px]  w-[85px] object-cover rounded-full"
                  height={85}
                  width={85}
                  // src={Profile.image ? Profile.image : image}
                  src={`${
                    Profile.image ? Profile.image : image
                  }?time=${new Date().getTime()}`}
                  alt="Image"
                />
                <div className=" text-[#272728] mb-4">{Profile.name}</div>
              </div>
              {data.map((val, i) => (
                <div className="cursor-pointer w-full" key={i}>
                  <div
                    className={`py-3 text-md ${
                      selectedValue === val.name
                        ? "bg-[#D1F1FC] text-black"
                        : "bg-[white]"
                    }`}
                    onClick={() => setSelectedValue(val.name)}
                  >
                    {t(val.name)}
                  </div>
                </div>
              ))}
            </div>
            <div className="overflow-y-auto custom-scrollbar w-full sm:w-[76%] mx-auto">
              {data.map((val, i) => (
                <div
                  key={i}
                  style={{
                    display: val.name === selectedValue ? "block" : "none",
                  }}
                >
                  {val?.component}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
