import React, { useState, useCallback, useEffect } from "react";
import upload from "../assets/Images/Uploadminimalistic.svg";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { useTranslation } from "react-i18next";
import axiosInstance from "../AxiosInstance";
import { URLS } from "../API/API endpoints";
import { useSelector } from "react-redux";

const UploadMedia = ({
  onfileAdd,
  userDetail,
  urlgenerated,
  multiple = false,
  keyname,
  message,
}) => {
  const { t } = useTranslation();
  const [fileError, setFileError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  // const user_id = useSelector((state) => state.login?.user?.result?.user_id);
  const user_id = useSelector((state) => state.login.user.result.user_id);

  const handleUpload = useCallback(async (file, base64Data) => {
    try {
      const payload = {
        user_id: user_id, // Use the user_id from userDetail
        filename: onfileAdd, // Use the filename here
        attachment: base64Data, // Use the base64 data here
      };

      const response = await axiosInstance.post(URLS.UPLOAD_RETAILER_DOCS, payload);
        // process.env.REACT_APP_API_URL, // Update the API endpoint accordingly
        

      const data = {
        name: onfileAdd,
        url: response.data.file_url + "?" + Date.now(), // Assuming the response contains the file URL
      };
      keyname ? urlgenerated(data, keyname) : urlgenerated(data);
    } catch (error) {
      console.error("Error uploading PDF:", error);
    }
  }, [onfileAdd, urlgenerated, userDetail, keyname]);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.size > 5 * 1024 * 1024) { // Check for file size
          setSizeError(true);
        } else {
          setSizeError(false);
          const base64Data = reader.result.split(',')[1]; // Get the base64 part of the data URL
          if (file.type === "application/pdf") {
            handleUpload(file, base64Data);
            setFileError(false);
          } else {
            message(false);
            setFileError(true);
          }
        }
      };
      reader.onerror = (error) => {
        console.log("Error:", error);
      };
    });
  }, [handleUpload, message]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/pdf", // Accept only PDF files
    multiple,
    onDrop,
  });

  return (
    <div className="upload">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <p className="flex flex-col justify-end text-center text-sm text-body">
          <span className="flex flex-row justify-center gap-1 items-center font-semibold text-[#1b4597]">
            <img src={upload} width={18} alt="Upload Icon" />
            {t('upload PDF file')}
          </span>
          <div className="">
            <span className="text-xs text-body text-[#999999]">
              Only PDF files are allowed
            </span>
            <div className="text-xs text-body text-[#999999]">
              (max 5mb)
            </div>
          </div>
        </p>
      </div>
      {sizeError && (
        <p className="text-red text-xs mt-1">File cannot be more than 5 mb</p>
      )}
      {fileError && (
        <p className="text-red text-xs mt-1">Unsupported File Type</p>
      )}
    </div>
  );
};

export default UploadMedia;

// import React, { useState, useCallback, useEffect } from "react";
// import upload from "../assets/Images/Uploadminimalistic.svg";
// import { useDropzone } from "react-dropzone";
// import { Image } from "antd";
// import axios from "axios";
// import { useTranslation } from "react-i18next";

// const UploadMedia = ({
//   onfileAdd,
//   foldername,
//   urlgenerated,
//   multiple = false,
//   keyname,
//   imgurl = "",
//   message,
// }) => {
//   const { t } = useTranslation();
//   const [image, setImages] = useState([]);
//   const [sizeError, setSizeError] = useState(false);
//   const [fileError, setFileError] = useState(false);
//   const [imageurl, setImageUrl] = useState(""); // State to hold the uploaded image URL
//   const [forceUpdate, setForceUpdate] = useState(false); // State to force component update

//   useEffect(() => {
//     if (imageurl) {
//       // Toggle forceUpdate to force re-render of Image component
//       setForceUpdate((prev) => !prev);
//     }
//   }, [imageurl]);

//   const handleUpload = useCallback(async (file, imageArray) => {
//     try {
//       const imageData = file.type.substring(6) === "jpeg"
//         ? imageArray[0].substring(23)
//         : imageArray[0].substring(22);

//       const payload = {
//         image_base64: imageData,
//         bucket_name: process.env.REACT_APP__IMAGE_BUCKET,
//         folder_path: foldername,
//         image_name: `${
//           localStorage?.getItem("store_id")
//             ? localStorage?.getItem("store_id")
//             : JSON.parse(localStorage.getItem("retailer_info"))?.id
//           }-${onfileAdd}.${file.type.substring(6)}`,
//       };

//       const location = await axios.post(
//         process.env.REACT_APP__IMAGE_UPLOAD,
//         payload
//       );

//       const data = {
//         name: onfileAdd,
//         url: location.data.image_url+"?"+Date.now(),
//       };
//       keyname ? urlgenerated(data, keyname) : urlgenerated(data);
//       setImageUrl(data?.url); // Update imageurl state with the new URL
//     } catch (error) {
//       console.error("Error uploading image:", error);
//     }
//   }, [foldername, onfileAdd, urlgenerated, keyname]);

//   const onDrop = useCallback((acceptedFiles) => {
//     acceptedFiles.forEach((file) => {
//       const reader = new FileReader();
//       reader.readAsDataURL(file);
//       reader.onload = () => {
//         if (file.size > 5 * 1024 * 1024) {
//           setSizeError(true);
//         } else {
//           setSizeError(false);
//           const imageArray = [reader.result];
//           setImages(imageArray);

//           if (
//             file.type === "image/jpeg" ||
//             file.type === "image/jpg" ||
//             file.type === "image/png"
//           ) {
//             handleUpload(file, imageArray);
//             setFileError(false);
//           } else {
//             message(false);
//             setFileError(true);
//           }
//         }
//       };
//       reader.onerror = (error) => {
//         console.log("Error:", error);
//       };
//     });
//   }, [handleUpload, message]);

//   const { getRootProps, getInputProps } = useDropzone({
//     accept: "image/jpeg, image/png",
//     multiple,
//     onDrop,
//   });

//   return (
//     <div className="upload">
//       <div {...getRootProps()}>
//         <input {...getInputProps()} />
//         <p className="flex flex-col justify-end text-center text-sm text-body">
//           <span className="flex flex-row justify-center gap-1 items-center font-semibold text-[#1b4597]">
//             {(onfileAdd !== "storeuserurl" || onfileAdd !== "userphoto") && (
//               <img
//                 className=""
//                 src={upload}
//                 width={18}
//                 alt="Upload Icon"
//               />
//             )}
//             {imageurl === "" ? `${" "} ${t('upload')}` : `${" "}${t('change')}`}
//           </span>
//           {onfileAdd !== "userphoto" && (
//             <div className="">
//               {imageurl === "" && (
//                 <>
//                   {" "}
//                   <span className="text-xs text-body text-[#999999]">
//                     png, jpg, jpeg
//                   </span>
//                   <div className="text-xs text-body text-[#999999]">
//                     (max 5mb)
//                   </div>
//                   {/* <div className="text-xs text-body text-[#a6d88b]">
//                     Verified
//                   </div> */}
//                 </>
//               )}
//             </div>
//           )}
//         </p>
//       </div>
//       {sizeError && (
//         <p className="text-red text-xs mt-1">File cannot be more than 5 mb</p>
//       )}
//       {fileError && (
//         <p className="text-red text-xs mt-1">Unsupported File Type</p>
//       )}
//     </div>
//   );
// };

// export default UploadMedia;
