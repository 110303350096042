import React, { useEffect, useState } from "react";
import { InputBox } from "../../components/CustomWidgets/InputBox";
import { useFormik } from "formik";
import SelectBox from "../CustomWidgets/SelectBox";

import "../../styles.css";
import UsermanagementPopup from "../../Popups/usermanagementPopup";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import Select from "react-select";
import { Select } from "antd";
import {
  createStoreUser,
  updateStoreUser,
} from "../../API  Functions/UserMangement";
import {
  createShippingCost,
  getDeliveryZones,
  getServiceAreas,
  getServiceLevels,
  updateShippingCost,
} from "../../API  Functions/ShippingCostFunctions";
import { addCostSchema } from "../validationSchema";

const AddCost = ({ onClose, isEdit }) => {
  const { t, i18n } = useTranslation();

  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const store_id = useSelector((state) => state.login?.user?.store_id);

  const [file, setFile] = useState();
  const [serviceLevels, setServiceLevels] = useState([]);
  const [serviceAreas, setServiceAreas] = useState([]);
  const [deliveryZones, setDeliveryZones] = useState([]);
  const [showUpdatedProfile, setshowUpdatedProfile] = useState(false);
  const [popup, setpopup] = useState(false);
  const [message, setmessage] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [Error, setError] = useState(0);

  // console.log("EDIT DATA", isEdit);

  const formik = useFormik({
    initialValues: {
      service_level: isEdit?.level ?? "",
      service_area: isEdit?.service_area ?? "",
      delivery_zone: isEdit?.delivery_area ?? [], // Ensure this is initialized as an array if it's for multiple selection
      price: isEdit?.price ?? "",
    },
    validationSchema: addCostSchema,
    onSubmit: (values, { resetForm }) => {
      const payload = {
        user_id: user_id,
        level_id: values.service_level,
        area_id: values.service_area,
        zone_ids: values.delivery_zone,
        shipping_cost: Number(values.price),
      };

      if (isEdit) {
        updateShippingCost(payload).then((res) => {
          if (res?.result?.status_code === 200) {
            setmessage("Shipping Cost Updated Successfully");
            setpopup(true);
          }
        });
      } else {
        createShippingCost(payload).then((res) => {
          if (res?.result?.status_code === 200) {
            setmessage("Shipping Cost Created Successfully");
            setpopup(true);
            resetForm();
          } else if (res?.result?.status_code === 400) {
            setError(1);
          }
        });
      }
    },
  });

  useEffect(() => {
    if (isEdit) {
      // Fetch Level ID
      const level = serviceLevels.find(
        (item) => item.level_name === isEdit.level
      );
      const area = serviceAreas.find(
        (item) => item.area_name === isEdit.service_area
      );
      const zone = deliveryZones.find(
        (item) => item.zone_name === isEdit.delivery_area
      );
      console.log("AAA", level);
      console.log("BBB", area);
      console.log("CCC", zone);


      formik.setValues({
        service_level: level ? level.level_id : "", // Set level_id
        service_area: area ? area.area_id : "", // Set area_id
        delivery_zone: zone ? [zone.zone_id] : [], // Set zone_id as an array
        price: isEdit.price ?? "",
      });
    }
  }, [isEdit, serviceLevels, serviceAreas, deliveryZones]);

  useEffect(() => {
    const getServiceLevelsData = () => {
      getServiceLevels({
        user_id,
      })
        .then((res) => {
          if (
            res?.result &&
            Array.isArray(res.result) &&
            res.result.length > 0
          ) {
            setServiceLevels(res.result[0]?.response);
          } else {
            setServiceLevels([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching service levels:", error);
          setServiceLevels([]);
        });
    };

    getServiceLevelsData();
  }, [user_id]);

  useEffect(() => {
    if (formik.values.service_level) {
      const fetchServiceAreas = () => {
        getServiceAreas({
          level_id: formik.values.service_level,
        })
          .then((res) => {
            if (
              res?.result &&
              Array.isArray(res.result) &&
              res.result.length > 0
            ) {
              setServiceAreas(res.result[0]?.response);
            } else {
              setServiceAreas([]);
            }
          })
          .catch((error) => {
            console.error("Error fetching service areas:", error);
            setServiceAreas([]);
          });
      };

      fetchServiceAreas();
    }
  }, [formik.values.service_level, i18n.language]);

  useEffect(() => {
    if (formik.values.service_area) {
      const fetchDeliveryZones = () => {
        getDeliveryZones({
          area_id: formik.values.service_area,
        }).then((res) => {
          if (res?.result[0]?.status_code === 200) {
            setDeliveryZones(res?.result[0]?.response);
          }
        });
      };

      fetchDeliveryZones();
    }
  }, [formik.values.service_area, i18n.language]);

  // Update form fields if editing
  useEffect(() => {
    if (isEdit) {
      formik.setValues({
        service_level: isEdit?.level ?? "",
        service_area: isEdit?.service_area ?? "",
        delivery_zone: isEdit?.delivery_area ?? "",
        price: isEdit?.price ?? "",
      });
    }
  }, [isEdit]);

  const handleDeliveryZoneChange = (selectedValues) => {
    formik.setFieldValue("delivery_zone", selectedValues);
  };

  const isFormValid = () => {
    return (
      formik.values.service_level &&
      formik.values.service_area &&
      formik.values.delivery_zone &&
      formik.values.price
    );
  };

  console.log("VALUEEESSS", formik.values);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="">
          <div className="flex justify-between items-baseline">
            <div className="font-semibold text-2xl mt-5">
              {isEdit ? t("edit_user") : t("Add Shipping Cost")}
            </div>
            <div className="flex gap-5 ">
              <button
                onClick={() => onClose()}
                className="w-36 h-10 bg-white text-primaryBlue font-semibold rounded-lg inline border border-primaryBlue"
              >
                {t("cancel")}
              </button>
              <button
                type="submit"
                className={`w-36 h-10 rounded-lg font-semibold ${
                  isFormValid()
                    ? "bg-primaryBlue text-white"
                    : "bg-primaryBlue text-white opacity-50"
                }`}
                disabled={!isFormValid()}
                // className="w-36 h-10 bg-primaryBlue text-white rounded-lg font-semibold"
              >
                {t("add")}
              </button>
            </div>
          </div>
          <div className="md:w-full min-h-auto bg-[#FFFFFF] border rounded-xl mt-5">
            <div className="w-full h-10 bg-[#C9ECF7] px-4 flex items-center font-medium text-base rounded-tr-xl rounded-tl-xl text-[#272728]">
              {t("Shipping Cost Details")}
            </div>
            <div className="flex justify-center mt-4 pb-4 gap-x-8 px-6">
              <div className="font-normal text-sm text-[#5C5C5C] flex flex-col gap-6 sm:flex-row sm:gap-7 sm:items-baseline sm:w-[75%]">
                <div className="w-full flex flex-col gap-4 leading-[0px]">
                  <div className="flex flex-col text-[#5C5C5C] gap-2 mt-2">
                    <label>{t("Service Level")}</label>
                    <SelectBox
                      name="service_level"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.service_level}
                      touched={formik.touched.service_level}
                      error={formik.errors.service_level}
                      options={
                        Array.isArray(serviceLevels)
                          ? serviceLevels.map((item) => ({
                              value: item.level_id,
                              label: item.level_name,
                            }))
                          : []
                      }
                      disabled={isEdit}
                    />
                  </div>
                  <div className="flex flex-col text-[#5C5C5C] gap-2 mt-2">
                    <label>{t("Delivery Zone")}</label>
                    <Select
                      style={{ width: "100%", overflowY: "hidden" }}
                      mode="multiple"
                      className="h-10 mt-2 w-full text-md text-body !border-[#CCCCCC] !text-[#5C5C5C]"
                      defaultValue={formik.values.delivery_zone}
                      value={formik.values.delivery_zone}
                      touched={formik.touched.delivery_zone}
                      error={formik.errors.delivery_zone}
                      onChange={handleDeliveryZoneChange}
                      onBlur={formik.handleBlur}
                      dropdownStyle={{ maxHeight: 200, overflowY: "auto" }}
                      options={deliveryZones?.map((zone) => ({
                        value: zone.zone_id,
                        label: zone.zone_name,
                      }))}
                      disabled={isEdit}
                    />
                  </div>
                </div>

                <div className="w-full flex flex-col gap-4 leading-[0px]">
                  <div className="flex flex-col gap-2">
                    <label className="text-[14px] text-[#5C5C5C]">
                      {t("Service Area")}
                    </label>
                    <SelectBox
                      name="service_area"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      touched={formik.touched.service_area}
                      error={formik.errors.service_area}
                      className="h-10 mt-4 w-full text-md text-body !border-[#CCCCCC] !text-[#5C5C5C]"
                      value={formik.values.service_area}
                      options={
                        Array.isArray(serviceAreas)
                          ? serviceAreas.map((item) => ({
                              value: item.area_id,
                              label: item.area_name,
                            }))
                          : []
                      }
                      disabled={isEdit}
                    />
                  </div>

                  <div className="mt-2">
                    <label className="">{t("Price")}</label>
                    <InputBox
                      name="price"
                      type="number"
                      placeholder={t("Enter value in EGP")}
                      onChange={(val) => {
                        const value = val.target.value;
                        if (/^\d{0,4}$/.test(value)) {
                          formik.handleChange(val);
                          setError(0);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      touched={formik.touched.price}
                      error={formik.errors.price}
                      value={formik.values.price}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {popup && (
        <UsermanagementPopup
          message={message}
          close={() => {
            setpopup(false);
            if (message === "User Updated Successfully") onClose();
          }}
        />
      )}
    </div>
  );
};

export default AddCost;
