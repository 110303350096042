import React, { useState } from "react";
import one from "../../assets/Order/one.svg";
import two from "../../assets/Order/two.svg";
import three from "../../assets/Order/three.svg";
import fourth from "../../assets/Order/fourth.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const OrderAmount = ({ setShowDeliveryPartners }) => {
  // console.log("DTAA OF DASHBOARD ", data);
  const data = useSelector((state) => state?.EorderReducer?.kpiData)

  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="flex flex-wrap mt-5">
        <div className="w-1/4 sm:w-1/4 md:w-1/4 xl:w-1/4 px-3 mb-2 cursor-pointer">
          <div className="w-full bg-[#D1F1FC] rounded-lg flex flex-row items-center p-2 px-3 xl:mb-0 shadow-sm relative">
            <>
              {" "}
              <img
                src={one}
                layout="fixed"
                width={40}
                height={40}
                className="absolute top-2 left-2"
                alt="img"
              />
            </>
            <div className="text-gray-700 w-[15rem] flex flex-col items-center justify-center">
              <p className="font-normal text-md">{t("total_amount")}</p>
              <div className="flex items-center gap-1">
                <p className="font-medium text-xl  pt-2">
                 EGP {data?.total_order_amount ? data.total_order_amount : "EGP 0"}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-1/4 sm:w-1/4 md:w-1/4 xl:w-1/4 px-3 mb-2 cursor-pointer">
          <div className="w-full bg-[#D1F1FC] rounded-lg flex flex-row items-center p-2 px-3 xl:mb-0 shadow-sm relative">
            <>
              {" "}
              <img
                src={two}
                layout="fixed"
                width={40}
                height={40}
                className="absolute top-2 left-2"
                alt="img"
              />
            </>
            <div className="text-gray-700 w-[15rem] flex flex-col items-center justify-center">
              <p className="font-normal text-md">{t("cod_orders")}</p>
              <div className="flex items-center gap-1">
              <p className="font-medium text-xl  pt-2">
                  {data?.cod_orders ? data.cod_orders : "0"}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-1/4 sm:w-1/4 md:w-1/4 xl:w-1/4 px-3 mb-2 cursor-pointer">
          <div className="w-full bg-[#D1F1FC] rounded-lg flex items-center p-2 px-3 shadow-sm relative">
            <img
              src={three}
              layout="fixed"
              width={40}
              height={40}
              className="absolute top-2 left-2"
              alt="img"
            />
            <div className="text-gray-700 w-[15rem] flex flex-col items-center justify-center">
              <p className="font-normal text-md ">{t("paid_orders")}</p>
              <div className="flex items-center gap-1">
              <p className="font-medium text-xl  pt-2">
                  {data?.paid_orders ? data.paid_orders : "EGP 0"}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="w-1/4 sm:w-1/4 md:w-1/4  xl:w-1/4 px-3 mb-2 cursor-pointer"
          onClick={() => setShowDeliveryPartners(true)}
        >
          <div className="w-full bg-[#D1F1FC] rounded-lg flex items-center p-2 px-3 shadow-sm relative">
            <img
              src={fourth}
              layout="fixed"
              width={40}
              height={40}
              className="absolute top-2 left-2"
              alt="img"
            />
            <div className="text-gray-700 flex w-[15rem] flex-col items-center justify-center">
              <p className="font-normal text-md ">{t("delivery_boy")}</p>
              <p className="font-medium text-xl  pt-2">
                {data?.delivery_partner ? data.delivery_partner : "0"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderAmount;
