// formReducer.js
import {
    SET_VENDOR,
    SET_BILL_DATE,
    SET_BILL_NUMBER,
    SET_VAT_NUM,
    SET_PRODUCT_LIST,
  } from '../Action/formActions';
  
  const initialState = {
    vendor: null,
    billDate: null,
    billNumber: '',
    vatNum: '',
    productList: [],
  };
  
  const formReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_VENDOR:
        return { ...state, vendor: action.payload };
      case SET_BILL_DATE:
        return { ...state, billDate: action.payload };
      case SET_BILL_NUMBER:
        return { ...state, billNumber: action.payload };
      case SET_VAT_NUM:
        return { ...state, vatNum: action.payload };
      case SET_PRODUCT_LIST:
        return { ...state, productList: action.payload };
      default:
        return state;
    }
  };
  
  export default formReducer;