import React, { useState, useEffect } from "react";
import plus from "../../../src/assets/Images/plus.svg";
import { Breadcrumb } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import Navbar from "../../components/Navbar/Navbar";
import { routes } from "../../Routes";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import i18next from "i18next";
import {
  getPosSessionHistory,
  getPosSessionList,
} from "../../API  Functions/SessionHistoryFunctions";
import CloseSessionModal from "../../modals/CloseSession_Modal";
import { Drawer } from "antd";
import SelectBox from "../../components/CustomWidgets/SelectBox";
import { DatePicker } from "antd";
import {
  getBillPaymentHistory,
  getPaymentsList,
} from "../../API  Functions/PurchaseFunctions";
import moment from "moment";
import PurchasePayment from "../../modals/purchasePaymentModal";
import SearchBox from "../../components/CustomWidgets/SearchBox";
import LoadingSpinner from "../../components/Loading";
const { RangePicker } = DatePicker;

const SupplyPayment = () => {
  const { t, i18n } = useTranslation();
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);

  const [popup, setpopup] = useState(false);
  const [selectedBillId, setSelectedBillId] = useState(null);
  const [selectedDueAmount, setSelectedDueAmount] = useState(null);
  const [visible, setVisible] = useState(false);
  const [paymentHistoryData, setPaymentHistoryData] = useState([]);
  const [drawerData, setDrawerData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedPaymentType, setSelectedPaymentType] = useState(
    t("all_orders")
  );
  const [dateRange, setDateRange] = useState([null, null]);
  const [noOrdersFound, setNoOrdersFound] = useState(false);
  const [fetchedOrdersCount, setFetchedOrdersCount] = useState(0); // Track the number of orders fetched
  const [currentPage, setCurrentPage] = useState(1); // Current page

  const [data, setData] = useState([]);

  const showDrawer = (historyData) => {
    setDrawerData(historyData);
    setVisible(true);
    getPaymentHistory(historyData.bill_id);
    setSelectedUserId(historyData.bill_id);
  };

  useEffect(() => {
    getSessionList();
  }, [popup]);

  const getPaymentHistory = (billId) => {
    const payload = {
      bill_id: billId,
    };
    getBillPaymentHistory(payload)
      .then((res) => {
        if (res?.result?.payments.length > 0) {
          setPaymentHistoryData(res?.result?.payments);
          setLoading(false);
        } else {
          console.error("No session history found");
        }
      })
      .catch((error) => {
        console.error("Error fetching session history:", error);
      });
  };
  const onClose = () => {
    setVisible(false);
  };

  const getSessionList = (
    search = "",
    paymentType = "all",
    startDate = null,
    endDate = null,
    offset
  ) => {
    let paymentStatus = "";
    if (paymentType === 1) {
      paymentStatus = "paid";
    } else if (paymentType === 2) {
      paymentStatus = "not_paid";
    } else if (paymentType === 3) {
      paymentStatus = "partial";
    }
    setLoading(true);
    const payload = {
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
      user_id: user_id,
      payment_status: paymentStatus,
      vendor_name: search,
      start_date: startDate,
      end_date: endDate,
      limit: 10,
      offset: offset * 10,
    };

    getPaymentsList(payload)
      .then((res) => {
        if (res?.result?.status === "No Orders") {
          setNoOrdersFound(true);
          setData([]);
          setLoading(false);
        } else if (res?.result?.vendor_bills.length > 0) {
          const AllList = res.result?.vendor_bills;
          setData(AllList);
          setFetchedOrdersCount(AllList.length);
          setNoOrdersFound(false);
          setLoading(false);
        } else {
          console.error("Response was not successful:", res);
          setNoOrdersFound(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching store users:", error);
        setNoOrdersFound(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    getSessionList();
  }, []);

  const handlePaymentClick = (val) => {
    setSelectedBillId(val?.bill_id);
    setSelectedDueAmount(val?.due_amount);
    setIsModalVisible(true);
  };

  const handleSearchChange = (value) => {
    setSearchKeyword(value);
    getSessionList(value, selectedPaymentType, dateRange[0], dateRange[1], 0);
  };

  const handlePaymentTypeChange = (event) => {
    const paymentType = event.target.value;
    setSearchKeyword("");
    setSelectedPaymentType(paymentType);
    getSessionList(searchKeyword, paymentType, dateRange[0], dateRange[1], 0);
  };

  const handleRangePickerChange = (dates, dateStrings) => {
    setDateRange(dates);
    getSessionList(
      searchKeyword,
      selectedPaymentType,
      dateStrings[0],
      dateStrings[1],
      0
    );
  };

  const options = [
    {
      value: 0,
      label: `${t("all_orders")}`,
    },
    {
      value: 1,
      label: `${t("Paid")}`,
    },
    {
      value: 2,
      label: `${t("Unpaid")} `,
    },
    {
      value: 3,
      label: `${t("Partial Payment")} `,
    },
  ];

  //   console.log("CURRENT PAGE ", currentPage);

  return (
    <>
      {loading && <LoadingSpinner />}

      <div className="flex">
        <div className="flex flex-col bg-[#F6F7F9] min-h-[93vh]  flex-1 mt-14">
          <Navbar heading="Purchase" />
          <div className="mr-4 ml-3 font-extrabold">
            <Breadcrumb
              separator={<DoubleRightOutlined />}
              items={[
                {
                  title: t("Purchase"),
                  key: "purchase",
                  children: (
                    <Link to={routes.purchase.supplyPayment}>
                      {t("supplyPayment")}
                    </Link>
                  ),
                },
                { title: t("Supply Payment") },
              ]}
              className="relative mt-3"
            />
            <div className="flex justify-between items-end mt-2">
              <SearchBox
                placeholder={t("Search Supplier/Order No")}
                searchResult={handleSearchChange}
              />
              <div className="flex gap-x-4 items-end justify-end ">
                <SelectBox
                  style={{ width: 190 }}
                  width={200}
                  placeholder={"all_orders"}
                  name="filter"
                  onChange={handlePaymentTypeChange}
                  options={options}
                  value={selectedPaymentType}
                />
                <RangePicker
                  className="w-10 h-10 range-picker-placeholder"
                  style={{ minWidth: 220 }}
                  onChange={handleRangePickerChange}
                  placeholder={[t("Start Date"), t("End Date")]}
                  // locale={i18next.language === "ar" ? arEG : undefined}
                />
              </div>
            </div>
            <>
              <div className="flex justify-between items-center"></div>
              {noOrdersFound ? (
                <p className="text-center text-red-500 mt-20">
                  {t("No Orders Found")}
                </p>
              ) : (
                <div className="grid grid-cols-1 gap-x-8 mt-1 mb-4">
                  {data.map((val, i) => (
                    <div
                      key={i}
                      className="w-[345px] md:w-full h-30 shadow-sm hover:shadow-lg rounded-xl bg-[#FFFFFF] mt-4 pb-2"
                    >
                      <div className="flex flex-col justify-between ms-4 me-4 mt-4">
                        <div className="flex flex-row justify-between font-medium text-lg text-[#272728]">
                          <span className="font-semibold">
                            {val.supplier_name}
                          </span>
                          <span
                            className={`font-semibold text-sm justify-end rounded-lg ${
                              val?.paid_amount > 0 && val?.due_amount > 0
                                ? "bg-yellow-500 text-white" // Partial Payment
                                : val?.paid_amount === 0
                                ? "bg-yellow-500 text-white" // Unpaid
                                : val?.paid_amount > 0 && val?.due_amount === 0
                                ? "bg-green-500 text-white" // Full Payment
                                : "text-gray-500" // Default case
                            }`}
                            style={{
                              display: "inline-block",
                              padding: "0.5rem 1rem",
                            }}
                          >
                            {t(
                              val?.paid_amount > 0 && val?.due_amount > 0
                                ? "Partial Payment"
                                : val?.paid_amount === 0
                                ? "Unpaid"
                                : val?.paid_amount > 0 && val?.due_amount === 0
                                ? "Full Payment"
                                : "Unknown Status"
                            )}
                          </span>
                        </div>

                        <div className="">
                          <p className="flex flex-row font-semibold text-sm">
                            {t("Order No.")}:
                            <p className="font-normal ms-2">
                              {val.order_no || "N/A"}
                            </p>
                          </p>
                          <p className="flex flex-row font-semibold text-sm">
                            {t("Order Date")}:{" "}
                            <p className="font-normal ms-2">
                              {val.order_date
                                ? moment(val.order_date).format("YYYY-MM-DD")
                                : "N/A"}
                            </p>
                          </p>
                          <p className="flex flex-row font-semibold text-sm">
                            {t("Bill No.")}:
                            <p className="font-normal ms-2">
                              {val.vendor_reference || "N/A"}
                            </p>
                          </p>
                        </div>

                        <>
                          <div className="grid grid-cols-5 gap-4 mt-4 mx-4">
                            <div className="bg-[#F6F6F6] p-2 rounded-lg text-center">
                              <p className="font-normal text-sm">
                                {t("Invoice Value")}
                              </p>
                              <p className="text-sm">
                                {val.invoice_value || 0}
                              </p>
                            </div>
                            <div className="bg-[#F6F6F6] p-2 rounded-lg text-center">
                              <p className="font-normal text-sm">
                                {t("Paid Amount")}
                              </p>
                              <p className="text-sm">{val.paid_amount || 0}</p>
                            </div>
                            <div className="bg-[#ffe9e9] p-2 rounded-lg text-center">
                              <p className="font-normal text-sm">
                                {t("Due Amount")}
                              </p>
                              <p className="text-sm">{val.due_amount || "0"}</p>
                            </div>
                          </div>
                        </>
                        {/* )} */}

                        <div className="flex flex-row justify-end ms-4 gap-2">
                          <button
                            onClick={() => showDrawer(val)}
                            className={`font-semibold w-36 h-10 text-sm border border-primaryBlue rounded-lg bg-white text-primaryBlue flex justify-center items-center gap-2`}
                            type="submit"
                          >
                            <p className="">{t("Payment History")}</p>
                          </button>
                          {!(val.paid_amount > 0 && val.due_amount === 0) && (
                            <button
                              onClick={() => handlePaymentClick(val)}
                              className={`font-semibold w-32 h-10 border rounded-lg bg-primaryBlue text-white text-sm flex justify-center items-center gap-2`}
                              type="submit"
                            >
                              <p className="">{t("Make Payment")}</p>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="flex justify-between mt-4">
                    <button
                      onClick={() => {
                        if (currentPage > 1) {
                          setCurrentPage(currentPage - 1);
                          getSessionList(
                            searchKeyword,
                            selectedPaymentType,
                            dateRange[0],
                            dateRange[1],
                            currentPage - 1
                          );
                        }
                      }}
                      //   className="bg-primaryBlue text-white rounded-lg w-24 h-10"
                      className={`bg-primaryBlue rounded-lg w-24 h-10 ${
                        currentPage === 1
                          ? "opacity-50 cursor-not-allowed"
                          : "text-white"
                      }`}
                      disabled={currentPage === 1}
                    >
                      <p className="text-white">{t("Previous")}</p>
                    </button>
                    <span>
                      {t("Page")} {currentPage}
                    </span>
                    <button
                      onClick={() => {
                        if (fetchedOrdersCount === 10) {
                          // Only increment if we fetched 10 orders
                          setCurrentPage(currentPage + 1);
                          getSessionList(
                            searchKeyword,
                            selectedPaymentType,
                            dateRange[0],
                            dateRange[1],
                            currentPage + 1
                          );
                        }
                      }}
                      className={`bg-primaryBlue rounded-lg w-16 ${
                        fetchedOrdersCount < 10
                          ? "opacity-50 cursor-not-allowed"
                          : "text-white"
                      }`}
                      disabled={fetchedOrdersCount < 10}
                    >
                      <p className="text-white">{t("Next")}</p>
                    </button>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
      {isModalVisible && (
        <PurchasePayment
          isOpen={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          bill_id={selectedBillId}
          page={"supplyPayment"}
          due_amount={selectedDueAmount}
          Popup={() => {
            setpopup(true);
          }}
        />
      )}

      <Drawer
        title={t("Payment History")}
        placement="right"
        width={500}
        onClose={onClose}
        visible={visible}
      >
        {/* Display the session history data */}
        {loading ? (
          <div>{t("Loading session data...")}</div>
        ) : (
          <div>
            {paymentHistoryData.length > 0 ? (
              <>
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="p-2 text-start">{t("Payment Date")}</th>
                      <th className="p-2 text-start">{t("Type")}</th>
                      <th className="p-2 text-start">{t("amount")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentHistoryData.map((payment, index) => (
                      <tr key={index} className="border-b">
                        <td className="p-2">
                          {moment(payment.payment_date).format("YYYY-MM-DD")}
                        </td>
                        <td className="p-2">{t(payment.type)}</td>
                        <td className="p-2">{payment.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <p>{t("No payment history available")}</p>
            )}
          </div>
        )}
      </Drawer>
    </>
  );
};

export default SupplyPayment;
