import { CloseOutlined } from "@ant-design/icons";
import { Input, Select } from "antd";

import React, { useState, useEffect } from "react";
import {
  getLahloobCancelReasons,
  getcancelReasons,
  lahloobCancelUpdate,
  updateorderStage,
  updateordercancelreason,
} from "../API  Functions/OrderlistFunction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
  container: {
    width: "500px",
    padding: "30px",
    borderRadius: "18px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: "25px",
    fontWeight: "bold",
    textAlign: "left",
    marginBottom: "10px",
  },
  cancelIcon: {
    cursor: "pointer",
  },
  description: {
    textAlign: "left",
    fontSize: "16px",
    color: "#272728",
    marginTop: "10px",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "12px",
    border: "1px solid #CCCCCC",
    marginBottom: "20px",
    boxSizing: "border-box",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: "180px",
    height: "45px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    marginRight: "10px",
  },
  confirmButton: {
    backgroundColor: "primaryBlue",
    color: "#FFFFFF",
  },
  cancelButton: {
    backgroundColor: "#CCCCCC",
    color: "#000000",
  },
};

const CancelOrder = ({ isOpen, onClose, order_id, popup }) => {
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const is_lahloob = useSelector(
    (state) => state.login?.user?.result?.is_lahloob
  );
 
  
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const [CancelReasons, setCancelReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState(t("select"));
  const [customReason, setCustomReason] = useState("");
  const [showCustomInput, setShowCustomInput] = useState(false); 



  const fetchcancelreasons = () => {
    if (!is_lahloob){
      getcancelReasons(user_id).then(
        (result) => result && setCancelReasons(result)
      );
    }else{
      getLahloobCancelReasons(user_id).then(
        (result) => result && setCancelReasons(result)
      );
    }
    
  };

  useEffect(() => fetchcancelreasons(), []);

  const handleSelectChange = (value) => {
    setSelectedReason(value);

    const selectedReasonObj = CancelReasons.find(
      (item) => item.reason_id === value
    );
    if (selectedReasonObj && selectedReasonObj.reason_id === 7) {
      setShowCustomInput(true); 
    } else {
      setShowCustomInput(false); 
      setCustomReason(""); 
    }
  };

  // console.log("SELECTED REASON",selectedReason );

  const handleSubmit = (e) => {
    e.preventDefault();
    let reasonToSubmit;
  
    // Construct the reason to submit
    if (selectedReason === 7 && customReason) {
      reasonToSubmit = `${selectedReason}: ${customReason}`;
    } else {
      reasonToSubmit = selectedReason;
    }
  
    if (is_lahloob) {
      const lahloobCancelPayload = {
        order_id: order_id,
        ...(selectedReason ? { reason_id: selectedReason } : {}),
      };
  
      lahloobCancelUpdate(lahloobCancelPayload).then((response) => {
        console.log("Lahloob order cancelled", response);
        if (response?.data?.result[0]?.status === "Order Cancel Successfully") {
          popup();
        }
      });
    } else {
      updateordercancelreason(user_id, order_id, reasonToSubmit).then(
        (response) => {
          console.log("cancelled", response);
          if (response?.data?.result[0]?.status === "Order Cancel Successfully") {
            popup();
          }
        }
      );
    }
  
    onClose();
  };
  
  
  return (
    <>
      {isOpen && (
        <>
          <div style={styles.overlay} onClick={onClose}></div>
          <div style={styles.container}>
            <div style={styles.header}>
              <p style={styles.title}>{t("Cancel Order")}</p>
              <CloseOutlined style={styles.cancelIcon} onClick={onClose} />
            </div>
            <p style={styles.description}>{t("Select Cancel Reason")}</p>
            <form onSubmit={handleSubmit}>
              <div className="flex flex-row mb-5 mt-2 justify-between">
                <Select
                  style={{ width: 500, height: 40 }}
                  value={selectedReason}
                  onChange={handleSelectChange}
                >
                  {CancelReasons?.map((item) => (
                    <>
                      <Option key={item.reason_id} value={item.reason_id}>
                        {item.name}
                      </Option>
                    </>
                  ))}
                </Select>
              </div>
              {showCustomInput && (
                <Input
                  placeholder={t("Please specify your reason")}
                  value={customReason}
                  onChange={(e) => setCustomReason(e.target.value)}
                  style={{ marginBottom: "20px" }}
                />
              )}
              <div className="flex justify-center">
                <button
                  className="w-52 h-10 border-primaryBlue border text-primaryBlue  font-semibold rounded-lg me-5"
                  onClick={() => onClose()}
                >
                  {t("close")}
                </button>
                <button
                  type="submit"
                  className={`w-52 h-10 ${
                    selectedReason === (t("select")) ||
                    (showCustomInput && !customReason)
                      ? "bg-primaryBluedisabled"
                      : "bg-primaryBlue"
                  } text-white font-semibold rounded-lg`}
                  disabled={
                    selectedReason === (t("select")) ||
                    (showCustomInput && !customReason)
                  }
                >
                  {t("save")}
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default CancelOrder;
