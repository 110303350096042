import axiosInstance from "../AxiosInstance";
import { URLS } from "../API/API endpoints";
import { store } from "../Redux/store";
import i18next from "i18next";

const { login } = store.getState();

// const user_id = login.user;
// const store_id = login.user;


export const getPosSessionList = async (user_id) => {
  try {
    const payload = {
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
      user_id: user_id,
      //  store_id:store_id,
    };
    const response = await axiosInstance.post(URLS.GET_POS_SESSIONS, payload);
    // console.log("Shipping Cost", response);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getPosSessionHistory = async (payload) => {
  try {
    const response = await axiosInstance.post(URLS.GET_POS_SESSION_HISTORY, payload);
    console.log("GET_POS_SESSION_HISTORY", response);

    return response.data;
  } catch (error) {
    return error;
  }
};


