export const URLS = {
  // New Inventory APIs Odoo
  GET_INVENTORY_DASHBOARD: "/Store/1/GetInventoryDashboard",
  GET_ALL_PRODUCTS: "/Store/1/GetInstantStockDetails",
  GET_RETAILER_PRODUCT_CATEGORY: "/Store/1/GetCategories",
  GET_INCOMPLETE_PRODUCTS: "/Store/1/GetSalesCostPriceProducts",
  UPDATE_STORE_PRODUCT_DETAIL: "/Store/1/InstantStockUpdate",
  UPDATE_STORE_INCOMPLETE_PRODUCT_DETAIL: "/Store/1/AddSalesCostPrice",
  SEARCH_INVENTORY_ONBOARD: "Store/1/ScanBarcode",
  ADD_PRODUCT: "Store/1/AddProduct",
  GET_UOM: "Store/1/GetUoms",
  PRODUCT_REQUEST: "/Store/1/ProductRequest",
  UPDATE_GS_PRODUCT_REQUEST: "/update_gs_product_request",
  UPLOAD_BULK_INVENTORY: "/UploadBulkInventory",

  // New E-Orders APIs Odoo
  GET_EORDER_DASHBOARD: "Store/1/GetEOrderDashboard",
  ORDER_SEARCH_FILTER: "Store/1/Lahloob/SearchViewOrder",
  GET_PAYMENT_INFO: "Store/1/Lahloob/GetBillDetails",
  GET_ORDER_CANCEL_REASONS: "Store/1/CancelReason",
  LAHLOOB_CANCEL_REASON: "Store/1/Lahloob/RetailerCancelReason",
  LAHLOOB_CANCEL_ORDER: "Store/1/Lahloob/RetailerCancelOrder",
  UPDATE_CANCEL_REASON: "Store/1/CanceOrder",
  ACCEPT_ORDER: "Store/1/AceeptOrder",
  LAHLOOB_ACCEPT_ORDER: "/Lahloob/StoreAcceptOrder",
  LAHLOOB_UPDATE_PICKER_ORDER: "/Lahloob/StorePickerUpdateOrder",
  LAHLOOB_ORDER_PREPARED: "Store/1/Lahloob/OrderReadyForDelivery",
  UPDATE_E_ORDER: "Store/1/UpdateEOrder",
  PROCESS_ORDER: "Store/1/ProcessOrder",
  GET_DELIVERY_BOY: "Store/1/GetDeliveryBoy",
  SET_DELIVERY_BOY: "Store/1/AssignDeliveryBoy",
  ORDER_DELIVER: "/DeliveryBoyDeliveredOrder",
  LAHLOOB_CONFIRM_RETURN_ORDER: "Store/1/Lahloob/ConfirmReturnOrder",

  //Login,Signup and Logout Api ODOO
  LOGIN_API_URL: "Store/1/Lahloob/UserLogin",
  SIGNUP_OTP_API_URL: "Store/1/NewNumberSentOtp",
  VERIFY_OTP_API_URL: "Store/1/NewNumberVerifyOtp",
  LOGOUT_API_URL: "retialer-logout",

  //Register Api ODOO
  USER_SIGNUP_API_URL: "Store/1/UserSignup",

  // SHOP_LOCATION_API_URL: "GetDeliveryArea" ODOO
  SHOP_LOCATION_API_URL: "/V2/19/delivery_area_fencing",
  GET_STORE_TYPE: "/Store/1/ShopType",
  UPDATE_RETAILER_DETAILS: "/Store/1/UpdateRetailerDetails",

  // Image upload urls
  UPLOAD_IMAGE_URLS: "/Store/1/RetailerShopImagesUpload",
  PROD_UPLOAD_IMAGE_URLS: "Store/1/ProductImageUpload",
  RETAILER_SHOP_IMAGE_UPLOAD: "/Store/1/RetailerShopImagesUpload",

  UPLOAD_RETAILER_DOCS: "/Store/1/UploadRetailerDocuments",

  // MY PROFILE API ODOO
  GET_RETAILER_DETAILS: "Store/1/GetRetailerDetails",
  GET_RETAILER_DOCS: "Store/1/GetRetailerDocuments",
  UPLOAD_RETAILER_DOCUMENTS: "Store/1/UploadRetailerDocuments",

  // USER MANAGEMENT API ODOO
  GET_RETAILER_USER: "Store/1/GetReatilerUser",
  DEACTIVATE_RETAILER_USER: "Store/1/DeactivateReatilerUser",
  CREATE_REAILER_USER: "Store/1/CreateReatilerUser",
  EDIT_RETAILER_USER: "Store/1/UpdateReatilerUser",

  // SHIPPING COST API ODOO
  GET_SHIPPING_COSTS: "Store/1/GetShippingCost",
  GET_SERVICE_LEVEL: "Store/1/GetServiceLevel",
  GET_SERVICE_AREA: "Store/1/GetServiceArea",
  GET_DELIVERY_ZONE: "Store/1/GetDeliveryZone",
  CREATE_MULTIPLE_SHIPPING_COST: "Store/1/CreateMultipleShippingCost",
  UPDATE_SHIPPING_COST: "Store/1/CreateShippingCost",
  DELETE_SHIPPING_COST: "Store/1/DeleteShippingCost",

  // DELIVERY BOYS API ODOO
  CREATE_DELIVERY_BOY: "Store/1/CreateDeliveryBoy",
  DEACTIVATE_DELIVERY_BOY: "Store/1/DeactivateDeliveryBoy",
  UPDATE_DELIVERY_BOY: "Store/1/UpdateDeliveryBoy",
  GET_DELIVERY_BOY_ORDERS: "Store/GetDeliveryBoyOrders",

  // SESSION HISTORY API ODOO
  GET_POS_SESSIONS: "Store/1/GetPosSessionList",
  GET_SESSION_SUMMARY: "/PosOpenSessionDataAdmin",
  GET_POS_SESSION_HISTORY: "Store/1/GetPosSessionHistory",

  // PURCHASE API ODOO
  GET_VENDORS: "Store/1/GetVendors",
  CREATE_VENDOR: "Store/1/CreateVendor",
  SEARCH_PRODUCTS: "Store/1/SearchProduct",
  GET_PAYMENT_LIST: "Store/1/AllVendorBillsSin",
  GET_BILL_PAYMENT_HISTORY: "Store/1/BillPaymentDetails",
  GET_PAYMENT_TERMS: "/Store/1/GetJournals",
  REGISTER_PAYMENT: "Store/1/RegisterPayment",
  GET_PURCHASE_LIST: "Store/1/GetPurchaseOrder",
  UPLOAD_PO_IMAGE: "Store/1/UploadPOImage",
  GET_PAY_TERMS: "/Store/1/GetPaymentTerms",
  CREATE_PURCHASE_ORDER: "/Store/Web/Purchase/CreatePurchaseOrder",
  ADD_BARCODE: "Store/1/AddBarcode",
  // ------------------------------------------------------------

  //Forgot Password and update Password Api
  FORGOT_PASS_OTP_API_URL: "retailer-forgot-password",
  UPDATE_PASSWORD: "retailer-forgot-password",

  //Orders
  GET_ALL_ORDERS_API_URL: "GetAllOrders", //

  //Inventory
  GET_ALL_inventory_API_URL: "GetAllInventory",

  //profile
  //getapis
  GET_STORE_BASIC_DETAIL: "getstorebasicdetails",
  GET_STORE_SHOP_DETAIL: "getshopdetails",
  GET_STORE_BUSINESSPROOF: "getstorebussinessproof",

  GET_BDO_DETAILS: "getbdodetails",

  //POSTapis
  SET_STORE_BASIC_DETAIL: "updatebasicdetails",
  SET_STORE_SHOP_DETAIL: "updateshopdetails",
  SET_STORE_BUSINESSPROOF: "updatebussinessproof",

  //Delivery Partner
  DELIVERY_PARTNER_CREATE: "retialer-createdeliverypartner", //
  DELIVERY_PARTNER_DETAILS: "retialer-getdeliverypartner", //Get API
  UPDATE_DELIVERY_PARTNER: "retialer-updatedeliverypartner",
  GET_DELIVERY_PARTNER_ORDERS: "delivery_partner_orders",
  SEARCH_DELIVERY_PARTNER: "searchdeliverypartner",

  //Orders Api
  GET_STORE_ORDER_COUNT: "storeordercount",
  GET_KPI_DETAIL: "getordersdetailsamount",
  GET_STAGE_IDS: "getorderstages",
  GET_ALL_ORDERS: "getordersdetails",
  UPDATE_ORDERLINE: "updateorderline",
  SEARCH_ORDER: "searchorder",
  // GET_ORDER_CANCEL_REASONS: "getordercancelreason",
  // UPDATE_CANCEL_REASON: "updateordercancelreason",
  UPDATE_ORDER_STAGE: "updateorderstages",
  // ORDER_SEARCH_FILTER: "searchfilter",
  ORDER_NOTIFICATION: "/ordernotification",

  //Inventory Api
  // GET_UOM: "GetUom",
  // GET_RETAILER_PRODUCT_CATEGORY: "getretailerproductcategory",
  // GET_ALL_PRODUCTS: "getretailerallproduct",
  GET_INVENTORY_PRODUCT_LOGS: "getproductlog",
  // UPDATE_STORE_PRODUCT_DETAIL: "updatestoreproductdetails",
  // GET_INCOMPLETE_PRODUCTS: "getallincompleteproduct",
  CATEGORY_FILTER: "categoryfilter",
  SEARCH_INVENTORY: "searchinventory",
  STOCKOUT_SEARCH_INVENTORY: "searchoutstockinventory",
  HIGHON_SEARCH_INVENTORY: "searchhighinventory",
  LOWON_SEARCH_INVENTORY: "searclowhinventory",
  // PRODUCT_REQUEST: "/productrequest",

  // SEARCH_INVENTORY_ONBOARD: "searchinventoryonboard",
  // ADD_PRODUCT: "updateinventoryonboard",
  INVENTORY_REPORT: "inventory_report",
  FETCH_GS1_DATA: "fetch_gs1_data",

  //User Management
  // CREATE_STORE_USER: "createstoreuser",
  GET_ALL_STORE_USERS: "getallstoreusers",
  GET_USER_TYPES: "getstoreusertype",
  // UPDATE_STORE_USER: "UpdateRetailerUser",
  // INACTIVE_STORE_USER: "/inactiveretaileruser",

  //subscription
  MY_SUBSCRIPTION: "getSubscriptionstion",
  SUBSCRIPTION_PLANS: "getSubscriptionstionPlans",
};
