import React, { useState } from "react";
import { Select } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
// import "../../styles.css"
const customTheme = {
  token: {
    colorPrimary: "#00b96b",
    borderRadius: 2,
    colorBgContainer: "#f6ffed",
    borderColor: "#CCCCCC", // Setting default border color
    hoverBorderColor: "#CCCCCC", // Setting hover border color
  },
  components: {
    Select: {
      base: {
        "&:hover .ant-select-selector": {
          borderColor: "var(--antd-border-hover-color)", // Reference hoverBorderColor
        },
      },
    },
  },
};

function SelectBox({
  label,
  options,
  error,
  onChange,
  touched,
  name,
  width = 0,
  defaultValue,
  ...props
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSelectFocused, setIsSelectFocused] = useState(false);

  const handleSelectChange = (value) => {
    const event = {
      target: {
        name: name,
        value: value,
      },
    };
    onChange(event);
  };
  const handleSuffixIconClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSelectFocus = () => {
    setIsSelectFocused(true);
    setIsExpanded(true);
  };

  const handleSelectBlur = () => {
    setIsSelectFocused(false);
    setIsExpanded(false);
  };

  return (
    <div className="flex flex-col mt-2 border-red  w-full">
      {label && <label className="py-2 text-md text-black font-medium">{label}</label>}
      <div
        className={`${
          touched && error ? "border border-red" : "border border-[#CCCCCC]"
        } rounded-md bg-white`}
      >
        <Select
          {...props}
          defaultValue={defaultValue}
          className={`custom-scrollbar h-[2.42rem] w-full ${
            width != 0 ? width : "min-w-[9.5rem]"
          }  text-md text-body !border-[#CCCCCC]  !text-[#aeaeae] ${
            touched && error ? "border-red" : "" // Apply red border only when not focused
          }`}
          onChange={handleSelectChange}
          onFocus={handleSelectFocus}
          onBlur={handleSelectBlur}
          options={options.map((item) => ({
            value: item.value,
            label: item.label,
          }))}
          variant="borderless"
          suffixIcon={<CaretDownOutlined onClick={handleSuffixIconClick} />}
          onClick={handleSuffixIconClick}
          open={isExpanded} // Set the open prop to the isExpanded state
          // onDropdownVisibleChange={handleSuffixIconClick}// Use the DownOutlined icon as the suffixIcon
        />
      </div>
      {/* {touched && error ? (
        <div className="font-medium italic text-red">{error}</div>
      ) : null} */}
    </div>
  );
}

export default SelectBox;
