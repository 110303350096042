import React from 'react';
import doc from "../../assets/sidebar/Documents.svg";
import chat from "../../assets/sidebar/Chat Dots.svg";
import pos from "../../assets/sidebar/pos1.svg";
import pos_blue from "../../assets/sidebar/pos_blue.svg";
// import pos2 from "../../assets/sidebar/pos.svg";
import setting from "../../assets/sidebar/Settings1.svg";
import purchase from "../../assets/sidebar/purchase.svg";
import dashboard from "../../assets/sidebar/dashboard1.svg";
import invent from "../../assets/sidebar/invent1.svg";
import order from "../../assets/sidebar/order1.svg";

import inventoryblue from "../../assets/sidebar/inventoryblue1.svg";
import purchaseblue from "../../assets/sidebar/purchaseblue.svg";
import orderblue from "../../assets/sidebar/orderblue.svg";
import settingblue from "../../assets/sidebar/settingblue.svg";
import dashboardblue from "../../assets/sidebar/dashboardblue1.svg";






export const headings = [
    // { text: 'dashboard', icon: <img className='mx-1'  src={dashboard} alt="dashboard" />,iconActive: <img className='mx-1' src={dashboardblue} alt="dasboard" /> },
    { text: 'inventory', icon: <img className='mx-1' src={invent} alt="inventory" />, iconActive: <img className='mx-1' src={inventoryblue} alt="inventory" /> },
    { text: 'Purchase', icon: <img className='mx-1' src={purchase} alt="purchase" />, iconActive: <img className='mx-1' src={purchaseblue} alt="dasboard active" />},
    { text: 'pos', icon: <img className='mx-1' src={pos} alt="pos" />,iconActive: <img className='mx-1' src={pos_blue} alt="pos" />},
    { text: 'eorders', icon: <img className='mx-1' src={order} alt="eorders" />, iconActive: <img className='mx-1' src={orderblue} alt="eorders" /> },
    { text: 'settings', icon: <img className='mx-1' src={setting} alt="settings" />, iconActive: <img className='mx-1'src={settingblue} alt="settings" />},
    // { text: 'purchase', icon: <img className='mx-1' src={invent} alt="purchase" />, iconActive: <img className='mx-1' src={inventoryblue} alt="purchase" /> },

    
    // { text: 'Accounting', icon: <img src={doc} alt="document" />},
    // { text: 'Chat', icon: <img src={chat} alt="chats" /> },

    // Add more headings as needed
  ];

  export const subheadings = [
    // [
    //   {text:'dashboard_data',route: '/dashboard'},
      
    // ],
    [
      { text: 'view_inventory', route: '/inventory' },
      { text: 'complete_onboarding', route: '/inventory/InventoryOnboard' },
      // { text: 'low_on_inventory', route: '/inventory/Lowoninventory' },
      // { text: 'high_on_inventory', route: '/inventory/Highoninventory' },
      // { text: 'out_of_stock', route: '/inventory/Outofstock' },
      // { text: 'product_request', route: '/inventory/ProductRequest' },
      
      // { text: 'Inventory Report', route: '/inventory' },
    ],
    [
     
      { text: 'Add Purchase Bill', route: '/purchase/add-bill' },
      { text: 'Purchase History', route: '/purchase/list' },
      { text: 'Supplier Payment', route: '/purchase/supplyPayment' },
      // { text: 'Expenses', route: '/purchase/expenses' },
     ],
    [
     
      { text: 'Cashier'},
      { text: 'Session History' , route: '/pos/sessionHistory'},
    ],
     
    // [],
    [
      { text: 'view_orders', route: '/orders' },
      // { text: 'Return/Replacement', route: '/orders' },
      
    ],
    [
      // { text: 'my_subscription', route: '/mysubscription' },
      { text: 'my_profile', route: '/settings/profile' },
      { text: 'user_management', route: '/settings/usermanagement' },
      { text: 'shipping_cost', route: '/settings/shippingcost' },
      { text: 'delivery_boy', route: '/deliverypartner' },
      // { text: 'bdo_details', route: '/settings/bdo' },
      // { text: 'Employee management', route: '/settings/employee-management' },
      // { text: 'Subscription', route: '/settings/subscription' },
      // { text: 'Return & Refund policy', route: '/settings/refund-policy' },
    ],
    // [
      
    // ],
    // [
      
    // ],
    // Repeat for other subheading arrays
  ];



