export const getdatahtml = (data) => {
  console.log("ORDER DATA---->", data);

  let htmlProductLines = "";
  let totalAmount = 0; // Initialize total amount


  // if (data?.new_items) {
  //   data?.new_items.forEach((item, index) => {

  //     const itemTotal = item?.price_unit * item?.product_uom_qty; // Calculate item total
  //     totalAmount += itemTotal; // Add item total to totalAmount
  //     htmlProductLines += `
  //       <tr key="${index}">
  //         <td style="text-align: center; vertical-align: text-top;">${
  //           index + 1
  //         }</td>
  //         <td style="text-align: left;">${item?.product_name}</td>
  //         <td style="text-align: end;">${item?.product_uom_qty}</td>
  //         <td style="text-align: center;"> ${item?.price_unit.toFixed(2)}</td>
  //         <td style="text-align: end;"> ${(
  //           item?.price_unit * item?.product_uom_qty
  //         ).toFixed(2)}</td>
  //       </tr>
  //     `;
  //   });
  // }

  if (data?.new_items && data.new_items.length > 0) {
    data.new_items.forEach((item, index) => {
      const itemTotal = item?.price_unit * item?.product_uom_qty; // Calculate item total
      totalAmount += itemTotal; // Add item total to totalAmount
      htmlProductLines += `
        <tr key="${index}">
          <td style="text-align: center; vertical-align: text-top;">${index + 1}</td>
          <td style="text-align: left;">${item?.product_name}</td>
          <td style="text-align: end;">${item?.product_uom_qty}</td>
          <td style="text-align: center;">${item?.price_unit.toFixed(2)}</td>
          <td style="text-align: end;">${(item?.price_unit * item?.product_uom_qty).toFixed(2)}</td>
        </tr>
      `;
    });
  } else if (data?.existing_items && data.existing_items.length > 0) {
    data.existing_items.forEach((item, index) => {
      const itemTotal = item?.price_unit * item?.product_uom_qty; // Calculate item total
      totalAmount += itemTotal; // Add item total to totalAmount
      htmlProductLines += `
        <tr key="${index}">
          <td style="text-align: center; vertical-align: text-top;">${index + 1}</td>
          <td style="text-align: left;">${item?.product_name}</td>
          <td style="text-align: end;">${item?.product_uom_qty}</td>
          <td style="text-align: center;">${item?.price_unit.toFixed(2)}</td>
          <td style="text-align: end;">${(item?.price_unit * item?.product_uom_qty).toFixed(2)}</td>
        </tr>
      `;
    });
  }

  const deliveryFee = data?.order_details?.shipping_charges || 0;
  totalAmount += deliveryFee;

  
  htmlProductLines += `
  <tr>
    <td style="text-align: center;"></td> <!-- Serial number for Delivery Fee -->
    <td style="text-align: left;">${"Delivery Fee"}</td>
    <td style="text-align: end;"></td>
    <td style="text-align: center;"></td>
    <td style="text-align: end;">${
      data?.order_details?.shipping_charges?.toFixed(2) || "0.00"
    }</td>
  </tr>
`;

  const htmlContent = `
<!DOCTYPE html>
<html>
  <head>
    <style>
    body {
      font-family: Arial, sans-serif;
       width:"100%";
      color: #000000;
      font-size: 10px;
    }
   
    .pos-receipt {
      text-align: center;
     
    }
   
    // .pos-receipt-contact div{
    //   font-size:12 px;
     margin-top: 5px;
     font-weight: semi-bold;
    // }
    .pos-receipt-contact .cashier {
      // margin-top: 10px;
      // margin-bottom: 10px;
      border-top: 1px dashed black;
    }
 
    // .pos-receipt-contact .cashier div {
    //   margin-top: 5px;
    //   margin-bottom: 5px;
    // }
 
    .pos-receipt-contact table {
      width: 100%;
      margin-bottom: 5px;
    }
 
    .pos-receipt-contact table td:first-child {
      width: 100%;
    }
 
    .receipt-orderdata {
      width: 100%;
      font-size:10 px;
      margin-bottom: 5px;
      margin-top:5px
    }
    
    .receipt-orderdata th,
    .receipt-orderdata td {
      padding: 0px;
    }
    .receipt-orderdata td {
     font-size: 10px;
    }
    .receipt-orderdata td {
      text-align: center;
      // font-weight: semi-bold;
     
    }
 
    .pos-receipt-right-align {
      float: right;
    }
    .pos-receipt .pos-receipt-right-align {
      // float: right;
      font-size:10 px;
    }
    .orderDetail{
      display: flex;
      flex-direction:  $ {i18n.language === 'ar' ? 'row-reverse' : 'row'};
      justify-content: space-between;
  
     
    }
    .orderDetail div, .cashierDetail{
      font-size:10px;
      margin-top: 5px;
    }
    .total{
      border-top: 1px dashed black;
       display: flex;
       flex-direction: row;
      justify-content: space-between;
      padding-top: 10px;
      margin-top: 10px;
    }
    .total div, .cashierDetail{
      font-size:9px;
    }
    .lines{
      // margin-top:5px;
      // margin-bottom:5px;

    }
      .order-delivery{
       margin: 10px 0px;
      }
    .receiptHead{
      text-align: center;
    font-weight: semi-bold;
    // padding: 5px 0px 5px;
    font-size: 14px;
    margin: 10px 0px;
    width:100%
    }
    
    .pos-receipt-bottomSection
    {
      border-top: 1px dashed black;
      margin-top:10px;
      font-size:14px;
     
    }
    .bottomSection img{
      width:100px;
    }
    .after-footer{
      margin-top:0px;
    }
  </style>
    </head>
  <body>
    <div class="pos-receipt-container">
      <div class="pos-receipt">
        <div style="text-align: center;">
        <img style="max-height: 50px; max-width: 40px;" src="${
          data?.order_details?.retailer_image_url
        }" alt="My Barcode Image" width="100">
        </div>
        <div class="pos-receipt-contact">
          <div class="lines" ><strong>${
            data?.order_details?.retailer_name
          }</strong></div>
          <div class="lines"><strong> +20 ${
            data?.order_details?.partner_mobile
          }</strong></div>
        </div>
        <div class="receiptHead">
          <strong >${"Order Receipt"}</strong>
        </div>
       
        <div style="text-align: $ {i18n.language === 'ar' ? 'row-reverse' : 'row'};border-top: 1px dashed black;
        border-bottom: 1px dashed black; padding:5px 0px ;">
         
 
          <div class="orderDetail">
          <div class="lines">
           <strong>${"Order No:"}</strong>
          </div>
          <div class="lines">
          ${data?.order_details?.order_no}
          </div>
         </div>
 
         <div class="orderDetail">
         <div class="lines">
         <strong>${"Order Date:"}</strong>
         </div>
         <div class="lines">
        
         ${data?.order_details?.order_date}
         </div>
        </div>
 
        <div class="orderDetail">
        <div class="lines">
        <strong>${"Customer:"}</strong>
        </div>
        <div class="lines">
        ${data?.order_details?.partner_name}
        </div>
       </div>
 
       <div class="orderDetail">
       <div class="lines">
         <strong>${"Customer Phone:"}</strong>
       </div>
       <div class="lines">
       ${data?.order_details?.partner_mobile}
       </div>
      </div>
 
        </div>
       
        <div>
          <table class="receipt-orderdata">
            <colgroup>
             <col width="10%">
              <col width="40%">
              <col width="10%">
              <col width="10%">
              <col width="10%">
            </colgroup>
            <tr >
             <th>${"S.N"}.</th>
              <td style="text-align: center; font-weight: semi-bold; margin-top: 5px"><strong>${"Product Name"}</strong></td>
              <td style="text-align: end; font-weight: semi-bold; margin-top: 5px"><strong>${"Qty"}</strong></td>
              <td style="text-align: end; font-weight: semi-bold; margin-top: 5px"><strong>${"Unit Price"}</strong></td>
              <td style="text-align: end; font-weight: semi-bold; margin-top: 5px "><strong>${"Amount"}</strong></td>
            </tr>
            ${htmlProductLines}


            <br/>
            <tr style={margin-top:5px;} class="order-delivery">
         
          <td style="text-align: end;"></td>
         
          <td style="text-align: end;"></td>
          
        </tr>
       
       
          </table>
        </div>
        <div class="total">
          <div><strong>${"TOTAL"}</strong></div>
          <div><strong>EGP ${totalAmount.toFixed(
            2
          )}</strong></div>
        </div>
      <div class="pos-receipt-bottomSection"><br>
     ${"Your Technology Partner"}<br>
        <img style="max-height: 50px; max-width: 100px;" src="https://images.myginne.com/myginne.webp" alt="myGinne Logo">
  </div>
    </div>
  </body>
</html>
`;
  return htmlContent;
};
