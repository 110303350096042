import React, { useMemo, useState ,useEffect} from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import "tailwindcss/tailwind.css";
import CustomPagination from "../CustomWidgets/CustomPagination";
import { Empty } from "antd";
import "../../styles.css"
import ShimmerLoader from "../../loading";
import Vectorsorting from "../../assets/Vectorsorting.svg";
import { useTranslation } from "react-i18next";


const Completedorderdata = ({ tabledata ,pagination}) => {
  const {t,i18n}=useTranslation()
  const [buttonClicked, setButtonClicked] = useState({});
  const [flag, setflag] = useState(false);

  const data = useMemo(() => tabledata || [], [tabledata]);
  const columns = useMemo(
    () => [
      { Header: t('order_no'), accessor: "order_number" },
      { Header:t('customer_name'), accessor: "customer_details.name" },
      { Header: "Delivery Date & Time", accessor: "delivery_time" },
      { Header: t('items'), accessor: "order_line_count" },
      { Header: t('order_value'), accessor: "order_total_amt" },
      {Header: t('payment'), accessor: "payment_mode" },
      {Header: t('delivery_boy'), accessor: "delivery_boy" },
      {
        Header: "Status",
        accessor: "order_stage.name",
        Cell: ({ value }) => (
              
            <div
              className={`${
                value == "Order Completed"
                  ? "bg-[#D1FFDE] text-[#00B833]"
                  : "bg-[#E0F0FF] text-primaryBlue"
              } w-36 h-9 rounded-3xl py-2 inline-block`}
            >
             
              {value?.substring(6,15)}
            </div>
          ),
      },

      //   {
      //     accessor: "action",
      //     Cell: ({ row }) => (
      //       <button
      //       className={`${
      //         buttonClicked[row.id]
      //           ? "bg-none text-[#999999] border-none"
      //           : "bg-[#1D5C96] text-white border"
      //       } px-4 py-2 rounded-md focus:outline-none`}
      //       onClick={() => {
      //         // Update the separate object without causing a re-render
      //         setButtonClicked((prev) => ({
      //           ...prev,
      //           [row.id]: !prev[row.id],
      //         }));
      //       }}
      //     >
      //       {buttonClicked[row.id] ? "Paid by customer" : "Mark as Deposited"}
      //     </button>
      //     ),
      //   },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data: tabledata,
      initialState: {  pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  const [pageIndex,setpageIndex]=useState(1)
  useEffect(() => {
    
    
    const pageDetails = {
    
      limit: pageSize,
      offset: pageSize * (pageIndex - 1) <0 ? 0:pageSize * (pageIndex - 1) ,
    };
    pagination(pageDetails);
    
  }, [pageIndex, pageSize,previousPage,nextPage]);


  return (
    <div className="container mx-auto float-left">
      {tabledata?.length == 0 && flag == false ? (
          <>
            <ShimmerLoader />{" "}
            {/* {setTimeout(() => {
              setflag(true);
            }, 3000)} */}
          </>
        ) : (
      <table
        {...getTableProps()}
        className="table-auto mx-2 ml-3 mt-7 font-normal w-[1100px] sm:w-[97.6%] md:w-[97.6%] lg:w-[97.6%] xl:w-[97.6%] min-h-auto shadow-xl rounded-lg overflow-hidden"
      >
        <thead>
        {headerGroups.map((headerGroup, headerIndex) => {
              const { key: headerKey, ...headerGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr
                  key={headerKey}
                  {...headerGroupProps}
                  className="bg-[#78CFEC66] text-sm font-semibold"
                >
                  {headerGroup.headers.map((column, colIndex) => {
                    const { key: columnKey, ...columnProps } =
                      column.getHeaderProps(column.getSortByToggleProps());
                    return (
                      <th
                        key={columnKey}
                        {...columnProps}
                        className="py-3 px-8 whitespace-nowrap "
                        style={{
                          textAlign: i18n.language === "ar" ? "right" : "left",  // <-- Add this line
                        }}
                      >
                        <div className="flex items-center justify-center">
                          {column.render("Header")}
                          <span className="">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <img src={Vectorsorting} style={{minHeight:10,minWidth:10}} />
                              ) : (
                                <img
                                  className="rotate-180"
                                  src={Vectorsorting}
                                  style={{minHeight:10,minWidth:10}}
                                />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
        </thead>
        <tbody {...getTableBodyProps()} className="bg-[#F7F9FD] text-center">
          {page.map((row) => {
            prepareRow(row);
            return (
              <React.Fragment key={row.id}>
                <tr
                  {...row.getRowProps()}
                  className="relative hover:bg-white transition duration-300 border-b border-gray-300 fixed-row-height "
                >
                  {row.cells.map((cell, index) => (
                    <td
                      {...cell.getCellProps()}
                      className={`p-2 px-8 text-[#272728] h-126 text-sm font-normal ${
                        index < row.cells.length - 1 ? "" : ""
                      }`}
                      style={{
                        textAlign: i18n.language === "ar" ? "right" : "left",  // <-- Add this line
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              </React.Fragment>
            );
          })}
          {/* Empty state row */}
          {page.length === 0 && (
            <tr>
              <td colSpan={columns.length}>
                <Empty description="No data available" />
              </td>
            </tr>
          )}
          <tr>{<td className="" colSpan={columns.length}>
              <CustomPagination
                pageIndex={pageIndex}
                pageCount={page.length}
                canPreviousPage={pageIndex!=1?true:false}
                canNextPage={tabledata?.length>=10?true:false}
                gotoPage={(page) => {
                  nextPage(page);
                }}
                previousPage={()=>{setpageIndex(pageIndex-1)}}
                nextPage={() => {setpageIndex(pageIndex+1)}}
                pageSize={pageSize}
                setPageSize={(pageSize) => {
                 // nextPage(0);

                  setPageSize(pageSize)
                
                
                  
                }}
              />
            </td>}
          </tr>
        </tbody>
      </table>)}
    </div>
  );
};

export default Completedorderdata;
