// signupActions.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { URLS } from "../../API/API endpoints";
import axiosInstance from "../../AxiosInstance"

export const signupUser = createAsyncThunk(
  "signup/handleSignup",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        URLS.SIGNUP_OTP_API_URL,
        payload,
        {
          // headers: {
          //   Accept: "application/json",
          //   "Content-Type": "application/json; charset=UTF-8",
          //   "Access-Control-Allow-Origin": "*",
          // },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        console.log(response.data);
        return response.data;
      } else {
        throw new Error("API request failed");
      }
    } catch (error) {
      console.error("API request error:", error);
      return rejectWithValue(error.message);
    }
  }
);
