import axiosInstance from "../AxiosInstance";
import { URLS } from "../API/API endpoints";
import { store } from "../Redux/store";
import i18next from "i18next";

const { login } = store.getState();

// const user_id = login.user;
// const store_id = login.user;

// Assuming URLS is correctly imported and contains the SEARCH_ORDER URL

export const getusertypes = async () => {
  try {
    const response = await axiosInstance.get(URLS.GET_USER_TYPES);

    return response;
  } catch (error) {
    return error;
  }
};

export const UserImageUpload = async (base64Image, imageName) => {
  
  const extension = base64Image.split(';')[0].split('/')[1]; 
  const filename = `${imageName}.${extension}`;
  const base64Data = base64Image.split(',')[1];
  // console.log("BASE CHALA", base64Data);


  const payload = {
    filename: filename,
    bucket: process.env.REACT_APP__IMAGE_BUCKET, 
    data: base64Data,
  };

  try {
    const response = await axiosInstance.post(process.env.REACT_APP__IMAGE_UPLOAD, payload, {
    });

    if (response.data && response.data.result && response.data.result.status_code === 200) {
      return response.data.result.url; 
    } else {
      console.error("Upload failed:", response.data);
      return null; 
    }
  } catch (error) {
    console.error("Error uploading image:", error);
    return null; 
  }
};

export const createStoreUser = async (payload) => {
  try {
    // const payload = {
    //   user_id: user_id,
    //   password: values.password,
    //   name: values.first_name,
    //   user_type: values.store_user_type_id,
    //   email: values.email,
    //   image_url: values.url,
    // };
    const response = await axiosInstance.post(URLS.CREATE_REAILER_USER, payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateStoreUser = async (
  paylaod
) => {
  try {
    const response = await axiosInstance.post(URLS.EDIT_RETAILER_USER, paylaod);

    return response;
  } catch (error) {
    return error;
  }
};

export const blockStoreUser = async (user_id, retailer_user_id) => {
  try {
    const payload = {
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
      user_id: user_id,
      retailer_user_id: retailer_user_id,
    };
    const response = await axiosInstance.post(
      URLS.DEACTIVATE_RETAILER_USER,
      payload
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const getStoreUsers = async (user_id) => {
  try {
    const payload = {
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
      user_id: user_id,
      //  store_id:store_id,
    };
    const response = await axiosInstance.post(URLS.GET_RETAILER_USER, payload);
    console.log("RESSSSS USER", response);

    return response.data;
  } catch (error) {
    return error;
  }
};
