// formActions.js
export const SET_VENDOR = 'SET_VENDOR';
export const SET_BILL_DATE = 'SET_BILL_DATE';
export const SET_BILL_NUMBER = 'SET_BILL_NUMBER';
export const SET_VAT_NUM = 'SET_VAT_NUM';
export const SET_PRODUCT_LIST = 'SET_PRODUCT_LIST';

export const setVendor = (vendor) => ({
  type: SET_VENDOR,
  payload: vendor,
});

export const setBillDate = (billDate) => ({
  type: SET_BILL_DATE,
  payload: billDate,
});

export const setBillNumber = (billNumber) => ({
  type: SET_BILL_NUMBER,
  payload: billNumber,
});

export const setVatNum = (vatNum) => ({
  type: SET_VAT_NUM,
  payload: vatNum,
});

export const setProductList = (productList) => ({
  type: SET_PRODUCT_LIST,
  payload: productList,
});