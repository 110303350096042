import i18next from "i18next";
import { URLS } from "../API/API endpoints";
import axiosInstance from "../AxiosInstance";


export const getdataDeliveryPartner = async (user_id,store_id,) => {

  try {
    const payload = {
      user_id:user_id,
     store_id:store_id,
    };
    const response = await axiosInstance.post(
      URLS.DELIVERY_PARTNER_DETAILS,
      payload
    );
    // .then((response) => {
    // ;
    //   return response.data?.delivery_partner_details
    // });
    const result=response.data.status===401?response.data.status:response.data.delivery_partner_details
    
    return result ;
  } catch (error) {
    return error;
  }
};

export const getDeliveryPartnerOrders = async (user_id,store_id,
  delivery_partner_id,
  limit = 10,
  offset = 0
) => {
  try {
    const payload = {
      user_id:user_id,
     store_id:store_id,
      delivery_partner_id: delivery_partner_id,
      limit: limit,
      offset: offset,
    };
    const response = await axiosInstance.post(
      URLS.GET_DELIVERY_PARTNER_ORDERS,
      payload
    );
    // .then((response) => {
    // ;
    //   return response.data?.delivery_partner_details
    // });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const searchdeliverypartner = async (user_id,store_id,query="",status="") => {
  try {
    const payload = {
      user_id:user_id,
     store_id:store_id,
      query: query,
      status: status,
    };
    const response = await axiosInstance.post(
      URLS.SEARCH_DELIVERY_PARTNER,
      payload
    );
    // .then((response) => {
    // console.log("response", response.data);
    //   return response.data?.delivery_partner_details
    // });
    return response.data;
  } catch (error) {
    return error;
  }
};



export const createDeliveryBoy = async (payload) => {
  try {
    const response = await axiosInstance.post(URLS.CREATE_DELIVERY_BOY, payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const deactivateDeliveryBoy = async (user_id, delivery_boy_id) => {
  try {
    const payload = {
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
      user_id: user_id,
      delivery_boy_id: delivery_boy_id,
    };
    const response = await axiosInstance.post(
      URLS.DEACTIVATE_DELIVERY_BOY,
      payload
    );

    return response;
  } catch (error) {
    return error;
  }
};


export const updateDeliveryBoy = async (
  paylaod
) => {
  try {
    const response = await axiosInstance.post(URLS.UPDATE_DELIVERY_BOY, paylaod);

    return response;
  } catch (error) {
    return error;
  }
};

export const GetDeliveryBoyOrders = async (payload
) => {
  try {
    const response = await axiosInstance.post(
      URLS.GET_DELIVERY_BOY_ORDERS,
      payload
    );
    console.log("DELIVERY BOY RESPONSE",response);
    
    return response;
  } catch (error) {
    return error;
  }
};