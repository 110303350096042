
import React, { useState,  useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Image } from "antd";
import axios from "axios";
import upload from "../assets/Images/Uploadminimalistic.svg";
import "./upload-image.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const UploadMediaonly = ({
  onfileAdd,
  foldername,
  urlgenerated,
  multiple = false,
  setBase64Images,
  keyname=false,
  imageurl,
  setIsFileValid
}) => {
  const {t,i18n}=useTranslation()
  const [image, setImages] = useState([]);
  const [sizeError, setSizeError] = useState(false);
  const [fileError, setfileError] = useState(false);
  const storeId = useSelector(
    (state) => state.login?.user?.store_id
  );

  const handleUpload = async (file, imageArray) => {
    if (!storeId) {
      console.error("Store ID not available");
      return;
    }
    try {
      // Extract base64 data without the prefix
      const imageData = imageArray[0].split(",")[1]; // Get the base64 part only

      const payload = {
        image_base64: imageData,
        bucket_name: process.env.REACT_APP__IMAGE_BUCKET,
        folder_path: foldername,
        image_name: `${storeId}-${onfileAdd}.${
          file.type.split("/")[1] // Get the file extension
        }`,
      };

      const location = await axios.post(
        process.env.REACT_APP__IMAGE_UPLOAD,
        payload
      );

      const data = {
        name: onfileAdd,
        url: location.data.image_url + "?" + Date.now(),
      };
      keyname ? urlgenerated(data, keyname) : urlgenerated(data);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const imageArray = [];
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (file.size > 5 * 1024 * 1024) {
            setSizeError(true);
          } else {
            setSizeError(false);
            setImages([]);
            imageArray.push(reader.result);
            setBase64Images(reader.result);

            if (
              file.type === "image/jpeg" ||
              file.type === "image/jpg" ||
              file.type === "image/png"
            ) {
              handleUpload(file, imageArray);
              setfileError(false);
              setIsFileValid(true);
            } else {
              setfileError(true);
              setIsFileValid(false);
            }
          }
        };
        reader.onerror = (error) => {
          console.log("Error:", error);
        };
      });
    },
    [handleUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, image/jpg",
    multiple,
    onDrop,
  });

  return (
    <div className="upload ">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <p className="flex flex-col justify-end text-center text-sm text-body cursor-pointer">
          <span className="flex flex-row justify-center gap-2 items-center font-semibold text-[#1b4597]">
            {(onfileAdd !== "storeuserurl" || onfileAdd !== "userphoto") && (
              <img
                className="ml-1"
                src={upload}
                width={18}
                alt="Upload Icon"
              />
            )}
            {imageurl === "" ? `${" "} ${t('upload')}` : `${" "}${t('change')}`}
          </span>
          {onfileAdd !== "userphoto" && (
            <div>
              {imageurl === "" && (
                <>
                  {" "}
                  <span className="text-xs text-body text-[#999999]">
                    png, jpg, jpeg
                  </span>
                  <div className="text-xs text-body text-[#999999]">
                    (max 5mb)
                  </div>
                  {/* <div className="text-xs text-body text-[#a6d88b]">
                    Verified
                  </div> */}
                </>
              )}
            </div>
          )}
        </p>
      </div>
      {sizeError && (
        <p className="text-red text-xs mt-1">File cannot be more than 5 mb</p>
      )}
      {fileError && (
        <p className="text-red text-xs mt-1">Unsupported File Type</p>
      )}
    </div>
  );
};

export default UploadMediaonly;
