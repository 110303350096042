import React from "react";
import Deliverypartners from "../../components/EordersDeliveryPartners/Deliverypartners";
import Sidebar from "../../components/SideBar/sidebar";
import Navbar from "../../components/Navbar/Navbar";
import AllDeliveryBoys from "../settings/DeliveryBoy/AllDeliveryBoy";


const DeliveryPartner = () => {
  return (
    <div className="flex">
      {/* <Sidebar/> */}
      <div className="flex flex-col flex-1  mt-14">
        <Navbar heading="delivery_boy" />
        <AllDeliveryBoys/>
        {/* <Deliverypartners  /> */}

       
      </div>
    </div>
  );
};

export default DeliveryPartner;
