import React, { useEffect, useState } from "react";
import { Breadcrumb, Button } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { routes } from "../../../Routes";
import { InputBox } from "../../../components/CustomWidgets/InputBox";
import { useFormik } from "formik";
import SelectBox from "../../../components/CustomWidgets/SelectBox";
import { Image } from "antd";
// import Group from "../../assets/Inventory/Group.svg";
import {
  InventoryProductRequest,
  fetchGS1,
  getUOM,
  updateGsProductRequest,
  uploadImage,
  uploadImageUrl,
} from "../../../API  Functions/InventoryFunctions";
import "../../../styles.css";
import UploadMediaonly from "../../../components/upload-imageonly";
import ProductrequestModal from "../../../modals/ProductrequestModal";
import { productRequestSchema } from "../../../components/validationSchema";
import Navbar from "../../../components/Navbar/Navbar";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const ProductRequest = () => {
  const { t, i18n } = useTranslation();
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const location = useLocation();

  const [UOM, setUOM] = useState([]);
  const [isUOMLoaded, setIsUOMLoaded] = useState(false);
  const [popup, setpopup] = useState(false);
  const [isnew, setisnew] = useState(false);
  const [isGS1, setisGS1] = useState(false);
  const [data, setdata] = useState([]);
  const [searchid, setsearchid] = useState("");
  const [base64Image, setBase64Images] = useState("");
  const [isFileValid, setIsFileValid] = useState(true); 
  const [processing, setProcessing] = useState(false); 

   useEffect(() => {
    console.log("isFileValid ", isFileValid);
    
      if (base64Image && isFileValid === false) {
        // getInventoryKPI();
        setBase64Images("");
        formik.setFieldValue("image_url", "")
      }
    }, [isFileValid, base64Image]);
  

  const get_UOM = async () => {
    const res = await getUOM();

    if (res?.result?.[0].status == "UOM List")
      setUOM(res?.result?.[0]?.uom_list);
    setIsUOMLoaded(true);
  };

  const ProductRequest = async (val) => {
    if (val.name === "") {
      return;
    }

    const costPrice = val?.costprice || 0;
    const salePrice = val?.saleprice || 0;

    setProcessing(true)

    const response = await InventoryProductRequest(user_id, val, costPrice, salePrice);

    if (response?.result[0]?.status_code === 200) {
      const productId = response.result[0].product_id;
      const uploadedImageUrl = await uploadImage(productId, base64Image);

      if (uploadedImageUrl) {
        formik.setFieldValue("image_url", uploadedImageUrl);
        const isImageUrlUploaded = await uploadImageUrl(
          productId,
          uploadedImageUrl
        );

        if (isImageUrlUploaded) {
          if (formik.values.is_gs1) {
            const productData = {
              brand_name: fetchedProduct?.brand?.name,
              arabic_brand_name: fetchedProduct?.brand?.arabic_name,
              product_description_arabic:
                fetchedProduct?.product_description_arabic,
              product_description_english:
                fetchedProduct?.product_description_english,
              net_content: fetchedProduct?.net_content,
              net_content_arabic: fetchedProduct?.net_content_arabic,
              image_url: uploadedImageUrl,
              tax_registration_number: fetchedProduct?.tax_registration_number,
              source: fetchedProduct?.source,
              company_name: fetchedProduct?.company_name,
              target_market: fetchedProduct?.target_market,
              active_from: fetchedProduct?.active_from,
              active_to: fetchedProduct?.active_to,
              gtin_record_status: fetchedProduct?.gtin_record_status,
              variant_name_english: fetchedProduct?.variant_name,
              variant_name_arabic: fetchedProduct?.variant_name_arabic,
              sku_part_number: fetchedProduct?.sku_part_number,
              hs_code: fetchedProduct?.hs_code,
              supplier_code: fetchedProduct?.supplier_code,
              created_at: fetchedProduct?.gcp?.created_at,
              category_id: fetchedProduct?.category?.id,
              gtin: fetchedProduct?.gtin,
              id: fetchedProduct?.gcp?.id,
              category_english_name: fetchedProduct?.category?.name,
              category_arabic_name: fetchedProduct?.category?.arabic_name,
              category_code: fetchedProduct?.category?.code,
            };

            const isGsProductUpdated = await updateGsProductRequest(
              user_id,
              productData
            );
            if (isGsProductUpdated) {
              console.log("GS1 Product request updated successfully.");
            } else {
              console.log("Failed to update GS1 Product request.");
            }
          }
        }
      }

      setisnew(true);
      setpopup(true);
      setProcessing(false)
      formik.resetForm();
      setBase64Images("");
      return true;
    } else if (response?.result[0]?.status_code === 400) {
      setisnew(false);
      console.log("Product already exists.");
      setpopup(true);
      setProcessing(false)
      formik.resetForm();
      setBase64Images("");
      return false;
    }
  };

  useEffect(() => {
    UOM?.length == 0 && get_UOM();
  }, []);

  const { fetchedProduct } = location.state || {};

  const formik = useFormik({
    initialValues: {
      name: fetchedProduct?.product_description_english || "",
      barcode: fetchedProduct?.gtin || "",
      product_uom: "",
      costprice: "",
      saleprice: "",
      quantity: "",
      image_url: "",
      product_code: "",
      is_gs1: fetchedProduct?.gtin ? true : false,
    },

    validationSchema: productRequestSchema,
  });

  useEffect(() => {
    if (isUOMLoaded && fetchedProduct?.gtin) {
      const uomId = UOM.find((uom) => uom.uom_name === "Units")?.uom_id;
      if (uomId) {
        formik.setFieldValue("product_uom", uomId);
      }
    }
  }, [isUOMLoaded, fetchedProduct, UOM]);

  const validate = (values) => {
    const errors = {};
    if (!values.product_uom) {
      errors.product_uom = "Product UOM is required";
    }
    if (
      values.costprice &&
      values.saleprice &&
      values.costprice > values.saleprice
    ) {
      errors.costprice = (t("Cost Price cannot be greater than Sale Price"));
    }
    return errors;
  };

  return (
    <>
      <div>
        <Navbar heading="product_request" />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="h-auto pb-6 bg-[#F6F7F9] min-h-[100vh] ">
          <div className="flex flex-col">
            <div className="mr-3 ml-3 font-extrabold  mt-14">
              <Breadcrumb
                separator={<DoubleRightOutlined />}
                items={[
                  {
                    title: t("inventory"),
                    // href: routes.inventory.root,
                  },
                  { title: t("product_request") },
                ]}
                className="relative top-3"
              />
            </div>
            <div></div>
            <div className="mx-2 h-10 bg-[#C9ECF7] rounded-t-xl mt-6 flex items-center">
              <p className=" ms-4">{t("product_details")}</p>
            </div>

            <div className="flex flex-row flex-1  bg-white mx-2 border rounded-b-xl">
              <div className="m-2 w-2/4 p-4 ">
                <span className=" block leading-[0px]">
                  <label>{t("product_name")}</label>
                  <div className="">
                    <InputBox
                      name="name"
                      placeholder={t("enter_product_name")}
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        const { value } = event.target;
                    
                        // Allow only alphanumeric characters and spaces
                        if (/^[a-zA-Z0-9\s]*$/.test(value) || value === "") {
                          formik.setFieldValue("name", value);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      error={formik.errors.name}
                      touched={formik.touched.name}
                    />
                  </div>
                </span>

                <span className="mt-5 block leading-[0px]">
                  <label>{t("barcode")}</label>
                  <InputBox
                    type="text"
                    name="barcode"
                    placeholder={t("barcode")}
                    // onChange={formik.handleChange}
                    onChange={(event) => {
                      const { value } = event.target;
                  
                      // Allow only numeric input
                      // if (/^\d*$/.test(value)) { // This regex allows only digits
                      //   formik.setFieldValue("barcode", value);
                      // }
                      if (/^\d*$/.test(value)) { // This regex allows only digits
                        formik.setFieldValue("barcode", value);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.barcode}
                    error={formik.errors.barcode}
                    touched={formik.touched.barcode}
                    readOnly={!!fetchedProduct?.gtin}
                  />
                </span>
                <div className="flex flex-row gap-5 mt-5">
                  <span className="w-full leading-[0px]">
                    <label>{t("quantity")}</label>
                    <InputBox
                      type="text"
                      name="quantity"
                      placeholder={t("enter_quantity")}
                      // onChange={formik.handleChange}
                      // onChange={(event) => {
                      //   const { value } = event.target;

                      //   // Allow only numbers and restrict to 7 digits
                      //   if (/^\d{0,5}$/.test(value)) {
                      //     formik.setFieldValue("quantity", value);
                      //   }
                      // }}

                      onChange={(event) => {
                        const { value } = event.target;

                         
                        const selectedUom = UOM.find(
                          (uom) =>
                            (uom.uom_name === "Units") &&
                            uom.uom_id === formik.values.product_uom
                        );
                        // {console.log("KKKK",selectedUom?.name)}


                        // Allow only valid input based on UOM
                        if (
                          (selectedUom?.uom_name !== "Units" &&
                            /^\d*\.?\d{0,2}$/.test(value)) || // Allow decimals for non-units
                          (selectedUom?.uom_name === "Units" && /^\d{0,5}$/.test(value)) // Only whole numbers for units
                        ) {
                          // Prevent multiple hyphens and other invalid characters
                          if (!/[^\d.]/.test(value) && !/(--)/.test(value)) {
                            formik.setFieldValue("quantity", value);
                          }
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.quantity}
                      error={formik.errors.name}
                      touched={formik.touched.name}
                    />
                  </span>
                  <span className="w-full leading-[0px] flex flex-col">
                    <label className="pb-2">{t("product_uom")}</label>
                    <SelectBox
                      name="product_uom"
                      defaultValue=""
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.product_uom}
                      options={[
                        { value: "", label: t("select"), disabled: true },
                        ...UOM?.map((item) => ({
                          value: item.uom_id,
                          label: item.uom_name,
                        })),
                      ]}
                      error={formik.errors.product_uom}
                      touched={formik.touched.product_uom}
                      disabled={!!fetchedProduct?.gtin}
                    />
                  </span>
                </div>

                {formik.values.product_uom &&
                  UOM.find(
                    (uom) =>
                     ( uom.uom_name === "Kg" || uom.uom_name === "Liter")&&
                      uom.uom_id === formik.values.product_uom
                  ) && (
                    <div className="mt-5 w-full leading-[0px]">
                      <label>{t("Product Code")}</label>
                      <InputBox
                        type="number"
                        name="product_code"
                        placeholder={t("Enter Product Code")}
                        // onChange={formik.handleChange}
                        onChange={(event) => {
                          const { value } = event.target;
  
                          // Allow only numbers and restrict to 7 digits
                          if (/^\d{0,7}$/.test(value)) {
                            formik.setFieldValue("product_code", value);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.product_code}
                        error={formik.errors.product_code}
                        touched={formik.touched.product_code}
                      />
                    </div>
                  )}
                <div className="flex flex-row gap-5 mt-5">
                  <span className="w-full leading-[0px]">
                    <label>{t("cost_price")}</label>
                    <InputBox
                      type="text"
                      name="costprice"
                      placeholder={t("enter_cost_price")}
                      onChange={(event) => {
                        const { value } = event.target;
                                            if (
                          value === "" || 
                          /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(value)
                        ) {
                          if (!/[^\d.]/.test(value) && !/(--)/.test(value)) {
                            formik.setFieldValue("costprice", value);
                          }
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.costprice}
                      error={formik.errors.costprice}
                      touched={formik.touched.costprice}
                    />
                  </span>
                  <span className="w-full leading-[0px]">
                    <label>{t("sale_price")}</label>
                    <InputBox
                      type="text"
                      name="saleprice"
                      placeholder={t("enter_sale_price")}
                     
                      onChange={(event) => {
                        const { value } = event.target;
                    
                        if (
                          value === "" || // Allow empty input
                          /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(value) // Allow numbers with up to 6 digits before decimal and 2 after
                        ) {
                          // Prevent multiple hyphens and other invalid characters
                          if (!/[^\d.]/.test(value) && !/(--)/.test(value)) {
                            formik.setFieldValue("saleprice", value);
                          }
                        }
                      }}
                      
                      onBlur={formik.handleBlur}
                      value={formik.values.saleprice}
                      error={formik.errors.saleprice}
                      touched={formik.touched.saleprice}
                    />
                  </span>
                </div>

                <button
                  type="submit"
                  onClick={async () => {
                    const errors = validate(formik.values);
                    formik.setErrors(errors);

                    if (Object.keys(errors).length === 0) {
                      const costPrice = formik.values.costprice || 0;
                      const salePrice = formik.values.saleprice || 0;

                      const check = await ProductRequest({
                        ...formik.values,
                        costprice: costPrice,
                        saleprice: salePrice,
                      });

                      if (check) {
                        formik.resetForm();
                      }
                    }
                  }}
                  disabled={
                    !formik.values.name ||
                    !formik.values.product_uom ||
                    !formik.values.quantity ||
                    (formik.values.costprice && !formik.values.saleprice) || // Disable if cost price is entered but sale price is not
                    (!formik.values.costprice && formik.values.saleprice) || 
                    (formik.values.saleprice < formik.values.costprice) ||
                    !base64Image ||
                    isFileValid === false ||
                    processing
                  }
                  className={`w-48 h-10 mt-8 bg-primaryBlue text-white text-sm font-semibold rounded-lg flex items-center justify-center gap-2 ${
                    !formik.values.name ||
                    !formik.values.product_uom ||
                    !formik.values.quantity ||
                    (Number(formik.values.saleprice) < Number(formik.values.costprice)) ||
                    (formik.values.costprice && !formik.values.saleprice) || 
                    (!formik.values.costprice && formik.values.saleprice) || 
                    !base64Image ||
                    isFileValid === false
                      ? "opacity-40"
                      : ""
                  }`}
                  // disabled={!formik.values || !base64Image}
                  // className={`w-48 h-10 mt-8 bg-primaryBlue text-white text-sm font-semibold rounded-lg flex items-center justify-center gap-2 ${
                  //   !formik.values || !base64Image || isFileValid ? "opacity-40" : ""
                  // }`}
                >
                  <span>{t("save_add")}</span>
                </button>
              </div>
              <div className="m-2 p-4 ">
                <span
                  className={`flex  flex-col w-72 h-72 border rounded-xl relative top-4 ${
                    i18n.language == "en" ? "left-20" : "right-20"
                  } justify-center items-center ${
                    // formik?.errors?.image_url && formik?.touched?.image_url
                    !formik?.values?.image_url &&
                    !base64Image &&
                    formik?.touched?.image_url
                      ? "border-red"
                      : ""
                  }`}
                >
                  {/* {console.log("imageurl", formik?.values?.image_url)} */}
                  {formik?.values?.image_url ? (
                    <img
                      src={formik.values.image_url}
                      height={100}
                      width={100}
                      alt="prod"
                      style={{
                        minWidth: "17rem",
                        maxWidth: "18rem",
                        maxHeight: "13rem",
                        minHeight: "12rem",
                      }}
                    />
                  ) : base64Image ? (
                    <img
                      src={base64Image}
                      height={100}
                      width={100}
                      alt="Uploaded"
                      style={{
                        minWidth: "17rem",
                        maxWidth: "18rem",
                        maxHeight: "13rem",
                        minHeight: "12rem",
                      }}
                    />
                  ) : (
                    <svg
                      className=" float-left h-10 w-12 text-gray-300"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                      />
                    </svg>
                  )}
                  <span className="cursor-pointer">
                    <UploadMediaonly
                      foldername={"store_image"}
                      onfileAdd={"Productrequest"}
                      setBase64Images={setBase64Images}
                      urlgenerated={(val) =>
                        formik.setFieldValue("image_url", val?.url)
                      }
                      imageurl={
                        formik?.values?.image_url == undefined
                          ? ""
                          : formik?.values?.image_url
                      }
                      setIsFileValid={setIsFileValid}
                    />
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
      {popup && (
        <ProductrequestModal onClose={() => setpopup(false)} isnew={isnew} />
      )}
    </>
  );
};

export default ProductRequest;
