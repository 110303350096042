import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { InputBox, Password } from "../../components/CustomWidgets/InputBox";
import "react-phone-number-input/style.css";
import SelectBox from "../CustomWidgets/SelectBox";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import UpdatedProfile from "../../modals/UpdatedProfile";
import { Image } from "antd";
import FlagIcon from "../../assets/egypt.svg";
import { Button, Image as Img, Input } from "antd";
import UploadMediaonly from "../upload-imageonly";
import "../../styles.css";
import { useNavigate } from "react-router";
import image from "../../assets/Images/roundimage.png";
import { store } from "../../Redux/store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { UpdateRetailerDetails } from "../../API  Functions/ProfileFunctions";
import { uploadImage } from "../../API  Functions/InventoryFunctions";

const defaultImage = "roundimage.png";

const BasicDetails = ({
  payload,
  onDataSubmit,
  profiledetail,
  data,
  fetchdetails,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [showUpdatedProfile, setShowUpdatedProfile] = useState(false); // State to control the visibility of UpdatedProfile modal
  const [restricted, setrestricted] = useState(true);
  const [base64Images, setBase64Images] = useState("");
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const company_id = useSelector(
    (state) => state?.login?.user?.result?.company_id
  );

  const [file, setFile] = useState(defaultImage);

  const handleurlgenerated = (values) => {
    // setBase64Images(values.base64); // Assuming values.base64 contains the base64 image
    setrestricted(false); // Set restricted to false when an image is uploaded
    // formik.setFieldValue("image_url", values.url);
  };

  // console.log("DATA", data);

  useEffect(() => {
    if (data) {
      formik.setValues({
        retailer_number: data?.retailer_details?.retailer_no || "",
        approval_status: data?.retailer_details?.status || "",
        businessProfile: data.retailer_details.business_type || "",
        business_name: data.retailer_details.company_name || "",
        business_name_ar: data.retailer_details.company_name_ar || "",
        owner_name: data.retailer_details.retailer_name || "",
        alt_phone: data.retailer_details.alt_phone || "",
        phoneNumber: data.retailer_details.mobile || "",
        email: data.retailer_details.email || "",
        user_id: payload.user_id,
        store_id: payload.store_id,
        image_url: data.retailer_details.logo || "",
        shopType: data.retailer_details.shop_type || "",
        bdo_name: data.retailer_details.bdo || "",
        bdo_phone: data.retailer_details.bdo_phone || "",
      });
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {
      retailer_number: data?.retailer_details?.retailer_no || "",
      businessProfile: data?.retailer_details?.business_type || "",
      approval_status: data?.retailer_details?.status || "",
      business_name: data?.retailer_details?.company_name || "",
      business_name_ar: data?.retailer_details?.company_name_ar || "",
      alt_phone: data?.retailer_details?.alt_phone || "",
      owner_name: data?.retailer_details?.retailer_name || "",
      phoneNumber: data?.retailer_details?.mobile || "",
      email: data?.retailer_details?.email || "",
      user_id: payload.user_id,
      store_id: payload.store_id,
      image_url: data?.retailer_details?.logo || "",
      shopType: data?.retailer_details?.shop_type || "",
      bdo_name: data?.retailer_details?.bdo || "",
      bdo_phone: data?.retailer_details?.bdo_phone || "",
    },
    onSubmit: async (values) => {
      try {
        let uploadedImageUrl = "";

        // If there's a base64 image, upload it
        if (base64Images) {
          uploadedImageUrl = await uploadImage(company_id, base64Images);
          console.log("asadadad", uploadedImageUrl);

          formik.setFieldValue("image_url", uploadedImageUrl);
        }

        const apipayload = {
          user_id: user_id,
          shop_name: values.business_name,
          shop_name_ar: values?.business_name_ar,
          name: values.owner_name,
          image_url: uploadedImageUrl,
        };
        // console.log("apipayload", apipayload);
        const response = await UpdateRetailerDetails(apipayload);

        console.log("AAAA", response);

        if (response?.status === "Update Successfully") {
          setShowUpdatedProfile(true); // Show UpdatedProfile modal
          setrestricted(true);
        }
      } catch (error) {}
    },
  });

  useEffect(() => {
    if (base64Images) {
      setrestricted(false);
    }
  }, [base64Images]);

  // console.log("BASE Image", base64Images);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex items-center gap-4 mx-11 my-6">
          {base64Images ? ( // Check if base64Images is available
            <Img
              className="h-16 w-16 object-cover rounded-full"
              src={base64Images} // Use the base64 image
              width={60}
              height={60}
              alt="current profile photo"
            />
          ) : formik.values?.image_url ? ( // Check if formik has an image URL
            <Img
              className="h-16 w-16 object-cover rounded-full"
              // src={formik.values.image_url} // Use the formik image URL
              src={`${formik.values.image_url}?time=${new Date().getTime()}`}
              width={60}
              height={60}
              alt="current profile photo"
            />
          ) : (
            <img
              className="h-14 w-14 object-cover rounded-full"
              src={image} // Default image
              width={50}
              height={50}
              alt="current profile photo"
            />
          )}
          <label
            id="fileinputlabel"
            htmlFor="fileinput" // Use htmlFor instead of for
            className="cursor-pointer text-primaryBlue text-center text-base"
          >
            <UploadMediaonly
              foldername={"store_app_UAT"}
              onfileAdd={"userphoto"}
              urlgenerated={handleurlgenerated}
              setBase64Images={setBase64Images} // This should set the base64 image
              imageurl={formik.values.image_url}
            />
          </label>
        </div>
        <div className="font-normal text-sm text-[#5c5c5c]  flex mx-8  mt-5">
          <div className=" w-1/2 px-3">
            <div className=" md:w-full leading-[0px] my-4">
              <label>{t("Retailer Number")}</label>

              <InputBox
                name="retailer_number"
                placeholder="Retailer Number"
                onChange={(val) => {
                  formik.handleChange(val);
                  setrestricted(false);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.retailer_number}
                error={formik.errors.retailer_number}
                touched={formik.touched.retailer_number}
                className={` mt-4 h-10 text-[#929EAE] text-md text-body outline-none focus:outline-none ltr:pl-4 rtl:pr-5 rounded-lg border  ${
                  formik.touched.businessProfile &&
                  formik.errors.businessProfile
                    ? "border-red"
                    : ""
                }`}
                disabled
              />
            </div>

            <div className=" md:w-full leading-[0px] my-4">
              <label>{t("Company & Business Name")}</label>

              <InputBox
                name="business_name"
                placeholder="Store Name"
                onChange={(val) => {
                  formik.handleChange(val);
                  setrestricted(false);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.business_name}
                error={formik.errors.business_name}
                touched={formik.touched.business_name}
              />
            </div>
            <div className=" md:w-full leading-[0px] my-4">
              <label>{t("Company & Business Name (Arabic)")}</label>

              <InputBox
                name="business_name_ar"
                placeholder="Store Name in Arabic"
                onChange={(val) => {
                  formik.handleChange(val);
                  setrestricted(false);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.business_name_ar}
                error={formik.errors.business_name_ar}
                touched={formik.touched.business_name_ar}
              />
            </div>
            <div className=" md:w-full leading-[0px] my-4 ">
              <label>{t("business_owner_name")}</label>

              <InputBox
                name="owner_name"
                placeholder="Business Owner Name"
                onChange={(val) => {
                  formik.handleChange(val);
                  setrestricted(false);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.owner_name}
                error={formik.errors.owner_name}
                touched={formik.touched.owner_name}
              />
            </div>

            <div className=" md:w-full leading-[0px] my-4 ">
              <label>{t("Alternate Phone Number")}</label>

              <InputBox
                name="alt_phone"
                placeholder="Alternate Phone Number"
                onChange={(val) => {
                  formik.handleChange(val);
                  setrestricted(false);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.alt_phone}
                error={formik.errors.alt_phone}
                touched={formik.touched.alt_phone}
                disabled
              />
            </div>
            <div className=" md:w-full leading-[0px] my-4 ">
              <label>{t("BDO")}</label>
              <Input
                defaultValue="BDO"
                name="bdo_name"
                onChange={formik.handleChange}
                value={formik.values.bdo_name}
                error={formik.errors.bdo_name}
                touched={formik.touched.bdo_name}
                className={` mt-4 h-10 text-[#929EAE] text-md text-body outline-none focus:outline-none ltr:pl-4 rtl:pr-5 rounded-lg border  ${
                  formik.touched.bdo_name && formik.errors.bdo_name
                    ? "border-red" // Apply red border when touched or there's an error
                    : ""
                }`}
                disabled
              />
            </div>
          </div>
          <div className=" w-1/2 px-3">
            <div className=" md:w-full leading-[0px] my-4">
              <label>{t("Business Profile")}</label>
              <Input
                defaultValue="Select profile type"
                name="businessProfile"
                onChange={(val) => {
                  formik.handleChange(val);
                  setrestricted(false);
                }}
                value={formik.values.businessProfile}
                error={formik.errors.businessProfile}
                touched={formik.touched.businessProfile}
                className={` mt-4 h-10 text-[#929EAE] text-md text-body outline-none focus:outline-none ltr:pl-4 rtl:pr-5 rounded-lg border  ${
                  formik.touched.businessProfile &&
                  formik.errors.businessProfile
                    ? "border-red" // Apply red border when touched or there's an error
                    : ""
                }`}
                disabled
              />
            </div>

            <div className=" md:w-full leading-[0px] my-4">
              <label>{t("Approval Status")}</label>
              <Input
                defaultValue="Select profile type"
                name="approval_status"
                onChange={(val) => {
                  formik.handleChange(val);
                  setrestricted(false);
                }}
                value={formik.values.approval_status}
                error={formik.errors.approval_status}
                touched={formik.touched.approval_status}
                className={` mt-4 h-10 text-[#929EAE] text-md text-body outline-none focus:outline-none ltr:pl-4 rtl:pr-5 rounded-lg border  ${
                  formik.touched.approval_status &&
                  formik.errors.approval_status
                    ? "border-red" // Apply red border when touched or there's an error
                    : ""
                }`}
                disabled
              />
            </div>

            <div className=" md:w-full leading-[0px] my-4 ">
              <label>{t("shop_type")}</label>
              <Input
                defaultValue="Select ShopType"
                name="shopType"
                onChange={formik.handleChange}
                value={formik.values.shopType}
                error={formik.errors.shopType}
                touched={formik.touched.shopType}
                className={` mt-4 h-10 text-[#929EAE] text-md text-body outline-none focus:outline-none ltr:pl-4 rtl:pr-5 rounded-lg border  ${
                  formik.touched.shopType && formik.errors.shopType
                    ? "border-red" // Apply red border when touched or there's an error
                    : ""
                }`}
                disabled
              />
            </div>
            <div className=" md:w-full leading-[0px] my-4  ">
              <label className=" ">{t("Phone Number")}</label>

              <div className="flex items-center">
                <div className="h-10 mt-4 w-16 text-md text-body outline-none focus:outline-none  rounded-lg border p-3 flex items-center justify-center bg-[#EBEBEB]">
                  <img src={FlagIcon} alt="img" />
                </div>
                <Input
                  international
                  defaultCountry="EG"
                  value={"0" + formik.values.phoneNumber}
                  onChange={(phone) =>
                    formik.setFieldValue("phoneNumber", phone)
                  }
                  onBlur={formik.handleBlur}
                  name="phoneNumber"
                  disabled
                  className={`ms-2 mt-4 h-10 text-md text-body outline-none focus:outline-none ltr:pl-5 rtl:pr-5 rounded-lg border p-3 ${
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                      ? "border-red" // Apply red border when touched or there's an error
                      : ""
                  }`}
                />
              </div>

              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div className="font-medium italic text-red">
                  {formik.errors.phoneNumber}
                </div>
              ) : null}
            </div>
            <div className=" md:w-full leading-[0px] my-4 ">
              <label>{t("email")}</label>

              <Input
                name="email"
                placeholder={t("enter_email")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.errors.email}
                touched={formik.touched.email}
                className={` mt-4 p-3 h-10 text-[#929EAE] text-md text-body outline-none focus:outline-none ltr:pl-4 rtl:pr-5 rounded-lg border  ${
                  formik.touched.businessProfile &&
                  formik.errors.businessProfile
                    ? "border-red" // Apply red border when touched or there's an error
                    : ""
                }`}
                disabled
              />
            </div>
            <div className=" md:w-full leading-[0px] my-4 ">
              <label>{t("BDO Phone Number")}</label>
              <Input
                defaultValue="BDO Phone Number"
                name="bdo_phone"
                onChange={formik.handleChange}
                value={formik.values.bdo_phone}
                error={formik.errors.bdo_phone}
                touched={formik.touched.bdo_phone}
                className={` mt-4 h-10 text-[#929EAE] text-md text-body outline-none focus:outline-none ltr:pl-4 rtl:pr-5 rounded-lg border  ${
                  formik.touched.bdo_phone && formik.errors.bdo_phone
                    ? "border-red" // Apply red border when touched or there's an error
                    : ""
                }`}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="flex gap-5 mx-11 mt-8  mb-10 ">
          <button
            type="submit"
            className={`w-[180px] h-[45px] ${
              restricted ? "bg-primaryBluedisabled" : "bg-primaryBlue"
            } text-white rounded-lg font-semibold`}
            disabled={restricted}
          >
            {t("update_and_save")}
          </button>

          <Button
            // type="submit"
            className="w-[180px] h-[45px] text-primaryBlue font-semibold rounded-lg  border-primaryBlue"
            onClick={() => {
              formik.setValues({
                retailer_number: data?.retailer_details?.retailer_no || "",
                businessProfile: data?.retailer_details?.business_type || "",
                approval_status: data?.retailer_details?.status || "",
                business_name: data?.retailer_details?.company_name || "",
                business_name_ar: data?.retailer_details?.company_name_ar || "",
                owner_name: data?.retailer_details?.retailer_name || "",
                alt_phone: data?.retailer_details?.alt_phone || "",
                phoneNumber: data?.retailer_details?.mobile || "",
                email: data?.retailer_details?.email || "",
                user_id: payload.user_id,
                store_id: payload.store_id,
                image_url: data?.retailer_details?.logo || "",
                shopType: data?.retailer_details?.shop_type || "",
                bdo_name: data?.retailer_details?.bdo || "",
                bdo_phone: data?.retailer_details?.bdo_phone || "",
              });
              setBase64Images("");
            }}
          >
            {t("cancel")}
          </Button>
        </div>
      </form>

      {/* Show UpdatedProfile modal when showUpdatedProfile state is true */}
      {showUpdatedProfile && (
        <UpdatedProfile
          onClose={() => {
            setShowUpdatedProfile(false);
            fetchdetails();
            // window.location.reload();
          }}
          message={"Basic Details"}
        />
      )}
    </>
  );
};

export default BasicDetails;
