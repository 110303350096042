import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import "../../styles.css";
import { Button, Image, Input, Select, TimePicker } from "antd";
import { ProfileShopDetailSchema } from "../validationSchema";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { URLS } from "../../API/API endpoints";
import axiosInstance from "../../AxiosInstance";
import UpdatedProfile from "../../modals/UpdatedProfile";
import { InputBox } from "../CustomWidgets/InputBox";
import { useTranslation } from "react-i18next";
import UploadMediaonly from "../upload-imageonly";
import {
  RetailerShopImagesUpload,
  ShopImageUpload,
  UpdateRetailerDetails,
} from "../../API  Functions/ProfileFunctions";
import { uploadImage } from "../../API  Functions/InventoryFunctions";

const ShopLocation = ({ payload, data }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const store_id = useSelector((state) => state.login?.user?.store_id);

  const [showUpdatedProfile, setShowUpdatedProfile] = useState(false);
  const [restricted, setrestricted] = useState(true);
  const [uploadBoxes, setUploadBoxes] = useState(1); // Start with one upload box
  const [base64Images, setBase64Images] = useState([]); // State to hold multiple Base64 images
  const company_id = useSelector(
    (state) => state?.login?.user?.result?.company_id
  );

  const addUploadBox = () => {
    if (uploadBoxes < 24) {
      setUploadBoxes(uploadBoxes + 1);
    }
  };

  //   console.log("HHHHHHHHHH", data);

  useEffect(() => {
    if (data) {
      //   console.log("JADAD", data?.retailer_details?.longitude);

      formik?.setValues({
        street: data?.retailer_details?.shop_address?.street || "",
        street_2: data?.retailer_details?.shop_address?.street2 || "",
        city: data?.retailer_details?.shop_address?.city || "",
        governate: data?.retailer_details?.shop_address?.state || "",
        zipcode: data?.retailer_details?.shop_address?.zip || "",
        country: data?.retailer_details?.shop_address?.country || "",
        longitude: data?.retailer_details?.longitude || "",
        latitude: data?.retailer_details?.latitude || "",
      });
      setBase64Images(new Array(25).fill(null).map(() => "")); // Initialize with empty strings
      setUploadBoxes(data?.retailer_details?.shop_image_urls.length || 1); // Set the number of upload boxes based on existing images
      setrestricted(true);
      //   const initialImages = data?.retailer_details?.shop_image_urls || [];
      //   setBase64Images(initialImages.map((url) => ({ url, base64: "" }))); // Initialize with URLs
      //   setUploadBoxes(initialImages.length); // Set the number of upload boxes based on existing images
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {
      street: data?.retailer_details?.shop_address?.street || "",
      street_2: data?.retailer_details?.shop_address?.street2 || "",
      city: data?.retailer_details?.shop_address?.city || "",
      governate: data?.retailer_details?.shop_address?.state || "",
      zipcode: data?.retailer_details?.shop_address?.zip || "",
      country: data?.retailer_details?.shop_address?.country || "",
      longitude: data?.retailer_details?.longitude || "",
      latitude: data?.retailer_details?.latitude || "",
    },

    // validationSchema: ProfileShopDetailSchema,
    validateOnChange: false,
    validateOnBlur: false,

    onSubmit: async (values) => {
      try {
        const uploadedImageUrls = []; // Array to hold the URLs of uploaded images

        // Loop through base64Images and upload each image
        for (let index = 0; index < base64Images.length; index++) {
          if (base64Images[index]) {
            const imageName = `${company_id}-${index}`; // Create the image name
            const uploadedImageUrl = await ShopImageUpload(
              company_id,
              base64Images[index],
              imageName
            ); // Upload the image
            console.log("uploadedImageUrl", uploadedImageUrl);

            uploadedImageUrls.push(uploadedImageUrl); // Store the returned URL
          }
        }

        // Now call the /RetailerShopImagesUpload API with the collected URLs
        const imageUploadPayload = {
          company_id: company_id,
          image_urls: uploadedImageUrls,
        };
        console.log("imageUploadPayload", imageUploadPayload);
        if (uploadedImageUrls) {
          await RetailerShopImagesUpload(imageUploadPayload); // Hit the API to upload image URLs
        }

        const payload = {
          user_id: user_id,
          street: values.street,
          street_2: values.street_2,
          city: values.city,
          longitude: values.longitude,
          latitude: values.latitude,
        };
        const response = await UpdateRetailerDetails(payload);

        if (response?.status === "Update Successfully") {
          setShowUpdatedProfile(true); 
          setrestricted(true);
        }
      } catch (error) {
        console.error("Error during submission:", error);
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    console.log("Submitting form...");

    // Check for validation errors
    if (formik.errors && Object.keys(formik.errors).length > 0) {
      console.log("Formik errors:", formik.errors);
      return; // Prevent submission if there are errors
    }

    formik.handleSubmit(); // Call Formik's submit handler
  };

  // resetForm();
  // },
  //   const handleSubmit = (e) => {
  //     formik.handleSubmit(e);
  //   };

  const handleurlgenerated = (values, keyname) => {
    setrestricted(false);
    formik.setFieldValue(keyname, values?.url);
  };

  // console.log("BASEEEEE", base64Images);

  return (
    <>
      <form onSubmit={handleSubmit} className="h-120vh">
        <div className="font-normal text-[14px] text-[#5C5C5C] grid grid-cols-2 gap-x-9 mx-7 mt-8">
          <div className="w-[363px] md:w-full  leading-[0px]">
            <label>{t("Street")}</label>
            <InputBox
              name="street"
              placeholder="Street"
              onChange={(val) => {
                formik.handleChange(val);
                setrestricted(false);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.street}
              //   error={formik.errors.street}
              //   touched={formik.touched.street}
            />
          </div>
          <div className="flex gap-x-4">
            <div className="w-[170px] md:w-full leading-[0px]">
              <label> {t("Governate")}</label>
              <Input
                name="governate"
                placeholder="Governate"
                className="text-[#5C5C5C] mt-4 h-10"
                onChange={(val) => {
                  formik.handleChange(val);
                  setrestricted(false);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.governate}
                // error={formik.errors.governate}
                // touched={formik.touched.governate}
                disabled
              />
            </div>
            <div className="w-[180px] md:w-full h-[89px] leading-[0px]">
              <label>{t("Zip")}</label>
              <Input
                name="zipcode"
                className="text-[#5C5C5C] mt-4 h-10"
                placeholder="Zip"
                onChange={(val) => {
                  formik.handleChange(val);
                  setrestricted(false);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.zipcode}
                // error={formik.errors.zipcodes}
                // touched={formik.touched.zipcode}
                disabled
              />
            </div>
          </div>
          <div className="w-[363px] md:w-full leading-[0px]">
            <label>{t("Street 2")}</label>
            <InputBox
              name="street_2"
              placeholder="Street 2"
              onChange={(val) => {
                formik.handleChange(val);
                setrestricted(false);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.street_2}
              //   error={formik.errors.street_2}
              //   touched={formik.touched.street_2}
            />
          </div>

          <div className="w-[363px] md:w-full leading-[0px] ">
            <label>{t("Country")}</label>
            <Input
              className="text-[#5C5C5C] mt-4 h-10"
              name="country"
              placeholder="Country"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.country}
              //   error={formik.errors.country}
              //   touched={formik.touched.country}
              disabled
            />
          </div>
          <div className="w-[363px] md:w-full leading-[0px] mt-7">
            <label>{t("City")}</label>
            <Input
              className="text-[#5C5C5C] mt-4 h-10"
              name="city"
              placeholder="Enter City"
              onChange={(val) => {
                formik.handleChange(val);
                setrestricted(false);
              }}
              //   onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city}
              //   error={formik.errors.city}
              //   touched={formik.touched.city}
              //   disabled
            />
          </div>
          <div className="flex gap-x-4 mt-7">
            <div className="w-[170px] md:w-full leading-[0px]">
              <label> {t("Latitude")}</label>
              <Input
                name="latitude"
                placeholder="Latitude"
                className="text-[#5C5C5C] mt-4 h-10"
                onChange={(val) => {
                  formik.handleChange(val);
                  setrestricted(false);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.latitude}
                // error={formik.errors.latitude}
                // touched={formik.touched.latitude}
                disabled
              />
            </div>
            <div className="w-[180px] md:w-full h-[89px] leading-[0px]">
              <label>{t("Longitude")}</label>
              <Input
                name="longitude"
                className="text-[#5C5C5C] mt-4 h-10"
                placeholder="Longitude"
                onChange={(val) => {
                  formik.handleChange(val);
                  setrestricted(false);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.longitude}
                // error={formik.errors.longitude}
                // touched={formik.touched.longitude}
                disabled
              />
              <div className="mt-2 justify-end">
                <Button
                  type="button"
                  className="w-[180px] text-primaryBlue font-semibold rounded-lg inline border border-primaryBlue"
                  onClick={() => {
                    if (navigator.geolocation) {
                      navigator.geolocation.getCurrentPosition(
                        (position) => {
                          const { latitude, longitude } = position.coords;
                          formik.setFieldValue("latitude", latitude);
                          formik.setFieldValue("longitude", longitude);
                          setrestricted(false);
                        },
                        (error) => {
                          console.error("Error getting location:", error);
                        }
                      );
                    } else {
                      console.error(
                        "Geolocation is not supported by this browser."
                      );
                    }
                  }}
                >
                  {t("Get Lat & Long")}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="font-medium text-xl mx-7 mt-10">{t("shop_photos")}</div>
        <div className="flex justify-start mx-7 mt-4">
          <Button
            type="button"
            onClick={addUploadBox}
            className="bg-primaryBlue text-white font-semibold rounded-lg"
          >
            Add More +
          </Button>
        </div>

        <div className="grid grid-cols-3 gap-5 mx-7 mt-4 font-normal text-sm">
          {[...Array(uploadBoxes)].map((_, index) => (
            <div key={index} className="w-full">
              <label className="text-[#5C5C5C]">
                {t(`Shop Photo ${index + 1}`)}
              </label>
              <div className="rounded-lg border border-inset border-gray-900/25 px-2 mt-3 py-3 flex justify-center">
                <div
                  className="flex flex-col items-center justify-center"
                  style={{ minHeight: 100, maxHeight: 100 }}
                >
                  <div className="flex  text-sm leading-6 text-gray-600">
                    {data?.retailer_details?.shop_image_urls[index] ? (
                      <Image
                        src={data.retailer_details.shop_image_urls[index]} // Use the URL from formik
                        height={100}
                        width={100}
                        alt="Image"
                        style={{
                          objectFit: "cover", // Ensures the image covers the box without distortion
                          minWidth: "100%",
                          maxWidth: "100%",
                          maxHeight: "100%",
                          borderRadius: "8px", // Optional: Add some border radius for aesthetics
                        }}
                      />
                    ) : base64Images[index] ? (
                      <Image
                        src={base64Images[index]} // Use the Base64 image if URL is not available
                        height={100}
                        width={100}
                        alt="Uploaded"
                        style={{
                          objectFit: "cover", // Ensures the image covers the box without distortion
                          minWidth: "100%",
                          maxWidth: "100%",
                          maxHeight: "100%",
                          borderRadius: "8px", // Optional: Add some border radius for aesthetics
                        }}
                      />
                    ) : (
                      <svg
                        className=" float-left h-10 w-12 text-gray-300"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                        />
                      </svg>
                    )}
                    <span
                      className={`cursor-pointer ${
                        base64Images[index] ? "mt-4" : "mt-0"
                      }`}
                    >
                      {!data?.retailer_details?.shop_image_urls[index] && (
                        <UploadMediaonly
                          foldername={"store_ShopImages"}
                          onfileAdd={`Shop_${index + 1}`}
                          setBase64Images={(base64) => {
                            const newImages = [...base64Images];
                            newImages[index] = base64; // Store the base64 data at the specific index
                            setBase64Images(newImages);
                            setrestricted(false);
                          }}
                          urlgenerated={(val) => {
                            formik.setFieldValue(
                              `image_url_${index}`,
                              val?.url
                            ); // Store the URL in Formik
                          }}
                          imageurl={base64Images[index]?.url || ""}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-8 mb-10 mx-7 flex gap-5">
          <button
            type="submit"
            className={`w-[180px] h-[45px] ${
              restricted ? "bg-primaryBluedisabled" : "bg-primaryBlue"
            } text-white rounded-lg font-semibold`}
            disabled={restricted}
          >
            {t("update_and_save")}
          </button>
          <Button
            className="w-[180px] h-[45px] text-primaryBlue font-semibold rounded-lg inline border border-primaryBlue"
            onClick={() => {
              formik?.setValues({
                street: data?.retailer_details?.shop_address?.street || "",
                street_2: data?.retailer_details?.shop_address?.street2 || "",
                city: data?.retailer_details?.shop_address?.city || "",
                governate: data?.retailer_details?.shop_address?.state || "",
                zipcode: data?.retailer_details?.shop_address?.zip || "",
                country: data?.retailer_details?.shop_address?.country || "",
                longitude: data?.retailer_details?.longitude || "",
                latitude: data?.retailer_details?.latitude || "",
              });
              //   setUploadBoxes(1);
              setBase64Images(new Array(25).fill(null).map(() => "")); // Initialize with empty strings
              setUploadBoxes(
                data?.retailer_details?.shop_image_urls.length || 1
              );
            }}
          >
            {t("cancel")}
          </Button>
        </div>
      </form>
      {showUpdatedProfile && (
        <UpdatedProfile
          onClose={() => setShowUpdatedProfile(false)}
          message={"Shop Details"}
        />
      )}
    </>
  );
};

export default ShopLocation;
