import React, { useState } from "react";
import Button from "../CustomWidgets/Button";
import CompleteProfile from "../../modals/CompleteProfile";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Image } from "antd";
import UploadImageMedia from "../upload-image-media";
import { useSelector } from "react-redux";

const ProfileCreation = ({ values, onDataSubmit }) => {
  const { t, i18n } = useTranslation();
  const [showCompleteModal, setShowCompleteModal] = useState(false); // State for modal visibility
  const companyId = useSelector((state) => state?.login?.user?.result?.company_id);


  const formik = useFormik({
    initialValues: {
      Shop_logo: "",
      Owner_Photo: "",
      Shop_1: "",
      Shop_2: "",
      Shop_3: "",
    },
    // validationSchema: LoginSchema,
    onSubmit: (values) => {

      const imageUrls = [
        values.Shop_logo,
        values.Owner_Photo,
        values.Shop_1,
        values.Shop_2,
        values.Shop_3,
      ].filter(url => url);

      const api_payload = {
        company_id: companyId,
        image_urls: imageUrls, 
      };
      onDataSubmit("profile_creation_data", api_payload);
    },
  });

  // Function to handle opening the modal
  const handleOpenModal = () => {
    setShowCompleteModal(true);
  };

  const handleurlgenerated = (values) => {
    formik.setFieldValue(values.name, values.url);
  };
  return (
    <>
      <div className="font-normal text-base leading-[0px] w-[95%] ms-4">
        <h1 className="text-2xl font-bold">{t("profile_creation")}</h1>
        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-2 gap-2 gap-x-10 mt-10">
            <div className="h-10">
              <label className="text-sm">{t("shop_logo")}</label>
              <div className="w-[363px] mt-2  md:w-full">
                <div className="rounded-lg border border-dashed border-primaryBlue px-2 py-3">
                  <div className="flex flex-row mx-auto justify-start gap-x-4" style={{ minHeight: 70, maxHeight: 70 }}>
                    {formik.values?.Shop_logo ? (
                      <Image
                        src={formik.values?.Shop_logo}
                        height={70}
                        width={70}
                        alt="image"
                      />
                    ) : (
                      <svg
                        className=" float-left  h-20 w-24 text-gray-300"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                        />
                      </svg>
                    )}

                    <div className="flex flex-row text-sm leading-6 ms-16 text-gray-600">
                      <label
                        for="file-upload"
                        className="flex items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                      >
                        <UploadImageMedia
                          foldername={"store_image"}
                          onfileAdd={"Shop_logo"}
                          urlgenerated={handleurlgenerated}
                          imageurl={formik.values.Shop_logo}
                          message={(message) => !message}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Shop Image 1 - Placed where Owner Photo was */}
            <div>
              <label className="text-sm">{t("shopphoto1")}</label>
              <div className="w-[363px] mt-2  md:w-full">
                <div className="rounded-lg border border-dashed border-primaryBlue px-2 py-3">
                  <div className="flex flex-row mx-auto justify-start gap-x-4" style={{ minHeight: 70, maxHeight: 70 }}>
                    {formik.values?.Shop_1 ? (
                      <Image
                        src={formik.values?.Shop_1}
                        height={70}
                        width={70}
                        alt="image"
                      />
                    ) : (
                      <svg
                        className=" float-left  h-20 w-24 text-gray-300"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                        />
                      </svg>
                    )}

                    <div className="flex flex-row text-sm leading-6 ms-16 text-gray-600">
                      <label
                        for="file-upload"
                        className="flex items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                      >
                        <UploadImageMedia
                          foldername={"store_app_UAT"}
                          onfileAdd={"Shop_1"}
                          urlgenerated={handleurlgenerated}
                          imageurl={formik.values.Shop_1}
                          message={(message) => !message}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-2 gap-x-10 mt-12 pb-8">
            {/* Shop Image 2 - Styled similarly to Shop Logo */}
            <div>
              <label className="text-sm">{t("shopphoto2")}</label>
              <div className="w-[363px] mt-2  md:w-full">
                <div className="rounded-lg border border-dashed border-primaryBlue px-2 py-3">
                  <div className="flex flex-row mx-auto justify-start gap-x-4" style={{ minHeight: 70, maxHeight: 70 }}>
                    {formik.values?.Shop_2 ? (
                      <Image
                        src={formik.values?.Shop_2}
                        height={70}
                        width={70}
                        alt="image"
                      />
                    ) : (
                      <svg
                        className=" float-left  h-20 w-24 text-gray-300"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                        />
                      </svg>
                    )}

                    <div className="flex flex-row text-sm leading-6 ms-16 text-gray-600">
                      <label
                        for="file-upload"
                        className="flex items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                      >
                        <UploadImageMedia
                          foldername={"store_app_UAT"}
                          onfileAdd={"Shop_2"}
                          urlgenerated={handleurlgenerated}
                          imageurl={formik.values.Shop_2}
                          message={(message) => !message}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Shop Image 3 - Styled similarly to Shop Logo */}
            <div>
              <label className="text-sm">{t("shopphoto3")}</label>
              <div className="w-[363px] mt-2  md:w-full">
                <div className="rounded-lg border border-dashed border-primaryBlue px-2 py-3">
                  <div className="flex flex-row mx-auto justify-start gap-x-4" style={{ minHeight: 70, maxHeight: 70 }}>
                    {formik.values?.Shop_3 ? (
                      <Image
                        src={formik.values?.Shop_3}
                        height={70}
                        width={70}
                        alt="image"
                      />
                    ) : (
                      <svg
                        className=" float-left  h-20 w-24 text-gray-300"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                        />
                      </svg>
                    )}

                    <div className="flex flex-row text-sm leading-6 ms-16 text-gray-600">
                      <label
                        for="file-upload"
                        className="flex items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                      >
                        <UploadImageMedia
                          foldername={"store_app_UAT"}
                          onfileAdd={"Shop_3"}
                          urlgenerated={handleurlgenerated}
                          imageurl={formik.values.Shop_3}
                          message={(message) => !message}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-[185px] h-[45px] float-right mt-8">
            <button
              type="submit"
              className="h-[45px] w-[185px] bg-primaryBlue text-white rounded-lg font-semibold float-right"
            >
              {t("complete")}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};


export default ProfileCreation;
