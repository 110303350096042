import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import i18next, { t } from "i18next";
import {
  Input,
  DatePicker,
  Button,
  Select,
  Breadcrumb,
  AutoComplete,
  Image,
} from "antd";
import { CloseOutlined, DoubleRightOutlined } from "@ant-design/icons";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../Routes";
import plus from "../../assets/Images/plus.svg";
import AddVendor from "./AddVendor";
import { useTable } from "react-table";
import {
  createPurchaseOrder,
  getPayTerms,
  getPurchaseProducts,
  getVendors,
  updatePOImages,
} from "../../API  Functions/PurchaseFunctions";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "@mui/material";
import deleteicon from "../../assets/Order/Delete.svg";
import ExpiryModal from "../../modals/ExpiryModal";
import ProductTable from "./ProductTable";
import PurchasePayment from "../../modals/purchasePaymentModal";
import SuccessModal from "../../modals/Sucessmodal";
import BarcodeScanner from "react-barcode-reader";
import {
  InventorySearchonboard,
  InventorySearchonboardBarcode,
} from "../../API  Functions/InventoryFunctions";
import axiosInstance from "../../AxiosInstance";
import { toast } from "react-toastify";
import LoadingSpinner from "../../components/Loading";
import UploadBillImage from "./uploadBillImage";
import { UserImageUpload } from "../../API  Functions/UserMangement";
import AddBarcode from "../../modals/AddBarcodeModal";

const AddBill = () => {
  const dispatch = useDispatch();
  const [vendor, setVendor] = useState(null);
  const [billDate, setBillDate] = useState(null);
  const [billNumber, setBillNumber] = useState("");
  const [vat, setVat] = useState(0);
  const [vatNum, setVatNum] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productList, setProductList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [billDiscount, setBillDiscount] = useState(0);
  const [vatAmount, setVatAmount] = useState(0);
  const [showComponent, setShowComponent] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [value, setValue] = useState("");
  const [Productoptions, setProductoptions] = useState([]);
  const [billDiscountPer, setBillDiscountPer] = useState(0);
  const [billDiscountVal, setBillDiscountVal] = useState(0);
  const [totalVatVal, settotalVatVal] = useState(0);
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState(null);
  const [isDraft, setIsDraft] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPayModalVisible, setIsPayModalVisible] = useState(false);
  const [orderId, setOrderId] = useState(0);
  const [requestProduct, setrequestProduct] = useState(false);
  const [loading, setLoading] = useState(false);
  const [base64Images, setBase64Images] = useState([]);
  const [addImage, setAddImage] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);

  const [currentProductIndex, setCurrentProductIndex] = useState(null);
  const [savedExpiryData, setSavedExpiryData] = useState({});
  const [isOrderCreated, setIsOrderCreated] = useState(false); // New state to track if the order is created
  const [popup, setpopup] = useState(false);
  const [isBarcodeModalVisible, setIsBarcodeModalVisible] = useState(false);

  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const company_id = useSelector(
    (state) => state?.login?.user?.result?.company_id
  );

  const location = useLocation();
  const { orderData } = location.state || {};

  const vendorData = useSelector((state) => state.form.vendor);
  const billDateData = useSelector((state) => state.form.billDate);
  const billNumberData = useSelector((state) => state.form.billNumber);
  const vatNumData = useSelector((state) => state.form.vatNum);
  const productListData = useSelector((state) => state.form.productList);

  useEffect(() => {
    setVendor(vendorData);
    setBillDate(billDateData);
    setBillNumber(billNumberData);
    setProductList(productListData);
  }, [vendorData, billDateData, billNumberData, productListData]);

  const isSaveDisabled =
    !vendor ||
    !billDate ||
    !billNumber ||
    // !vatNum ||
    !selectedPaymentTerm ||
    productList.length === 0;

  useEffect(() => {
    // console.log("order Data --->", orderData);

    if (orderData) {
      const { order_details, lines } = orderData;

      setVendor(order_details.partner_name);
      setBillDate(moment(order_details.order_date).format("YYYY-MM-DD"));
      setBillNumber(order_details.venfor_ref || "");
      // setVatNum(order_details.vat_value || "");
      setSelectedPaymentTerm(order_details.payment_term);
      setProductList(
        lines.map((line) => ({
          prod_id: line.product_id,
          name: line.product_name,
          uom: line.product_uom,
          cost_price: line.cost_price,
          salesPrice: line.sale_price,
          purchaseQty: line.product_qty,
          barcode: line.barcode,
          vat: 0,
          discount: 0,
          discountValue: 0,
          totalValue: line.sub_total,
          includingVat: line.sub_total,
          effectivePrice: line.sub_total,
          profit: 0,
          expiryData: [],
          expiryAlert: false,
          alertDays: 0,
        }))
      );
    }
  }, [orderData]);

  useEffect(() => {
    getVendorsData();
    getPaymentTerms();
  }, []);

  const getVendorsData = () => {
    getVendors(user_id).then((res) => {
      setVendors(res);
    });
  };

  const getPaymentTerms = () => {
    getPayTerms(company_id).then((res) => {
      // console.log("getPayTerms", res);

      setPaymentTerms(res);
    });
  };

  const handleOpenModal = (index) => {
    // console.log("Inde o the prod", index);

    setCurrentProductIndex(index);
    setIsModalVisible(true);
  };

  const handleSaveExpiryData = (
    expiryData,
    expiryAlert,
    alertDays,
    productIndex
  ) => {
    const updatedProductList = [...productList];
    updatedProductList[productIndex].expiryData = expiryData; // Store expiry data in the product
    updatedProductList[productIndex].expiryAlert = expiryAlert; // Store alert status
    updatedProductList[productIndex].alertDays = alertDays; // Store alert days
    setProductList(updatedProductList);
    setSavedExpiryData((prevData) => ({
      ...prevData,
      [productIndex]: { expiryData, expiryAlert, alertDays }, // Store all relevant data
    }));

    console.log("Saved expiry data for product index:", productIndex, {
      expiryData,
      expiryAlert,
      alertDays,
    }); // Log the saved data
  };

  // console.log("SelectedproductList", productList);

  const handleProductChange = (index, key, value) => {
    // console.log("WWWWW");

    const updatedProductList = [...productList];

    // Ensure value is a valid number (fallback to 0 if invalid)
    // console.log("updatedProductList", updatedProductList);

    if (
      key === "purchaseQty" ||
      key === "discount" ||
      key === "vat" ||
      key === "discount_value" ||
      key === "cost_price" ||
      key === "profit"
    ) {
      value = parseFloat(value) || 0;
    }

    if (key === "purchaseQty" && value < 0) value = 0; // Prevent negative quantity

    updatedProductList[index][key] = value;

    // Calculate the total value excluding VAT
    const totalValueExcludingVAT =
      (updatedProductList[index]?.cost_price || 0) *
        (updatedProductList[index]?.purchaseQty || 0) -
      (updatedProductList[index]?.discount || 0) *
        0.01 *
        (updatedProductList[index]?.purchaseQty || 0) *
        (updatedProductList[index]?.cost_price || 0) -
      (updatedProductList[index]?.discount_value || 0);

    // Update the totalValue field
    updatedProductList[index].totalValue = totalValueExcludingVAT;

    // Calculate discount Vat
    const vatAmount =
      (totalValueExcludingVAT * updatedProductList[index]?.vat) / 100;
    updatedProductList[index].includingVat = vatAmount;

    // console.log("vatAmount", vatAmount);

    // Calculate effective price (including VAT)
    const purchaseQty = updatedProductList[index]?.purchaseQty || 0;
    const freeQty = updatedProductList[index]?.freeQty || 0;
    const producVatVal = updatedProductList[index].includingVat; // Convert percentage to decimal
    const totalExcludingVat = updatedProductList[index]?.totalValue;
    const totalInvoiceDiscount = (
      (billDiscountPer / 100) * totalAmount +
      parseFloat(billDiscountVal || 0)
    ).toFixed(2);

    const totalInvoiceAmount = totalAmount;
    const totalInvoiceVat = totalVatVal;

    // console.log("totalInvoiceVat", totalInvoiceVat);

    const effectivePriceIncVat =
      (Number(totalExcludingVat) + Number(producVatVal)) /
      (Number(purchaseQty) + Number(freeQty)) /
      (1 +
        Number(totalInvoiceDiscount) /
          (Number(totalInvoiceAmount) + Number(totalInvoiceVat)));

    updatedProductList[index].effectivePrice = effectivePriceIncVat || 0;

    // console.log("effectivePrice", effectivePriceIncVat);
    // console.log("updatedProductList", updatedProductList);

    // Calculate profit
    const prodSalePrice = updatedProductList[index]?.salesPrice;
    const productProfit = Number(prodSalePrice) / effectivePriceIncVat - 1;
    const finalProfit = productProfit * 100;

    updatedProductList[index].profit = finalProfit || 0;

    // Update the product list and recalculate totals
    setProductList(updatedProductList);
    calculateTotal(updatedProductList); // Ensure this is called to recalculate totals
  };

  // Calculate the totals dynamically based on the updated product list
  const calculateTotal = (updatedProductList) => {
    let total = 0;
    let totalVat = 0;
    let totalDiscount = 0;

    updatedProductList.forEach((product) => {
      const totalValue = product.totalValue || 0;
      const vatAmount = product.includingVat || 0;
      const discountValue = product.discountValue || 0;

      // console.log("PRRP",product);

      total += totalValue;
      totalVat += vatAmount;
      totalDiscount += discountValue;
    });

    const totalAfterDiscount = total - totalDiscount; // Subtract the total discount from total value
    const vatTotal = totalAfterDiscount * (vat / 100) || 0; // Apply global VAT percentage

    // Update state with recalculated totals
    settotalVatVal(totalVat);
    setTotalAmount(totalAfterDiscount + vatTotal); // Grand total including VAT
    setVatAmount(vatTotal); // Total VAT amount
    setBillDiscount(totalDiscount); // Total discount value
  };

  // For VAT, Bill Discount, Grand Total, and other calculations
  const totalAfterDiscount = totalAmount - billDiscount; // Total after discount applied
  const vatTotal = totalAfterDiscount * (vat / 100); // VAT calculation
  const grandTotal = totalAfterDiscount + vatTotal; // Grand total calculation

  const debouncedSearch = debounce((value) => {
    if (value.length >= 3) {
      getPurchaseProducts(user_id, value, 10, 0)
        .then((res) => {
          if (res?.result && res.result.length > 0) {
            const products = res.result;
            setProductoptions(products);
          } else {
            setProductoptions([]);
          }
        })
        .catch(() => {
          setProductoptions([]);
        });
    } else {
      setProductoptions([]);
    }
  }, 1000);

  const handleProductSelect = (value) => {
    const selectedProduct = Productoptions.find(
      (product) => product.name === value
    );

    if (selectedProduct) {
      // Check if the product has a barcode
      if (!selectedProduct.barcode) {
        // If no barcode, open the modal to input the barcode
        setSelectedProduct(selectedProduct);
        setSelectedProductId(selectedProduct.prod_id);
        setIsBarcodeModalVisible(true);
        return; // Exit the function to prevent adding the product
      }
    }

    if (selectedProduct) {
      // Check if the product is already in the productList
      const isDuplicate = productList.some(
        (product) => product.prod_id === selectedProduct.prod_id // Assuming prod_id is unique
      );

      if (isDuplicate) {
        // Optionally, show a message to the user
        toast.error(`${selectedProduct.name} is already in the list.`); // Replace with a more user-friendly message or modal if needed
        return; // Exit the function to prevent adding the duplicate
      }

      const productWithQty = {
        ...selectedProduct,
        vat: 0,
        purchaseQty: 0,
        freeQty: 0,
        discount: 0,
        discountValue: "0",
        salesPrice: selectedProduct?.sale_price,
        totalValue: 0,
        includingVat: 0,
        effectivePrice: 0,
        profit: 0,
      };
      setSelectedProduct(productWithQty);
      setProductList([...productList, productWithQty]);
      calculateTotal([...productList, productWithQty]);
      setSearchValue("");
      setValue("");
      setProductoptions([]);
    }
  };

  const handleDeleteProduct = (index) => {
    const updatedProductList = [...productList];
    updatedProductList.splice(index, 1);
    setProductList(updatedProductList);
    calculateTotal(updatedProductList);
  };

  const closeAddVendor = () => {
    setShowComponent(false);
    getVendorsData();
  };

  const handleCancel = () => {
    setProductList([]);
    setProductoptions([]);
    setBase64Images([]);
    setIsDraft(false);
    setBase64Images([]);
    setVendor(null);
    setBillDate(null);
    setBillNumber("");
    setVatNum("");
    setSelectedPaymentTerm(null);
  };

  // console.log("PROD", productList);

  const handleSavePurchaseOrder = async () => {
    for (const product of productList) {
      if (product.cost_price <= 0) {
        toast.error(t("Unit Price cannot be 0."));
        return; // Prevent API call
      }
      if (product.purchaseQty <= 0) {
        toast.error(t("Purchase Quantity cannot be 0."));
        return; // Prevent API call
      }
      if (product.salesPrice <= 0) {
        toast.error(t("Sales Price cannot be 0."));
        return; // Prevent API call
      }
      if (product.salesPrice < product.cost_price) {
        toast.error(t("Sales Price cannot be less than cost price."));
        return; // Prevent API call
      }
    }
    const paymentTermId = paymentTerms.find(
      (term) => term.name === selectedPaymentTerm
    )?.id;

    const vendorId = vendors.find((ve) => ve.name === vendor)?.id;

    const payload = {
      user_id: user_id,
      company_id: company_id,
      payment_term_id: paymentTermId,
      vendor_id: vendorId,
      date_of_bill: billDate,
      vat_value: vatNum,
      state: "done",
      vendor_reference: billNumber,
      lines: productList.map((product) => ({
        product_id: product.prod_id,
        price: product.effectivePrice,
        selling_price: product.salesPrice,
        vat: product.vat,
        quantity: product.purchaseQty,
        free_qty: product.freeQty,
        discount_per: product.discount,
        discount_value: product.discountValue,
        product_expiry: (product.expiryData || []).map((expiry) => ({
          // Fallback to an empty array if expiryData is undefined
          quantity: expiry.qty,
          expiry_date: moment(expiry.expiryDate).format("YYYY-MM-DD"),
        })),
        is_alert: product.expiryAlert,
        alert_days: product.alertDays,
      })),
      bill_discount_per: billDiscountPer,
      bill_discount_value: billDiscountVal,
    };

    if (orderData) {
      payload.po_id = orderData.order_details.order_id;
    }

    // console.log("Payload for purchase order:", payload);

    // console.log("Payload for purchase order:", payload);
    setLoading(true);
    try {
      const response = await createPurchaseOrder(payload);
      console.log("Purchase order created successfully:", response);

      if (response.result.status_code === 200) {
        let order_id = response.result.order_id;
        setOrderId(order_id);

        if (base64Images.length > 0) {
          handleAttachImages(order_id);
        } else {
          setpopup(true);
          setIsOrderCreated(true);
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("Failed to create purchase order:", error);
      setLoading(false);
    }
  };

  // console.log("PROD LIS",productList);

  const handleDraftClose = async () => {
    setIsDraft(true);
    setLoading(true);
    const paymentTermId = paymentTerms.find(
      (term) => term.name === selectedPaymentTerm
    )?.id;
    const vendorId = vendors.find((ve) => ve.name === vendor)?.id;

    const payload = {
      user_id: user_id,
      company_id: company_id,
      payment_term_id: paymentTermId,
      vendor_id: vendorId,
      date_of_bill: billDate,
      vat_value: vatNum,
      vendor_reference: billNumber,
      state: "draft",
      lines: productList.map((product) => ({
        product_id: product.prod_id,
        price: product.effectivePrice,
        selling_price: product.salesPrice,
        vat: product.vat,
        quantity: product.purchaseQty,
        free_qty: product.freeQty,
        discount_per: product.discount,
        discount_value: product.discountValue,
        product_expiry: (product.expiryData || []).map((expiry) => ({
          quantity: expiry.qty,
          expiry_date: moment(expiry.expiryDate).format("YYYY-MM-DD"),
        })),
        is_alert: product.expiryAlert,
        alert_days: product.alertDays,
      })),
      bill_discount_per: billDiscountPer,
      bill_discount_value: billDiscountVal,
    };

    try {
      const response = await createPurchaseOrder(payload);
      // console.log("Draft order created successfully:", response);

      if (response.result.status_code === 200) {
        setBase64Images([]);
        setProductList([]);
        setIsDraft(false);
        setVendor(null);
        setBillDate(null);
        setBillNumber("");
        setVatNum("");
        setSelectedPaymentTerm(null);
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to create purchase order:", error);
      setLoading(false);
    }
  };

  const handleclose = () => {
    setVendor(null);
    setBillDate(null);
    setBillNumber("");
    setVatNum("");
    setProductList([]);
    setBase64Images([]);
    setSelectedPaymentTerm(null);
    setIsOrderCreated(false);
    setLoading(false);
  };

  const navigate = useNavigate();

  const searchBarcodeResult = debounce(async (value) => {
    let res = await InventorySearchonboardBarcode(value);
    // console.log("API Response Barcode:", res);

    if (res.result && Array.isArray(res.result)) {
      const productResponse = res.result[0];
      // console.log("PPPPPp", productResponse);

      if (productResponse.status_code === 400) {
        // If product not found, try fetching from GS1 API
        try {
          const fetchGtinResponse = await axiosInstance.post(
            "/fetch_gs1_data",
            {
              gtin: value, // Use the barcode value
            }
          );

          if (fetchGtinResponse.data && fetchGtinResponse.data.result) {
            const fetchedProduct = fetchGtinResponse.data.result;

            // Check if the fetched product has the necessary data
            if (fetchedProduct.gtin) {
              // Redirect to ProductRequest page with fetched product data
              navigate("/inventory/ProductRequest", {
                state: {
                  fetchedProduct,
                  // product_description_english: fetchedProduct.product_description_english,
                  // product_description_arabic: fetchedProduct.product_description_arabic,
                  // barcode: fetchedProduct.gtin,
                },
              });
            } else {
              // Show the Request Product option if no product found
              setrequestProduct(true);
              setProductoptions([]);
              setValue("");
              // setIsProductFound(false);
            }
          } else {
            // Handle case where no result is returned
            setrequestProduct(true);
            setProductoptions([]);
            setValue("");
            // setIsProductFound(false);
          }
        } catch (error) {
          console.error("Error fetching GS1 data:", error);
          setrequestProduct(true); // Show Request Product option on error
        }
      } else {
        // Fill out the input fields with the found product data
        // console.log("PPP", productResponse);

        const isDuplicate = productList.some(
          (product) => product.prod_id === productResponse.prod_id // Assuming prod_id is unique
        );

        if (isDuplicate) {
          // Optionally, show a message to the user
          setSearchValue("");
          setValue("");
          toast.error(`${productResponse.name} is already in the list.`); // Replace with a more user-friendly message or modal if needed
          return; // Exit the function to prevent adding the duplicate
        }

        const productWithQty = {
          ...productResponse,
          vat: 0,
          purchaseQty: 0,
          freeQty: 0,
          discount: 0,
          discountValue: 0,
          salesPrice: productResponse?.sale_price,
          totalValue: 0,
          includingVat: 0,
          effectivePrice: 0,
          profit: 0,
        };
        setSelectedProduct(productWithQty);
        setProductList([...productList, productWithQty]);
        setSearchValue("");
        setValue("");

        if (productResponse?.cost_price > 0) {
          // formik.setFieldValue("cost_price", productResponse.cost_price);
        }

        if (productResponse?.sale_price > 0) {
          // formik.setFieldValue("sale_price", productResponse.sale_price);
        }

        // setIsProductFound(true); // Mark product as found
      }
    }
  }, 500);

  const searchResult = debounce(async (value) => {
    let res = await InventorySearchonboard(value);
    // console.log("API Response:", res);

    if (res.result && Array.isArray(res.result)) {
      const productResponse = res.result[0];
      if (productResponse.status_code === 400) {
        setrequestProduct(true);
        setProductoptions([]);
        // setValue("");
      } else {
        setProductoptions(res.result);
        setrequestProduct(false);
      }
    } else {
      setProductoptions([]);
      setrequestProduct(false);
    }
  }, 500);

  // console.log("ORDER DATA", orderData);
  const handleCancelImageUpload = () => {
    setBase64Images([]);
  };

  const handleRemoveImage = (index) => {
    setBase64Images((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAttachImages = async (id) => {
    setLoading(true);
    if (base64Images.length > 0) {
      try {
        const uploadedUrls = [];

        const existingImagesCount = 0;

        for (let i = 0; i < base64Images.length; i++) {
          const imageName = `${id}-${String(
            existingImagesCount + i + 1
          ).padStart(2, "0")}`;

          const imageResponse = await UserImageUpload(
            base64Images[i],
            imageName
          );

          // console.log("imageResponse", imageResponse);

          if (imageResponse) {
            console.log(`Image ${i + 1} uploaded successfully`);
            uploadedUrls.push(imageResponse);
          } else {
            console.error(`Error uploading image ${i + 1}:`, imageResponse);
          }
        }

        // console.log("uploadedUrls", uploadedUrls);

        if (uploadedUrls.length > 0) {
          setImageUrls(uploadedUrls);
          const poId = id;

          const payload = {
            po_id: poId,
            image_urls: uploadedUrls,
          };
          const UploadRes = await updatePOImages(payload);
          if (UploadRes?.result?.status_code === 200) {
            console.log("updated successfully", UploadRes);
            // setmessage("Image Uploaded Successfully");\
            setpopup(true);
            setIsOrderCreated(true);
            setLoading(false);
            setLoading(false);
            // setOrderId("");

            // setpopup(true);
          } else {
            console.error("Error uploading image", UploadRes);
            setLoading(false);
          }
        }

        // setBase64Images([]);
      } catch (error) {
        console.error("Error during image upload:", error);
        setLoading(false);
      }
    } else {
      console.log("No images to upload");
      setLoading(false);
    }
  };
  const handleBarcodeAdded = async (barcode) => {
    // Search for the product using the barcode
    const res = await InventorySearchonboardBarcode(barcode);

    if (res.result && Array.isArray(res.result)) {
      const productResponse = res.result[0];

      if (productResponse.status_code === 400) {
        toast.error(t("Product not found."));
        return;
      }

      // Check if the product is already in the productList
      const isDuplicate = productList.some(
        (product) => product.prod_id === productResponse.prod_id // Assuming prod_id is unique
      );

      if (isDuplicate) {
        toast.error(`${productResponse.name} is already in the list.`);
        return; // Exit the function to prevent adding the duplicate
      }

      const productWithQty = {
        ...productResponse,
        vat: 0,
        purchaseQty: 0,
        freeQty: 0,
        discount: 0,
        discountValue: 0,
        salesPrice: productResponse?.sale_price,
        totalValue: 0,
        includingVat: 0,
        effectivePrice: 0,
        profit: 0,
      };

      setProductList([...productList, productWithQty]);
      calculateTotal([...productList, productWithQty]);
      setProductoptions([]);
    }
  };
  return (
    <div className="mx-3 flex flex-col justify-center">
      {loading && <LoadingSpinner />}
      <div className="mt-14">
        <Navbar heading={t("Purchase")} />
      </div>

      <Breadcrumb
        separator={<DoubleRightOutlined />}
        items={[
          {
            title: (
              <Link
                to={
                  orderData
                    ? routes.purchase.purchaseList
                    : routes.purchase.addBill
                }
              >
                {orderData ? t("Purchase History") : t("Purchase")}
              </Link>
            ),
            key: "purchaseOrder",
          },
          { title: t("Purchase Bill") },
        ]}
        className="relative mt-3 font-semibold"
      />
      {showComponent ? (
        <AddVendor
          onClose={() => {
            closeAddVendor();
          }}
        />
      ) : (
        <>
          <div className="flex flex-row justify-between">
            <h3 className="mt-3 font-bold">{t("Add Purchase Bill")}</h3>
            {!isOrderCreated &&
              !showComponent && ( // Only show the button if the order is not created and the component is not shown
                <button
                  onClick={() => setShowComponent(true)}
                  className="font-semibold w-48 h-10 border rounded-lg bg-primaryBlue text-white text-base flex justify-center items-center gap-2"
                  type="submit"
                >
                  <img src={plus} alt="plus" />
                  <p>{t("Add New Vendor")}</p>
                </button>
              )}
          </div>

          <div className="my-5">
            {/* Vendor and Date Row */}
            <div className="mb-3 flex gap-3">
              <div className="flex-1">
                <label className="text-sm mb-2">
                  {t("Vendor/Supplier Name")}
                </label>
                <Select
                  style={{ width: "100%" }}
                  placeholder={t("Select Vendor")}
                  className="h-10"
                  value={vendor}
                  onChange={(value) => !isOrderCreated && setVendor(value)} // Disable setting vendor
                  disabled={isOrderCreated}
                  showSearch // Enable search functionality
                  filterOption={
                    (input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0 // Filter options based on input
                  }
                  // onChange={(value) => setVendor(value)}
                >
                  {vendors.map((vendor) => (
                    <Select.Option key={vendor.id} value={vendor.name}>
                      {vendor.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>

              <div className="flex-1">
                <label className="text-sm mb-2">{t("Date of Bill")}</label>
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder={t("Select Bill Date")}
                  // value={billDate ? moment(billDate) : null}
                  onChange={(date) =>
                    setBillDate(date ? date.format("YYYY-MM-DD") : null)
                  }
                  disabled={isOrderCreated}
                  className="h-10"
                />
              </div>
            </div>

            {/* Bill Number and VAT Row */}
            <div className="mb-3 flex gap-3">
              <div className="flex-1">
                <label className="text-sm mb-2">{t("Bill Number")}</label>
                <Input
                  style={{ width: "100%" }}
                  placeholder={t("Enter Bill Number")}
                  value={billNumber}
                  onChange={(e) => setBillNumber(e.target.value)}
                  className="h-10"
                  disabled={isOrderCreated}
                />
              </div>
              {/* VAT FIELD  */}
              <div className="flex-1">
                <label className="text-sm mb-2">{t("Payment Term")}</label>
                {/* <Input
                  placeholder="VAT (%)"
                  value={vatNum}
                  onChange={(e) => setVatNum(e.target.value)}
                  disabled={isOrderCreated}
                  className="h-10"
                /> */}
                <Select
                  style={{ width: "100%" }}
                  placeholder={t("Select Payment Term")}
                  className="h-10"
                  value={t(selectedPaymentTerm)}
                  onChange={(value) => setSelectedPaymentTerm(value)}
                  disabled={isOrderCreated}
                >
                  {paymentTerms.map((selectedPaymentTerm) => (
                    <Select.Option
                      key={selectedPaymentTerm.id}
                      value={t(selectedPaymentTerm.name)}
                      disabled={isOrderCreated}
                    >
                      {t(selectedPaymentTerm.name)}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>

            {/* <div className="mb-3 flex flex-col gap-1">
              <label className="text-sm ">{t("Payment Term")}</label>
              <Select
                style={{ width: "50%" }}
                placeholder="Select Payment Term"
                className="h-10"
                value={selectedPaymentTerm}
                onChange={(value) => setSelectedPaymentTerm(value)}
                disabled={isOrderCreated}
              >
                {paymentTerms.map((selectedPaymentTerm) => (
                  <Select.Option
                    key={selectedPaymentTerm.id}
                    value={selectedPaymentTerm.name}
                    disabled={isOrderCreated}
                  >
                    {selectedPaymentTerm.name}
                  </Select.Option>
                ))}
              </Select>
            </div> */}

            {/* Display attached images section */}
            <div className="mt-6 min-w-full overflow-hidden bg-gray-50 rounded-lg">
              {!isOrderCreated ? (
                <div className="ms-2 me-2">
                  <h3 className="text-l mt-2 font-semibold text-[#272728]">
                    {t("Attach Bill Images")}
                  </h3>

                  {/* Check if there are any attached images */}

                  {/* Upload new images */}
                  <div className="mt-4 mb-4 ">
                    <UploadBillImage
                      foldername={"store_app_UAT"}
                      onfileAdd={`storeuserurl`}
                      setBase64Images={(images) => {
                        setBase64Images((prev) => [...prev, images]);
                      }}
                      imageurl={addImage}
                    />
                  </div>

                  {/* Display images that were uploaded */}
                  {base64Images.length > 0 && (
                    <>
                      <div className="mt-4">
                        <h4 className="font-normal mb-2 italic  text-gray-600">
                          {t("Selected Images")}
                        </h4>
                        <div className="flex space-x-4 overflow-x-auto">
                          {base64Images.map((image, index) => (
                            <div
                              key={index}
                              className="relative flex-none w-[120px] h-[120px] rounded-lg shadow-md overflow-hidden"
                            >
                              <Image
                                src={image}
                                alt={`Uploaded Image ${index + 1}`}
                                className="w-full h-full object-cover"
                              />

                              {/* Cross Button to remove the image */}
                              <button
                                onClick={() => handleRemoveImage(index)}
                                className="absolute top-0 right-0  text-black p-0 rounded-full"
                                style={{ zIndex: 10 }} // Ensure button is above the image
                              >
                                <CloseOutlined />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* Save and Cancel buttons */}
                      <div className="mt-2 flex mb-4 gap-2 justify-end">
                        <button
                          onClick={handleCancelImageUpload}
                          className="px-4 py-2 bg-white font-semibold text-sm text-primaryBlue border border-primaryBlue rounded-lg hover:bg-red-600"
                        >
                          {t("Cancel")}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              ) : base64Images.length > 0 && ( // Only show this section if there are attached images
                <div className="mt-4">
                  <div className="ms-2 me-2">
                    <h3 className="text-l mt-2 mb-2 font-semibold text-[#272728]">
                      {t("Attached Bill Images")}
                    </h3>
                    <div className="flex space-x-4 overflow-x-auto mb-5">
                      {base64Images.map((image, index) => (
                        <div
                          key={index}
                          className="relative flex-none w-[120px] h-[100px] rounded-lg shadow-md overflow-hidden"
                        >
                          <Image
                            src={image}
                            alt={`Uploaded Image ${index + 1}`}
                            className="w-full h-full object-cover"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Product Search Row */}
            <div className="flex flex-row justify-between mt-8">
              {!isOrderCreated && (
                <span className="relative w-[50%]">
                  <label htmlFor="default-search" className="">
                    {/* {t("Search Product")} */}
                  </label>
                  <div>
                    <AutoComplete
                      value={value}
                      options={Productoptions.map((option) => ({
                        value: option.name,
                        label: option.name,
                      }))}
                      onSearch={(value) => {
                        // if (!isProductSelected) {
                        setValue(value);
                        if (value.length >= 3) {
                          searchResult(value);
                        } else {
                          setrequestProduct(false);
                          setProductoptions([]);
                        }
                        // }
                      }}
                      onSelect={handleProductSelect}
                      style={{ width: "100%", height: "70px" }}
                      placeholder={t("search_product")}
                      disabled={isOrderCreated}
                    />
                    {value && (
                      <button
                        type="button"
                        onClick={() => {
                          setValue(""); // Clear the input value
                          setProductoptions([]); // Clear the product options
                          setValue(null);
                        }}
                        className={`absolute ${
                          i18next.language === "ar" ? "left-2" : "right-2"
                        } top-2 text-gray-500 hover:text-gray-700`}
                        // className="absolute right-2 top-2 text-gray-500 hover:text-gray-700"
                      >
                        &times;
                      </button>
                    )}
                  </div>
                  {requestProduct && (
                    <Link
                      to="/inventory/ProductRequest"
                      style={{ color: "blue", textDecoration: "underline" }}
                      className="flex flex-col mt-0 items-end text-sm"
                    >
                      {t("Request Product")}{" "}
                    </Link>
                  )}
                </span>
              )}
              <BarcodeScanner
                onScan={(data) => {
                  setValue(data);
                  searchBarcodeResult(data);
                }}
                onError={(err) => {
                  console.error(err);
                }}
                facingMode="environment"
                className="absolute inset-y-0 right-0 me-4 pointer-events-none cursor-pointer z-10"
              />

              {productList && productList.length > 0 && (
                <div className="flex flex-row justify-end  ms-4 gap-2">
                  {!isOrderCreated && (
                    <button
                      onClick={handleCancel}
                      className={`font-semibold w-36 h-10 text-sm border border-primaryBlue rounded-lg bg-white text-primaryBlue flex justify-center items-center gap-2`}
                      type="submit"
                    >
                      <p className="">{t("Cancel")}</p>
                    </button>
                  )}
                  {!isOrderCreated && (
                    <button
                      onClick={handleDraftClose}
                      disabled={isSaveDisabled}
                      className={`font-semibold w-32 h-10 border rounded-lg bg-primaryBlue text-white text-sm flex justify-center items-center gap-2 ${
                        isSaveDisabled ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      // className={`font-semibold w-32 h-10 border rounded-lg bg-primaryBlue text-white text-sm flex justify-center items-center gap-2`}
                      type="submit"
                    >
                      <p className="">{t("Draft & Close")}</p>
                    </button>
                  )}
                </div>
              )}
            </div>

            {productList && productList.length > 0 && (
              <ProductTable
                disabled={isOrderCreated}
                productList={productList}
                handleProductChange={handleProductChange}
                handleDeleteProduct={handleDeleteProduct}
                handleOpenModal={handleOpenModal}
              />
            )}

            {/* Summary Section  */}
            {productList && productList.length > 0 && (
              <div className="mt-5 text-xs mb-2 border bottom-1">
                <div className="flex flex-col w-[30%] mt-2 mb-2 ms-2 gap-4">
                  {/* Total (excl. VAT) */}
                  <div className="flex justify-between">
                    <span className="font-semibold text-xs">{t("Total:")}</span>
                    <span className="font-normal">
                      {totalAmount.toFixed(2)}
                    </span>
                  </div>

                  {/* Bill Discount %age */}
                  <div className="flex justify-between items-center">
                    <div className="font-semibold">{t("Invoice Discount %age:")}</div>
                    <div className="flex gap-2">
                      <Input
                        type="text"
                        className="w-16 h-5"
                        value={billDiscountPer}
                        // onChange={(e) => setBillDiscountPer(e.target.value)}
                        onChange={(e) => {
                          const value = e.target.value;

                          // Allow empty input or valid decimal numbers
                          if (
                            value === "" || // Allow empty input
                            /^\d*\.?\d{0,2}$/.test(value) // Allow numbers with up to 2 decimal places
                          ) {
                            // setLocalDiscount(value === "" ? 0 : parseFloat(value)); // Update state
                            setBillDiscountPer(value === "" ? 0 : value);
                          }
                        }}
                        disabled={isOrderCreated}
                        maxLength={7}
                      />
                      <span>
                        {((billDiscountPer / 100) * totalAmount).toFixed(2)}
                      </span>
                    </div>
                  </div>

                  {/* Bill Discount Value */}
                  <div className="flex justify-between items-center">
                    <div className="font-semibold">{t("Invoice Discount Value:")}</div>
                    <Input
                      type="text"
                      className="w-16 h-5"
                      value={billDiscountVal}
                      // onChange={(e) => setBillDiscountVal(e.target.value)}
                      onChange={(e) => {
                        const value = e.target.value;

                        // Allow empty input or valid decimal numbers
                        if (
                          value === "" || // Allow empty input
                          /^\d*\.?\d{0,2}$/.test(value) // Allow numbers with up to 2 decimal places
                        ) {
                          // setLocalDiscount(value === "" ? 0 : parseFloat(value)); // Update state
                          setBillDiscountVal(value === "" ? 0 : value);
                        }
                      }}
                      disabled={isOrderCreated}
                      maxLength={7}
                    />
                  </div>

                  {/* Total Invoice Discount */}
                  <div className="flex justify-between">
                    <div className="font-semibold">{t("Total Invoice Discount:")}</div>
                    <div>
                      {(
                        (billDiscountPer / 100) * totalAmount +
                        parseFloat(billDiscountVal || 0)
                      ).toFixed(2)}
                    </div>
                  </div>

                  {/* Discount %age */}
                  {/* <div className="flex justify-between">
                    <div className="font-semibold">Discount %age:</div>
                    <div>
                      {(
                        (((billDiscountPer / 100) * totalAmount +
                          parseFloat(billDiscountVal || 0)) /
                          (totalAmount + totalVatVal)) *
                        100
                      ).toFixed(2) || 0}
                    </div>
                  </div> */}

                  {/* Total after Discount */}
                  <div className="flex justify-between">
                    <div className="font-semibold">{t("Total after Discount:")}</div>
                    <div>
                      {(
                        totalAmount -
                        ((billDiscountPer / 100) * totalAmount +
                          parseFloat(billDiscountVal || 0))
                      ).toFixed(2)}
                    </div>
                  </div>

                  {/* VAT */}
                  <div className="flex justify-between">
                    <div className="font-semibold">{t("VAT:")}</div>
                    <div>{totalVatVal.toFixed(2)}</div>
                  </div>

                  {/* Grand Total */}
                  <div className="flex justify-between">
                    <div className="font-semibold">{t("Grand Total:")}</div>
                    <div>
                      {(
                        totalAmount -
                        ((billDiscountPer / 100) * totalAmount +
                          parseFloat(billDiscountVal || 0)) +
                        totalVatVal
                      ).toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {productList && productList.length > 0 && !isOrderCreated && (
              <div className="flex flex-row justify-end  ms-4 gap-2">
                <button
                  onClick={handleSavePurchaseOrder}
                  disabled={isSaveDisabled}
                  className={`font-semibold w-32 h-10 border rounded-lg bg-primaryBlue text-white text-sm flex justify-center items-center gap-2 ${
                    isSaveDisabled ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  type="submit"
                >
                  <p className="">{t("save")}</p>
                </button>
              </div>
            )}

            {isOrderCreated && (
              <div className="flex flex-row justify-end  ms-4 gap-2">
                <button
                  onClick={handleclose}
                  className={`font-semibold w-44 h-10 border rounded-lg bg-white text-primaryBlue border-primaryBlue text-sm flex justify-center items-center gap-2`}
                  type="button"
                >
                  <p className="">{t("close")}</p>
                </button>
                <button
                  onClick={() => setIsPayModalVisible(true)}
                  className={`font-semibold w-44 h-10 border rounded-lg bg-primaryBlue text-white text-sm flex justify-center items-center gap-2`}
                  type="button"
                >
                  <p className="">{t("Add Payment")}</p>
                </button>
              </div>
            )}
          </div>
        </>
      )}
      <ExpiryModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onSave={handleSaveExpiryData}
        productIndex={currentProductIndex}
        initialData={
          savedExpiryData[currentProductIndex]
            ? savedExpiryData[currentProductIndex]?.expiryData
            : []
        }
        purchaseQty={productList[currentProductIndex]?.purchaseQty}
        freeQty={productList[currentProductIndex]?.freeQty}
      />

      <PurchasePayment
        isOpen={isPayModalVisible}
        onClose={() => {
          setIsPayModalVisible(false);
        }}
        bill_id={orderId}
        page={"createOrder"}
        totalAmount={totalAmount}
        billDiscountPer={billDiscountPer}
        billDiscountVal={billDiscountVal}
        totalVatVal={totalVatVal}
        selectedPaymentTerm={selectedPaymentTerm}
        // due_amount={selectedDueAmount}
        Popup={() => {
          // setpopup(true);
          handleclose();
        }}
      />
      <AddBarcode
        isOpen={isBarcodeModalVisible}
        onClose={() => {
          setIsBarcodeModalVisible(false);
        }}
        prod_id={selectedProductId}
        onBarcodeAdded={handleBarcodeAdded}
      />

      {popup && (
        <SuccessModal
          onClose={() => {
            setpopup(false);
            // onClose();
          }}
          message={` ${t("Purchase Order Created Successfully")}  `}
          // route={routes.purchase.addBill}
        />
      )}
    </div>
  );
};

export default AddBill;
