import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTable, useSortBy, usePagination, useRowSelect } from "react-table";
import "tailwindcss/tailwind.css";
import CustomPagination from "../CustomWidgets/CustomPagination";
import { Image, Input, Modal } from "antd";
import oreo from "../../assets/Inventory/oreo.svg";
import { CaretDownOutlined, EllipsisOutlined } from "@ant-design/icons";
import { Dropdown, Empty, InputNumber, Menu } from "antd";
import Edit from "../../assets/Edit.svg";
import "../../styles.css";
import ShimmerLoader from "../../loading";
import Vectorsorting from "../../assets/Vectorsorting.svg";
import dummy_image from "../../assets/Images/gallery.svg";

import { useTranslation } from "react-i18next";

const InCompletetable = ({ additionalData, setAdditionalData, pagination }) => {
  const { t, i18n } = useTranslation();
  const [editRows, setEditRows] = useState(new Set());
  const [editValues, setEditValues] = useState({});
  const [editPriceClicked, setEditPriceClicked] = useState(false);
  const [indexarray, setindexarray] = useState([]);
  const [flag, setflag] = useState(false);
  const [tempEditValues, setTempEditValues] = useState({});
  const [isSalePriceValid, setIsSalePriceValid] = useState({});
    const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState("");
  

  useEffect(() => {
    if (additionalData?.length === 0 && !flag) {
      const timer = setTimeout(() => {
        setflag(true);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [additionalData, flag]);

  const data = useMemo(() => additionalData || [], [additionalData]);

  const columns = useMemo(
    () => [
      {
        Header: t("product"),
        accessor: "name",
        Cell: ({ row }) => (
          <div className="flex items-center justify-start">
            {row.original.image_url || row.original.image ? (
              // <img
              //   src={row.original.image_url || row.original.image}
              //   style={{
              //     maxHeight: 40,
              //     maxWidth: 40,
              //     minHeight: 40,
              //     minWidth: 40,
              //   }}
              //   height={40}
              //   width={30}
              //   alt="product"
              // />
              <img
              src={dummy_image}
              alt="Dummy"
              style={{
                maxHeight: 40,
                maxWidth: 40,
                minHeight: 40,
                minWidth: 40,
                objectFit: "contain",
                cursor: "pointer",
              }}
              onClick={() => {
                setCurrentImageUrl(row.original.image_url || row.original.image);
                setIsImageModalVisible(true);
              }}
            />
            ) : (
              <img
              style={{
                maxHeight: 40,
                maxWidth: 40,
                minHeight: 40,
                minWidth: 40,
                objectFit: "contain",
                cursor: "pointer",
              }}
              src={row.original.image_url || row.original.image}
              height={60}
              width={30}
              alt="prod_image"
            />
            )}

            <div className="ms-7">
              <span>{row.original.name}</span>
            </div>
          </div>
        ),
        headerClassName: "text-center",
      },

      // {
      //   Header: t("cost_price"),
      //   accessor: "cost_price",
      //   Cell: ({ row }) =>
      //     editRows.has(row.index) ? (
      //       <Input
      //         className="w-16"
      //         min={0}
      //         value={tempEditValues[row.index]?.cost_price}
      //         onChange={(e) => {
      //           const value = e.target.value; // Get the value from the event
      //           // Check if the value is a number and has a maximum length of 5
      //           if (value === '' || (value.length <= 5 && /^[0-9]*$/.test(value))) {
      //             handlePriceChange(row.index, "cost_price", value)
      //           }
      //         }}
      //         maxLength={5}
      //         onKeyPress={(e) => {
      //           // Prevent non-numeric input
      //           if (!/[0-9]/.test(e.key) && e.key !== 'Backspace') {
      //             e.preventDefault();
      //           }
      //         }}
      //         onFocus={() => handleInputFocus(row.index, "cost_price")}
      //         // onChange={(e) => {
      //         //   const value = e.target.value; // Get the value from the event
      //         //   handlePriceChange(row.index, "cost_price", value)

      //         // }}
      //         // onFocus={() => handleInputFocus(row.index, "cost_price")}

      //       />
      //     ) : (
      //       ` ${row.values.cost_price}`
      //     ),
      // },
      {
        Header: t("cost_price"),
        accessor: "cost_price",
        Cell: ({ row }) =>
          editRows.has(row.index) ? (
            <Input
              type="number"
              className="w-16"
              min={0}
              value={tempEditValues[row.index]?.cost_price}
              onChange={(e) => {
                const value = e.target.value; // Get the value from the event
                // Check if the value is a valid number with one decimal point
                if (
                  value === "" || // Allow empty input
                  /^[0-9]*\.?[0-9]{0,2}$/.test(value) // Allow numbers with up to one decimal place
                ) {
                  handlePriceChange(row.index, "cost_price", value);
                }
              }}
              maxLength={5}
              onKeyPress={(e) => {
                const value = e.target.value;
                // Prevent non-numeric input except for one decimal point
                // if (!/[0-9.]/.test(e.key) && e.key !== 'Backspace') {
                //   e.preventDefault();
                // }
                if (
                  !/[0-9]/.test(e.key) && // Allow numeric input
                  e.key !== "Backspace" && // Allow backspace
                  e.key === "." &&
                  value.includes(".") // Prevent more than one decimal point
                ) {
                  e.preventDefault();
                }
              }}
              onFocus={() => handleInputFocus(row.index, "cost_price")}
            />
          ) : (
            ` ${row.values.cost_price?.toFixed(2)}`
          ),
      },
      {
        Header: t("sale_price"),
        accessor: "sales_price",
        Cell: ({ row }) =>
          editRows.has(row.index) ? (
            <Input
              type="number"
              className={`w-16 ${
                isSalePriceValid[row.index] === false ? "border-red" : ""
              }`}
              min={
                tempEditValues[row.index]?.cost_price ?? row.values.cost_price
              }
              value={tempEditValues[row.index]?.sales_price}
              maxLength={5}
              onChange={(e) => {
                const value = e.target.value; // Get the value from the event
                // Check if the value is a valid number with one decimal point
                if (
                  value === "" || // Allow empty input
                  /^[0-9]*\.?[0-9]{0,2}$/.test(value) // Allow numbers with up to one decimal place
                ) {
                  handlePriceChange(row.index, "sales_price", value);
                }
              }}
              onFocus={() => handleInputFocus(row.index, "sales_price")}
              onKeyPress={(e) => {
                const value = e.target.value;
                // Prevent non-numeric input except for one decimal point
                if (
                  !/[0-9]/.test(e.key) && // Allow numeric input
                  e.key !== "Backspace" && // Allow backspace
                  e.key === "." &&
                  value.includes(".") // Prevent more than one decimal point
                ) {
                  e.preventDefault();
                }
              }}
            />
          ) : (
            `${row.values.sales_price?.toFixed(2)}`
          ),
      },

      {
        Header: "",
        accessor: "dropdown",
        Cell: (row) => (
          <img
            src={Edit}
            style={{
              cursor: "pointer",
              height: "0.9rem",
              width: "0.9rem",
            }}
            alt="Edit"
            className={` mt--2 ${i18n.language == "en" ? "mr-2" : "ml-2"}`}
            onClick={(e, index) => {
              e.preventDefault();

              setindexarray([...indexarray, parseInt(row.row.id)]);

              handleEditRow(row.row.id);
            }}
          />
        ),
      },
    ],
    [editRows, editValues]
  );

  const inputRefs = useRef([]);
  const timeoutRef = useRef(null);

  const handlePriceChange = (index, field, value) => {
    console.log("VAALLL", value);

    const numericValue = value === "" ? 0 : Number(value);

    setTempEditValues((prevValues) => ({
      ...prevValues,
      [index]: {
        ...prevValues[index],
        [field]: numericValue, // Update the field with the new value
      },
    }));

    if (field === "cost_price") {
      const costPrice =
        tempEditValues[index]?.cost_price ?? data[index].cost_price;
      if (numericValue < costPrice) {
        setIsSalePriceValid((prev) => ({ ...prev, [index]: false })); // Mark as invalid
      } else {
        setIsSalePriceValid((prev) => ({ ...prev, [index]: true })); // Mark as valid
      }
    }
  };
  const handleInputFocus = (index, field) => {
    if (inputRefs.current[index] && inputRefs.current[index][field]) {
      inputRefs.current[index][field].focus();
    }
  };

  const handleSaveAll = () => {
    for (const index of editRows) {
      const salePrice = tempEditValues[index]?.sales_price;

      const costPrice =
        tempEditValues[index]?.cost_price !== undefined
          ? tempEditValues[index]?.cost_price
          : data[index].cost_price;

      if (salePrice < costPrice) {
        alert(t("Sale price cannot be less than cost price."));
        return;
      }
    }
    let sendData = [];
    const newData = additionalData.map((row, index) => {
      if (editRows.has(index)) {
        const item = {
          product_id: row?.product_id ?? "",
          sales_price:
            tempEditValues[index]?.sales_price ?? row?.sales_price ?? 0,
          cost_price: tempEditValues[index]?.cost_price ?? row?.cost_price ?? 0,
          qty_available:
            tempEditValues[index]?.qty_available ?? row?.qty_available ?? 0,
        };

        sendData.push(item);
        return {
          ...row,
          ...editValues[index],
        };
      }
      return row;
    });

    setEditRows(new Set());
    setEditValues({});
    setindexarray([]);
    setEditPriceClicked(false);
    setTempEditValues({});
    setAdditionalData(sendData);
  };
  const handleEditRow = (index) => {
    setEditRows(new Set([...indexarray, parseInt(index)]));
    setEditPriceClicked(true);
  };

  const handleEditCancel = () => {
    setEditRows(new Set());
    setEditValues({});
    setindexarray([]);
    setTempEditValues({});
    setEditPriceClicked(false);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data: data,
      initialState: { pageSize: 10 },
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const [pageIndex, setpageIndex] = useState(1);
  useEffect(() => {
    const pageDetails = {
      limit: pageSize,
      offset: pageSize * (pageIndex - 1) < 0 ? 0 : pageSize * (pageIndex - 1),
    };

    pagination(pageDetails);
  }, [pageIndex, pageSize, previousPage, nextPage]);

  return (
    <>
    <Modal
        visible={isImageModalVisible}
        onCancel={() => setIsImageModalVisible(false)}
        footer={null}
      >
        <img src={currentImageUrl} alt="Product" style={{ width: "100%" }} />
      </Modal>
    <div className="container mx-auto " style={{ maxWidth: "100%" }}>
      {editPriceClicked && (
        <div className="flex justify-end mb-2">
          {editRows.size === 1 ? (
            <button
              onClick={handleSaveAll}
              className="w-28 h-8 mt-4 bg-primaryBlue text-white text-sm font-semibold rounded-lg flex items-center justify-center me-5"
            >
              <span>{t("save")}</span>
            </button>
          ) : (
            <button
              onClick={handleSaveAll}
              className="w-28 h-8 mt-4 bg-primaryBlue text-white text-sm font-semibold rounded-lg flex items-center justify-center me-5"
            >
              <span>{t("save_all")}</span>
            </button>
          )}
          <button
            onClick={handleEditCancel}
            className="w-28 h-8 mt-4 border border-primaryBlue text-primaryBlue text-sm font-semibold rounded-lg flex items-center justify-center me-5"
          >
            <span>{t("Cancel")}</span>
          </button>
        </div>
      )}
      {additionalData?.length == 0 && flag == false ? (
        <>
          <div className="h-screen w-[90%] mt-2 ms-4">
            <ShimmerLoader />{" "}
          </div>
        </>
      ) : (
        <table
          {...getTableProps()}
          className=" mx-2 ml-3 mt-4 font-normal max-w-[100%] sm:w-[97.6%] md:w-[97.6%] lg:w-[97.6%] xl:w-[97.6%] min-h-auto h-[100%] shadow-xl rounded-lg overflow-hidden"
        >
          <thead>
            {headerGroups.map((headerGroup, headerIndex) => {
              const { key: headerKey, ...headerGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr
                  key={headerKey}
                  {...headerGroupProps}
                  className="bg-[#78CFEC66] text-sm font-semibold"
                >
                  {headerGroup.headers.map((column, colIndex) => {
                    const { key: columnKey, ...columnProps } =
                      column.getHeaderProps(column.getSortByToggleProps());
                    return (
                      <th
                        key={columnKey}
                        {...columnProps}
                        className="py-3 px-2 whitespace-nowrap "
                      >
                        <div className="flex items-center justify-center">
                          {column.render("Header")}
                          <span className="">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <img
                                  src={Vectorsorting}
                                  style={{ minHeight: 10, minWidth: 10 }}
                                  alt="sort"
                                />
                              ) : (
                                <img
                                  className="rotate-180"
                                  src={Vectorsorting}
                                  style={{ minHeight: 10, minWidth: 10 }}
                                  alt="sort"
                                />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()} className="bg-[#F7F9FD] text-center">
            {page.map((row) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.id}>
                  <tr
                    {...row.getRowProps()}
                    className="relative hover:bg-white transition duration-300 border-b border-gray-300 fixed-row-height "
                  >
                    {row.cells.map((cell, index) => (
                      <td
                        {...cell.getCellProps()}
                        className={`p-2 text-[#272728] h-1212 text-sm font-normal ${
                          index < row.cells.length - 1 ? "relative" : ""
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                </React.Fragment>
              );
            })}
            {/* Empty state row */}
            {page.length === 0 && (
              <tr>
                <td colSpan={"100%"}>
                  <Empty description="No data available" />
                </td>
              </tr>
            )}
            <tr>
              <td className="" colSpan={"100%"}>
                <CustomPagination
                  pageIndex={pageIndex}
                  pageCount={page.length}
                  canPreviousPage={pageIndex != 1 ? true : false}
                  canNextPage={data?.length >= 10 ? true : false}
                  gotoPage={(page) => {
                    nextPage(page);
                  }}
                  previousPage={() => {
                    setpageIndex(pageIndex - 1);
                  }}
                  nextPage={() => {
                    setpageIndex(pageIndex + 1);
                  }}
                  pageSize={pageSize}
                  setPageSize={(pageSize) => {
                    // nextPage(0);

                    setPageSize(pageSize);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
    </>
  );
};

export default InCompletetable;
