
import React, { useState,useEffect } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Sidebar from "../../../components/SideBar/sidebar";
import InventoryBar from "../../../components/Inventory/InventoryBar";
import InventoryOnboard from "../InventoryOnboard/InventoryOnboard";
import InventoryTableHeader from "../../../components/Inventory/InventoryTableHeader";
import { useSelector } from "react-redux";


const Inventory = () => {
  const [showInventoryOnboard, setShowInventoryOnboard] = useState(false);
  const [selectedInventoryComponent, setSelectedInventoryComponent] = useState(null);
  const [prevData, setPrevdata] = useState({})

  // console.log("GSGDGHDA", prevData);
  

  // const handleInventoryBoxClick = (heading) => {
  //   switch (heading) {
  //     case "Stock Out":
  //       setSelectedInventoryComponent(<StockOut user_id={user_id} store_id={store_id} />);
  //       break;
  //     case "Low On Inventory":
  //       setSelectedInventoryComponent(<LowOnInventory user_id={user_id} store_id={store_id} />);
  //       break;
  //     case "High On Inventory":
  //       setSelectedInventoryComponent(<HighOnInventory user_id={user_id} store_id={store_id} />);
  //       break;
  //     default:
  //       setSelectedInventoryComponent(null);
  //   }
  // };

  const handleAddProductClick = () => {
    setShowInventoryOnboard(true);
  };

  return (
    <>
      <div className="float-left fixed z-10">
        {/* <Sidebar /> */}
      </div>
      <div className="w-full pb-6">
        <div className="flex flex-col flex-1">
          <Navbar heading="inventory" />
          {selectedInventoryComponent ? (
            <div>
              {selectedInventoryComponent}
            </div>
           ) : (
            <>
          {showInventoryOnboard ? (
            <InventoryOnboard onClose={() => setShowInventoryOnboard(false)} />
          ) : (
            <>
              <InventoryBar setPrevData={setPrevdata}
                // onInventoryBoxClick={handleInventoryBoxClick}
                
              />
              {/* <InventoryFunctionbar
                user_id={user_id}
                store_id={store_id}
                onAddProductClick={handleAddProductClick}
                kpiData={kpiData}
              /> */}
               <InventoryTableHeader dashData={prevData} />
            </>
          )}
           </>
          )}
        </div>
      </div>
    </>
  );
};

export default Inventory;
