import {store} from "./Redux/store";
import axios from "axios";

//Defaults will be combined with the instance
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// console.log("axios.defaults.baseURL", process.env);

const axiosInstance = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=UTF-8",
    // "Access-Control-Allow-Origin": "*",
    // 'api-key': 'b63bd82b-4a83-4d28-909d-30ce29614589',
    // 'lang': lang==='ar'?'ar_001':'en_US'
  },
});

axiosInstance.interceptors.request.use(function (config) {
  const { login } = store.getState();
  const auth = "";
  // const lang = auth?.lang;
  // console.log("LLL", lang);
  
  config.headers["access-token"] =  login?.user?.result?.access_token;
  // config.headers["lang"] =  "ar_001";

  return config;
});

//Add interceptors to add header
// axiosInstance.interceptors.request.use(function (config) {
//   const { login } = store.getState();
 
//   // const token = auth?.accessToken;
//   const token = login?.user?.result?.access_token
//   // const lang = login?.user?.lang;
//   config.headers.Authorization = token
//     ? `Bearer ${token}`
//     : "";
//   // config.headers.lang = lang === "ar" ? "ar_001" : "en_US";
//   return config;
// });
//Add interceptors to instance
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // showShortSnackbar(error?.message);
    return error;
  }
);

export default axiosInstance;
