import UserManagement from "../../../components/Setting/UserManagement";
import React, { useState, useEffect } from "react";
import { Image } from "antd";
import menu from "../../../assets/Images/menudots.svg";
import plus from "../../../assets/Images/plus.svg";
import noImage from "../../../assets/Images/roundimage.png";

import { Dropdown } from "antd";
import AddUser from "../../../components/Setting/AddUser";
import { Breadcrumb } from "antd";
import {
  DoubleRightOutlined,
  DeleteOutlined,
  EditOutlined,
  StopOutlined,
} from "@ant-design/icons";
import Sidebar from "../../../components/SideBar/sidebar";
import Navbar from "../../../components/Navbar/Navbar";
import { routes } from "../../../Routes";
import {
  blockStoreUser,
  getStoreUsers,
} from "../../../API  Functions/UserMangement";
import { useSelector } from "react-redux";
import Blockuserpopup from "../../../modals/Blockuserpopup";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Deliveryboy from "../../../components/EordersDeliveryPartners/Deliveryboy";
import { GetDeliveryBoy } from "../../../API  Functions/OrderlistFunction";
import AddDeliveryboy from "../../../components/EordersDeliveryPartners/AddDeliveryBoy";
import { deactivateDeliveryBoy } from "../../../API  Functions/DeliveryPartnerFunctions";
import Deposited from "../../../components/EordersDeliveryPartners/Deposited";
// Dropdown data array

//

const AllDeliveryBoys = () => {
  const { t, i18n } = useTranslation();
  // const user_id = useSelector((state) => state.login?.user?.user_id);
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const store_id = useSelector((state) => state.login?.user?.store_id);

  // This is for use in add user button to open a new component
  const [showComponent, setShowComponent] = useState(false);
  const [deliveryBoy, setDeliveryBoy] = useState(null);

  const [popup, setpopup] = useState(false);
  const [blockdata, setblockdata] = useState([]);
  const [status, setstatus] = useState(1);
  const [successMessage, setSuccessMessage] = useState("");
  const [showDeposited, setShowDeposited] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  // const getStoreusers = () => {
  //   getStoreUsers(user_id)
  //     .then((res) => {
  //       // console.log("USER REPONSE s",res);

  //       if (res?.result[0]?.status_code === 200) {
  //         const users = res.result[0]?.response;

  //         if (users) {
  //           setData(users);
  //         } else {
  //           console.error("No users found in the response.");
  //         }
  //       } else {
  //         console.error("Response was not successful:", res);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching store users:", error);
  //     });
  // };

  const getdata = async () => {
    const response = await GetDeliveryBoy(user_id);
    response?.length > 0 && setData(response);
  };

  useEffect(() => {
    getdata();
  }, []);

  const blockDeliveryBoy = async (val, block) => {
    console.log("AAAAAAA", val);

    const response = await deactivateDeliveryBoy(user_id, val?.id);
    // console.log("DELLLL response", response?.data);
    if (response?.data?.result[0]?.status === "Delivery Partner Deactivate") {
      // setstatus(block);
      // setblockdata(val);
      setSuccessMessage("Delivery Boy Deactivated Successfully");
      setpopup(true);
      getdata();
    }
  };

  // const items = [
  //   {
  //     key: "1",
  //     label: (
  //       <>
  //         <EditOutlined className="pr-2" />
  //         {t("edit")}
  //       </>
  //     ),
  //   },
  //   {
  //     type: "divider",
  //   },

  //   {
  //     key: "2",
  //     label: (
  //       <a
  //         className=" text-[#0F1828] font-normal"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="#"
  //       >
  //         <StopOutlined className="pr-2" />
  //         {t("block")}
  //       </a>
  //     ),
  //   },
  //   {
  //     type: "divider",
  //   },

  // {
  //   key: "3",
  //   label: (
  //     <a
  //       className=" text-[#0F1828] font-normal text-base"
  //       target="_blank"
  //       rel="noopener noreferrer"
  //       href="#"
  //     >
  //       <DeleteOutlined className="pr-2" />
  //       Delete
  //     </a>
  //   ),
  // },
  // ];
  const items = [
    {
      key: "edit",
      label: (
        <>
          <EditOutlined className="pr-2" />
          {t("edit")}
        </>
      ),
    },
    {
      key: "deactivate",
      label: (
        <>
          <StopOutlined className="pr-2" />
          {t("Deactivate")}
        </>
      ),
    },
  ];

  // const isSmall = data && data?.length < 10;

  const handleBoxClick = (val) => {
    console.log("VVVVVVV", val);

    navigate(routes.deliveryPartnerOrder, {
      state: { deliveryPartnerData: val },
    });
  };

  const handleMenuClick = (e, val) => {
    if (e.domEvent) {
      e.domEvent.stopPropagation();
    }

    if (e.key === "edit") {
      setDeliveryBoy(val);
      setShowComponent(true);
    } else if (e.key === "deactivate") {
      blockDeliveryBoy(val);
    }
  };

  return (
    <>
      <div className="flex">
        {/* <Sidebar /> */}
        <div className="flex flex-col bg-[#F6F7F9] min-h-[93vh] flex-1">
          <Navbar heading="Delivery Boy" />
          <div className="mr-4 ml-3 font-extrabold">
            <Breadcrumb
              separator={<DoubleRightOutlined />}
              items={[
                {
                  title: t("settings"),
                  key: "settings",
                  children: (
                    <Link to={routes.settings.deliveryPartner}>
                      {t("settings")}
                    </Link>
                  ),
                },
                { title: t("Delivery Boy") },
              ]}
              className="relative mt-3"
            />
            {showComponent ? (
              <AddDeliveryboy
                onClose={() => {
                  setShowComponent(false);
                  setDeliveryBoy(null);
                  getdata();
                }}
                isEdit={deliveryBoy}
              />
            ) : (
              <>
                <div className="flex justify-between items-center">
                  <div className="font-semibold text-xl mt-2 ">
                    {/* {t(" ")} */}
                    {data?.length > 0 && (
                      <div className="font-semibold text-xl flex flex-row ">
                        {t("All Users")}
                        <div
                          style={{
                            borderRadius: "12px",
                            maxHeight: "24px",
                            marginLeft: "5px",
                            // backgroundColor: !isSmall ? "#E7E7E7" : "#FCC71D",
                            backgroundColor: "#FCC71D",
                            padding: "0px 7px",
                          }}
                        >
                          <span style={{ color: "black", marginBottom: 2 }}>
                            {data?.length ?? data?.length ?? 0}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <button
                    onClick={() => setShowComponent(true)}
                    className={`font-semibold w-40   h-10 border  rounded-lg bg-primaryBlue text-white text-base   flex justify-center items-center gap-2`}
                    type="submit"
                  >
                    <img src={plus} alt="plus" />
                    <p className="">{t("add_user")}</p>
                  </button>
                </div>
                <div className="bg-[#FFFFFF] shadow-sm rounded-xl grid mt-4 h-32">
                  <UserManagement />
                </div>

                <div className="grid grid-cols-3 gap-x-8 mt-1 mb-4">
                  {/* {data.map((val, i) => ( */}
                  {data
                    .sort((a, b) =>
                      a.active === b.active ? 0 : a.active ? -1 : 1
                    )
                    .map((val, i) => (
                      <div
                        key={i}
                        className="w-[345px] md:w-full h-30 shadow-sm cursor-pointer hover:shadow-lg rounded-xl bg-[#FFFFFF] mt-4 pb-2"
                        onClick={() => handleBoxClick(val)}
                        // onClick={() => {
                        //   setShowDeposited(true);
                        //   setSelectedUser (val);
                        // }}
                        // onClick={() => {
                        //   navigate(routes.deliveryPartnerOrder, {
                        //     state: { deliveryPartnerData: val } // Pass data through navigation state
                        //   });
                        // }}
                      >
                        <span className="flex flex-row  justify-between items-center ">
                          <span className="mx-2">
                            {" "}
                            {val?.active === true ? (
                              <p className=" text-green-500 font-semibold text-3xl">
                                •
                              </p>
                            ) : (
                              <p className=" text-red font-semibold text-3xl">
                                •
                              </p>
                            )}
                          </span>
                          {val.active && (
                            <Dropdown
                              menu={{
                                items: items,
                                onClick: (e) => handleMenuClick(e, val), // Handle menu item clicks
                              }}
                              trigger={["click"]}
                              placement="bottomRight"
                              arrow
                            >
                              <img
                                src={menu}
                                alt="menu"
                                className="float-right cursor-pointer"
                                onClick={(e) => e.stopPropagation()}
                              />
                            </Dropdown>
                          )}
                        </span>

                        <div className="flex items-center">
                          {val?.image_url?.length >= 30 ? (
                            <img
                              className="h-16 w-16 object-cover rounded-full ml-4"
                              src={val.image_url}
                              alt=""
                            />
                          ) : (
                            <img
                              className="h-16 w-16 object-cover rounded-full ml-4"
                              src={noImage}
                              alt=""
                            />
                          )}
                          <div className="ms-4 text-nowrap">
                            <div className="font-semibold text-[#272728]">
                              {val.name}
                            </div>
                            <div className="font-normal text-sm text-[#707070]">
                              {t(val.phone)}
                            </div>
                            {/* <div className="font-normal text-sm text-[#707070]"> */}
                            <div className="font-normal text-sm text-[#707070] max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                              {t(val.email)}
                            </div>
                          </div>
                        </div>
                        <div className="ms-5 mt-2 flex flex-row justify-between gap-4 text-sm font-normal me-2">
                          <span>
                            {val.last_login ? (
                              `${t("Last Login")} : ${val.last_login}`
                            ) : val.active ? (
                              ""
                            ) : (
                              <span className="flex flex-row text-red gap-2">
                                <p className="font-semibold text-[#4f4e4e]">
                                  {" "}
                                  {t("Status")}{" "}
                                </p>
                                {t("Deactivated")}
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
                {/* {showDeposited && (
                    <Deposited
                      Deliverypartnerdata={selectedUser}
                      onClose={() => {
                        setShowDeposited(false);
                        getdata();
                      }}
                    />
                  )} */}
              </>
            )}
            {popup && (
              <Blockuserpopup
                onClose={() => setpopup(false)}
                data={blockdata}
                status={status}
                successMessage={successMessage}
              />
            )}

            {/* <p className='ml-20 mt-20'>Yet to be integrated</p> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllDeliveryBoys;
