import React from "react";
import { hydrateRoot } from "react-dom/client";
import "./index.css";
import {store,persistor} from "./Redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import './i18n'; // Import the i18n configuration

const root = document.getElementById("root");
// console.log(root);

if (root) {
  hydrateRoot(
    root,
    //  <HomeComponent />
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
} else {
  console.error("Target container is not a DOM element");
}

reportWebVitals();

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//         <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <App />
//       </PersistGate>
//     </Provider>
//     ,
//   </React.StrictMode>
// );
