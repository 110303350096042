import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { InputBox, Password } from "../../components/CustomWidgets/InputBox";
import { Button, DatePicker, Input } from "antd";
import gallery from "../../assets/Images/gallery.svg";
import { Image } from "antd";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import SelectBox from "../CustomWidgets/SelectBox";
import vector from "../../assets/Images/Vector.svg";
import UploadMedia from "../upload-media";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import { routes } from "../../Routes";
import FlagIcon from "../../assets/egypt.svg";
import UploadMediaonly from "../upload-imageonly";
import SuccessModal from "../../modals/Sucessmodal";
import { deliveryBoyschema } from "../validationSchema";
import "../../styles.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import image from "../../assets/Images/roundimage.png";
import {
  createStoreUser,
  updateStoreUser,
  UserImageUpload,
} from "../../API  Functions/UserMangement";
import { createDeliveryBoy, updateDeliveryBoy } from "../../API  Functions/DeliveryPartnerFunctions";

const AddDeliveryboy = ({ isEdit, onClose }) => {
  const { t, i18n } = useTranslation();
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);

  const [file, setFile] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [showsucesspopup, setshowsucesspopup] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showemail, setshowemail] = useState(false);
  const [base64Images, setBase64Images] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [Error, setError] = useState(0);

  console.log("ISEEEEE",isEdit);
  


  const formik = useFormik({
    initialValues: {
      user_id: user_id,
      name: isEdit?.name ?? "",
      mobile: isEdit?.phone ?? "",
      image_url: isEdit?.image_url ?? "",
      email: isEdit?.email ?? "",
      password: isEdit?.password ?? "",
    },
    validationSchema: deliveryBoyschema,
    // validateOnChange: false,
    // validateOnBlur: false,

    onSubmit: async (values, { resetForm }) => {
      const apipayload = {
        user_id: user_id,
        delivery_boy_id: isEdit?.id,
      };

      try {
        if (!isEdit) {
          // Handle user creation
          if (base64Images.length > 0) {
            formik.setFieldError("image_url", undefined);
          }

          const imageName = values.mobile;
          const imageResponse = await UserImageUpload(base64Images, imageName);
          console.log("imageResponse", imageResponse);

          if (imageResponse) {
            formik.setFieldValue("image_url", imageResponse);

            const payload = {
              user_id: user_id,
              password: values.password,
              name: values.name,
              email: values.email.toLowerCase(),
              phone: values.mobile,
              image_url: imageResponse,
            };

            const res = await createDeliveryBoy(payload);
            console.log("CREATE DELIVERY BOY RES", res);

            if (res?.data?.result?.status === "Delivery Boy Created") {
              // setmessage("User  Created Successfully");
              setshowsucesspopup(true);
              resetForm();
            } else if (
              res?.data?.result?.status_code === 400 &&
              res?.data?.result?.status === "Duplicate Email Not Allowed!"
            ) {
              // setshowemail(true);
              setError(1);
              // setmessage("You can not create user, No. of user limit is only 3");
              //   setshowsucesspopup(true);
              //   resetForm();
              // setError(1);
            } else if (
              res?.data?.result?.status === "Duplicate Phone/Name Not Allowed!"
            ) {
              setError(2);
            }
          }
        } else {
          if (base64Images.length > 0) {
            const imageName = values.mobile;
            const imageResponse = await UserImageUpload(
              base64Images,
              imageName
            );
            console.log("Image Response:", imageResponse);

            apipayload.image_url = imageResponse;
          }

          if (formik.values.name !== isEdit?.name) {
            apipayload.name = values.name;
          }
          if (formik.values.password) {
            apipayload.password = values.password;
          }
          if (
            formik.values.email.toLowerCase() !== isEdit?.email.toLowerCase()
          ) {
            apipayload.email = values.email.toLowerCase();
          }

          const res = await updateDeliveryBoy(apipayload);
          console.log("Update Response:", res);

          if (
            res?.data?.result[0]?.status === "Delivery Partner Details Updated"
          ) {
            //   setmessage("User Updated Successfully");
            setshowsucesspopup(true);
          }
        }
      } catch (error) {
        console.error("Error during submission:", error);
      }
    },
  });


  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className=" ">
          {/* {console.log("user type", formik.values.store_user_type_id)} */}
          <div className="flex justify-between items-baseline">
            <div className="font-semibold text-2xl mt-5">
              {isEdit ? t("Edit Details") : t("Add New Delivery Boy")}{" "}
            </div>
            <div className="flex gap-5 ">
              <button
                onClick={() => onClose()}
                className="w-36 h-10 bg-white text-primaryBlue font-semibold rounded-lg inline border border-primaryBlue"
              >
                {t("cancel")}
              </button>
              <button
                type="submit"
                // className="w-36 h-10 bg-primaryBlue text-white rounded-lg font-semibold"
                className={`w-36 h-10 rounded-lg font-semibold ${
                  hasChanges
                    ? "bg-primaryBlue text-white"
                    : "bg-primaryBlue text-white opacity-50 cursor-not-allowed"
                }`}
                disabled={!hasChanges}
              >
                {t("Save")}
              </button>
            </div>
          </div>
          <div className="md:w-full min-h-auto bg-[#FFFFFF] border rounded-xl mt-5">
            <div className="w-full h-10 bg-[#C9ECF7] px-4  flex items-center font-medium text-base rounded-tr-xl rounded-tl-xl text-[#272728]">
              {t("basic_details")}
            </div>
            <div className="flex justify-center mt-4 pb-4 gap-x-8 px-6">
              <div
                className={`w-44 h-50 flex flex-col  sm:w-[20%] ${
                  formik.values?.image_url == ""
                    ? "rounded-lg border border-gray-900/25 bg-[#ffffff]"
                    : "rounded-full"
                } relative ${
                  formik.errors.image_url && formik.touched.image_url
                    ? "border-red"
                    : ""
                }`}
              >
                <label
                  for="file-upload"
                  class=" items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue  hover:text-primaryBlue"
                >
                  <div className={`flex h-10 items-center justify-center`}>
                    {isEdit ? (
                      formik.values.image_url ? (
                        <img
                          className="w-[8rem] h-[7rem] object-cover rounded-full mt-24"
                          src={formik.values.image_url}
                          width={100}
                          height={100}
                          alt="store user"
                        />
                      ) : base64Images.length > 0 ? (
                        <img
                          className="w-[8rem] h-[7rem] object-contain mt-24"
                          src={base64Images}
                          width={60}
                          height={60}
                          alt="store user"
                        />
                      ) : (
                        <img
                          className="w-[8rem] h-[7rem] object-contain mt-24"
                          src={image}
                          width={60}
                          height={60}
                          alt="store user"
                        />
                      )
                    ) : formik.values.image_url ? (
                      <img
                        className="w-[8rem] h-[7rem] object-cover rounded-full mt-24"
                        src={formik.values.image_url}
                        width={100}
                        height={100}
                        alt="store user"
                      />
                    ) : base64Images.length > 0 ? (
                      <img
                        className="w-[8rem] h-[7rem] object-cover rounded-full mt-24"
                        src={base64Images}
                        width={60}
                        height={60}
                        alt="store user"
                      />
                    ) : (
                      <img
                        className="w-[8rem] h-[7rem] object-contain mt-24"
                        src={image}
                        width={60}
                        height={60}
                        alt="store user"
                      />
                    )}
                  </div>
                  <div className="mt-[5.9rem] bottom-0 mb-2">
                    <UploadMediaonly
                      foldername={"store_app_UAT"}
                      onfileAdd={`storeuserurl`}
                      // setBase64Images={setBase64Images}
                      setBase64Images={(images) => {
                        setBase64Images(images);
                        setHasChanges(true);
                        formik.setFieldValue("image_url", images);
                      }}
                      urlgenerated={(val) => {
                        formik.setFieldValue("image_url", val.image_url);
                      }}
                      imageurl={formik.values.image_url}
                      touched={formik.touched.image_url}
                      error={formik.errors.image_url}
                    />
                  </div>
                </label>
              </div>
              <div className="font-normal text-sm text-[#5C5C5C] flex flex-col gap-6 sm:flex-row sm:gap-7 sm:items-baseline sm:w-[75%]">
                <div className="w-full flex flex-col gap-4 leading-[0px]">
                  <label style={{ marginBottom: -15, marginTop: 15 }}>
                    {t("Name")}
                    
                  </label>
                  <InputBox
                    name="name"
                    placeholder={t("enter_fullname")}
                    className={` ${
                      formik.errors.name
                        ? "border-red" // Apply red border when touched or there's an error
                        : ""
                    }`}
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setHasChanges(true);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    touched={formik.touched.name}
                    error={formik.errors.name}
                    // required
                  />

                  <div className="flex flex-col text-[#5C5C5C] gap-2">
                    <label>{t("Phone Number")}</label>
                    <div className="flex items-center ">
                      <div className="h-10 w-16 mt-2 text-md text-body outline-none focus:outline-none rounded-md border border-[#ccc] p-3 flex items-center justify-center">
                        <Image src={FlagIcon} alt="img" />
                      </div>

                      <Input
                        type="number"
                        // maxLength={11}
                        max={9999999999}
                        placeholder="Enter Number"
                        international
                        defaultCountry="EG"
                        //   onChange={handleChange}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.length > 10) {
                            e.target.value = value.slice(0, 10);
                          }
                          formik.handleChange(e);
                          setHasChanges(true);
                        }}
                        value={formik.values.mobile}
                        onBlur={formik.handleBlur}
                        name="mobile"
                        className={` h-10 w-100 ms-2 mt-2 text-[#5C5C5C] text-md text-body outline-none focus:outline-none ltr:pl-5 rtl:pr-5 rounded-md border p-3 ${
                          (formik.errors.mobile && formik.touched.mobile) ||
                          showNotification
                            ? "border-red" // Apply red border when touched or there's an error
                            : ""
                        }`}
                        // required
                      />
                    </div>
                    {Error == 2 && (
                      <div className="text-xs text-red">
                        {" "}
                        {t("Phone Number already registered")}
                      </div>
                    )}
                  </div>
                </div>

                <div className="w-full flex flex-col gap-4 leading-[0px]">
                  <div>
                    <label className="">{t("email")}</label>
                    <InputBox
                      name="email"
                      placeholder={t("enter_email")}
                      onChange={(val) => {
                        formik.handleChange(val);
                        setHasChanges(true);
                        setshowemail(false);
                        setError(0);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      touched={formik.touched.email}
                      error={formik.errors.email}
                      // required
                      autoComplete="new-password"
                    />
                    {Error == 1 && (
                      <div className="text-xs text-red">
                        {" "}
                        {t("Email already registered")}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col gap-2">
                    <label>{t("Password")}</label>
                    <Password
                      loc="0"
                      del="0"
                      isEdit={isEdit}
                      style={{ marginBottom: `${isEdit ? 0 : "2rem "}` }}
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setHasChanges(true);
                      }}
                      value={formik.values.password}
                      onBlur={formik.handleBlur}
                      name="password"
                      className={`h-10 mt-2  ml-2 text-md text-body outline-none focus:outline-none ltr:pl-5 rtl:pr-5 rounded-sm border p-3 ${
                        formik.touched.password && formik.errors.password
                          ? "border-red" // Apply red border when touched or there's an error
                          : ""
                      }`}
                      touched={formik.touched.password}
                      error={formik.errors.password}
                      // required
                      autoComplete="new-password"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {showsucesspopup && (
        <SuccessModal
          onClose={() => {
            setshowsucesspopup(false);
            onClose();
          }}
          state={isEdit ? true : false}
          message={` ${
            isEdit ? t("deliveryboy_updated") : t("deliveryboy_added")
          }  `}
          route={routes.deliveryPartner}
        />
      )}
    </>
  );
};

export default AddDeliveryboy;
