import React from "react";
import { Breadcrumb } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import BDODetails from "../../../components/Profile/BDODetails";
import Navbar from "../../../components/Navbar/Navbar";
import { useTranslation } from "react-i18next";

const Bdo = () => {
  const {t,i18n}=useTranslation();
  return (
    <div>
      <div className="float-left">
        {/* <Sidebar /> */}
      </div>
      <div className="flex flex-col flex-1 bg-[#F6F7F9]   min-h-screen overflow-y-auto">
        <Navbar heading="bdo_details" />
        <div className="min-h-16"></div>
        <div className="mr-4 ml-3 font-extrabold ">
          <Breadcrumb
            separator={<DoubleRightOutlined />}
            items={[{ title: t('settings') }, { title: t('bdo_details') }]}
            className="relative top-3"
          />
        </div>

        <BDODetails />
      </div>
    </div>
  );
};

export default Bdo;
