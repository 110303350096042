import { CloseOutlined } from "@ant-design/icons";
import { Input, Select } from "antd";

import React, { useState, useEffect } from "react";
import {
    confirmReturnOrder,
  getLahloobCancelReasons,
  getcancelReasons,
  lahloobCancelUpdate,
  updateorderStage,
  updateordercancelreason,
} from "../API  Functions/OrderlistFunction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Checkbox } from "antd"; // Add this import

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
  container: {
    width: "500px",
    padding: "30px",
    borderRadius: "18px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: "25px",
    fontWeight: "bold",
    textAlign: "left",
    marginBottom: "10px",
  },
  cancelIcon: {
    cursor: "pointer",
  },
  description: {
    textAlign: "left",
    fontSize: "16px",
    color: "#272728",
    marginTop: "10px",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "12px",
    border: "1px solid #CCCCCC",
    marginBottom: "20px",
    boxSizing: "border-box",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: "180px",
    height: "45px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    marginRight: "10px",
  },
  confirmButton: {
    backgroundColor: "primaryBlue",
    color: "#FFFFFF",
  },
  cancelButton: {
    backgroundColor: "#CCCCCC",
    color: "#000000",
  },
};

const checkboxStyle = {
  borderColor: "#1B4597", // primaryBlue
};

const checkedStyle = {
  backgroundColor: "#1B4597", // primaryBlue
  borderColor: "#1B4597", // primaryBlue
};

const ReturnCancelOrder = ({ isOpen, onClose, order_id, popup, data }) => {
 
  const [customReason, setCustomReason] = useState("");
  const { t, i18n } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);

//   console.log("DATA-------->", data);


const handleSubmit = async (e) => {
    e.preventDefault();
  
    try { 
      const agree = isChecked;
      const comments = customReason.trim() === "" ? "" : customReason; 
    //   const comments = customReason; 

      const payload = {
        order_id: order_id,
        report_to_myginne: agree,
        comments: comments,
      };
  
      const response = await confirmReturnOrder(payload);
  
    //   console.log("API Response:", response);
      if (response?.data?.result?.status === "success") {
        popup();
      }
  
      onClose();
    } catch (error) {
      console.error("Error confirming return order:", error);
    }
  };

  return (
    <>
      {isOpen && (
        <>
          <div style={styles.overlay} onClick={onClose}></div>
          <div style={styles.container}>
            <div style={styles.header}>
              <p style={styles.title}>Order Details</p>
              {/* <CloseOutlined style={styles.cancelIcon} onClick={onClose} /> */}
            </div>
            <div className="flex flex-row font-semibold">
              {t("Items")} :
              <p className="ms-2 font-normal">{data?.existing_items?.length}</p>
            </div>
            <div className="flex flex-row font-semibold">
              {t("Total Price")} :
              <p className="ms-1 font-normal">{data?.order_details?.total}</p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="flex flex-row mb-5 mt-2 justify-between"></div>
              <Input
                placeholder="Add Comment..."
                value={customReason}
                onChange={(e) => setCustomReason(e.target.value)}
                style={{ marginBottom: "20px", height: "80px" }}
              />
              <Checkbox
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
                style={{ marginBottom: "1.25rem" }} // Tailwind's mb-5 equivalent
                className="custom-checkbox"
              >
                <span style={checkboxStyle} className="ant-checkbox-inner" />
                <span
                  style={isChecked ? checkedStyle : {}}
                  className="ant-checkbox-inner"
                />
                Inform myGINNE about the issue with receiving the order.
              </Checkbox>
              <div className="flex justify-center">
                <button
                  className="w-52 h-10 border-primaryBlue border text-primaryBlue  font-semibold rounded-lg mr-5"
                  onClick={() => onClose()}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="w-52 h-10 bg-primaryBlue text-white font-semibold rounded-lg"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default ReturnCancelOrder;
