import React, { useEffect, useState } from "react";
import SelectBox from "../CustomWidgets/SelectBox";
import { useFormik } from "formik";
import Deliverypartnersorderstable from "./Deliverypartnersorderstable";
import vector from "../../assets/Images/Vector.svg";
import { Breadcrumb, Image } from "antd";
import Deliveryboy from "./Deliveryboy";
import { useTranslation } from "react-i18next";
import Navbar from "../Navbar/Navbar";
import {
  DoubleRightOutlined,
  DeleteOutlined,
  EditOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../../Routes";
import AddDeliveryboy from "./AddDeliveryBoy";

const DeliveryBoyOrders = ({ onClose }) => {
  const { t, i18n } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  const location = useLocation();
  const { deliveryPartnerData } = location.state || {};

  console.log("Delivery Partner Data", deliveryPartnerData);

  // const deliverypartnerorders=()=>{
  //   getDeliveryPartnerOrders()
  // }
  // useEffect(() => {
  //   console.log("Deliverypartnerdata", Deliverypartnerdata);
  // }, []);

//   const handleEditProfileClick = () => {
//     setIsActive(true);
//   };

  return (
    <>
      {isActive ? (
        <AddDeliveryboy
          isActive={isActive}
          onEditProfile={() => setIsActive(false)}
          DeliveryBoyData={deliveryPartnerData}
          onClose={() => {
            onClose();
          }}
        />
      ) : (
        <>
          <div className=" bg-[#F6F7F9] p-4 mt-10 h-screen">
            <Navbar heading="delivery_boy" />
            <div className="font-extrabold">
              <Breadcrumb
                separator={<DoubleRightOutlined />}
                items={[
                  {
                    title: (
                      <Link to={routes.deliveryPartner}>
                        {" "}
                        {/* Update the Link here */}
                        {t("Delivery Boy")}{" "}
                        {/* This is the breadcrumb link for "Delivery Boy" */}
                      </Link>
                    ),
                    key: "delivery_boy", // Unique key for this breadcrumb
                  },
                  { title: t("Delivery Boy Orders") }, // Current breadcrumb for the "Orders" page
                ]}
                className="relative mt-3"
              />
            </div>
            {/* <div className="md:w-full h-[18rem] bg-[#FFFFFF] border rounded-xl mt-5">
              <div className="w-full h-10 bg-[#C9ECF7] px-4 flex items-center font-semibold text-base rounded-tr-xl rounded-tl-xl text-[#272728]">
                {t("basic_details")}
              </div>

              <div className="flex justify-center items-start mt-6 gap-8 px-6">
                <div className="w-[14rem] h-[12.5rem] sm:w-[18%] flex items-center justify-center rounded-full border border-gray-900/25 bg-[#F6F7F9]">
                  {" "}
                  {Deliverypartnerdata?.image_url != "" ? (
                    <img
                      className=" m-0.5 w-[96%] h-[12rem]  object-cover rounded-full"
                      style={{ width: "96%" }}
                      src={Deliverypartnerdata?.image_url}
                      // width={70}
                      // height={100}
                      alt="delivery partner image"
                    />
                  ) : (
                    <svg
                      className=" float-left h-10 w-12 text-gray-300 rounded-full object-cover"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                        // clip-rule="evenodd"
                      />
                    </svg>
                  )}
                  {/* <img  src={Deliverypartnerdata?.Deliverypartnerdata?.image_url}  alt="delivery partner image"/>{console.log("DElivery partner data",Deliverypartnerdata)} */}
                {/* </div> */}
                {/* <div className="font-normal text-sm text-[#5C5C5C] grid grid-cols-2 sm:items-baseline sm:w-[75%]">
                  <div className="grid gap-2">
                    <label>{t("full_name")}</label>
                    <h1 className="font-medium text-[#272728] text-base">
                      {Deliverypartnerdata?.name}
                    </h1>
                    <span className="font-medium text-[#272728] text-base"></span>
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="grid gap-2">
                      <label>{t("phone_number")}</label>
                      <h1 className="font-medium text-[#272728] text-base">
                        {"+20 "}
                        {Deliverypartnerdata?.mobile?.length == 11
                          ? Deliverypartnerdata?.mobile?.substring(1)
                          : Deliverypartnerdata?.mobile}
                      </h1>

                      <span className="font-medium text-[#272728] text-base"></span>
                    </div>
                    <button
                      className="flex justify-center items-start border rounded-lg border-primaryBlue text-primaryBlue text-base px-6 py-2"
                      type="button"
                      onClick={handleEditProfileClick}
                    >
                      <img src={vector} />
                      <p className="pl-3">{t("edit_profile")}</p>
                    </button>
                  </div>

                  <div className="grid gap-1">
                    <label>{t("joined_on")}</label>
                    <h1 className="font-medium text-[#272728] text-base">
                      {Deliverypartnerdata?.joined_on?.replace(/-/g, "/")}
                    </h1>
                    <span className="font-medium text-[#272728] text-base"></span>{" "}
                  </div>
                  <div className="grid gap-1 ">
                    <label>{t("status")}</label>
                    <h1 className="font-medium text-[#272728] text-base">
                      {Deliverypartnerdata?.status}
                    </h1>
                    <span className="font-medium text-[#272728] text-base"></span>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <h1 className="font-semibold text-xl pt-4">
                {Deliverypartnerdata?.name}
                {t("deliveryboyorders")}{" "} */}
              {/* </h1> */} 
              {/* <div className="w-36 leading-[0px]">
                <SelectBox
                  name="today"
                  defaultValue="Today"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.today}
                  options={[
                    { value: "Today", label: "Today" },
                    { value: "Manager", label: "Manager" },
                    { value: "Executive", label: "Executive" },
                  ]}
                />
              </div> */}
            {/* </div> */}
            {/* {showDeliveryBoy && <Deliveryboy />} */}
            <Deliverypartnersorderstable
              deliverypartnerid={deliveryPartnerData?.id}
            />
          </div>
        </>
      )}
    </>
  );
};

export default DeliveryBoyOrders;
