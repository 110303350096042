import React, { useEffect, useMemo, useState, useRef } from "react";
import { useTable, useSortBy, usePagination, useRowSelect } from "react-table";
import "tailwindcss/tailwind.css";
import CustomPagination from "../CustomWidgets/CustomPagination";
import { Empty, Input, InputNumber, Modal } from "antd";
import Edit from "../../assets/Edit.svg";
import "../../styles.css";
import Vectorsorting from "../../assets/Vectorsorting.svg";
import ShimmerLoader from "../../loading";
import ProductModal from "../../modals/showproductmodal";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import dummy_image from "../../assets/Images/gallery.svg";


const InventoryTableComponent = ({
  additionalData,
  setAdditionalData,
  callafterUpdate,
  pagination,
}) => {
  const { t, i18n } = useTranslation();
  const [flag, setflag] = useState(false);
  const [editRows, setEditRows] = useState(new Set());
  const [editValues, setEditValues] = useState({});
  const [editPriceClicked, setEditPriceClicked] = useState(false);
  const [indexarray, setindexarray] = useState([]);
  const [showpopup, setshowpopup] = useState(false);
  const [showpopupdata, setshowpopupdata] = useState([]);
  const costPriceInputRefs = useRef({});
  const sellPriceInputRefs = useRef({});
  const qtyInputRefs = useRef({});
  const timeoutRef = useRef(null);
  const focusedInputRef = useRef(null);
  const [tempEditValues, setTempEditValues] = useState({});
  const [isSalePriceValid, setIsSalePriceValid] = useState({});
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
const [currentImageUrl, setCurrentImageUrl] = useState("");

  const data = useMemo(
    () =>
      additionalData?.status
        ? additionalData?.all_product
        : additionalData || [],
    [additionalData]
  );

  
  useEffect(() => {
    if (additionalData?.length === 0 && !flag) {
      const timer = setTimeout(() => {
        setflag(true);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [additionalData, flag]);

  useEffect(() => {
    handleEditCancel();
  }, [additionalData]);

  
  const columns = useMemo(
    () => [
      // {
      //   Header: t(""),
      //   accessor: "product_image_url",
      //   headerClassName: "text-center",
      //   Cell: ({ row }) => (
      //     <div className="flex h-50 w-50 items-center gap-3 justify-start">
      //       {row.original.product_image_url || row.original.image ? (
      //         <img
      //           style={{
      //             maxHeight: 40,
      //             maxWidth: 40,
      //             minHeight: 40,
      //             minWidth: 40,
      //             objectFit: "contain",
      //           }}
      //           src={row.original.product_image_url || row.original.image}
      //           height={60}
      //           width={30}
      //           alt="prod_image"
      //         />
      //       ) : (
      //         <svg
      //           className=" float-left h-10 w-12 text-gray-300"
      //           viewBox="0 0 24 24"
      //           fill="currentColor"
      //           aria-hidden="true"
      //         >
      //           <path
      //             fill-rule="evenodd"
      //             d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
      //           />
      //         </svg>
      //       )}
      //     </div>
      //   ),
      // },
      {
        Header: t(""),
        accessor: "product_image_url",
        headerClassName: "text-center",
        Cell: ({ row }) => (
          <div className="flex h-50 w-50 items-center gap-3 justify-start">
            {row.original.product_image_url || row.original.image ? (
             
              <img
              src={dummy_image}
              alt="Dummy"
              style={{
                maxHeight: 40,
                maxWidth: 40,
                minHeight: 40,
                minWidth: 40,
                objectFit: "contain",
                cursor: "pointer",
              }}
              onClick={() => {
                setCurrentImageUrl(row.original.product_image_url || row.original.image);
                setIsImageModalVisible(true);
              }}
            />
            ) : (
              <img
                style={{
                  maxHeight: 40,
                  maxWidth: 40,
                  minHeight: 40,
                  minWidth: 40,
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                src={row.original.product_image_url || row.original.image}
                height={60}
                width={30}
                alt="prod_image"
              />
            )}
          </div>
        ),
      },
      {
        Header: t("Barcode"),
        accessor: "barcode",
        headerClassName: "text-center ms-8 gap-6",
        Cell: ({ row }) => (
          <div className=" flex justify-center text-center">
            <span>{row.original.barcode}</span>
          </div>
        ),
      },
      {
        Header: t("Product Name"),
        accessor: "product_name",
        headerClassName: "text-center",
        Cell: ({ row }) => <span>{row.original.product_name}</span>,
      },
      {
        Header: t("Product Code"),
        accessor: "machine_barcode",
        headerClassName: "text-center",
        Cell: ({ row }) => {
          const machineBarcode =
            editValues[row.index]?.machine_barcode ??
            row.values.machine_barcode;
          const uom = row.original.uom;

          if (editRows?.has(row.index) && (uom === "KG" || uom === "كجم")) {
            return (
              <InputNumber
                className="w-20"
                controls={false}
                value={machineBarcode === false ? "" : machineBarcode}
                onChange={(value) => {
                  // Ensure the value is a number and does not exceed 7 digits
                  if (value === "" || (value >= 0 && value <= 9999999)) {
                    handleInputChange(row.index, "machine_barcode", value);
                  }
                }}
                maxLength={7}
                onKeyPress={(e) => {
                  // Allow only numeric input
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault(); // Prevent non-numeric input
                  }
                }}
                onFocus={(e) => e.target.select()}
                // onChange={(value) => {
                //   handleInputChange(row.index, "machine_barcode", value);
                // }}
              />
            );
          }
          return machineBarcode === false ? "" : `${machineBarcode}`;
        },
      },
     
      {
        Header: t("category"),
        accessor: "categ_name",
        headerClassName: "text-center",
        Cell: ({ row }) => <span>{row.original.categ_name}</span>,
      },
      {
        Header: t("Sub Category"),
        accessor: "sub_categ_name",
        headerClassName: "text-center",
        Cell: ({ row }) => <span>{row.original.sub_categ_name}</span>,
      },
      {
        Header: t("cost_price"),
        accessor: "current_cost_price",
        Cell: ({ row }) =>
          editRows?.has(row.index) ? (
            <Input
              type="number"
              className="w-16"
              placeholder={row.values.current_cost_price}
              ref={(element) =>
                (costPriceInputRefs.current[row.index] = element)
              }
              value={tempEditValues[row.index]?.current_cost_price}
              onChange={(e) => {
                const value = e.target.value;

                // Allow digits with one decimal point
                if (
                  value === "" ||
                  (/^[0-9]*\.?[0-9]{0,2}$/.test(value) && value.length <= 5)
                ) {
                  handleInputChange(row.index, "current_cost_price", value);
                }
              }}
              maxLength={5}
              onFocus={() => handleInputFocus(row.index, "current_cost_price")}
              onKeyPress={(e) => {
                const value = e.target.value;

                // Prevent non-numeric input and only allow one decimal point
                if (
                  !/[0-9]/.test(e.key) && // Allow numeric input
                  e.key !== "Backspace" && // Allow backspace
                  e.key === "." &&
                  value.includes(".") // Prevent more than one decimal point
                ) {
                  e.preventDefault();
                }
              }}
            />
          ) : (
            `${
              editValues[row.index]?.current_cost_price ??
              row.values.current_cost_price?.toFixed(2)
            }`
          ),
      },
      {
        Header: t("sale_price"),
        accessor: "current_price",
        Cell: ({ row }) => (
          <>
            {editRows?.has(row.index) ? (
              <Input
                type="number"
                className={`w-16 ${
                  isSalePriceValid[row.index] === false ? "border-red" : ""
                }`} // Add red border if invalid
                placeholder={row.values.current_price}
                ref={sellPriceInputRefs.current[row.index]}
                value={tempEditValues[row.index]?.current_price}
                onChange={(e) => {
                  const value = e.target.value;

                  // Allow only digits and one decimal point between digits
                  if (
                    value === "" ||
                    (/^\d*\.?\d{0,2}$/.test(value) && value.length <= 5)
                  ) {
                    handleInputChange(row.index, "current_price", value);
                  }
                }}
                maxLength={5}
                onFocus={() => handleInputFocus(row.index, "current_price")}
                onKeyPress={(e) => {
                  const value = e.target.value;

                  // Allow only numbers and one decimal point
                  if (
                    !/[0-9]/.test(e.key) && // Allow numbers
                    e.key !== "Backspace" && // Allow backspace
                    e.key === "." &&
                    (value === "" || value.includes(".")) // Prevent decimal at the start or if one already exists
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            ) : (
              `${
                editValues[row.index]?.current_price ?? (row.values.current_price?.toFixed(2))
              }`
            )}
          </>
        ),
      },
      {
        Header: t("quantity"),
        accessor: "system_quantity",
        Cell: ({ row }) => {
          const uom = row.original.uom; // Get the UOM from the original row data
        
          return editRows?.has(row.index) ? (
            <Input
              type="text" // Keep as text to handle custom validation
              className="w-16"
              placeholder={row.values.system_quantity}
              ref={(element) => {
                qtyInputRefs.current[row.index] = element;
                focusedInputRef.current = element; // Set the focused input ref
              }}
              min={0}
              value={tempEditValues[row.index]?.system_quantity}
              onChange={(e) => {
                const value = e.target.value; // Get the value from the event
        
                // Allow only digits and one decimal point if UOM is not "Units"
                if (
                  value === "" ||
                  (uom !== "Units" && /^\d*\.?\d{0,3}$/.test(value) && value.length <= 5) || // Allow decimals with up to two decimal places
                  (uom === "Units" && /^[0-9]*$/.test(value) && value.length <= 5) // Only whole numbers for units
                ) {
                  handleInputChange(row.index, "system_quantity", value);
                }
              }}
              onFocus={() => handleInputFocus(row.index, "system_quantity")}
              onKeyPress={(e) => {
                const value = e.target.value;
        
                // Allow only numbers and one decimal point if UOM is not "Units"
                if (
                  (uom !== "Units" &&
                    !/[0-9]/.test(e.key) &&
                    e.key !== "Backspace" &&
                    !(e.key === "." && (value === "" || !value.includes(".")))) || // Prevent decimal at the start or if one already exists
                  (uom === "Units" &&
                    !/[0-9]/.test(e.key) &&
                    e.key !== "Backspace") // Prevent any non-numeric input if UOM is "Units"
                ) {
                  e.preventDefault();
                }
              }}
            />
          ) : (
            `${(editValues[row.index]?.system_quantity ?? row.values.system_quantity)}` // Format to two decimal places
          );
        },
      }
      ,
      {
        Header: "",
        accessor: "dropdown",
        Cell: (row) => (
          <>
            <img
              src={Edit}
              style={{
                cursor: "pointer",

                height: "0.9rem",
                width: "0.9rem",
              }}
              alt="Edit"
              className={` mt--2 ${i18n.language == "en" ? "mr-2" : "ml-2"}`}
              onClick={(e, index) => {
                e.preventDefault();

                setindexarray([...indexarray, parseInt(row.row.id)]);

                handleEditRow(row.row.id);
              }}
            />
          </>
        ),
      },
    ],
    [editRows, editValues]
  );

  const inputRefs = useRef([]);

  const handleInputChange = (index, field, value) => {
    console.log("VALueeL", value);

    const numericValue = value === "" ? 0 : parseFloat(value); // Convert to number if valid

    setTempEditValues((prevValues) => ({
      ...prevValues,
      [index]: {
        ...prevValues[index],
        [field]: numericValue, // Update the field with the new value
      },
    }));

    if (field === "current_price") {
      const costPrice =
        tempEditValues[index]?.current_cost_price ??
        data[index].current_cost_price;
      if (numericValue < costPrice) {
        setIsSalePriceValid((prev) => ({ ...prev, [index]: false })); // Mark as invalid
      } else {
        setIsSalePriceValid((prev) => ({ ...prev, [index]: true })); // Mark as valid
      }
    }
  };

  const handleInputFocus = (index, field) => {
    if (inputRefs.current[index] && inputRefs.current[index][field]) {
      inputRefs.current[index][field].focus();
    }
  };

  const handleSaveAll = () => {
    for (const index of editRows) {
      const salePrice = tempEditValues[index]?.current_price;

      const costPrice =
        tempEditValues[index]?.current_cost_price !== undefined
          ? tempEditValues[index]?.current_cost_price
          : data[index].current_cost_price;

      if (salePrice < costPrice) {
        toast.error(t("Sale price cannot be less than cost price."));
        return;
      }
    }

    let sendData = [];

    const newData = data?.map((row, index) => {
      if (editRows.has(index)) {
        const item = {
          quant_id: row?.quant_id ?? "",
          new_price:
            tempEditValues[index]?.current_price ?? row?.current_price ?? 0,
          new_cost_price:
            tempEditValues[index]?.current_cost_price ??
            row?.current_cost_price ??
            0,
          new_quantity:
            tempEditValues[index]?.system_quantity ?? row?.system_quantity ?? 0,
          machine_barcode:
            tempEditValues[index]?.machine_barcode ?? row?.machine_barcode ?? 0,
        };

        console.log("tempEditValues", tempEditValues);

        sendData.push(item);
        return {
          ...row,
          ...tempEditValues[index],
        };
      }
      return row;
    });

    setEditRows(new Set());
    setindexarray([]);
    setEditValues(tempEditValues);
    setTempEditValues({});
    setEditPriceClicked(false);

    setAdditionalData(sendData);
  };
  const handleEditRow = (index) => {
    setEditRows(new Set([...indexarray, parseInt(index)]));
    setEditPriceClicked(true);
  };

  const handleEditCancel = () => {
    setEditRows(new Set());
    setindexarray([]);
    setEditValues({});
    setTempEditValues({});
    setEditPriceClicked(false);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    state: { pageSize },
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: data,
      initialState: { pageSize: 10 },
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const [pageIndex, setpageIndex] = useState(1);

  useEffect(() => {
    const pageDetails = {
      limit: pageSize,
      offset: pageSize * (pageIndex - 1) < 0 ? 0 : pageSize * (pageIndex - 1),
    };

    if (pageSize) pagination(pageDetails);
  }, [pageSize, pageIndex]);

  return (
    <>
    <Modal
        visible={isImageModalVisible}
        onCancel={() => setIsImageModalVisible(false)}
        footer={null}
      >
        <img src={currentImageUrl} alt="Product" style={{ width: "100%" }} />
      </Modal>
    <div
      className="container flex flex-col mx-auto mt-4"
      style={{ maxWidth: "100%" }}
    >
      {/* Save All button */}
      {editPriceClicked && (
        <div className="flex justify-end mb-5">
          {editRows.size === 1 ? (
            <button
              onClick={handleSaveAll}
              className="w-28 h-8 mt-1 bg-primaryBlue text-white text-sm font-semibold rounded-lg flex items-center justify-center me-5"
            >
              <span>{t("save")}</span>
            </button>
          ) : (
            <button
              onClick={handleSaveAll}
              className="w-28 h-8 mt-1 bg-primaryBlue text-white text-sm font-semibold rounded-lg flex items-center justify-center me-5"
            >
              <span>{t("save_all")}</span>
            </button>
          )}
          <button
            onClick={handleEditCancel}
            className="w-28 h-8 mt-1 bg-white border border-primaryBlue text-primaryBlue text-sm font-semibold rounded-lg flex items-center justify-center me-5"
          >
            <span>{t("Cancel")}</span>
          </button>
          {/* <Button type="primary" onClick={handleSaveAll} className="bg-green-500">
          Save All
        </Button> */}
        </div>
      )}
      {additionalData?.length == 0 && flag == false ? (
        <>
          <div className="h-screen w-[90%] mt-2 ms-4">
            <ShimmerLoader />{" "}
          </div>
        </>
      ) : (
        <table
          {...getTableProps()}
          className=" font-normal mx-auto max-w-[100%] sm:w-[97.6%] md:w-[97.6%] lg:w-[97.6%] xl:w-[97.6%] min-h-auto shadow-xl rounded-lg overflow-hidden"
        >
          <thead>
            {headerGroups.map((headerGroup, headerIndex) => {
              const { key: headerKey, ...headerGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr
                  key={headerKey}
                  {...headerGroupProps}
                  className="bg-[#78CFEC66] text-sm font-semibold"
                >
                  {headerGroup.headers.map((column, colIndex) => {
                    const { key: columnKey, ...columnProps } =
                      column.getHeaderProps(column.getSortByToggleProps());
                    return (
                      <th
                        key={columnKey}
                        {...columnProps}
                        className="py-3 px-2  whitespace-nowrap "
                      >
                        <div className="flex items-center text-sm justify-center">
                          {column.render("Header")}
                          <span className="">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <img
                                  src={Vectorsorting}
                                  style={{ minHeight: 10, minWidth: 10 }}
                                  alt="vector"
                                />
                              ) : (
                                <img
                                  className="rotate-180"
                                  src={Vectorsorting}
                                  alt="vector"
                                />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()} className="bg-[#F7F9FD] text-center">
            {page.map((row) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.id}>
                  <tr
                    {...row.getRowProps()}
                    className="relative hover:bg-white transition duration-300 border-b border-gray-300 fixed-row-height"
                  >
                    {row.cells.map((cell, index) => (
                      <td
                        key={index}
                        {...cell.getCellProps()}
                        className={`p-2 text-[#272728] h-1212 text-sm font-normal ${
                          index < row.cells.length - 1 ? "relative" : ""
                        } ${
                          cell?.column?.Header == "Product"
                            ? "cursor-pointer"
                            : ""
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                </React.Fragment>
              );
            })}
            {showpopup && (
              <ProductModal
                onClose={() => setshowpopup(false)}
                data={showpopupdata}
              />
            )}
            {/* Empty state row */}
            {page.length === 0 && (
              <tr>
                <td colSpan={"100%"}>
                  <Empty className="mt-10" description="No data available" />
                </td>
              </tr>
            )}
            <tr>
              <td className="" colSpan={"100%"}>
                <CustomPagination
                  data={data?.length}
                  pageIndex={pageIndex}
                  pageCount={page?.length}
                  canPreviousPage={pageIndex != 1 ? true : false}
                  canNextPage={data?.length == pageSize ? true : false}
                  gotoPage={(page) => {
                    nextPage(page);
                  }}
                  previousPage={() => {
                    setpageIndex(pageIndex - 1);
                  }}
                  nextPage={() => {
                    setpageIndex(pageIndex + 1);
                  }}
                  pageSize={pageSize}
                  setPageSize={(pageSize) => {
                    // nextPage(0);

                    setPageSize(pageSize);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
    </>
  );
};

export default InventoryTableComponent;
