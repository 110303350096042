import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
// import {
//   // Cashin,
//   // Cashout,
//   // Customers,
//   // Dashboard,
//    Login,
//   Navbar,
//   // OrderDone,
//   // Orders,
//   // ProductCo,
//   // ReturnOrder,
//   Session,
//   UserProfile,
// } from "./Components/index";

import { useSelector } from "react-redux";

// import LoginPage from "./";
import Login from "./app/login/Login";
import Orderpage from "./app/orders/Orders";
import Deliverypartners from "./components/EordersDeliveryPartners/Deliverypartners";
import Navbar from "./components/Navbar/Navbar";
import ForgotPassword from "./app/forgot-password/ForgotPassword";
import SignupForm from "./app/signup/signupForm";
import Signup from "./app/signup/Signup";
import Mysubscription from "./app/mysubscription/Mysubscription";
import DeliveryPartner from "./app/deliverypartner/DeliveryPartner";
import Dashboard from "./app/dashboard/dashboard";
import Orderlist from "./app/Orderlist/Orderlist";
import UserRegistration from "./app/register/register";
import ProductRequest from "./app/inventory/ProductRequest/ProductRequest";
import InventoryOnboard from "./app/inventory/InventoryOnboard/InventoryOnboard";
import Profile from "./app/settings/profile/Profile";
import Setting from "./app/settings/usermanagement/Setting";
import Sidebar from "./components/SideBar/sidebar";
import Inventory from "./app/inventory/Inventory/inventory";
import Bdo from "./app/settings/Bdo/Bdo";
import shippingCost from "./app/settings/shippingCost/shippingCost";
import "./styles.css"
import ShippingCost from "./app/settings/shippingCost/shippingCost";
import Deposited from "./components/EordersDeliveryPartners/Deposited";
import DeliveryBoyOrders from "./components/EordersDeliveryPartners/DeliveryBoyOrders";
import SessionHistory from "./app/SessionHistory/sessionHistory";
import AddBill from "./app/purchase/addBill";
import SupplyPayment from "./app/purchase/SupplyPayment";
import PurchaseList from "./app/purchase/PurchaseList";
import PurchaseOrder from "./app/purchase/PurchaseOrder";

// Import components or containers for each route

export const routes = {
  home: "/",
  login: "/login",
  signup: "/signup",
  forgotPassword: "/forgot-password",
  orders: "/orders",
  register: "/register",
  settings: {
    userManagement: "/settings/usermanagement",
    shippingCost: "/settings/shippingcost",
    profile: "/settings/profile",
    bdo: "/settings/bdo",
  },
  orderlist: "/Orderlist",
  inventory: {
    root: "/inventory",
    // highOnInventory: "/inventory/Highoninventory",
    // lowOnInventory: "/inventory/Lowoninventory",
    // outOfStock: "/inventory/Outofstock",
    inventoryOnboard: "/inventory/InventoryOnboard",
    productRequest: "/inventory/ProductRequest",
  },
  purchase: {
    addBill: "/purchase/add-bill",
    supplyPayment: "/purchase/supplyPayment",
    purchaseList: "/purchase/list",
    purchaseOrder: "/purchase/list/order",
  },
  dashboard: "/dashboard",
  deliveryPartner: "/deliverypartner",
  deliveryPartnerOrder: "/deliveryPartner/Order",
  mySubscription: "/mysubscription",
  sessionHistory: "/pos/sessionHistory",
};

// const PrivateRoute = ({ children }) => {
//   const { user } = useSelector((state) => state?.login);

//   return user?.access_token ? (
//     <div className="flex " style={{ width: '100vw', height: '100vh' }}>
//       <Sidebar />
//       {/* <Navbar /> */}
//      <div className="w-full overflow-y-auto custom-scrollbar bg-[#FFFFFF] ">{children}</div> 

//     </div>
//   ) : (
//     <Navigate to="/login" />
//   );
// };

const PrivateRoute = ({ children }) => {
  const { user } = useSelector((state) => state?.login);

  if (!user?.result?.access_token) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="flex" style={{ width: '100vw', height: '100vh' }}>
      <Sidebar />
      <div className="w-full overflow-y-auto custom-scrollbar bg-[#FFFFFF]">
      <Navbar/>
      {children}
      </div>
    </div>
  );
};

const LoginExist = ({ children }) => {
  const { user } = useSelector((state) => state?.login);

  // return user ? children : <Login />;
  return !user ? children : <Navigate to="/orders" />;
};

const ProjectRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* Nested Routing for Settings */}
        <Route
          path={routes.settings.userManagement}
          element={
            <PrivateRoute>
              <Setting />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.settings.shippingCost}
          element={
            <PrivateRoute>
              <ShippingCost />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.settings.profile}
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.settings.bdo}
          element={
            <PrivateRoute>
              <Bdo />
            </PrivateRoute>
          }
        />

        {/* Add more nested routes for settings as needed */}

        {/* Nested Routing for Inventory */}
        <Route
          path={routes.inventory.root}
          element={
            <PrivateRoute>
              <Inventory />
            </PrivateRoute>
          }
        />
        {/* <Route
          path={routes.inventory.highOnInventory}
          element={
            <PrivateRoute>
              <HighOnInventory />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.inventory.lowOnInventory}
          element={
            <PrivateRoute>
              <LowOnInventory />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.inventory.outOfStock}
          element={
            <PrivateRoute>
              <StockOut />
            </PrivateRoute>
          }
        /> */}
        <Route
          path={routes.inventory.inventoryOnboard}
          element={
            <PrivateRoute>
              <InventoryOnboard />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.inventory.productRequest}
          element={
            <PrivateRoute>
              <ProductRequest />
            </PrivateRoute>
          }
        />
        {/* Add more nested routes for inventory as needed */}

        {/* Other routes */}
        <Route
          path={routes.home}
          element={
            <LoginExist>
              <Login />
            </LoginExist>
          }
        />
        <Route path={routes.login} element={<Login />} />
        <Route path={routes.signup} element={<Signup />} />
        <Route path={routes.forgotPassword} element={<ForgotPassword />} />
        <Route path={routes.register} element={<UserRegistration />} />
        <Route
          path={routes.orders}
          element={
            <PrivateRoute>
              <Orderpage />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.orderlist}
          element={
            <PrivateRoute>
              <Orderlist />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.dashboard}
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.deliveryPartner}
          element={
            <PrivateRoute>
              <DeliveryPartner />
            </PrivateRoute>
          }
        />
         <Route
          path={routes.deliveryPartnerOrder}
          element={
            <PrivateRoute>
              <DeliveryBoyOrders />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.mySubscription}
          element={
            <PrivateRoute>
              <Mysubscription />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.purchase.addBill}
          element={
            <PrivateRoute>
              <AddBill />
            </PrivateRoute>
          }
        />
         <Route
          path={routes.purchase.supplyPayment}
          element={
            <PrivateRoute>
              <SupplyPayment />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.purchase.purchaseList}
          element={
            <PrivateRoute>
              <PurchaseList />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.purchase.purchaseOrder}
          element={
            <PrivateRoute>
              <PurchaseOrder/>
            </PrivateRoute>
          }
        />
        <Route
          path={routes.sessionHistory}
          element={
            <PrivateRoute>
              <SessionHistory />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default ProjectRoutes;
