import BasicDetails from "../../components/Register/BasicDetails";
import BusinessProof from "../../components/Register/BusinessProof";
import ProfileCreation from "../../components/Register/ProfileCreation";
import RegistrationSideBar from "../../components/Register/RegistrationSideBar";
import ShopLocation from "../../components/Register/ShopLocation";
import Arrow from "../../assets/Register/arrowleft.svg";
import { Image } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { routes } from "../../Routes";
import Notification from "../../modals/Notification";
import { userSignup } from "../../components/Actions/userActions";
import CompleteProfile from "../../modals/CompleteProfile";
import { useNavigate } from "react-router";
import AlreadyRegisteredModal from "../../modals/AlreadyRegisteredmodal";
import { useTranslation } from "react-i18next";
import "../../styles.css";
import { loginUser } from "../../Redux/Action/loginAction";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";

const UserRegistration = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [data, setretailerInfo] = useState("");
  const [mobile, setuserPhone] = useState("");
  const [existemail, setexistemail] = useState(false);
  const [currentpage, setcurrentpage] = useState(0);

  useEffect(() => {
    const retailerInfo = localStorage.getItem("retailer_info");
    const userPhone = localStorage.getItem("UserPhone");
    setretailerInfo(retailerInfo ? JSON.parse(retailerInfo) : "");
    setuserPhone(userPhone || "");
    setcurrentpage(Number(localStorage?.getItem("registrationComponent")));
  }, []);

  // const data =
  //   localStorage?.getItem("retailer_info") != "undefined"
  //     ? JSON.parse(localStorage?.getItem("retailer_info"))
  //     : "";
  // const mobile = localStorage?.getItem("UserPhone");

  const dispatch = useDispatch();

  //Use States
  const [component, setComponent] = useState(() => currentpage || 0);
  const [shopLocationData, setshopLocationData] = useState("");
  const [BasicDetailData, setsBasicDetailData] = useState("");
  const [BusinessProofData, setBusinessProofData] = useState("");
  const [showNotification, setshowNotification] = useState(false);
  const [showNotificationMessage, setshowNotificationMessage] = useState("");
  const [showCompleteModal, setShowCompleteModal] = useState(false); // State for modal visibility
  const [message, setmessage] = useState(0);

  useEffect(() => {
    localStorage.setItem("registrationComponent", component);
  }, [component]);

  // const backgroundlogin=async(loginpayload)=>{

  //   setTimeout(async () => {
  //     try {
  //       const userData = await dispatch(loginUser(loginpayload));
  //       console.log("login user", userData);

  //       // Check if the response contains an error message
  //       if (
  //         (userData && userData.error) ||
  //         userData.payload.message.includes("Invalid")
  //       ) {
  //         // Display error message for invalid credentials
  //         alert("Invalid email/phone or password. Please try again.");
  //       } else {
  //         //setUserDetail Check if access token and user ID are the same
  //         if (userData && userData.access_token === userData.user_id) {
  //           // Redirect to the orders page
  //           const userDetail = {
  //             access_token: userData.payload.token,
  //             user_id: userData.payload.userDetails.user_id,
  //           };
  //           console.log("after login", userData);
  //           localStorage.setItem("user_detail", JSON.stringify(userDetail));
  //           localStorage.setItem(
  //             "retailer_info",
  //             JSON.stringify(userData.payload.RetailerDetails[0])
  //           );
  //           localStorage.setItem("isAuthenticated", true);
  //           router.push(routes.Profile);
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error occurred during login:", error);
  //       // Handle other errors, such as network issues
  //     }
  //   }, 2000); // 5000 milliseconds = 5 seconds

  // }

  const handleDataSubmit = (dataType, Data) => {
    console.log(dataType, Data);
    if (dataType === "shopLocationData") {
      // const response = dispatch(userSignup(Data));
      // response.then((result) => {
      //   if (
      //     result.payload.status == 201 &&
      //     result.payload.message == "Retailer Record not found in the system."
      //   ) {
      //     setshowNotification(true);
      //     setshowNotificationMessage(t("login_exist"));

      //     // router.push(routes.login);
      //   } else {
      localStorage?.setItem("shopdata", JSON.stringify(Data));
      setshopLocationData(Data);
      setComponent(component + 1);
      //   }
      // });
    } else if (dataType === "basicDetailsData") {
      const response = dispatch(userSignup(Data));
      response
        .then((result) => {
          if (
            result.payload.result.status ==
            "With Email Retailer Already Registered"
          ) {
            //setshowNotification(true);
            setshowNotificationMessage(t("email_already_registered"));
            setexistemail(true);
            // router.push(routes.login);
          } else {
            localStorage?.setItem("shopdata", JSON.stringify(Data));
            setshopLocationData(Data);
            setComponent(component + 1);
            console.log("RESPONSE OF BASICDETAILS", result);
          }
          if (
            result.payload.result.status == "Retailer Register Successfully"
          ) {
            const loginData = {
              login: result.payload.result.login.toLowerCase(),
              password: result.payload.result.password,
            };

            // Call the login action after successful registration
            dispatch(loginUser(loginData.login, loginData.password))
              .then((loginResult) => {
                if (loginResult?.error) {
                  console.error("Login failed:", loginResult.error);
                  // Optionally, show a notification or message to the user
                  setshowNotificationMessage(t("login_failed"));
                } else if (
                  loginResult?.data?.result?.status === "Login Successfully"
                ) {
                  // Handle successful login
                  setmessage(1);
                  setShowCompleteModal(true);
                  setsBasicDetailData(Data);
                  setComponent(component + 1);
                }
              })
              .catch((loginError) => {
                console.error("Error during login:", loginError);
              });
          }

          // localStorage?.setItem("user_id", result.payload.user_id);
          // localStorage?.setItem("store_id", result.payload.store_id);

          //backgroundlogin(payloadLogin)
          //router.push(routes.login)
          // setmessage(1);
          // setShowCompleteModal(true);
          // setsBasicDetailData(Data);

          // setComponent(component + 1);
          console.log("usercreation", JSON.stringify(Data));
          localStorage?.setItem("basicdata", JSON.stringify(Data));
          console.log(result);
        })
        .catch((error) => {
          console.error(error);
        });
      // router.push(routes.Orders)
    } else if (dataType === "businessProofData") {
      if (
        Data?.bussiness_proof?.[0]?.name === "" &&
        Data?.bussiness_proof?.[1]?.name === "" &&
        Data?.bussiness_proof?.[2]?.name === ""
      ) {
        setComponent(component + 1);
      } else {
        const response = axiosInstance
          .post(URLS.UPDATE_RETAILER_DETAILS, Data)
          .then((result) => {
            console.log("REDDEEEEEE", result);

            if (result.data.result.status === "Update Successfully") {
              console.log("businessproof data", Data);
              setBusinessProofData(Data.bussiness_proof);
              setComponent(component + 1);
            }
            localStorage.setItem("businessproofdata", JSON.stringify(Data));
            console.log(result);
          })
          .catch((error) => {
            console.error("There was an error making the request:", error);
          });
      }
    } else if (dataType === "profile_creation_data") {
      console.log("PPPEP", Data);

      const validUrls = Data?.image_urls.filter(
        (url) => url && url !== "undefined" && url.trim() !== ""
      ); 

      if (!validUrls || validUrls.length === 0) {
        setmessage(0);
        setShowCompleteModal(true);
      } else {
        const payload = {
          company_id: Data.company_id,
          image_urls: validUrls,
        };

        axiosInstance
          .post(URLS.UPLOAD_IMAGE_URLS, payload)

          .then((result) => {
            console.log("result", result.data.result[0].status);
            if (
              result.data.result[0].status ===
              "Images Urls Uploaded Successfully"
            ) {
              console.log("result", result);
              setShowCompleteModal(true);
              setmessage(0);

              // Optionally, you can handle post-upload actions here
              // let payloadLogin = {
              //   mobile: Data.mobile,
              //   password: Data.password
              // }
              // backgroundlogin(payloadLogin)
              // router.push(routes.login)
            }
          })
          .catch((error) => {
            console.error("Error uploading images:", error);
            // Handle error appropriately, e.g., show a message to the user
          });
      }
    }
  };

  const CurrentStep = [
    <ShopLocation
      onDataSubmit={handleDataSubmit}
      data={data}
      mobile={mobile}
      savedata={shopLocationData}
      showNotificationMessage={showNotificationMessage}
    />,
    <BasicDetails
      onDataSubmit={handleDataSubmit}
      data={data}
      mobile={mobile}
      shopLocationData={shopLocationData}
      savedata={BasicDetailData}
      showNotificationMessage={showNotificationMessage}
      email={existemail}
    />,
    <BusinessProof
      onDataSubmit={handleDataSubmit}
      data={data}
      savedata={BusinessProofData}
    />,
    <ProfileCreation onDataSubmit={handleDataSubmit} data={data} />,
  ];

  //Function calls when back button is clicked
  const handleBackClick = () => {
    setComponent((currComponent) => Math.max(currComponent - 1, 0));
  };
  //Function calls when skip button is clicked
  const handleskip = () => {
    console.log("skip called");
    navigate(routes.login);
  };

  return (
    <div className="flex bg-white min-h-screen custom-scrollbar">
      <div className="w-full md:w-1/4 fixed h-full z-10 bg-secondaryBlue">
        <RegistrationSideBar currentStep={component} />
      </div>
      <div className="w-full md:w-3/4 h-full relative mx-14 mt-16 ms-[29%] bg-white">
        <div className="flex flex-row justify-between ">
          {component > 0 && component !== 2 && (
            <>
              <p
                className={`font-bold text-sm text-[#5C5C5C] relative bottom-8 flex items-center gap-3 cursor-pointer float-left ${
                  component === 0 ? "ml-auto" : "" // Add ml-auto (margin-left: auto) when on Shop Location page
                }`}
                onClick={handleBackClick}
              >
                <img className="w-6" src={Arrow} alt="arrow" />
                {t("back_to_previous")}{" "}
              </p>
            </>
          )}
          <div className="flex-grow">
            {component > 1 && (
              <p
                className={`font-bold text-sm text-[#5C5C5C] relative flex items-center gap-3 cursor-pointer bottom-8 float-right ml-auto`}
                style={{ right: 5 }}
                onClick={handleskip}
              >
                {t("skip")}
              </p>
            )}
          </div>
          {/* {localStorage?.getItem("store_id") && (<p
    className={`font-bold text-sm text-[#5C5C5C] relative bottom-8 flex items-center gap-3 cursor-pointer ${
      component === 0 ? 'ml-auto' : '' // Add ml-auto (margin-left: auto) when on Shop Location page
    }`}
    onClick={
      setComponent(component + 1)}
  >
    
   Next{" "}
  </p>)} */}
          {showNotification && (
            <AlreadyRegisteredModal
              message={showNotificationMessage}
              // duration={2000}
              // onClose={() => setshowNotification(false)}
            />
          )}
          {showCompleteModal && (
            <CompleteProfile
              onClose={() => setShowCompleteModal(false)}
              message={message}
            />
          )}
        </div>

        {CurrentStep[component]}
      </div>
    </div>
  );
};

export default UserRegistration;
