import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";

export const loginUserRequest = () => ({ type: "LOGIN_USER_REQUEST" });
export const loginUserSuccess = (user) => ({
  type: "LOGIN_USER_SUCCESS",
  payload: user,
});
export const loginUserFailure = (error) => ({
  type: "LOGIN_USER_FAILURE",
  payload: error,
});
export const logoutUserSuccess = () => ({
  type: "LOGOUT_USER_SUCCESS",
  payload: null,
});

// export const loginUser = (login, password) => async (dispatch) => {
//   dispatch(loginUserRequest());
//   try {
//     const response = await axiosInstance.post(URLS.LOGIN_API_URL, {
//       email: login,
//       password: password,
//     });
       
//    if(response?.data) dispatch(loginUserSuccess(response?.data));
//     return response
//   } catch (error) {
//     dispatch(loginUserFailure(error.message));
//     return error
//   }
// };

export const loginUser  = (login, password) => async (dispatch) => {
  dispatch(loginUserRequest());
  try {
    // Check if login is a 10-digit number
    const isMobile = /^\d{10}$/.test(login); // Regular expression to check for 10 digits

    const response = await axiosInstance.post(URLS.LOGIN_API_URL, {
      [isMobile ? 'mobile' : 'email']: login, // Use 'mobile' if it's a 10-digit number, otherwise use 'email'
      password: password,
    });

    if (response?.data) dispatch(loginUserSuccess(response?.data));
    return response;
  } catch (error) {
    dispatch(loginUserFailure(error.message));
    return error;
  }
};

export const logoutUser = () => async (dispatch) => {
  console.log("logout action called")
dispatch(logoutUserSuccess());

};

// import axiosInstance from "../../AxiosInstance";
// import { URLS } from "../../API/API endpoints";

// export const loginUserRequest = () => ({ type: "LOGIN_USER_REQUEST" });
// export const loginUserSuccess = (user) => ({
//   type: "LOGIN_USER_SUCCESS",
//   payload: user,
// });
// export const loginUserFailure = (error) => ({
//   type: "LOGIN_USER_FAILURE",
//   payload: error,
// });
// export const logoutUserSuccess = () => ({
//   type: "LOGOUT_USER_SUCCESS",
//   payload: null,
// });

// export const loginUser = (login, password) => async (dispatch) => {
//   console.log("MOO", login);
//   dispatch(loginUserRequest());
  
//   try {
//     const normalizedLogin = login.trim();
//     let payload;

//     if (/^\d{10}$/.test(normalizedLogin)) {
//       payload = {
//         mobile: normalizedLogin.replace(/^0+/, ''), // Remove leading zeros
//         password: password,
//       };
//     } else {
//       // Otherwise, treat it as an email
//       payload = {
//         email: normalizedLogin.toLowerCase(),
//         password: password,
//       };
//     }

//     const response = await axiosInstance.post(URLS.LOGIN_API_URL, payload);

//     if(response?.data) dispatch(loginUserSuccess(response?.data));
//     return response
//   } catch (error) {
//     dispatch(loginUserFailure(error.message));
//     return error
//   }
// };

// export const logoutUser = () => async (dispatch) => {
//   console.log("logout action called")
// dispatch(logoutUserSuccess());

// };

