import React from "react";
import { useFormik } from "formik";
import { InputBox } from "../CustomWidgets/InputBox";
import UploadMedia from "../upload-media";
import Button from "../CustomWidgets/Button";
import { BusinessProofSchema } from "../validationSchema";
import { useTranslation } from "react-i18next";
import { Image } from "antd";
import { useSelector } from "react-redux";

function BusinessProof({ onDataSubmit, savedata }) {
  const { t, i18n } = useTranslation();
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const formik = useFormik({
    initialValues: {
      commercialregistrynumber: savedata ? savedata[0]?.name : "",
      commercialregistrynumberurl: savedata ? savedata[0]?.url : "",

      taxcardnumber: savedata ? savedata[1]?.name : "",
      taxcardnumberurl: savedata ? savedata[1]?.url : "",

      managerid: savedata ? savedata[2]?.name : "",
      manageridurl: savedata ? savedata[2]?.url : "",
    },
    validationSchema: BusinessProofSchema,

    onSubmit: (values) => {
      const api_payload = {
        user_id: user_id,
        manager_id: values.managerid,
        tax_card_number: values.taxcardnumber,
        company_registry: values.commercialregistrynumber,
      };

      onDataSubmit("businessProofData", api_payload);
    },
  });

  const handleurlgenerated = (values) => {
    formik.setFieldValue(values.name, values.url);
  };

  return (
    <>
      <div>
        {/* <div className=""> */}
        <h1 className="text-xl font-bold">Business Proof</h1>
        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-2 mt--4 text-sm gap-x-7 pb-12">
            <div>
              <InputBox
                label={t("commercial_registry_number")}
                name="commercialregistrynumber"
                placeholder={t("enter_commercial_registry_number")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.commercialregistrynumber}
                error={
                  (formik.values.commercialregistrynumberurl !== "" &&
                    formik.values.commercialregistrynumber === "") ||
                  formik.errors.commercialregistrynumber
                }
                touched={
                  (formik.values.commercialregistrynumberurl !== "" &&
                    formik.values.commercialregistrynumber === "") ||
                  formik.errors.commercialregistrynumber
                }
              />
              {formik.errors.commercialregistrynumber && (
                <div className="text-red text-xs">
                  {formik.errors.commercialregistrynumber}
                </div>
              )}
              <div className="w-[363px] md:w-full mt-2">
                <div className=" rounded-lg border border-dashed border-primaryBlue px-2  py-3">
                  <div
                    className="flex flex-row justify-start gap-x-4 "
                    style={{ minHeight: 70, maxHeight: 70 }}
                  >
                    {formik.values?.commercialregistrynumberurl != "" ? (
                       <a
                       href={formik.values.commercialregistrynumberurl}
                       target="_blank"
                       rel="noopener noreferrer"
                       className="text-blue-500 underline ms-5 mt-2"
                     >
                    
                      <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 ms-5 mt-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m-3-3H8m4-4V4m0 0H4a2 2 0 00-2 2v16a2 2 0 002 2h16a2 2 0 002-2V8l-6-6H8z" />
                    </svg>
                    <span className=" text-sm">
                    {t("View File")}
                    </span>
                    </a>
                    ) : null}
                  

                    <div class="flex flex-row text-sm leading-6 pl-24 text-gray-600">
                      <label
                        for="file-upload"
                        class="flex items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                      >
                        <UploadMedia
                          foldername={"store_app_UAT"}
                          onfileAdd={"commercialregistrynumberurl"}
                          urlgenerated={handleurlgenerated}
                          imageurl={formik.values.commercialregistrynumberurl}
                          message={(message) => !message}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            
            </div>

            <div>
              <InputBox
                label={t("taxcard_number")}
                name="taxcardnumber"
                placeholder={t("enter_taxcard_number")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.taxcardnumber}
                error={
                  (formik.values.taxcardnumberurl !== "" &&
                    formik.values.taxcardnumber === "") ||
                  formik.errors.taxcardnumber
                }
                touched={
                  (formik.values.taxcardnumberurl !== "" &&
                    formik.values.taxcardnumber === "") ||
                  formik.errors.taxcardnumber
                }
              />
              {formik.errors.taxcardnumber && (
                <div className="text-red text-xs">
                  {formik.errors.taxcardnumber}{" "}
                </div>
              )}
              <div className="w-[363px] md:w-full mt-2">
                <div className=" rounded-lg border border-dashed border-primaryBlue px-2  py-3">
                  <div
                    className="flex flex-row justify-start gap-x-4"
                    style={{ minHeight: 70, maxHeight: 70 }}
                  >
                    {formik.values?.taxcardnumberurl != "" ? (
                      <a
                      href={formik.values.taxcardnumberurl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline ms-5 mt-2"
                    >
                   
                     <svg
                     xmlns="http://www.w3.org/2000/svg"
                     className="h-6 w-6 ms-5 mt-2"
                     fill="none"
                     viewBox="0 0 24 24"
                     stroke="currentColor"
                   >
                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m-3-3H8m4-4V4m0 0H4a2 2 0 00-2 2v16a2 2 0 002 2h16a2 2 0 002-2V8l-6-6H8z" />
                   </svg>
                   <span className=" text-sm">
                   View File
                   </span>
                   </a>
                   ) : null}

                    <div class="flex flex-row text-sm leading-6 pl-24 text-gray-600">
                      <label
                        for="file-upload"
                        class="flex items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                      >
                        <UploadMedia
                          foldername={"store_app_UAT"}
                          onfileAdd={"taxcardnumberurl"}
                          urlgenerated={handleurlgenerated}
                          imageurl={formik.values.taxcardnumberurl}
                          message={(message) => !message}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <InputBox
                label={t("managerid")}
                name="managerid"
                placeholder={t("enter_nationalid")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.managerid}
                error={
                  (formik.values.manageridurl !== "" &&
                    formik.values.managerid === "") ||
                  formik.errors.managerid
                }
                touched={
                  (formik.values.manageridurl !== "" &&
                    formik.values.managerid === "") ||
                  formik.errors.managerid
                }
              />
              {formik.errors.managerid && (
                <div className="text-red text-xs">
                  {formik.errors.managerid}
                </div>
              )}
              <div className="w-[363px] md:w-full mt-2">
                <div className=" rounded-lg border border-dashed border-primaryBlue px-2  py-3">
                  <div
                    className="flex flex-row justify-start gap-x-4"
                    style={{ minHeight: 70, maxHeight: 70 }}
                  >
                    {formik.values?.manageridurl != "" ? (
                       <a
                       href={formik.values.manageridurl}
                       target="_blank"
                       rel="noopener noreferrer"
                       className="text-blue-500 underline ms-5 mt-2"
                     >
                    
                      <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 ms-5 mt-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m-3-3H8m4-4V4m0 0H4a2 2 0 00-2 2v16a2 2 0 002 2h16a2 2 0 002-2V8l-6-6H8z" />
                    </svg>
                    <span className=" text-sm">
                    {t("View File")}
                    </span>
                    </a>
                    ) : null}

                    <div class="flex flex-row text-sm leading-6 pl-24 text-gray-600">
                      <label
                        for="file-upload"
                        class="flex items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                      >
                        <UploadMedia
                          foldername={"store_app_UAT"}
                          onfileAdd={"manageridurl"}
                          urlgenerated={handleurlgenerated}
                          imageurl={formik.values.manageridurl}
                          message={(message) => !message}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className={`absolute right-0 bottom-0 w-[185px] h-[45px] ${
              (formik.values.manageridurl !== "" &&
                formik.values.managerid === "") ||
              (formik.values.taxcardnumberurl !== "" &&
                formik.values.taxcardnumber === "") ||
              (formik.values.commercialregistrynumberurl !== "" &&
                formik.values.commercialregistrynumber === "") ||
              formik.errors.managerid ||
              formik.errors.taxcardnumber ||
              formik.errors.commercialregistrynumber
                ? "bg-primaryBluedisabled"
                : "bg-primaryBlue"
            }
            }  text-white rounded-lg font-semibold float-right `}
            disabled={
              (formik.values.manageridurl !== "" &&
                formik.values.managerid === "") ||
              (formik.values.taxcardnumberurl !== "" &&
                formik.values.taxcardnumber === "") ||
              (formik.values.commercialregistrynumberurl !== "" &&
                formik.values.commercialregistrynumber === "")
            }
          >
            {" "}
            {t("next")}
          </button>
        </form>
      </div>
    </>
  );
}

export default BusinessProof;
