import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import "tailwindcss/tailwind.css";
import CustomPagination from "../CustomWidgets/CustomPagination";
import Vectorsorting from "../../assets/Vectorsorting.svg";
import { Empty } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Image } from "antd";
import arrowright from "../../assets/arrowright.svg";
import "../../styles.css";
import { useNavigate } from "react-router";
import ShimmerLoader from "../../loading";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ReadyforDeliverytable = ({
  user_id,
  store_id,
  tabledata,
  pagination,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [flag, setflag] = useState(false);
  const is_lahloob = useSelector((state) => state.login?.user?.result?.is_lahloob);


  useEffect(() => {
    if (tabledata?.length === 0 && !flag) {
      const timer = setTimeout(() => {
        setflag(true);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [tabledata, flag]);

  useEffect(() => {
    if (tabledata) console.log(tabledata);
  }, [tabledata]);

  const data = useMemo(() => tabledata || [], [tabledata]);

  const openOrderList = (orderNumber) => {
    console.log("order_number",orderNumber);
    
    navigate(`/Orderlist?orderDetail=${orderNumber}`);
    // navigate(`/Orderlist`, { state: { orderDetail: rowData } });
  }


  const columns = useMemo(
    () => [
      {
        Header: t("order_no"),
        accessor: "order_details.order_no",
        Cell: ({ value }) => (
          <>
            {/* {(value.substring(value.lastIndexOf(",") + 1) == "Order Pending" ||
            value.substring(value.lastIndexOf(",") + 1) == "In Processing" ||
            value.substring(value.lastIndexOf(",") + 1) ==
              "Ready For Delivery") && ( */}
            <button
              // className="bg-[#7EC242] text-white w-28 h-10 rounded-lg"
              className=" text-[#1b4497] rounded-lg"
              onClick={() => openOrderList(value)}
              // onClick={() => openOrderList("ordernamehar" + value)}
            >
              {value}
            </button>
            {/* )
          } */}
          </>
        ),
      },
      { Header: t("customer_name"), accessor: "order_details.partner_name" },
      // {
      //   Header: t("delivery_schedule"),
      //   accessor: "order_details.delivery_schedule",
      // },
      ...(is_lahloob ? [] : [
        {
          Header: t("delivery_schedule"),
          accessor: "order_details.delivery_schedule",
        }
      ]),
      {
        Header: t("items"),
        // accessor: "order_line_details",
        accessor: (row) =>
          (row.existing_items?.length || 0) + (row.new_items?.length || 0),
        cell: ({ value }) => value || 0,
      },
      {
        Header: t("order_value"),
        accessor: "order_details.total",
        Cell: ({ value }) => `EGP ${value}`,
      },
      // { Header: t("order_value"), accessor: "order_details.total" },
      // { Header: t("payment"), accessor: "payment_mode" },
      // {
      //  Header: t('order_status'),
      //   accessor: "order_stage.name",
      //   Cell: ({ value }) => (
      //     <div
      //       className={`${
      //         value == "Ready For Delivery"
      //           ? "bg-[#D1FFDE] text-[#00B833]"
      //           : "bg-[#E0F0FF] text-primaryBlue"
      //       } w-36 h-9 rounded-3xl py-2 inline-block`}
      //     >
      //       {value.substring(0, 5)}
      //     </div>
      //   ),
      // },
      {
        id: "action",
        Header: "",
        accessor: (row) => `${row.order_number}`,
        Cell: ({ row }) => (
          <>
            {/* {(value.substring(value.lastIndexOf(",") + 1) == "Order Pending" ||
              value.substring(value.lastIndexOf(",") + 1) == "In Processing" ||
              value.substring(value.lastIndexOf(",") + 1) ==
                "Ready For Delivery") && ( */}
            <button
              // className="bg-[#7EC242] text-white w-28 h-10 rounded-lg"
              className=" text-[#1b4497] rounded-lg"
              onClick={() => openOrderList(row.original.order_details.order_no)} 
              // onClick={() => openOrderList("ordernamehar" + value)}
            >
              <img
                src={arrowright}
                className={`${i18n.language == "en" ? "" : " rotate-180"}`}
                alt="arrowright"
                height={3}
                width={20}
              />{" "}
              {/* Open Order <ArrowRightOutlined /> */}
            </button>
            {/* )
            } */}
          </>
        ),
      },
      //   {
      //     Header: "",
      //     accessor: "actions",
      //     Cell: () => (
      //       <div className="flex justify-end">
      //         <button
      //           className="bg-[#7EC242] text-white px-4 py-2 rounded-lg mr-4 -ml-12"
      //           onClick={() => alert("Open Order button clicked")}
      //         >
      //           request Pickup
      //         </button>
      //       </div>
      //     ),
      //   },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data: tabledata,
      initialState: { pageSize: 10 },
    },
    useSortBy,
    usePagination
  );
  const [pageIndex, setpageIndex] = useState(1);
  useEffect(() => {
    const pageDetails = {
      limit: pageSize,
      offset: pageSize * (pageIndex - 1) < 0 ? 0 : pageSize * (pageIndex - 1),
    };
    pagination(pageDetails);
  }, [pageIndex, pageSize, previousPage, nextPage]);

  return (
    <div className="container mx-auto float-left" style={{ maxWidth: "100%" }}>
      {tabledata?.length == 0 && flag == false ? (
        <>
          <ShimmerLoader />{" "}
          {/* {setTimeout(() => {
              setflag(true);
            }, 3000)} */}
        </>
      ) : (
        <table
          {...getTableProps()}
          className="table-auto mx-2 ml-3 mt-7 font-normal  sm:w-[97.6%] md:w-[97.6%] lg:w-[97.6%] xl:w-[97.6%]  shadow-xl rounded-lg overflow-hidden"
          style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }} 
        >
          <thead>
            {headerGroups.map((headerGroup, headerIndex) => {
              const { key: headerKey, ...headerGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr
                  key={headerKey}
                  {...headerGroupProps}
                  className="bg-[#78CFEC66] text-sm font-semibold"
                >
                  {headerGroup.headers.map((column, colIndex) => {
                    const { key: columnKey, ...columnProps } =
                      column.getHeaderProps(column.getSortByToggleProps());
                    return (
                      <th
                        key={columnKey}
                        {...columnProps}
                        className="py-3 px-8 whitespace-nowrap "
                        style={{
                          textAlign: i18n.language === "ar" ? "right" : "left",  // <-- Add this line
                        }}
                      >
                        <div className="flex items-center justify-start">
                          {column.render("Header")}
                          <span className="">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <img
                                  src={Vectorsorting}
                                  style={{ minHeight: 10, minWidth: 10 }}
                                />
                              ) : (
                                <img
                                  className="rotate-180"
                                  src={Vectorsorting}
                                  style={{ minHeight: 10, minWidth: 10 }}
                                />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()} className="bg-[#F7F9FD] text-center">
            {page.map((row) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.id}>
                  <tr
                    {...row.getRowProps()}
                    className="relative hover:bg-white transition duration-300 border-b border-gray-300 fixed-row-height "
                  >
                    {row.cells.map((cell, index) => (
                      <td
                        {...cell.getCellProps()}
                        className={`p-2 px-8 text-[#272728] h-12 text-sm font-normal ${
                          index < row.cells.length - 1 ? "" : ""
                        }`}
                        style={{
                          textAlign: i18n.language === "ar" ? "right" : "left",  // <-- Add this line
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                </React.Fragment>
              );
            })}
            {/* Empty state row */}
            {page.length === 0 && (
              <tr>
                <td colSpan={columns.length}>
                  <Empty description="No data available" />
                </td>
              </tr>
            )}
            <tr>
              {" "}
              {
                <td className="" colSpan={columns.length}>
                  <CustomPagination
                    data={tabledata?.length}
                    pageIndex={pageIndex}
                    pageCount={page.length}
                    canPreviousPage={pageIndex != 1 ? true : false}
                    canNextPage={tabledata?.length >= 10 ? true : false}
                    gotoPage={(page) => {
                      nextPage(page);
                    }}
                    previousPage={() => {
                      setpageIndex(pageIndex - 1);
                    }}
                    nextPage={() => {
                      setpageIndex(pageIndex + 1);
                    }}
                    pageSize={pageSize}
                    setPageSize={(pageSize) => {
                      // nextPage(0);

                      setPageSize(pageSize);
                    }}
                  />
                </td>
              }
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ReadyforDeliverytable;
