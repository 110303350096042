import React, { useState, useEffect } from "react";
import Sidebar from "../../components/SideBar/sidebar";
import Navbar from "../../components/Navbar/Navbar";
import { Breadcrumb, Button } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { Image } from "antd";
import ProcessDone from "../../components/Orders/ProcessDone";
import CancelOrder from "../../modals/cancelorder";
import {
  acceptOrder,
  DeliverOrder,
  GetBillDetails,
  getdata,
  LahloobprocessOrder,
  LahloobupdateOrder,
  orderHandover,
  processOrder,
  quantityUpdate,
  updateOrder,
  updateorderStage,
} from "../../API  Functions/OrderlistFunction";
import OrdercustomerDetails from "../../components/Orders/OrdercustomerDetails";
import {
  htmlContent,
  getdatahtml,
} from "../../components/orderlist/printorderlist";
import ShimmerLoader from "../../loading";
import "../../styles.css";
import Cancelledpopup from "../../modals/Cancelledpopup";
import PaymentInfo from "../../components/Orders/PaymentInfo";
import Orderdeliveredpopup from "../../modals/Orderdeliveredpopup";
import deleteicon from "../../assets/Order/Delete.svg";
import { searchdeliverypartner } from "../../API  Functions/DeliveryPartnerFunctions";
import DeliveryBoyDetails from "../../modals/DeliveryBoyDetails";
import AssignDeliveryBoypopup from "../../modals/AssignDeliveryBoypopup";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../../Routes";
import weight_icon from "../../assets/Order/weight_icon.svg";
import ReturnCancelOrder from "../../modals/ReturnCancelOrder";
import SuccessModal from "../../modals/Sucessmodal";
import CompleteSuccess from "../../modals/CompleteSuccess";

const Orderlist = () => {
  const { t, i18n } = useTranslation();
  const store_data = useSelector((state) => state.login?.user);
  // console.log("store_data", store_data);
  // const user_id = useSelector((state) => state.login?.user?.user_id);
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const is_lahloob = useSelector(
    (state) => state.login?.user?.result?.is_lahloob
  );
  const store_id = useSelector(
    (state) => state.login?.user?.result?.company_id
  );

  const [showComponent, setShowComponent] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [isCancelOrderOpen, setCancelOrderOpen] = useState(false);
  const [isReturnCancelOrderOpen, setReturnCancelOrderOpen] = useState(false);
  const [Quantity, setQuantity] = useState(0);
  const [Error, setError] = useState(false);
  const [processButton, setprocessbutton] = useState(false);
  const [acceptbutton, setacceptbutton] = useState(false);
  const [loading, setloading] = useState(false);
  const [cancelledpopup, setcancelledpopup] = useState(false);
  const [deliveredpopup, setdeliveredpopup] = useState(false);
  const [zero, setzero] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [DeliveryPartners, setDeliveryPartners] = useState([]);
  const [popup, setpopup] = useState(false);
  const [searchid, setsearchid] = useState(localStorage.getItem("searchid"));
  const [draftquantites, setdraftquantities] = useState([]);
  const location = useLocation();
  const [orderDetail, setorderDetail] = useState({});
  const [data, setdata] = useState(orderDetail || {});
  const [billDetails, setBillDetails] = useState(null);
  const [ProdLoading, setProdLoading] = useState(true);
  const [canProcessOrder, setCanProcessOrder] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [SuccessPopup, setSuccessPopup] = useState(false);

  

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("orderDetail"))
      localStorage?.setItem("searchid", params.get("orderDetail"));
    const searchparam =
      params.get("orderDetail") || localStorage.getItem("searchid");
    setsearchid(searchparam);
    if (searchparam) {
      calldata(searchparam);
    }
  }, []);

  const calldata = (searchid) => {
    getdata(user_id, searchid, "", 1, 0).then((datagot) => {
      setloading(false);
      setdata(datagot);
      setorderDetail(datagot);
    });
  };

  useEffect(() => {
    if (
      orderDetail &&
      orderDetail.order_details &&
      orderDetail.order_details.order_id
    ) {
      const fetchBillDetails = async () => {
        try {
          const response = await GetBillDetails(
            orderDetail.order_details.order_id
          );

          setBillDetails(response?.data?.result[0]?.response);
        } catch (error) {
          console.error("Error fetching bill details:", error);
        }
      };

      fetchBillDetails();
    }
  }, [orderDetail]);

  const handleOpenCancelOrder = () => {
    setCancelOrderOpen(true);
  };

  const handleCloseCancelOrder = () => {
    setCancelOrderOpen(false);
  };
  const [orderedItems, setOrderedItems] = useState([]);

  useEffect(() => {
    const orderedItems =
      data?.existing_items?.filter((item) => item.product_uom_qty > 0) ?? [];
    {
      (data?.order_details?.order_status === "Order Pending" ||
        data?.order_details?.order_status === "In Processing") &&
        setOrderedItems(orderedItems);
      console.log("orderedItems", orderedItems);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.existing_items) {
      const initialQuantities = data.existing_items.map((item) => ({
        product_id: item.product_id,
        sell_price: item.price_unit,
        value: item.product_uom_qty,
      }));
      setdraftquantities(initialQuantities);
      setProdLoading(false);
    }
  }, [data]);

  const handleQuantityChange = (
    newQuantity,
    index,
    order_id,
    id,
    product_id
  ) => {
    setzero(true);

    if (newQuantity < 0) {
      newQuantity = 0;
    }

    const product = data.existing_items.find(
      (item) => item.product_id === product_id
    );
    const available_qty = product?.product_uom_qty;
    const sell_price = product?.price_unit;

    if (newQuantity > available_qty) {
      newQuantity = available_qty;
    }

    setdraftquantities((draftquantities) => {
      const existingIndex = draftquantities.findIndex(
        (item) => item.product_id === product_id
      );

      if (existingIndex !== -1) {
        return draftquantities.map((item, idx) =>
          idx === existingIndex
            ? {
                product_id: product_id,
                sell_price: sell_price,
                value: newQuantity,
              }
            : item
        );
      } else {
        if (newQuantity > 0) {
          return [
            ...draftquantities,
            {
              product_id: product_id,
              sell_price: sell_price,
              value: newQuantity,
            },
          ];
        }
        return draftquantities;
      }
    });
  };

  const handlePrintOrderList = () => {
    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.width = "0";
    iframe.style.height = "0";
    iframe.style.border = "none";

    document.body.appendChild(iframe);

    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write(getdatahtml(data));
    doc.close();

    iframe.contentWindow.focus();
    // iframe.contentWindow.print();

    setTimeout(() => {
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    }, 1000);
  };

  const handleAssignForDelivery = async () => {
    setIsModalVisible(true);
  };

  const handleHandover = async (order_id)=> {
     try { 
    
          const payload = {
            order_id: order_id,
          };
      
          const response = await orderHandover(payload);
      
        //   console.log("API Response:", response);
          if (response?.data?.result?.status === "success") {
            setSuccessPopup(true)
            setPopupMessage("Order Handedover Successfully")
            // popup();
          }
              } catch (error) {
          console.error("Error confirming return order:", error);
        }
      };
      
 

  useEffect(() => {
    const allZeroQuantities = draftquantites.every((item) => item.value === 0);
    setCanProcessOrder(!allZeroQuantities);
  }, [draftquantites]);

  console.log("Order Details", orderDetail);

  const processOrderActions = (orderId) => {
    // Make sure orderId is valid before proceeding
    if (!orderId) {
      setloading(false);
      console.error("Invalid order ID");
      return;
    }

    if (is_lahloob) {
      // Construct the payload for LAHLOOB_UPDATE_PICKER_ORDER
      const pickerPayload = {
        order_id: orderId,
        picker_name: JSON.stringify(user_id),
        lines: draftquantites.map((item) => ({
          product_id: item.product_id,
          pick_qty:
            item.value === undefined ? item.product_uom_qty : item.value,
          // pick_qty: item.pick_qty,
        })),
      };

      // Call the LAHLOOB_UPDATE_PICKER_ORDER API
      LahloobupdateOrder(pickerPayload)
        .then((response) => {
          // console.log("LAHLOOB_UPDATE_PICKER_ORDER", response);

          if (response?.status_code === 200) {
            const preparedPayload = {
              order_id: orderId,
            };

            // Call the LAHLOOB_ORDER_PREPARED API
            LahloobprocessOrder(preparedPayload)
              .then((response) => {
                // console.log("LAHLOOB_ORDER_PREPARED", response);

                if (response?.status_code === 200) {
                  calldata(searchid);
                  setloading(false); // Stop loading indicator
                } else {
                  setloading(false); // Stop loading even if LAHLOOB_ORDER_PREPARED fails
                  console.error("Failed to prepare Lahloob order", response);
                }
              })
              .catch((error) => {
                setloading(false); // Stop loading if LAHLOOB_ORDER_PREPARED fails
                console.error("Error during LAHLOOB_ORDER_PREPARED:", error);
              });
          } else {
            setloading(false); // Stop loading if LAHLOOB_UPDATE_PICKER_ORDER fails
            console.error("Failed to update Lahloob picker order", response);
          }
        })
        .catch((error) => {
          setloading(false); // Stop loading if LAHLOOB_UPDATE_PICKER_ORDER fails
          console.error("Error during LAHLOOB_UPDATE_PICKER_ORDER:", error);
        });
    } else {
      // If not Lahloob, proceed with the regular order update and processing
      updateOrder(user_id, orderId, [], draftquantites)
        .then((response) => {
          if (response?.status_code === 200) {
            // If updateOrder is successful, proceed with processing the order
            processOrder(user_id, orderId)
              .then((response) => {
                if (response?.status_code === 200) {
                  // If both API calls succeed, trigger callback function
                  calldata(searchid);
                  setloading(false); // Stop loading indicator
                } else {
                  setloading(false); // Stop loading even if processOrder fails
                  console.error("Failed to process order", response);
                }
              })
              .catch((error) => {
                setloading(false); // Stop loading if processOrder fails
                console.error("Error during processOrder:", error);
              });
          } else {
            setloading(false); // Stop loading if updateOrder fails
            console.error("Failed to update order", response);
          }
        })
        .catch((error) => {
          setloading(false); // Stop loading if updateOrder fails
          console.error("Error during updateOrder:", error);
        });
    }
  };

  const handleReturnCancelOrder = () => {
    console.log("handleReturnCancelOrder");

    setReturnCancelOrderOpen(true);
    console.log("setReturnCancelOrderOpen", isReturnCancelOrderOpen);
  };

  return (
    <>
      <div className="w-full flex flex-col md:h-full pb-2 overflow-y-auto  bg-[#F6F7F9]">
        {/* <div className="md:h-[8vh] flex mb-8"> */}
        <Navbar heading="eorders" />
        {/* </div> */}
        <div className="flex-grow overflow-y-auto custom-scrollbar overflow-hidden ms-3 mt-16">
          {loading ? (
            <ShimmerLoader />
          ) : (
            <>
              <div className="font-extrabold">
                <Breadcrumb
                  separator={<DoubleRightOutlined />}
                  items={[
                    // {
                    //   title: t("all_orders"),
                    //   href: "/orders",
                    // },
                    {
                      title: (
                        <Link to={routes.orders}> {t("all_orders")} </Link>
                      ),
                      key: "all_orders", // Unique key for this breadcrumb
                    },
                    {
                      title: `${orderDetail?.order_details?.order_no}`,
                    },
                  ]}
                />
              </div>

              <div>
                <div className="flex flex-row items-start justify-between">
                  <div className="flex items-center text-base font-medium  mt-2 ">
                    <span>{orderDetail?.order_details?.partner_name}</span>
                    <span className="w-2 h-2 bg-[#1D5C96] rounded mx-2"></span>
                    <span>
                      {orderDetail ? (
                        <>
                          {t("Placed on")}{" "}
                          {orderDetail?.order_details?.order_date
                            .substring(0, 10)
                            .replace(/-/g, "/")}{" "}
                          {orderDetail?.order_details?.order_date.substring(
                            11,
                            13
                          ) == 12
                            ? orderDetail?.order_details?.order_date.substring(
                                11,
                                16
                              ) + " PM"
                            : orderDetail?.order_details?.order_date.substring(
                                11,
                                13
                              ) > 12
                            ? orderDetail?.order_details?.order_date.substring(
                                11,
                                13
                              ) -
                              12 +
                              orderDetail?.order_details?.order_date.substring(
                                13,
                                16
                              ) +
                              " PM"
                            : orderDetail?.order_details?.order_date.substring(
                                11,
                                16
                              ) + " AM"}
                        </>
                      ) : (
                        <span> </span>
                      )}
                    </span>
                  </div>
                  <div className="flex   float-right ">
                    {(orderDetail?.order_details?.order_status ===
                      "Order Pending" ||
                      (orderDetail?.order_details?.order_status ===
                        "In Processing" &&
                        is_lahloob) ||
                      (orderDetail?.order_details?.order_status ===
                        "Out For Delivery" &&
                        !is_lahloob)) && (
                      <button
                        type="submit"
                        className="w-[160px] h-[45px] me-2 bg-white text-primaryBlue font-semibold rounded-lg inline border border-primaryBlue"
                        onClick={handleOpenCancelOrder}
                      >
                        {t("cancel")}
                      </button>
                    )}

                    {orderDetail?.order_details?.order_status ===
                      "Order Cancelled" &&
                      orderDetail?.order_details?.order_items_returned ===
                        false && (
                        <button
                          type="submit"
                          className="w-[190px] h-[45px] me-2 bg-primaryBlue text-white font-semibold rounded-lg inline border border-primaryBlue"
                          onClick={handleReturnCancelOrder}
                        >
                          {t("Return Cancel Order")}
                        </button>
                      )}

                    {data?.order_details?.order_status === "Order Pending" ||
                    (data?.order_details?.order_status === "In Processing" &&
                      !data?.order_details?.order_accepted) ? (
                      <button
                        type="submit"
                        className={`w-[160px] h-[45px] mx-5 ${
                          acceptbutton
                            ? "bg-primaryBluedisabled"
                            : "bg-primaryBlue"
                        } text-white rounded-lg font-semibold  `}
                        onClick={() => {
                          setloading(true);

                          let orderId = orderDetail?.order_details?.order_id;
                          // console.log("ORDER ID", orderId);

                          acceptOrder(user_id, orderId, is_lahloob).then(
                            (response) => {
                              calldata(searchid);
                              setloading(false);
                            }
                          );
                        }}
                        disabled={acceptbutton}
                      >
                        {t("accept")}
                      </button>
                    ) : data?.order_details?.order_status === "In Processing" &&
                      data?.order_details?.order_accepted ? (
                      <button
                        type="submit"
                        className={`w-[150px] h-[45px] me-3 bg-primaryBlue text-white rounded-lg font-semibold  ${
                          !canProcessOrder
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                        onClick={() => {
                          setloading(true);
                          let orderId = orderDetail?.order_details?.order_id;
                          processOrderActions(orderId);
                          // updateOrder(
                          //   user_id,
                          //   orderId,
                          //   [],
                          //   draftquantites
                          // ).then((response) => {
                          //   if (response?.status_code === 200) {
                          //     processOrder(user_id, orderId).then(
                          //       (response) => {
                          //         if (response?.status_code === 200) {
                          //           calldata(searchid);
                          //           setloading(false);
                          //         }
                          //       }
                          //     );
                          //   }
                          // });
                        }}
                        disabled={!canProcessOrder}
                      >
                        {t("process_order")}
                      </button>
                    ) : (
                      data?.order_details?.order_status ===
                        "Out For Delivery" &&
                      !is_lahloob && (
                        <button
                          type="submit"
                          className="w-[150px] h-[45px] mx-5 bg-primaryBlue text-white rounded-lg font-semibold  "
                          onClick={async () => {
                            setloading(true);
                            let orderId = orderDetail?.order_details?.order_id;
                            const response = await DeliverOrder(orderId);
                            // console.log("DeliverOrder", response);

                            {
                              response?.status == "Order Delivered" &&
                                calldata(searchid);
                              setloading(false);
                              setdeliveredpopup(true);
                              // setTimeout(() => {
                              //   router.push("/orders");
                              // }, 2000);
                            }

                            // setShowComponent(true);
                          }}
                        >
                          {t("deliver_order")}
                        </button>
                      )
                    )}

                    {/* {data?.order_details?.order_status ===
                      "Ready For Delivery" &&
                      !is_lahloob && (
                        <button
                          type="submit"
                          className="w-[180px] h-[45px] mr-4 bg-primaryBlue text-white rounded-lg font-semibold  "
                          onClick={handleAssignForDelivery}
                        >
                          {t("assign_delivery")}
                        </button>
                      )} */}

                    {data?.order_details?.delivery_boy_id &&
                      data?.order_details?.order_handed_over === false &&
                      data?.order_details?.order_status ===
                        "Ready For Delivery" &&
                      (
                        <button
                          type="submit"
                          className="w-[180px] h-[45px] mr-4 bg-primaryBlue text-white rounded-lg font-semibold  "
                          onClick={() => handleHandover(data?.order_details?.order_id)}                        >
                          {t("Order Handover")}
                        </button>
                      )}
                  </div>
                </div>
              </div>
              {/* Order List  */}
              <div className="flex w-full item-center justify-between ">
                <div className=" md:w-full   rounded-xl bg-[#FFFFFF] text-black float-left mb-4 me-4 mt-3">
                  <p className="flex justify-between items-center px-4 py-[1.37vh] border-b">
                    <span className="font-semibold text-base">
                      {t("item_list")}
                    </span>
                    {(orderDetail?.order_details?.order_status ===
                      "Order Delivered" ||
                      orderDetail?.order_details?.order_status ===
                        "Ready For Delivery") && (
                      <button
                        type="submit"
                        className=" px-4 h-10 bg-[#7EC242] text-white text-sm rounded-lg font-semibold"
                        onClick={handlePrintOrderList}
                      >
                        {orderDetail?.order_details?.order_status ===
                        "Ready For Delivery"
                          ? t("print_packing_slip")
                          : t("invoice")}
                      </button>
                    )}
                  </p>
                  <div className="overflow-auto h-auto">
                    {(orderDetail?.new_items?.length > 0
                      ? data?.new_items
                      : data?.existing_items
                    )?.map((val, i) => (
                      // {data?.existing_items?.map((val, i) => (
                      <div
                        className={`border-b overflow-hidden mt-2 ${
                          val.product_uom_qty != null ? "bg-[#FCC71D1A]" : ""
                        }`}
                        key={i}
                      >
                        {/* {console.log("value", val)} */}
                        <div className="flex my-3 mb-2 mx-6  justify-between">
                          <div className="flex items-center">
                            <span className="flex flex-col">
                              {" "}
                              <Image
                                src={val.image}
                                width={50}
                                height={50}
                                alt="P.Image"
                              />
                              <span className="flex flex-row text-xs mt-2 ">
                                <img
                                  src={weight_icon}
                                  height={16}
                                  width={16}
                                  alt="weight"
                                  // style={{
                                  //   minWidth: "17rem",
                                  //   maxWidth: "18rem",
                                  //   maxHeight: "13rem",
                                  //   minHeight: "12rem",
                                  // }}
                                />
                                <p className="mt-1">{val.product_uom}</p>
                              </span>
                            </span>
                            <span className="flex flex-col justify-center gap-1">
                              <span className="font-semibold  text-xs ms-3 me-3">
                                {val?.product_name}
                              </span>

                              <span className="ms-3 text-xs font-normal">
                                {/* {data?.order_details?.order_status ===
                              "Ready For Delivery"
                                ? 1
                                : 1}{" "} */}
                                {val.product_barcode}
                              </span>
                              <span className="text-xs ms-3">
                                {t("Order Qty")} {val.product_uom_qty}
                              </span>
                              {data?.order_details?.order_status !==
                                "In Processing" && (
                                <>
                                  <span className="text-xs ms-3">
                                    {t("Pick Qty")} {val?.pick_qty}
                                  </span>
                                </>
                              )}
                              {data?.order_details?.order_status ===
                                "In Processing" && (
                                <>
                                  <span className="text-xs ms-3 font-semibold">
                                    EGP {val.price_total}
                                  </span>
                                </>
                              )}
                            </span>
                          </div>

                          <span className="ms-auto flex flex-row items-center">
                            {data?.order_details?.order_status ===
                            "In Processing" ? (
                              <div className="flex flex-row items-center justify-between">
                                <div className="flex flex-row items-center rounded-lg bg-[#61d0f2] justify-between  border">
                                  <button
                                    className="font-extrabold text-lg text-center p-1.5 px-2"
                                    onClick={() => {
                                      const currentQuantity =
                                        draftquantites.find(
                                          (item) =>
                                            item?.product_id === val?.product_id
                                        )?.value || 0;

                                      // Call handleQuantityChange with currentQuantity - 1
                                      handleQuantityChange(
                                        currentQuantity - 1,
                                        i,
                                        val.order_id,
                                        val.id,
                                        val.product_id
                                      );
                                    }}
                                    disabled={
                                      (draftquantites.find(
                                        (item) =>
                                          item?.product_id === val?.product_id
                                      )?.value || 0) === 0
                                    }
                                  >
                                    -
                                  </button>
                                  <input
                                    type="number"
                                    className="w-16 h-10 border-x float-right text-center"
                                    placeholder="Qty"
                                    style={{
                                      outline: "none", // Remove outline on focus
                                    }}
                                    min="0"
                                    max={
                                      val?.available_qty > val?.product_uom_qty
                                        ? val?.product_uom_qty
                                        : val?.available_qty
                                    }
                                    onChange={(e) => {
                                      handleQuantityChange(
                                        e,
                                        i,
                                        val?.available_qty,
                                        val.order_id,
                                        val.id,
                                        val.product_id,
                                        val.sell_price
                                      );
                                    }}
                                    value={
                                      draftquantites.find(
                                        (item) =>
                                          item?.product_id === val?.product_id
                                      )?.value !== undefined
                                        ? draftquantites.find(
                                            (item) =>
                                              item?.product_id ===
                                              val?.product_id
                                          )?.value
                                        : val?.product_uom_qty // Set initial value to product_uom_qty
                                    }
                                    readOnly
                                  />

                                  <button
                                    className="font-extrabold text-lg text-center p-1.5 px-2"
                                    onClick={() => {
                                      const currentQuantity =
                                        draftquantites.find(
                                          (item) =>
                                            item?.product_id === val?.product_id
                                        )?.value || 0;
                                      handleQuantityChange(
                                        currentQuantity + 1,
                                        i,
                                        val.order_id,
                                        val.id,
                                        val.product_id
                                      );
                                    }}
                                    disabled={
                                      (draftquantites.find(
                                        (item) =>
                                          item?.product_id === val?.product_id
                                      )?.value || 0) >= val?.product_uom_qty
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                                {orderDetail?.order_details?.order_status ===
                                  "In Processing" && (
                                  <button
                                    className="rounded-md font-semibold text-gray-100 ms-s"
                                    onClick={() => {
                                      handleQuantityChange(
                                        0,
                                        "btn",
                                        val.order_id,
                                        val.id,
                                        val.product_id
                                      );
                                    }}
                                  >
                                    <img
                                      className="mx-1"
                                      src={deleteicon}
                                      alt="deleteicon"
                                    />
                                  </button>
                                )}
                              </div>
                            ) : data?.order_details?.order_status !=
                              "In Processing" ? (
                              <div className="flex flex-col mt-0 items-center">
                                <span className="text-xs ms-3 font-semibold">
                                  EGP {val.price_total}
                                </span>
                                {/* <span className="font-semibold text-xs">
                                  {t("Pick Qty")}
                                </span>
                                <span className="font-normal mt-1 text-xs">
                                  {val?.pick_qty}
                                </span> */}
                              </div>
                            ) : (
                              <></>
                            )}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* (data?.order_stage?.id==4 || data?.order_stage?.id==5 )  &&  */}
                  {
                    <PaymentInfo
                      billDetails={billDetails}
                      totalPrice={data?.order_total_amt}
                      payment={data?.payment_mode}
                      delivery_charge={data?.delivery_charge}
                    />
                  }
                </div>
                {/* {data?.order_stage?.name != "In Processing" ? ( */}
                <OrdercustomerDetails
                  OrderData={orderDetail}
                  // deliverydate={orderDetail}
                  // address_details={orderDetail}
                  // order_stage={orderDetail}
                  // Deliveryboy={orderDetail}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <DeliveryBoyDetails
        isOpen={isModalVisible} // Pass the state to manage modal visibility
        onClose={() => setIsModalVisible(false)}
        order_id={orderDetail.order_details?.order_id}
        Popup={() => {
          setpopup(true);
          setPopupMessage("Delivery Boy Assigned Successfully");
        }}
      />
      {/* Render CancelOrder component when isCancelOrderOpen is true */}

      {isReturnCancelOrderOpen && (
        <ReturnCancelOrder
          isOpen={isReturnCancelOrderOpen}
          onClose={() => setReturnCancelOrderOpen(false)}
          order_id={orderDetail.order_details?.order_id}
          data={orderDetail}
          popup={() => setcancelledpopup(true)}
        />
      )}

      {isCancelOrderOpen && (
        <CancelOrder
          isOpen={isCancelOrderOpen}
          onClose={() => setCancelOrderOpen(false)}
          order_id={orderDetail.order_details?.order_id}
          popup={() => setcancelledpopup(true)}
        />
      )}
      {cancelledpopup && (
        <Cancelledpopup
          onClose={() => {
            setcancelledpopup(false);
            calldata(searchid);
          }}
        />
      )}
      {deliveredpopup && (
        <Orderdeliveredpopup
          orderno={data?.order_number}
          onClose={() => {
            setdeliveredpopup(false);
            calldata(searchid);
          }}
        />
      )}
      {popup && (
        <AssignDeliveryBoypopup
          message={t(popupMessage)}
          onClose={() => {
            setpopup(false);
            calldata(searchid);
          }}
        />
      )}
      {SuccessPopup && (
        <CompleteSuccess
          message={t(popupMessage)}
          onClose={() => {
            setSuccessPopup(false);
            calldata(searchid);
          }}
        />
      )}
    </>
  );
};

export default Orderlist;
