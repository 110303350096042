import React, { useState, useEffect } from "react";
import { Breadcrumb, Image } from "antd";
import { CloseOutlined, DoubleRightOutlined } from "@ant-design/icons";
import Navbar from "../../components/Navbar/Navbar";
import { routes } from "../../Routes";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UploadBillImage from "./uploadBillImage";
import { UserImageUpload } from "../../API  Functions/UserMangement";
import { updatePOImages } from "../../API  Functions/PurchaseFunctions";
import UsermanagementPopup from "../../Popups/usermanagementPopup";
import LoadingSpinner from "../../components/Loading";
import i18next from "i18next";

const PurchaseOrder = () => {
  const { t } = useTranslation();
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const [noOrdersFound, setNoOrdersFound] = useState(false);
  const location = useLocation();
  const { orderData } = location.state || {};
  const data = orderData;

  // State to store base64 image data
  const [base64Images, setBase64Images] = useState([]);
  const [addImage, setAddImage] = useState(false);
  const [imageUrls, setImageUrls] = useState([]); // New state to store image URLs
  const [popup, setpopup] = useState(false);
  const [message, setmessage] = useState("");
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);

  // Effect to check for already attached images
  useEffect(() => {
    if (data?.order_details?.attached_image_urls?.length > 0) {
      setAddImage(true);
    }
  }, [data]);

  const handleAttachImages = async () => {
    setProcessing(true);
    if (base64Images.length > 0) {
      try {
        const uploadedUrls = [];

        const existingImagesCount =
          data?.order_details?.attached_image_urls?.length || 0;

        for (let i = 0; i < base64Images.length; i++) {
          const imageName = `${data?.order_details.order_id}-${String(
            existingImagesCount + i + 1
          ).padStart(2, "0")}`;

          const imageResponse = await UserImageUpload(
            base64Images[i],
            imageName
          );

          // console.log("imageResponse", imageResponse);

          if (imageResponse) {
            console.log(`Image ${i + 1} uploaded successfully`);
            uploadedUrls.push(imageResponse);
          } else {
            console.error(`Error uploading image ${i + 1}:`, imageResponse);
          }
        }

        // console.log("uploadedUrls", uploadedUrls);

        if (uploadedUrls.length > 0) {
          setImageUrls(uploadedUrls);
          const poId = data?.order_details?.order_id;

          const payload = {
            po_id: poId,
            image_urls: uploadedUrls,
          };
          const UploadRes = await updatePOImages(payload);
          if (UploadRes?.result?.status_code === 200) {
            console.log("updated successfully", UploadRes);
            setmessage("Image Uploaded Successfully");
            setProcessing(false);

            setpopup(true);
          } else {
            console.error("Error uploading image", UploadRes);
            setProcessing(false);
          }
        }

        setBase64Images([]);
      } catch (error) {
        console.error("Error during image upload:", error);
        setProcessing(false);
      }
    } else {
      console.log("No images to upload");
      setProcessing(false);
    }
  };

  const handleCancel = () => {
    setBase64Images([]);
  };

  const handleRemoveImage = (index) => {
    setBase64Images((prev) => prev.filter((_, i) => i !== index));
  };

  const handleClose = () => {
    setpopup(false);
    navigate(routes.purchase.purchaseList);
  };

  return (
    <>
      {processing && <LoadingSpinner />}
      <div className="flex">
        <div className="flex flex-col bg-[#F6F7F9] min-h-[93vh] min-w-[100vh] flex-1 mt-14">
          <Navbar heading="Purchase" />
          <div className="me-4 ms-3 font-extrabold">
            <Breadcrumb
              separator={<DoubleRightOutlined />}
              items={[
                {
                  title: (
                    <Link to={routes.purchase.purchaseList}>
                      {t("Purchase History")}
                    </Link>
                  ),
                  key: "purchaseOrder",
                },
                { title: `${data?.order_details?.order_no}` },
              ]}
              className="relative mt-3"
            />

            {/* Display attached images section */}
            <div className="mt-6 min-w-full overflow-hidden bg-white rounded-lg">
              <div className="ms-2 me-2">
                <h3 className="text-l mt-2 font-semibold text-[#272728]">
                  {t("Attached Bill Images")}
                </h3>

                {/* Check if there are any attached images */}
                {data?.order_details?.attached_image_urls?.length > 0 ? (
                  <>
                    {/* Horizontal Scroll for images */}
                    <div className="mt-4 pb-2 min-w-full">
                      <div className="flex space-x-4 overflow-x-auto scrollbar-thin overflow-hidden">
                        {data?.order_details?.attached_image_urls.map(
                          (image, index) => (
                            <div
                              key={index}
                              className="flex-none w-[120px] h-[120px] rounded-lg shadow-md overflow-hidden"
                            >
                              <Image
                                src={image}
                                alt={`Bill${index + 1}`}
                                className="w-full h-full object-cover"
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <p className="mt-4 text-center text-gray-600">
                    {t("No Bill Images Attached")}
                  </p>
                )}

                {/* Upload new images */}
                <div className="mt-4 mb-4 ">
                  <UploadBillImage
                    foldername={"store_app_UAT"}
                    onfileAdd={`storeuserurl`}
                    setBase64Images={(images) => {
                      setBase64Images((prev) => [...prev, images]);
                    }}
                    imageurl={addImage}
                  />
                </div>

                {/* Display images that were uploaded */}
                {base64Images.length > 0 && (
                  <>
                    <div className="mt-4">
                      <h4 className="font-normal mb-2 italic  text-gray-600">
                        {t("Selected Images")}
                      </h4>
                      <div className="flex space-x-4 overflow-x-auto">
                        {base64Images.map((image, index) => (
                          <div
                            key={index}
                            className="relative flex-none w-[120px] h-[120px] rounded-lg shadow-md overflow-hidden"
                          >
                            <Image
                              src={image}
                              alt={`Uploaded Image ${index + 1}`}
                              className="w-full h-full object-cover"
                            />

                            {/* Cross Button to remove the image */}
                            <button
                              onClick={() => handleRemoveImage(index)}
                              className="absolute top-0 right-0  text-black p-0 rounded-full"
                              style={{ zIndex: 10 }} // Ensure button is above the image
                            >
                              <CloseOutlined />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* Save and Cancel buttons */}
                    <div className="mt-4 flex mb-4 gap-2 justify-end">
                      <button
                        onClick={handleCancel}
                        className="px-4 py-2 bg-white text-primaryBlue border border-primaryBlue rounded-lg hover:bg-red-600"
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        onClick={handleAttachImages}
                        disabled={processing}
                        className="px-4 py-2 bg-primaryBlue text-white rounded-lg hover:bg-primaryBlue"
                      >
                        {t("Save")}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* Display Purchase Order Table */}
            <div className="mt-6">
              <table className="min-w-full bg-white shadow-md rounded-lg border-collapse">
                <thead className="bg-[#D1F1FC] rounded-lg">
                  <tr>
                    <th className={`py-3 px-4 text-start text-sm font-semibold text-[#272728]  ${i18next.language === "ar" ? "rounded-tr-lg" : "rounded-tl-lg"}`}>
                      {t("Product Name")}
                    </th>
                    <th className="py-3 px-4 text-start text-sm font-semibold text-[#272728]">
                      {t("UOM")}
                    </th>
                    <th className="py-3 px-4 text-start text-sm font-semibold text-[#272728]">
                      {t("Quantity")}
                    </th>
                    <th className="py-3 px-4 text-start text-sm font-semibold text-[#272728]">
                      {t("Cost Price")}
                    </th>
                    <th className="py-3 px-4 text-start text-sm font-semibold text-[#272728]">
                      {t("Sales Price")}
                    </th>
                    <th className={`py-3 px-4 text-start text-sm font-semibold text-[#272728]  ${i18next.language === "ar" ? "rounded-tl-lg" : "rounded-tr-lg"}`}>
                      {t("Total")}
                    </th>
                  </tr>
                </thead>
                <tbody className="rounded-b-lg">
                  {data.lines.map((product, index) => (
                    <tr
                      key={index}
                      className={`border-t ${
                        index % 2 === 0 ? "bg-white" : "bg-[#f9f9f9]"
                      }`}
                    >
                      {/* Product Name */}
                      <td className={`py-3 px-4 font-normal text-sm text-[#272728] flex items-center ${i18next.language === "ar" ? "rounded-r-lg" : "rounded-l-lg"}`}>
                        <img
                          className="w-12 h-12 object-contain me-3"
                          src={product.image}
                          alt={product.product_name}
                        />
                        {product.product_name}
                      </td>

                      {/* Units */}
                      <td className="py-3 px-4 font-normal text-sm text-[#272728]">
                        {product.product_uom || "N/A"}
                      </td>

                      {/* Quantity */}
                      <td className="py-3 px-4 font-normal text-sm text-[#272728]">
                        {product.product_qty || "N/A"}
                      </td>

                      {/* Cost Price */}
                      <td className="py-3 px-4 font-normal text-sm text-[#272728]">
                        {product.cost_price?.toFixed(2) || "0.00"}
                      </td>

                      {/* Sales Price */}
                      <td className="py-3 px-4 font-normal text-sm text-[#272728]">
                        {product.sale_price?.toFixed(2) || "0.00"}
                      </td>

                      {/* Total */}
                      <td
                        className={`py-3 px-4 font-normal text-sm text-[#272728]${
                          i18next.language === "ar"
                            ? "rounded-l-lg"
                            : "rounded-r-lg"
                        }`}
                      >
                        {product.sub_total?.toFixed(2) || "0.00"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {popup && (
          <UsermanagementPopup
            message={message}
            close={() => {
              handleClose();
              // setpopup(false);
              if (message === t("User Updated Successfully"));
            }}
          />
        )}
      </div>
    </>
  );
};

export default PurchaseOrder;
