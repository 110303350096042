import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import "tailwindcss/tailwind.css";
import CustomPagination from "../CustomWidgets/CustomPagination";
import { Empty } from "antd";
import {
  GetDeliveryBoyOrders,
  getDeliveryPartnerOrders,
} from "../../API  Functions/DeliveryPartnerFunctions";
import Vectorsorting from "../../assets/Vectorsorting.svg";
import arrowright from "../../assets/arrowright.svg";
import { Image } from "antd";
import "../../styles.css";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import ShimmerLoader from "../../loading";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Deliverypartnersorderstable = ({ deliverypartnerid }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const [flag, setflag] = useState(false);
  const [paginatedData, setPaginatedData] = useState([]);

  const [data, setdata] = useState([]);
  
  useEffect(() => {
    if (data?.length === 0 && !flag) {
      const timer = setTimeout(() => {
        setflag(true);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [data, flag]);

  const getDeliveryBoyOrders = async (deliverypartnerid, user_id) => {
    try {
      const payload = {
        lang: i18next.language === "ar" ? "ar_001" : "en_US",
        delivery_boy_id: deliverypartnerid,
        user_id: user_id,
      };
  
      const response = await GetDeliveryBoyOrders(payload);  
  
      if (response?.data?.result[0]?.status_code === 200) {
        console.log("GetDeliveryBoyOrders response", response);  
        setdata(response?.data?.result[0]?.response); 
      } else {
        console.error("Error: Response status code is not 200");
      }
    } catch (error) {
      console.error("Error fetching delivery boy orders:", error);
    }
  };

  useEffect(() => {
    if (deliverypartnerid && user_id) {
      getDeliveryBoyOrders(deliverypartnerid, user_id);
    }
  }, [deliverypartnerid, user_id]);
  

  // console.log("DATAA ORDR", data);
  

  const openOrderList = (value) => {
    // const senddata = [value];
    console.log("sending value", value);
    navigate(`/Orderlist?orderDetail=${value}`);
  };

  const columns = useMemo(
    () => [
      {
        Header: t("order_no"),
        accessor: "order_name",
        Cell: ({ value }) => (
          <>
           
            <button
              className=" text-[#1b4497] rounded-lg"
              onClick={() =>{
                console.log("1st click")
                 openOrderList(value)}}
              // onClick={() => openOrderList("ordernamehar" + value + ",")}
            >
              {value}
            </button>
            {/* )
          } */}
          </>
        ),
      },
      { Header: t("customer_name"), accessor: "customer_name" },
      { Header: t("Order Date"), accessor: "order_date" },
      { Header: t("items"), accessor: "total_item" },
      { Header: t("order_value"), accessor: "total_amount" },
      // {Header: t('payment'), accessor: "payment_mode" },
      {
        Header: t("order_status"),
        accessor: "order_status",
        Cell: ({ value }) => {
          let backgroundColor,
            textColor,
            borderRadius,
            paddingTop,
            paddingBottom,
            paddingLeft,
            paddingRight;

          if (value === "Order Pending") {
            backgroundColor = "#999999";
           
          } else if (value === "In Processing") {
            backgroundColor = "#f1c232  ";
           
          } else if (value === "Ready For Delivery") {
            backgroundColor = "#93c47d";
           
          } else if (value === "Out For Delivery") {
            backgroundColor = "#f1c232";
           
          } else if (value === "Order Delivered") {
            backgroundColor = "#1b4497";
         
          } else {
            backgroundColor = "#E74C3C"; 
           
          }

          textColor = "#ffffff";
          paddingTop = "8px";
          paddingBottom = "8px";
          paddingLeft = "1px";
          paddingRight = "1px";
          borderRadius = "10px";

          return (
            <h4
              style={{
                backgroundColor,
                color: textColor,
                borderRadius,
                padding: `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}`,
                fontWeight: 600,
                display: "inline-block",
                minWidth: "90px",
                alignItems: "center",
              }}
            >
              {(() => {
                switch (value) {
                  case "Order Pending":
                    return "Pending";
                  case "In Processing":
                    return "Processing";
                  case "Ready For Delivery":
                    return "Ready";
                  case "Out For Delivery":
                    return "Out";
                  case "Order Delivered":
                    return "Delivered";

                  default:
                    return "Cancelled";
                }
              })()}
            </h4>
          );
        },
      },
      {
        id: "action",
        // Header: "Action",
        accessor: (row) => `${row.order_name}`,
        Cell: ({ value }) => (
          <>
            <button
              className=" text-primaryBlue rounded-lg"
              onClick={() => openOrderList(value)}
            >
              <img
                src={arrowright}
                alt="arrowright"
                className={`${i18n.language == "en" ? "" : " rotate-180"}`}
                height={3}
                width={20}
              />
            </button>
          </>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data : paginatedData,
      initialState: { pageSize: 10 },
    },
    useSortBy,
    usePagination
  );
  const [pageIndex, setpageIndex] = useState(1);



  useEffect(() => {
    const startIndex = (pageIndex - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setPaginatedData(data.slice(startIndex, endIndex));
  }, [data, pageIndex, pageSize]);


  return (
    <div className="bg-[#F6F7F9] flex items-center justify-center">
      {data?.length == 0 && flag == false ? (
        <>
        <div className="h-screen w-screen mt-8">
          <ShimmerLoader />{" "}
          </div>
          {/* {setTimeout(() => {
              setflag(true);
            }, 3000)} */}
        </>
      ) : (
        <table
          {...getTableProps()}
          className="table-auto mt-4 font-normal w-[1100px] sm:w-[99.8%] md:w-[99.8%] lg:w-[99.8%] xl:w-[99.8%] shadow-xl rounded-lg overflow-hidden"
        >
          <thead>
            {headerGroups.map((headerGroup, headerIndex) => {
              const { key: headerKey, ...headerGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr
                  key={headerKey}
                  {...headerGroupProps}
                  className="bg-[#78CFEC66] text-sm font-semibold"
                >
                  {headerGroup.headers.map((column, colIndex) => {
                    const { key: columnKey, ...columnProps } =
                      column.getHeaderProps(column.getSortByToggleProps());
                    return (
                      <th
                        key={columnKey}
                        {...columnProps}
                        className="py-3 px-2 whitespace-nowrap "
                      >
                        <div className="flex items-center justify-center">
                          {column.render("Header")}
                          <span className="">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <img
                                  src={Vectorsorting}
                                  style={{ minHeight: 10, minWidth: 10 }}
                                />
                              ) : (
                                <img
                                  className="rotate-180"
                                  src={Vectorsorting}
                                  style={{ minHeight: 10, minWidth: 10 }}
                                />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()} className="bg-[#F7F9FD] text-center">
            {page?.length === 0 ? (
              <tr>
                <td colSpan={columns?.length} className="py-4">
                  <Empty description="No data available" />
                </td>
              </tr>
            ) : (
              page?.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="relative hover:bg-white transition duration-300 border-b border-gray-300 fixed-row-height "
                  >
                    {row.cells.map((cell, index) => (
                      <td
                        {...cell.getCellProps()}
                        className={`p-2 text-[#272728] text-sm font-normal ${
                          index < row.cells.length - 1 ? "relative" : ""
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })
            )}
            {
              <tr>
                <td className="" colSpan={columns.length}>
                  <CustomPagination
                    pageIndex={pageIndex}
                    pageCount={page?.length}
                    canPreviousPage={pageIndex != 1 ? true : false}
                    canNextPage={data?.length >= 10 ? true : false}
                    gotoPage={(page) => {
                      nextPage(page);
                    }}
                    previousPage={() => {
                      setpageIndex(pageIndex - 1);
                    }}
                    nextPage={() => {
                      setpageIndex(pageIndex + 1);
                    }}
                    pageSize={pageSize}
                    setPageSize={(pageSize) => {
                      // nextPage(0);
                      setPageSize(pageSize);
                    }}
                  />
                </td>
              </tr>
            }
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Deliverypartnersorderstable;
