import axiosInstance from "../AxiosInstance";
import { URLS } from "../API/API endpoints";
import { store } from "../Redux/store";
import i18next from "i18next";

const { login } = store.getState();

// const user_id = login.user;
// const store_id = login.user;




export const createShippingCost = async (payload) => {
  try {
    const response = await axiosInstance.post(
      URLS.CREATE_MULTIPLE_SHIPPING_COST,
      payload
    );

    console.log("CREATE SHIPPING COST", response);
    
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateShippingCost = async (payload) => {
  try {

    if (payload.zone_ids && Array.isArray(payload.zone_ids) && payload.zone_ids.length > 0) {
      payload.zone_id = payload.zone_ids[0]; // Set zone_id to the first element
      delete payload.zone_ids; // Remove zone_ids from the payload
    }

    const response = await axiosInstance.post(URLS.UPDATE_SHIPPING_COST, payload);

    console.log("UPDATE COST", response);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteShippingCost = async (payload) => {
  try {
    // const payload = {
    //   lang: i18next.language === "ar" ? "ar_001" : "en_US",
    //   user_id: user_id,
    //   retailer_user_id: retailer_user_id,
    // };
    const response = await axiosInstance.post(
      URLS.DELETE_SHIPPING_COST,
      payload
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const getShippingCosts = async (user_id) => {
  try {
    const payload = {
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
      user_id: user_id,
      //  store_id:store_id,
    };
    const response = await axiosInstance.post(URLS.GET_SHIPPING_COSTS, payload);
    // console.log("Shipping Cost", response);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getServiceLevels = async (user_id) => {
  try {
    const payload = {
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
      user_id: user_id,
      //  store_id:store_id,
    };
    const response = await axiosInstance.post(URLS.GET_SERVICE_LEVEL, payload);
    // console.log("getServiceLevels", response);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getServiceAreas = async ({ level_id }) => {
    // console.log("LEEEVEL IDD", level_id);
    
  try {
    const payload = {
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
      level_id,
      //  store_id:store_id,
    };

    // console.log("LVELE PAYLAOD", payload);
    
    const response = await axiosInstance.post(URLS.GET_SERVICE_AREA, payload);
    console.log("getServiceAreas", response);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getDeliveryZones = async ({area_id}) => {
  try {
    const payload = {
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
      area_id: area_id,
      //  store_id:store_id,
    };
    const response = await axiosInstance.post(URLS.GET_DELIVERY_ZONE, payload);
    console.log("getServiceAreas", response);

    return response.data;
  } catch (error) {
    return error;
  }
};
