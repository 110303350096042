import React from "react";
import { css } from "@emotion/react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const ShimmerLoader = ({ loading }) => {
  const override = css`
    display: block;
    margin: auto;
    border-color: red; // Customize the color if needed
  `;

  return (
    <Stack spacing={1}>
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      <Skeleton variant="rectangular" width={485} height={30} />
      <Skeleton variant="rectangular" width={210} height={20} />
      <Skeleton variant="rounded" width={685} height={40} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      {/* <Skeleton variant="rectangular" width={210} height={20} /> */}
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      <Skeleton variant="rectangular" width={485} height={30} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      <Skeleton variant="rectangular" width={210} height={20} />
      <Skeleton variant="rounded" width={685} height={40} />


      {/* <Skeleton variant="rectangular" width={385} height={60} /> */}
    </Stack>
  );
};

export default ShimmerLoader;
