import { CloseOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import sampleFile from '../../src/data/sample_file_upload_bulk_inventory.xlsx';
import download_icon from '../../src/assets/download_icon.svg';
import { useSelector } from "react-redux";
import { UploadBulkInventory } from "../API  Functions/InventoryFunctions";
import * as XLSX from "xlsx"; // Importing xlsx library


const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
  container: {
    width: "500px",
    padding: "30px",
    borderRadius: "18px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    textAlign: "left",
    marginBottom: "10px",
  },
  cancelIcon: {
    cursor: "pointer",
  },
  description: {
    textAlign: "left",
    fontSize: "16px",
    color: "#272728",
    marginTop: "10px",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "12px",
    border: "1px solid #CCCCCC",
    marginBottom: "20px",
    boxSizing: "border-box",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: "180px",
    height: "45px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    marginRight: "10px",
    transition: "opacity 0.3s", // Smooth transition for opacity change
  },
  confirmButton: {
    backgroundColor: "primaryBlue",
    color: "#FFFFFF",
  },
  cancelButton: {
    backgroundColor: "#CCCCCC",
    color: "#000000",
  },
};

const ImportInventory = ({ isOpen, onClose }) => {
  const { t, i18n } = useTranslation();
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);

  const [isPriceChecked, setIsPriceChecked] = useState(false);
  const [quantityOption, setQuantityOption] = useState("add");
  const [fileError, setFileError] = useState(""); 
  const [base64File, setBase64File] = useState(""); 
  const [fileName, setFileName] = useState(""); 
  const [loading, setLoading] = useState(false); 
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); 
  const [isUpdatePriceOnlyChecked, setIsUpdatePriceOnlyChecked] = useState(false); 
  const [bounceDetails, setBounceDetails] = useState([]); // State to store bounce details


  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 
    setErrorMessage(""); 
    setSuccessMessage("");
  
    const payload = {
      user_id: user_id,
      file: base64File,
      is_update_price: isPriceChecked,
      update_price_only: isUpdatePriceOnlyChecked,
      is_update_qty: quantityOption === "update",
      is_add_qty: !isUpdatePriceOnlyChecked && quantityOption === "add", 
    };
  
    try {
      const result = await UploadBulkInventory(payload);

      // console.log("result", result.result.bounce_details.length );
      // console.log("bpunce result", result.result.bounce_details );


  
    
        if (result.result.status_code === 200 && result.result.bounce_details.length === 0) {
          onClose();
          setBase64File(""); // Clear the base64 file
          setFileName(""); // Clear the file name
        } else if (result.result.bounce_details.length > 0) {
          console.log("RESSS",result.result.bounce_details);
          
          setBounceDetails(result.result.bounce_details);
          setFileName(""); // Clear the file name
        // }
      } else {
        setErrorMessage(result.message || "An error occurred during file upload.");
      }
    } catch (error) {
      setErrorMessage("Failed to upload file. Please try again.");
    } finally {
      setLoading(false); 
    }
  };

  // console.log("BOUNCE DETAILS", bounceDetails);
  

  const downloadBounceExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      bounceDetails.map(item => ({
        "Barcode": item.barcode,
        "Quantity": item.onhand_qty,
        "Sales Price": item.sale_price,
        "Cost Price": item.cost_price,
        "Warning Message": item.message,
      }))
    );
    
    XLSX.utils.book_append_sheet(wb, ws, "Bounce Details");

    // Generate a file and trigger download
    XLSX.writeFile(wb, "bounce_details.xlsx");
  };

  useEffect(() => {
    setIsPriceChecked(true);
    }, []);
  
  

  // Handle checkbox change for Price checkbox
  const handlePriceChange = (e) => {
    setIsPriceChecked(e.target.checked);
    setIsUpdatePriceOnlyChecked(false);
  };

  // Handle checkbox change for Update Price Only checkbox
  const handleUpdatePriceOnlyChange = (e) => {
    const checked = e.target.checked;
    setIsUpdatePriceOnlyChecked(checked);

    // If "Update Price Only" is checked, uncheck and disable the other checkboxes/radio buttons
    if (checked) {
      setIsPriceChecked(false); 
      setQuantityOption("add"); 
    }
  };

  // Handle radio button change for Quantity options
  const handleQuantityChange = (e) => {
    setQuantityOption(e.target.value);
  };

  // Function to trigger file download
  const downloadSampleFile = () => {
    const link = document.createElement("a");
    link.href = sampleFile;
    link.download = "sample_file_upload_bulk_inventory.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Function to handle file upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (fileExtension !== "xls" && fileExtension !== "xlsx") {
        setFileError("Please upload a valid Excel file.");
        setBase64File("");
        setFileName(""); // Clear file name if the file type is invalid
      } else {
        setFileError(""); 
        setFileName(file.name); // Store the file name
        convertToBase64(file); 
      }
    }
  };



const convertToBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64Data = reader.result.split(',')[1];
      setBase64File(base64Data); 
    };
  };

  // console.log("base64File", base64File);

  // const isSubmitDisabled = loading || !base64File; 
  const isSubmitDisabled = loading || !base64File ;


  return (
    <>
      {isOpen && (
        <>
          <div style={styles.overlay} onClick={onClose}></div>
          <div style={styles.container}>
            <div style={styles.header}>
              <p style={styles.title}>{t("Update Inventory")}</p>
              <CloseOutlined style={styles.cancelIcon} onClick={onClose} />
            </div>
            <form onSubmit={handleSubmit}>
              {/* Price Checkbox */}
              <div className="flex flex-row">
                <label>{t("Update Price & Quantity")}:</label>
                <input
                  type="checkbox"
                  checked={isPriceChecked}
                  onChange={handlePriceChange}
                  // disabled={isUpdatePriceOnlyChecked} 
                  className="flex ms-4 cursor-pointer"
                />
              </div>

              {/* Update Price Only Checkbox */}
              <div className="flex flex-row mt-2">
                <label>{t("Update Price Only")}:</label>
                <input
                  type="checkbox"
                  checked={isUpdatePriceOnlyChecked}
                  onChange={handleUpdatePriceOnlyChange}
                  // disabled={isPriceChecked} 
                  className="flex ms-4 cursor-pointer"
                />
              </div>

              {/* Quantity Section */}
              <div className="flex flex-row items-center gap-2 mt-2">
                <span>{t("Quantity")}: </span>
                <label>
                  <input
                    type="radio"
                    name="quantityOption"
                    value="add"
                    checked={quantityOption === "add"}
                    onChange={handleQuantityChange}
                    disabled={isUpdatePriceOnlyChecked} 
                    className="me-1 cursor-pointer"
                  />
                  {t("Update Quantity")}
                </label>
                <label>
                  <input
                    type="radio"
                    name="quantityOption"
                    value="update"
                    checked={quantityOption === "update"}
                    onChange={handleQuantityChange}
                    disabled={isUpdatePriceOnlyChecked} 
                    className="me-1 cursor-pointer"
                  />
                  {t("Add Quantity")}
                </label>
              </div>

              {/* Error message for file upload */}
              {fileError && <div style={{ color: "red", marginTop: "10px" }}>{fileError}</div>}

              {/* File Upload Section */}
              <div className="mt-8">
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="upload-file"
                  accept=".xlsx,.xls" // Limit to Excel files only
                  onChange={handleFileUpload}
                />
                <button
                  type="button"
                  className="w-full h-10 border-primaryBlue border text-primaryBlue font-semibold rounded-lg"
                  onClick={() => document.getElementById("upload-file").click()}
                >
                  {t("Upload File")}
                </button>
                {/* Display the uploaded file name */}
                {fileName && (
                  <div style={{ marginTop: "10px" }}>
                    <strong>{t("Uploaded File")}: </strong>
                    {fileName}
                  </div>
                )}
              </div>

              {/* Sample File Download */}
              <div className="flex flex-row items-center gap-2 mt-5">
                <span>{t("Download Sample File")}: </span>
                <img
                  type="button"
                  src={download_icon}
                  className="h-4 cursor-pointer"
                  onClick={downloadSampleFile}
                  alt="download"
                />
              </div>

              {/* Display error or success messages */}
              {loading && <div>Loading...</div>}
              {errorMessage && <div style={{ color: "red", marginTop: "10px" }}>{errorMessage}</div>}
              {successMessage && <div style={{ color: "green", marginTop: "10px" }}>{successMessage}</div>}

               {/* Bounce Details Excel Download Button (if bounce details exist) */}
              {bounceDetails.length > 0 && (
                <div className="mt-5">
                  <button
                    type="button"
                    className="w-full h-10 bg-primaryBlue text-white font-semibold rounded-lg"
                    onClick={downloadBounceExcel}
                  >
                    {t("Download Bounce Excel File")}
                  </button>
                </div>
              )}

              {/* Form Action Buttons */}
              <div className="flex justify-center mt-5">
                <button
                  className="w-52 h-10 border-primaryBlue border text-primaryBlue font-semibold rounded-lg me-5"
                  onClick={() => onClose()}
                >
                  {t("Cancel")}
                </button>
                <button
                  type="submit"
                  className="w-52 h-10 text-white font-semibold rounded-lg bg-primaryBlue"
                  style={{
                    opacity: isSubmitDisabled ? 0.5 : 1, 
                    pointerEvents: isSubmitDisabled ? "none" : "auto", 
                  }}
                  disabled={isSubmitDisabled} 
                >
                  {t("Submit")}
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default ImportInventory;
