import { useSelector } from "react-redux";
import { URLS } from "../API/API endpoints";
import axiosInstance from "../AxiosInstance";
import i18next from "i18next";

export const GetRetailerDetails = async (user_id) => {
  try {
    const payload = {
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
      user_id: user_id,
    };
    const response = await axiosInstance.post(URLS.GET_RETAILER_DETAILS, payload);
    console.log("DETAIL RES", response);
    
    if (response.data?.result?.status_code == 200) {
      return response?.data?.result;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};



export const UpdateRetailerDetails = async (payload) => {
    try {
    //   const payload = {
    //     user_id: user_id,
    //   };
      const response = await axiosInstance.post(URLS.UPDATE_RETAILER_DETAILS, payload);
      console.log("DETAIL RES", response);
      
      if (response.data?.result?.status_code == 200) {
        return response?.data?.result;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  };


  export const ShopImageUpload = async (company_id, base64Image, imageName) => {
  
    const extension = base64Image.split(';')[0].split('/')[1]; 
    const filename = `${imageName}.${extension}`;
    const base64Data = base64Image.split(',')[1];
    // console.log("BASE CHALA", base64Data);
  
  
    const payload = {
      filename: filename,
      bucket: process.env.REACT_APP__IMAGE_BUCKET, 
      data: base64Data,
    };
  
    try {
      const response = await axiosInstance.post(process.env.REACT_APP__IMAGE_UPLOAD, payload, {
      });
  
      if (response.data && response.data.result && response.data.result.status_code === 200) {
        return response.data.result.url; 
      } else {
        console.error("Upload failed:", response.data);
        return null; 
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      return null; 
    }
  };

  export const RetailerShopImagesUpload = async (payload) => {
    try {
    //   const payload = {
    //     user_id: user_id,
    //   };
      const response = await axiosInstance.post(URLS.RETAILER_SHOP_IMAGE_UPLOAD, payload);
      console.log("DETAIL RES", response);
      
      if (response.data?.result?.status_code == 200) {
        return response?.data?.result;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  };
  
  
  export const GetRetailerDocs = async (user_id) => {
    try {
      const payload = {
        user_id: user_id,
      };
      const response = await axiosInstance.post(URLS.GET_RETAILER_DOCS, payload);
      console.log("DETAIL RES", response);
      
      if (response.data?.result?.status_code == 200) {
        return response?.data?.result;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  };
  