import React, { useState, useEffect } from "react";
import plus from "../../../src/assets/Images/plus.svg";
import { Breadcrumb } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import Navbar from "../../components/Navbar/Navbar";
import { routes } from "../../Routes";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Link, useNavigate } from "react-router-dom";
import i18next from "i18next";
import {
  getPosSessionHistory,
  getPosSessionList,
} from "../../API  Functions/SessionHistoryFunctions";
import CloseSessionModal from "../../modals/CloseSession_Modal";
import { Drawer } from "antd";
import SelectBox from "../../components/CustomWidgets/SelectBox";
import { DatePicker } from "antd";
import {
  createPurchaseOrder,
  getBillPaymentHistory,
  getPaymentsList,
  getPurchaseList,
} from "../../API  Functions/PurchaseFunctions";
import moment from "moment";
import PurchasePayment from "../../modals/purchasePaymentModal";
import SearchBox from "../../components/CustomWidgets/SearchBox";
import LoadingSpinner from "../../components/Loading";
import { DeleteOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;

const PurchaseList = () => {
  const { t, i18n } = useTranslation();
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const company_id = useSelector((state) => state?.login?.user?.result?.company_id);

  const navigate = useNavigate();

  const [popup, setpopup] = useState(false);
  const [selectedBillId, setSelectedBillId] = useState(null);
  const [selectedDueAmount, setSelectedDueAmount] = useState(null);
  const [visible, setVisible] = useState(false);
  const [paymentHistoryData, setPaymentHistoryData] = useState([]);
  const [drawerData, setDrawerData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedPaymentType, setSelectedPaymentType] = useState(t("all_orders"));
  const [dateRange, setDateRange] = useState([null, null]);
  const [noOrdersFound, setNoOrdersFound] = useState(false);
  const [fetchedOrdersCount, setFetchedOrdersCount] = useState(0); // Track the number of orders fetched
  const [currentPage, setCurrentPage] = useState(1); // Current page

  const [data, setData] = useState([]);

  const showDrawer = (historyData) => {
    setDrawerData(historyData);
    setVisible(true);
    getPaymentHistory(historyData.bill_id);
    setSelectedUserId(historyData.bill_id);
  };

  useEffect(() => {
    getSessionList();
  }, [popup]);

  const getPaymentHistory = (billId) => {
    const payload = {
      bill_id: billId,
    };
    getBillPaymentHistory(payload)
      .then((res) => {
        if (res?.result?.payments.length > 0) {
          setPaymentHistoryData(res?.result?.payments);
          setLoading(false);
        } else {
          console.error("No session history found");
        }
      })
      .catch((error) => {
        console.error("Error fetching session history:", error);
      });
  };
  const onClose = () => {
    setVisible(false);
  };

  const getSessionList = (search = "", paymentType = "all", offset) => {
    setLoading(true);
    let paymentStatus = "";
    if (paymentType === 1) {
      paymentStatus = "draft";
    } else if (paymentType === 2) {
      paymentStatus = "done";
    }
    const payload = {
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
      user_id: user_id,
      state: paymentStatus,
      order_no: search,
      limit: 10,
      offset: offset * 10,
    };

    getPurchaseList(payload)
      .then((res) => {
        // console.log("PURCHASSE LIST ", res);

        if (res?.result?.status === "No Orders") {
          setLoading(false);

          setNoOrdersFound(true);
          setData([]);
        } else if (res?.result[0]?.response.length > 0) {
          const AllList = res?.result[0]?.response;
          //   console.log("AllList", AllList);

          setData(AllList);
          setFetchedOrdersCount(AllList.length);
          setLoading(false);

          setNoOrdersFound(false);
        } else {
          console.error("Response was not successful:", res);
          setNoOrdersFound(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching store users:", error);
        setNoOrdersFound(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    getSessionList();
  }, []);

  const handlePaymentClick = (val) => {
    setSelectedBillId(val?.bill_id);
    setSelectedDueAmount(val?.due_amount);
    setIsModalVisible(true);
  };

  const handleSearchChange = (value) => {
    setSearchKeyword(value);
    getSessionList(value, selectedPaymentType, 0);
  };

  const handlePaymentTypeChange = (event) => {
    const paymentType = event.target.value;
    setSelectedPaymentType(paymentType);
    getSessionList(searchKeyword, paymentType, 0);
  };

  const options = [
    {
      value: 0,
      label: `${t("all_orders")}`,
    },
    {
      value: 1,
      label: `${t("Draft Orders")}`,
    },
    {
      value: 2,
      label: `${t("Purchase Orders")} `,
    },
  ];

  //   console.log("CURRENT PAGE ", currentPage);
  const formatSessionState = (state) => {
    if (!state) return "";

    if (state === "Unpaid") return t("Draft Order");

    return state
      .split("_") // Split by underscore
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" "); // Join the words with a space
  };

  const handleBoxClick = (val) => {
    // console.log("VVVVVVV", val.order_details.status);
    if (val.order_details.status === "draft") {
      navigate(routes.purchase.addBill, {
        state: { orderData: val },
      });
    } else {
      navigate(routes.purchase.purchaseOrder, {
        state: { orderData: val },
      });
    }
  };

  // const handleDelete = (val) => {
  //   // Confirm deletion
  //   const confirmDelete = window.confirm(
  //     t("Are you sure you want to delete this order?")
  //   );
  //   if (confirmDelete) {
     

  //     console.log("Deleting order with bill ID:", billId);
  //     // Implement your delete logic here
  //   }
  // };

  const handleDelete = async (val) => {
     console.log("handleDeleteVAL", val);
     
      setLoading(true);
      // const paymentTermId = val.find(
      //   (term) => term.name === val
      // )?.id;
      // const vendorId = val.find((ve) => ve.name === val)?.id;
  
      const payload = {
        po_id: val.order_details.order_id,
        user_id: user_id,
        company_id: company_id,
        // payment_term_id: val,
        // vendor_id: val,
        date_of_bill: val.order_details.order_date,
        // vat_value: val,
        vendor_reference: val.order_details.venfor_ref,
        state: "delete",
        lines: val.lines,
        // lines: {
        //   product_id: val,
        //   price:val,
        //   selling_price: val,
        //   vat: val,
        //   quantity: val,
        //   free_qty: val,
        //   discount_per: val,
        //   discount_value: val,
          // product_expiry: {
          //   quantity: val,
          //   expiry_date: moment(val).format("YYYY-MM-DD"),
          // },
          // is_alert: val,
          // alert_days: val,
        // },
        // bill_discount_per: val,
        // bill_discount_value: val,
      };
  
      // console.log("Payload for purchase order:", payload);
  
      try {
        const response = await createPurchaseOrder(payload);
        console.log("Draft order created successfully:", response);
  
        if (response.result.status_code === 200) {
          setSelectedPaymentType("draft")
          getSessionList("",selectedPaymentType, 0)
          setLoading(false)
          // setProductList([]);
          // setIsDraft(false);
          // setVendor(null);
          // setBillDate(null);
          // setBillNumber("");
          // setVatNum("");
          // setSelectedPaymentTerm(null);
        }else if (response.result.status_code === 400){
          setLoading(false)
        }
      } catch (error) {
        console.error("Failed to create purchase order:", error);
        setLoading(false)
      }
    };
  return (
    <>
      {loading && <LoadingSpinner />}
      <div className="flex">
        <div className="flex flex-col bg-[#F6F7F9] min-h-[93vh]  flex-1 mt-14">
          <Navbar heading="Purchase" />
          <div className="mr-4 ml-3 font-extrabold">
            <Breadcrumb
              separator={<DoubleRightOutlined />}
              items={[
                {
                  title: t("Purchase"),
                  key: "purchase",
                  children: (
                    <Link to={routes.purchase.purchaseList}>
                      {t("purchaseList")}
                    </Link>
                  ),
                },
                { title: t("Purchase History") },
              ]}
              className="relative mt-3"
            />
            <div className="flex justify-between items-end mt-2">
              <SearchBox
                placeholder={t("Search Supplier/Order No")}
                searchResult={handleSearchChange}
              />
              <div className="flex gap-x-4 items-end justify-end ">
                <SelectBox
                  style={{ width: 190 }}
                  width={200}
                  placeholder={t("all_orders")}
                  name="filter"
                  onChange={handlePaymentTypeChange}
                  options={options}
                  value={selectedPaymentType}
                />
              </div>
            </div>
            <>
              <div className="flex justify-between items-center"></div>
              {noOrdersFound ? (
                <p className="text-center text-red-500 mt-20">
                  {t("No Orders Found")}
                </p>
              ) : (
                <div className="grid grid-cols-2 gap-x-8 mt-1 mb-4">
                  {data.map((val, i) => (
                    <div
                      key={i}
                      className="w-[345px] md:w-full h-30 shadow-sm hover:shadow-lg rounded-xl bg-[#FFFFFF] mt-4 pb-2 cursor-pointer"
                      onClick={() => handleBoxClick(val)}
                    >
                      {console.log("val", val)}
                      <div className="flex flex-col justify-between ms-4 me-4 mt-4">
                        <div className="flex flex-row justify-between font-medium text-lg text-[#272728]">
                          <span className="font-semibold">
                            {val?.order_details?.partner_name}
                          </span>
                          <span
                            className={`font-semibold text-sm justify-end rounded-lg ${
                              val?.order_details?.payment_status === "partial"
                                ? "bg-yellow-400 text-white" // Partial Payment
                                : val?.order_details?.payment_status ===
                                  "not_paid"
                                ? "bg-yellow-400 text-white" // Not Paid
                                : val?.order_details?.payment_status === "paid"
                                ? "bg-green-400 text-white" // Full Payment
                                : "text-white" // Default case
                            }`}
                            style={{
                              display: "inline-block",
                              padding: "0.5rem 1rem",
                            }}
                          >
                            {t(
                              formatSessionState(
                                t(val?.order_details?.payment_status)
                              )
                            )}
                            {/* {console.log("ANANA", formatSessionState(
                                (val?.order_details?.payment_status)
                              ))} */}
                          </span>
                          {val.order_details.status === "draft" && (
                            <DeleteOutlined
                              className="justify-end w-10 h-8 text-red cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent the click from triggering the onClick of the parent div
                                handleDelete(val); // Call the delete function with the bill ID
                              }}
                            />
                          )}
                        </div>

                        <div className="">
                          {val.order_details?.order_no ? (
                            <div>
                            <p className="flex flex-row font-semibold text-sm">
                              {t("Order No.")}:
                              <span className="font-normal ms-2">
                                {val.order_details.order_no}
                              </span>
                            </p>
                             <p className="flex flex-row font-semibold text-sm">
                             {t("Bill No.")}:
                             <span className="font-normal ms-2">
                               {val.order_details.venfor_ref || "--"}
                             </span>
                           </p>
                           </div>
                          ) : (
                            <p className="font-semibold text-gray-500 text-sm">
                              {t("Draft Order")}
                            </p>
                          )}
                          <p className="flex flex-row font-semibold text-sm">
                            {t("Order Date")}:{" "}
                            <p className="font-normal ms-2">
                              {val.order_details?.order_date
                                ? moment(val.order_details?.order_date).format(
                                    "YYYY-MM-DD"
                                  )
                                : "N/A"}
                            </p>
                          </p>
                          {val.order_details?.payment_term && (
                            <p className="flex flex-row font-semibold text-sm">
                              {t("Payment Term")}:
                              <span className="font-normal ms-2">
                                {val.order_details.payment_term}
                              </span>
                            </p>
                          )}
                        </div>

                        <>
                          <div className="grid grid-cols-2 gap-4 mt-4 mx-4">
                            <div className="bg-[#F6F6F6] p-2 rounded-lg text-center">
                              <p className="font-normal text-sm">
                                {t("Quantity")}
                              </p>
                              {/* <p className="text-sm">{val.lines.length || 0}</p> */}
                              <p className="text-sm">
                                {val.lines
                                  .reduce(
                                    (sum, line) => sum + line.product_qty,
                                    0
                                  )
                                  }
                              </p>
                            </div>
                            <div className="bg-[#F6F6F6] p-2 rounded-lg text-center">
                              <p className="font-normal text-sm">
                                {t("Invoice Value")}
                              </p>
                              <p className="text-sm">
                                {val.order_details.total_amount.toFixed(2) || 0}
                              </p>
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {data.length != 1 ||
                (!noOrdersFound && (
                  <div className="flex justify-between mt-4 mb-4">
                    <button
                      onClick={() => {
                        if (currentPage > 1) {
                          setCurrentPage(currentPage - 1);
                          getSessionList(
                            searchKeyword,
                            selectedPaymentType,
                            currentPage - 1
                          );
                        }
                      }}
                      className={`bg-primaryBlue rounded-lg w-24 h-10 ${
                        currentPage === 1
                          ? "opacity-50 cursor-not-allowed"
                          : "text-white"
                      }`}
                      disabled={currentPage === 1}
                    >
                      <p className="text-white">{t("Previous")}</p>
                    </button>
                    <span>{t("Page")} {currentPage}</span>
                    <button
                      onClick={() => {
                        if (fetchedOrdersCount === 10) {
                          setCurrentPage(currentPage + 1);
                          getSessionList(
                            searchKeyword,
                            selectedPaymentType,
                            currentPage + 1
                          );
                        }
                      }}
                      className={`bg-primaryBlue rounded-lg w-16 ${
                        fetchedOrdersCount < 10
                          ? "opacity-50 cursor-not-allowed"
                          : "text-white"
                      }`}
                      disabled={fetchedOrdersCount < 10}
                    >
                      <p className="text-white">{t("Next")}</p>
                    </button>
                  </div>
                ))}
            </>
          </div>
        </div>
      </div>
      {isModalVisible && (
        <PurchasePayment
          isOpen={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          bill_id={selectedBillId}
          due_amount={selectedDueAmount}
          Popup={() => {
            setpopup(true);
          }}
        />
      )}

      <Drawer
        title={t("Payment History")}
        placement="right"
        width={500}
        onClose={onClose}
        visible={visible}
      >
        {/* Display the session history data */}
        {loading ? (
          <LoadingSpinner/>
          // <div>{t("Loading session data...")}</div>
        ) : (
          <div>
            {paymentHistoryData.length > 0 ? (
              <>
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="p-2 text-left">{t("Payment Date")}</th>
                      <th className="p-2 text-left">{t("Type")}</th>
                      <th className="p-2 text-left">{t("Amount")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentHistoryData.map((payment, index) => (
                      <tr key={index} className="border-b">
                        <td className="p-2">
                          {moment(payment.payment_date).format("YYYY-MM-DD")}
                        </td>
                        <td className="p-2">{payment.type}</td>
                        <td className="p-2">{payment.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <p>{t("No payment history available")}</p>
            )}
          </div>
        )}
      </Drawer>
    </>
  );
};

export default PurchaseList;
