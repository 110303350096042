import React, { useState } from "react";
import { useFormik } from "formik";
import { InputBox } from "../../components/CustomWidgets/InputBox";
import { ForgetPasswordSchema } from "../../components/validationSchema";
// import { useModalAction } from "../../modals/modal.context";
import { useDispatch } from "react-redux";
import { recreateUser } from "../../components/Actions/forgototpgenerator";
import { useTranslation } from "react-i18next";

const PhoneOrEmail = ({
  onDatasubmit,
  Loading,
  showNotification,
  changeerror,
}) => {
  const {t,i18n}=useTranslation()
  // const dispatch = useDispatch();
  // const { openModal, closeModal } = useModalAction();

  // const handleSubmit = async (values) => {
  //   openModal("FORGOT_VIEW");
  //   // setOTPModalOpen(true);
  // };

  const formik = useFormik({
    initialValues: { mobile: "" },
    //validationSchema: ForgetPasswordSchema,
    onSubmit: (values) => {
      onDatasubmit("Send Otp", values);
    },
  });

  const handleInputChange = (e) => {
    
      
      e.target.value < 9999999999 && formik.setFieldValue("mobile", e.target.value);
     
  
    

    changeerror(); // Call the parent onChange function to update formik state
  };

  return (
    <div className="  w-[75%] mx-auto">
      <h1 className="text-lg font-bold text-black text-center mb-1">
        {t('forgot_password_heading1')}
      </h1>
      <p className="text-center text-sm font-normal text-[#5C5C5C] mb-6">
      {t('forgot_password_note').split('<br/>').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
     
      </p>
      <form onSubmit={formik.handleSubmit}>
        <div className=" mb-6">
          <InputBox
            type="number"
            maxlength={11}
            label={t('Mobile Number')}
            name="mobile"
            placeholder={t('Enter mobile number')}
            onChange={handleInputChange}
            onBlur={formik.handleBlur}
            value={formik.values.mobile}
            error={formik.errors.mobile || showNotification}
            touched={formik.touched.mobile || showNotification}
          />
          { showNotification ? (
            <div className=" text-sm mt-2 text-red justify-center">
             {t('provide_registered_number')}
            </div>
          ) : null}
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 mt-4 text-white bg-primaryBlue rounded-lg hover:bg-primaryBlue font-semibold focus:outline-none"
          Loading={Loading}
        >
           {t('send')}
        </button>
      </form>
    </div>
  );
};

export default PhoneOrEmail;
