import React, { useEffect, useState } from "react";
import { InputBox, Password } from "../../components/CustomWidgets/InputBox";
import { useFormik } from "formik";
import SelectBox from "../CustomWidgets/SelectBox";
import gallery from "../../assets/Images/gallery.svg";
import { Image, Input } from "antd";
import UploadMediaonly from "../upload-imageonly";
import {
  createStoreUser,
  updateStoreUser,
  UserImageUpload,
} from "../../API  Functions/UserMangement";
import "../../styles.css";
import UsermanagementPopup from "../../Popups/usermanagementPopup";
import { useSelector } from "react-redux";
import { addUserSchema } from "../validationSchema";
import { useTranslation } from "react-i18next";
import image from "../../assets/Images/roundimage.png";

const staticUserTypes = [
  { id: 1, name: "Online Sale" },
  { id: 2, name: "Online POS" },
];

const AddUser = ({ onClose, isEdit }) => {
  const { t, i18n } = useTranslation();

  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);

  const [file, setFile] = useState();
  const [userTypes, setuserTypes] = useState(staticUserTypes); 
  const [showUpdatedProfile, setshowUpdatedProfile] = useState(false);
  const [popup, setpopup] = useState(false);
  const [message, setmessage] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [base64Images, setBase64Images] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);


  const [Error, setError] = useState(0);


  const formik = useFormik({
    initialValues: {
      password: isEdit?.password ?? "",
      first_name: isEdit?.name ?? "",
      store_user_type_id:
        isEdit?.user_type === "online_sale"
          ? 1
          : isEdit?.user_type === "online_pos"
          ? 2
          : "", 
      email: isEdit?.email ?? "",
      url: isEdit?.image_url ?? "",
    },
    validationSchema: addUserSchema,
    onSubmit: async (values, { resetForm }) => {
      const apipayload = {
        user_id: user_id,
        retailer_user_id: isEdit?.retailer_user_id,
      };
    
      try {
        if (!isEdit) {
          // Handle user creation
          if (base64Images.length > 0) {
            formik.setFieldError("url", undefined);
          }
    
          const imageName = values.email.split("@")[0];
          const imageResponse = await UserImageUpload(base64Images, imageName);
          console.log("imageResponse", imageResponse);
    
          if (imageResponse) {
            formik.setFieldValue("url", imageResponse);
    
            const payload = {
              user_id: user_id,
              password: values.password,
              name: values.first_name,
              user_type: values.store_user_type_id === 1 ? "online_sale" : "online_pos",
              email: values.email.toLowerCase(),
              image_url: imageResponse,
            };
    
            const res = await createStoreUser (payload);
            // console.log("CREATE USER RES", res);
    
            if (res?.data?.result?.status === "User  Created Successfully") {
              setmessage("User  Created Successfully");
              setpopup(true);
              resetForm();
            } else if (res?.data?.result?.status_code === 400 && res?.data?.result?.status === "You can not create user.no of user limit is only 3") {
              setmessage("You can not create user, No. of user limit is only 3");
              setpopup(true);
              resetForm();
              // setError(1);
            }
          }
        } else {
          if (base64Images.length > 0) {
            const imageName = values.email.split("@")[0];
            const imageResponse = await UserImageUpload(base64Images, imageName);
            console.log("Image Response:", imageResponse);
            
            apipayload.image_url = imageResponse; 
          }
    
          if (formik.values.first_name !== isEdit?.name) {
            apipayload.name = values.first_name;
          }
          if (formik.values.password) {
            apipayload.password = values.password;
          }
          if (formik.values.email.toLowerCase() !== isEdit?.email.toLowerCase()) {
            apipayload.email = values.email.toLowerCase();
          }
          
          const res = await updateStoreUser (apipayload);
          console.log("Update Response:", res);
          
          if (res?.data?.result[0]?.status === "Retailer User Details Updated") {
            setmessage("User Updated Successfully");
            setpopup(true);
          }
        }
      } catch (error) {
        console.error("Error during submission:", error);
      }
    }
  });

  useEffect(() => {
    if (isEdit && userTypes) {
      const userType = userTypes.find((item) => isEdit.user_type === item.name);
      if (userType) {
        formik.setFieldValue("store_user_type_id", userType.id);
      }
    }
  }, [isEdit, userTypes]);

  // console.log("Data", formik.values);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className=" ">
          {/* {console.log("user type", formik.values.store_user_type_id)} */}
          <div className="flex justify-between items-baseline">
            <div className="font-semibold text-2xl mt-5">
              {isEdit ? t("edit_user") : t("add_user")}{" "}
            </div>
            <div className="flex gap-5 ">
              <button
                onClick={() => onClose()}
                className="w-36 h-10 bg-white text-primaryBlue font-semibold rounded-lg inline border border-primaryBlue"
              >
                {t("cancel")}
              </button>
              <button
                type="submit"
                // className="w-36 h-10 bg-primaryBlue text-white rounded-lg font-semibold"
                className={`w-36 h-10 rounded-lg font-semibold ${hasChanges ? "bg-primaryBlue text-white" : "bg-primaryBlue text-white opacity-50 cursor-not-allowed"}`}
                disabled={!hasChanges}
              >
                {t("Save")}
              </button>
            </div>
          </div>
          <div className="md:w-full min-h-auto bg-[#FFFFFF] border rounded-xl mt-5">
            <div className="w-full h-10 bg-[#C9ECF7] px-4  flex items-center font-medium text-base rounded-tr-xl rounded-tl-xl text-[#272728]">
              {t("basic_details")}
            </div>
            <div className="flex justify-center mt-4 pb-4 gap-x-8 px-6">
              <div
                className={`w-44 h-50 flex flex-col  sm:w-[20%] ${
                  formik.values?.url == ""
                    ? "rounded-lg border border-gray-900/25 bg-[#ffffff]"
                    : "rounded-full"
                } relative ${
                  formik.errors.url && formik.touched.url ? "border-red" : ""
                }`}
              >
                <label
                  for="file-upload"
                  class=" items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue  hover:text-primaryBlue"
                >

                  <div className={`flex h-10 items-center justify-center`}>
                    {isEdit ? (
                      formik.values.url ? (
                        <img
                          className="w-[8rem] h-[7rem] object-cover rounded-full mt-24"
                          src={formik.values.url}
                          width={100}
                          height={100}
                          alt="store user"
                        />
                      ) : (
                        base64Images.length > 0 ? (
                          <img
                            className="w-[8rem] h-[7rem] object-contain mt-24"
                            src={base64Images} 
                            width={60}
                            height={60}
                            alt="store user"
                          />
                        ) : (
                        <img
                          className="w-[8rem] h-[7rem] object-contain mt-24"
                          src={image} 
                          width={60}
                          height={60}
                          alt="store user"
                        />
                      )
                    )
                    ) : 
                    formik.values.url ? (
                      <img
                        className="w-[8rem] h-[7rem] object-cover rounded-full mt-24"
                        src={formik.values.url}
                        width={100}
                        height={100}
                        alt="store user"
                      />
                    ) : base64Images.length > 0 ? (
                      <img
                        className="w-[8rem] h-[7rem] object-cover rounded-full mt-24"
                        src={base64Images} 
                        width={60}
                        height={60}
                        alt="store user"
                      />
                    ) : (
                      <img
                        className="w-[8rem] h-[7rem] object-contain mt-24"
                        src={image}
                        width={60}
                        height={60}
                        alt="store user"
                      />
                    )}
                  </div>
                  <div className="mt-[5.9rem] bottom-0 mb-2">
                    <UploadMediaonly
                      foldername={"store_app_UAT"}
                      onfileAdd={`storeuserurl`}
                      // setBase64Images={setBase64Images}
                      setBase64Images={(images) => {
                        setBase64Images(images);
                        setHasChanges(true); 
                      }}
                      urlgenerated={(val) => {
                        formik.setFieldValue("url", val.url);
                      }}
                      imageurl={formik.values.url}
                    />
                  </div>
                </label>
              </div>
              <div className="font-normal text-sm text-[#5C5C5C] flex flex-col gap-6 sm:flex-row sm:gap-7 sm:items-baseline sm:w-[75%]">
                <div className="w-full flex flex-col gap-4 leading-[0px]">
                  <label style={{ marginBottom: -15, marginTop: 15 }}>
                    {t("Name")}
                  </label>
                  <InputBox
                    name="first_name"
                    placeholder={t("Enter Name")}
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setHasChanges(true);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.first_name}
                    touched={formik.touched.first_name}
                    error={formik.errors.first_name}
                  />

                  <div className="flex flex-col text-[#5C5C5C] gap-2">
                    <label>{t("permission")}</label>

                    <SelectBox
                      name="store_user_type_id"
                      placeholder={t("Select")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.store_user_type_id}
                      touched={formik.touched.store_user_type_id}
                      error={formik.errors.store_user_type_id}
                      options={[
                        { value: "", label: "Select" }, // Optional: Add a default option
                        ...userTypes?.map((item) => ({
                          value: item?.id,
                          label: item?.name,
                        })),
                      ]}
                      disabled={isEdit}
                    />
                  </div>
                </div>

                <div className="w-full flex flex-col gap-4 leading-[0px]">
                  <div>
                    <label className="">{t("email")}</label>
                    <InputBox
                      // style={{marginTop:}}
                      name="email"
                      placeholder={t("enter_email")}
                      // onChange={(val) => {
                      //   formik.handleChange(val);
                      //   setError(0);
                      // }}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setError(0);
                        setHasChanges(true);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      touched={formik.touched.email}
                      error={formik.errors.email}
                      autoComplete="new-email"
                    />
                    {Error == 1 && (
                      <div className="text-xs text-red">
                        {" "}
                        {t("Email already registered")}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col gap-2">
                    <label>{t("Password")}</label>
                    <Password
                      loc="0"
                      // label="Enter Password"
                      name="password"
                      placeholder={t("Enter Password")}
                      maxLength={20}
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setHasChanges(true);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      error={formik.errors.password}
                      touched={formik.touched.password}
                      autoComplete="new-email"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </form>
      {popup && (
        <UsermanagementPopup
          message={message}
          close={() => {
            setpopup(false);
            if (message === "User Updated Successfully") onClose();
          }}
        />
      )}
    </div>
  );
};

export default AddUser;
